import * as React from 'react';
import {observer} from 'mobx-react';
import {Heading} from '@youtoken/ui.primitives';
import {TitleTooltip} from './TitleTooltip';
import {getBundlesSurfaceTitle} from './utils';

export const BundlesSurfaceTitleWeb: React.FC = observer(() => {
  return (
    <>
      <Heading variant="$heading-01-responsive" mb={30}>
        {getBundlesSurfaceTitle()}
        <TitleTooltip />
      </Heading>
    </>
  );
});
