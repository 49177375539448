import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {ModalDynamic} from '@youtoken/ui.modal';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Link} from '@youtoken/ui.elements';
import {LoyaltyVideoPreview} from '@youtoken/ui.video-preview';

type AccountLevelsHowItWorksModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AccountLevelsHowItWorksModal: React.FC<AccountLevelsHowItWorksModalProps> =
  observer(({isOpen, onClose}) => {
    const {t} = useTranslation();

    return (
      <ModalDynamic
        testID="ACCOUNT_LEVELS_HOW_IT_WORKS_MODAL"
        isOpen={isOpen}
        onClose={onClose}
        snapPoints={['60%']}
        enableDynamicSizing={false}
        title={t('surface.loyalty.how_it_works_modal.title')}
        webWidth={550}
      >
        <Box p={24}>
          <Box justifyContent="center" alignItems="center">
            <LoyaltyVideoPreview />
          </Box>
          <Text variant="$body-01" color="$text-01" mt={24}>
            {t('surface.loyalty.how_it_works_modal.primary_text')}
          </Text>
          <Text variant="$body-01" color="$text-02" mt={24}>
            <Trans
              t={t}
              components={{
                Link: (
                  <Link url="https://help.youhodler.com/en/articles/7858285-how-trading-volume-is-calculated" />
                ),
              }}
              i18nKey="surface.loyalty.how_it_works_modal.secondary_text"
            />
          </Text>
        </Box>
      </ModalDynamic>
    );
  });
