import {useCallback} from 'react';
import {Platform} from 'react-native';
import {
  GoogleSignin,
  GoogleOneTapSignIn,
} from '@react-native-google-signin/google-signin';
import {getAnalyticsData} from '@youtoken/ui.utils';
import {__GLOBAL_RECAPTCHA__} from '@youtoken/ui.two-factor-auth-and-recaptcha';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {TRANSPORT} from '@youtoken/ui.transport';

export const useGoogleAuth = () => {
  const {requestToken} = __GLOBAL_RECAPTCHA__;

  const {handleSignInError} = AuthMeResource.use({});

  return useCallback(
    async (authToken: string) => {
      return Promise.all([requestToken('sign_in'), getAnalyticsData()])
        .then(([token, analytics]) => {
          return TRANSPORT.API.post(`/auth/google/signin`, {
            accessCode: authToken,
            token,
            analytics,
          });
        })
        .catch(handleSignInError);
    },
    [requestToken, getAnalyticsData]
  );
};

export const useGoogleAuthForNativePlatform = (clientId: string) => {
  return useCallback(() => {
    if (Platform.OS === 'ios') {
      return GoogleSignin.signIn();
    }
    return GoogleOneTapSignIn.presentExplicitSignIn({
      webClientId: clientId,
    });
  }, [clientId]);
};
