import * as React from 'react';
import {Animated} from 'react-native';
import {observer} from 'mobx-react';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {AnimatedCard} from '../components';
import {useCards} from '../hooks';
import {CARD_HEIGHTS, CARDS_GAP} from '../constants';

interface ExpandbleCardListProps {
  isExpanded: boolean;
}

export const ExpandableCardList: React.FC<ExpandbleCardListProps> = observer(
  ({isExpanded}) => {
    const {levels, currentLevel} = LoyaltyResource.use({});

    const cards = useCards(levels, currentLevel);

    const boxHeight = React.useMemo(
      () => cards.reduce((acc, card) => acc + card.height + CARDS_GAP, 0),
      [cards]
    );

    const animatedHeight = React.useRef(new Animated.Value(0)).current;

    const toggle = () => {
      if (isExpanded) {
        Animated.timing(animatedHeight, {
          toValue: boxHeight,
          duration: 300,
          useNativeDriver: false,
        }).start();
      } else {
        Animated.timing(animatedHeight, {
          toValue:
            CARD_HEIGHTS.NEXT + CARDS_GAP * (levels.length - currentLevel + 1),
          duration: 300,
          useNativeDriver: false,
        }).start();
      }
    };

    React.useEffect(() => {
      toggle();
    }, [isExpanded, cards, currentLevel]);

    return (
      <Animated.View style={{height: animatedHeight}}>
        {cards.map((card, i) => (
          <AnimatedCard
            key={i}
            level={levels[i]!}
            isExpanded={isExpanded}
            {...card}
          />
        ))}
      </Animated.View>
    );
  }
);
