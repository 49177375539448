import * as React from 'react';
import {smoothScroll} from '@youtoken/ui.utils';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {ClosedHODLsPaginationAndFilterFeature} from '../../features';
import {HODLsList} from '../../components';
import {ClosedFilter, ClosedPagination} from '../elements';
import {CLOSED_HEADER_ID} from '../index.meta';

export type PositionsClosedProps = {
  handleNavigate?: (param: {}) => void;
} & BoxProps;

export const PositionsClosedSurface: React.FC<PositionsClosedProps> = ({
  handleNavigate,
  ...boxProps
}) => {
  const {
    subscribeToUpdates,
    closedHODLs,
    closedTotal,
    totalPages,
    closedHodlsTickerFilters,
    applyFilters,
  } = ClosedHODLsPaginationAndFilterFeature.use({});

  const handlePageChange = React.useCallback(
    (pageNumber: number) => {
      handleNavigate?.({pageClosed: pageNumber});

      setTimeout(() => {
        smoothScroll(CLOSED_HEADER_ID);
      }, 500);
    },
    [handleNavigate]
  );

  const handleApplyFilters = ({
    selectedTickers,
  }: {
    selectedTickers: string[];
  }) => {
    applyFilters({selectedTickers});
    handleNavigate?.({filterClosed: selectedTickers, pageClosed: 1});
  };

  React.useEffect(subscribeToUpdates, []);
  React.useEffect(() => {
    smoothScroll(CLOSED_HEADER_ID);
  }, []);

  return (
    <Box {...boxProps}>
      {closedHodlsTickerFilters.length > 1 && (
        <ClosedFilter
          handleApplyFilters={handleApplyFilters}
          mx={{default: -20, tablet: 20, desktop: 0}}
          pb={{default: 0, desktop: 8}}
        />
      )}

      {closedTotal > 0 && <HODLsList hodlsList={closedHODLs} />}

      {totalPages > 1 && (
        <ClosedPagination
          handlePageChange={handlePageChange}
          px={{default: 20, desktop: 0}}
          my={24}
        />
      )}
    </Box>
  );
};
