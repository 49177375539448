import * as React from 'react';
import {observer} from 'mobx-react';
import {Clipboard} from '@youtoken/ui.utils';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {LoansListItemFeature, LoansListItemFeatureArgs} from '../../../state';
import {TableRow} from '../../HODLs';

export interface DetailedTableProps extends BoxProps {
  mode: LoansListItemFeatureArgs['mode'];
  id: LoansListItemFeatureArgs['id'];
  onPressCopy: (message: string, value: string) => void;
}

export const DetailedTable: React.FC<DetailedTableProps> = observer(
  ({mode, id, onPressCopy, ...rest}) => {
    const {t} = useTranslation();

    const loan = LoansListItemFeature.use({
      mode,
      id,
    });

    const createHandlePressCopy = React.useCallback(
      (message: string, value: string) => () => {
        Clipboard.setString(value);
        onPressCopy(message, value);
      },
      [onPressCopy]
    );

    if (!loan.id) {
      console.error(`Loan id=${id} not found`);
      return null;
    }

    return (
      <Box width="100%" {...rest}>
        <TableRow leftText={t('surface.loans.item.table.ltv')}>
          <Text variant="$body-02" color="$text-02">
            <Text variant="$body-02-high-accent" color="$text-01">
              {loan.LTV}
            </Text>{' '}
            %
          </Text>
        </TableRow>
        <TableRow
          leftText={t('surface.loans.item.table.pdl')}
          tooltipText={
            loan.mode !== 'turbo'
              ? t('surface.loans.item.table.pdl_hint')
              : undefined
          }
        >
          <Text variant="$body-02" color="$text-02">
            <Text variant="$body-02-high-accent" color="$text-01">
              {loan.marginCall}
            </Text>{' '}
            {loan.borrowedTicker!.toUpperCase()}
          </Text>
        </TableRow>
        <TableRow leftText={t('surface.loans.item.table.borrowed')}>
          <Text variant="$body-02" color="$text-02">
            <Text variant="$body-02-high-accent" color="$text-01">
              {loan.borrowedAmount}
            </Text>{' '}
            {loan.borrowedTicker!.toUpperCase()}
          </Text>
        </TableRow>
        <TableRow leftText={t('surface.loans.item.table.apr')}>
          <Text variant="$body-02" color="$text-02">
            <Text variant="$body-02-high-accent" color="$text-01">
              {loan.APR}
            </Text>{' '}
            %
          </Text>
        </TableRow>
        <TableRow
          leftText={t('surface.loans.item.table.term')}
          tooltipText={t('surface.loans.item.table.term_hint', {
            days: loan.days,
          })}
        >
          <Text variant="$body-02" color="$text-02">
            <Trans
              t={t}
              i18nKey="surface.loans.item.table.term_value"
              components={{
                Strong: (
                  <Text variant="$body-02-high-accent" color="$text-01" />
                ),
              }}
              values={{days: loan.days}}
            />
          </Text>
        </TableRow>
        <TableRow
          leftText={t(
            loan.isClosed
              ? 'surface.loans.item.table.closed'
              : 'surface.loans.item.table.repay_until'
          )}
        >
          <Text variant="$body-02" color="$text-02">
            {loan.repayUntil}
          </Text>
        </TableRow>
        <TableRow leftText={t('surface.loans.item.table.id')}>
          <TouchableBox
            onPress={createHandlePressCopy(
              t('surface.loans.item.table.id_copied'),
              loan.id
            )}
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Text variant="$body-02-high-accent" color="$interactive-01">
              {loan.id.substr(0, 8)}
            </Text>
            <Icon name="copy" color="$interactive-01" width={20} height={20} />
          </TouchableBox>
        </TableRow>
      </Box>
    );
  }
);
