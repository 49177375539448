import * as React from 'react';
import {observer} from 'mobx-react';
import {type TKey} from '@youtoken/ui.service-i18n';
import {type BoxProps} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {ConversionFormClass, ExchangeFormState} from '../../../state';
import {ExchangeFormConversionPairField} from '../Field';
import {ExchangeFormConversionPairSourceBalance} from './Balance';

interface ExchangeFormConversionPairSourceProps {
  showBalance?: boolean;
  labelKey?: TKey;
  initialAmount?: number;
}

export const ExchangeFormConversionPairSource: React.FC<
  ExchangeFormConversionPairSourceProps & BoxProps
> = observer(
  ({
    showBalance = true,
    labelKey = 'surface.wallets.conversion_form.from',
    initialAmount,
    ...boxProps
  }) => {
    const {
      form: {ticker, amount, hasSourceError, sourceError, setTicker, setAmount},
      view: {wallets},
    } = useForm<ConversionFormClass | ExchangeFormState>();

    return (
      <ExchangeFormConversionPairField
        labelKey={labelKey}
        labelPlugins={
          showBalance && <ExchangeFormConversionPairSourceBalance />
        }
        wallets={wallets}
        ticker={ticker}
        amount={amount}
        hasError={hasSourceError}
        error={sourceError}
        onChangeTicker={setTicker}
        onChangeAmount={setAmount}
        zIndex={3}
        {...boxProps}
      />
    );
  }
);
