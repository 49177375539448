import * as React from 'react';
import {observer} from 'mobx-react';
import {BundleTariffTickersListItem} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {ScrollableArea} from '@youtoken/ui.elements';
import {type LogoColoredIconName, LogoColored} from '@youtoken/ui.icons';

interface BundleTickersScrollableAreaProps {
  items: BundleTariffTickersListItem[];
}

export const BundleTickersScrollableArea: React.FC<
  BundleTickersScrollableAreaProps & BoxProps
> = ({items, ...boxProps}) => {
  const renderItem = React.useCallback(
    ({item, index}: {item: BundleTariffTickersListItem; index: number}) => {
      return (
        <BundleTickersScrollableAreaItem
          item={item}
          mr={index < items.length - 1 ? 12 : 0}
        />
      );
    },
    []
  );

  return (
    <ScrollableArea
      isExpandRestricted
      items={items}
      renderItem={renderItem}
      mobileEstimatedListHeight={64}
      {...boxProps}
    />
  );
};

interface BundleTickersScrollableAreaItemProps {
  item: BundleTariffTickersListItem;
}

const BundleTickersScrollableAreaItem: React.FC<
  BundleTickersScrollableAreaItemProps & BoxProps
> = observer(
  ({item: {ticker, tickerFormatted, percentFormatted}, ...boxProps}) => {
    return (
      <Box
        flexDirection="row"
        alignItems="center"
        width={110}
        height={64}
        px={20}
        py={16}
        gap={8}
        bg="$ui-background"
        borderColor="$ui-01"
        borderWidth={1}
        borderRadius={12}
        {...boxProps}
      >
        <LogoColored name={ticker as LogoColoredIconName} size={32} />
        <Box>
          <Text variant="$body-02-medium-accent" color="$text-01">
            {tickerFormatted}
          </Text>
          <Text variant="$body-02" color="$text-02">
            {percentFormatted}
          </Text>
        </Box>
      </Box>
    );
  }
);
