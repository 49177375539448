import * as React from 'react';
import {Platform} from 'react-native';
import axios, {type Canceler} from 'axios';
import {debounce} from 'lodash';
import Big from 'big.js';
import {
  action,
  autorun,
  comparer,
  computed,
  type IReactionDisposer,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import {computedFn, now} from 'mobx-utils';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import {stringify} from 'query-string';
import {deserialize} from 'serializr';
import PlatformPayment, {
  CANCELLED_STATUSES,
  CompleteStatus,
  type PaymentData,
} from '@youtoken/ui.platform-payment';
import * as yupPackage from '@youtoken/ui.yup';
import {
  getTranslatedValidationMessage,
  handleGeneralErrorTranslated,
  messages,
} from '@youtoken/ui.validation-messages';
import {
  normalizeAmount,
  normalizeAmountByTicker,
} from '@youtoken/ui.normalizers';
import {invariant, openBrowserAsync} from '@youtoken/ui.utils';
import {
  formatByTicker,
  formatPercent,
  formatPercentTillPrecision,
  toBig,
} from '@youtoken/ui.formatting-utils';
import {TRANSPORT} from '@youtoken/ui.transport';
import {
  handleFormFieldsErrors,
  handleFormSubmitError,
} from '@youtoken/ui.form-utils';
import {i18n} from '@youtoken/ui.service-i18n';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {calculateAll, calculateAllReverse} from '@youtoken/converts-calculator';
import {Icon, LogoColored} from '@youtoken/ui.icons';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {__GLOBAL_RECAPTCHA__} from '@youtoken/ui.two-factor-auth-and-recaptcha';
import {
  type BuyCryptoFormAuthorizedStateBaseArgs,
  type BuyCryptoFormAuthorizedStateBaseResources,
  DepositMethodRampEnum,
  type DepositMethodType,
} from './types';
import {FeeDepositResponse} from '@youtoken/ui.resource-fee-deposit';
import {SENTRY} from '@youtoken/ui.sentry';
import {Logo} from '@youtoken/ui.elements';
import {Box} from '@youtoken/ui.primitives';
import {getFeeString} from '../../../../components/PaymentMethods/utils';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export const CURRENT_RATE_UPDATE_INTERVAL = 15;

export class FormBase<
  Args extends BuyCryptoFormAuthorizedStateBaseArgs = BuyCryptoFormAuthorizedStateBaseArgs,
  Resources extends BuyCryptoFormAuthorizedStateBaseResources = BuyCryptoFormAuthorizedStateBaseResources
> {
  checkUrl!: string;

  apiUrl!: string;

  get redirectUrls(): {successUrl: string; failUrl: string} {
    throw new Error(`BuyCryptoForm: "redirectUrls" was not set!`);
  }

  @observable
  args: Args;

  @observable
  resources: Resources;

  @observable
  instance: MobxReactForm;

  @observable
  disposers: IReactionDisposer[] = [];

  @observable
  side: 'from' | 'to' = 'from';

  // Form :  swap, swapEnable, swapOnPress

  //#region swap

  @observable
  swap: boolean = false;

  @computed
  get swapEnable() {
    return false;
  }

  @action
  swapOnPress = () => {
    // do nothing
  };

  //#endregion swap

  //#region balance

  @computed
  get useYHBalance() {
    return this.depositMethod === DepositMethodRampEnum.YH_BALANCE;
  }

  @computed
  get balance() {
    return (
      this.resources.walletsResource.getByTicker(this.ticker)?.amount ??
      toBig(0)
    );
  }

  @computed
  get hasBalance() {
    return this.balance.gt(0);
  }

  @computed
  get balancePercent() {
    if (this.hasBalance) {
      return Number(
        formatPercentTillPrecision(this.amountBig.div(this.balance), 0)
      );
    }

    return 0;
  }

  @action
  setAmountByBalance = () => {
    return this.setAmount(this.balance.toString());
  };

  @action
  setAmountByPercentOfBalance = (percent: number) => {
    const amountValue = this.balance.mul(percent).div(100);

    this.setAmount(formatByTicker(amountValue, this.ticker, 0));
  };

  //#endregion

  //#region source

  @computed({
    equals: comparer.shallow,
  })
  get youhodlerFiatTickers() {
    return this.resources.walletsResource.fiatTickers.filter(ticker => {
      return this.resources.exchangeTariffs.tickers.includes(ticker);
    });
  }

  @computed({
    equals: comparer.shallow,
  })
  get tickers() {
    // NOTE: only fiat tickers are allowed in on-ramp widget
    let fromTickers = ['eur', 'usd'];

    if (
      this.useYHBalance ||
      this.depositMethod === DepositMethodRampEnum.BANK_WIRE
    ) {
      fromTickers = this.youhodlerFiatTickers;
    }

    // NOTE: as toTicker can't be changed in on-ramp widget,
    // we should exclude fromTickers if they don't have tariff pair with set toTicker
    // we can skip this check after BE will start using session-id and filter tariffs by it
    return fromTickers.filter(fromTicker =>
      Boolean(
        this.resources.exchangeTariffs.getTariff(
          fromTicker,
          this.args.conversionTicker
        )
      )
    );
  }

  @computed
  get tickerInitial() {
    if (this.args.ticker && this.tickers.includes(this.args.ticker)) {
      return this.args.ticker;
    }

    return this.tickers[0] ?? '';
  }

  @computed
  get ticker() {
    return this.instance?.$('ticker').get('value') ?? this.tickerInitial;
  }

  @computed
  get amountInitial() {
    return this.args.amount ?? '';
  }

  @computed
  get amount() {
    return this.instance?.$('amount').get('value') ?? this.amountInitial;
  }

  @computed
  get amountBig() {
    return toBig(this.amount);
  }

  @computed
  get amountWithoutFee() {
    let res = this.amountBig.minus(this.fee);

    if (res.lt(0)) {
      return toBig(0);
    }

    return res;
  }

  @computed
  get cardId() {
    return this.instance?.$('cardId').get('value');
  }

  @computed
  public get cardsList() {
    return this.resources.cardsResource.cardsList ?? [];
  }

  @computed
  public get isCardsListEmpty() {
    return !this.cardsList.length;
  }

  @computed
  public get shouldHideFooter() {
    return (
      this.depositMethod === DepositMethodRampEnum.BANK_CARD_FRAME &&
      this.isCardsListEmpty
    );
  }

  @computed
  get sourceError() {
    return getTranslatedValidationMessage(
      this.instance?.$('ticker').get('error') ||
        this.instance?.$('amount').get('error')
    );
  }

  @computed
  get hasSourceError() {
    return Boolean(this.sourceError);
  }

  @action
  setTicker = (value: string) => {
    const onChange = this.instance.$('ticker').get('onChange');

    onChange(value);
  };

  @action
  setAmount = (value: string) => {
    const onChange = this.instance.$('amount').get('onChange');

    onChange(normalizeAmount(value));
  };

  @action
  public setCardId = (value: string) => {
    this.instance.$('cardId').set('value', value.toString());
  };

  getConversionAmount = computedFn(_amount => {
    if (!this.tariff || !this.rate || !this.ticker || !this.conversionTicker) {
      return null;
    }

    const amount = toBig(_amount);

    const {toAmount} = calculateAll(
      amount,
      this.tariff.fee,
      this.rate,
      this.ticker,
      this.conversionTicker
    );

    return toAmount;
  });

  getConversionAmountFormatted = computedFn(amount => {
    if (amount === '') {
      return '';
    }

    const conversionAmount = this.getConversionAmount(amount);

    if (conversionAmount === null) {
      return '';
    }

    return conversionAmount.gt(0)
      ? formatByTicker(conversionAmount, this.conversionTicker, 0)
      : '0';
  });

  @observable
  rateTickerUsd: Big | undefined;

  @computed
  get tickerPrecision() {
    return getCoinDecimalPrecision(this.ticker);
  }

  @computed
  get conversionTickerPrecision() {
    return this.tariff.precision;
  }

  @computed
  get minAmount() {
    const tariffFee = toBig(this.tariff.min);

    if (
      this.depositMethod === DepositMethodRampEnum.VOLET &&
      this.voletFee?.minAmount &&
      tariffFee.lt(this.voletFee.minAmount)
    ) {
      return this.voletFee.minAmount;
    }

    if (
      this.depositMethod === DepositMethodRampEnum.BANK_CARD &&
      this.bankCardFee?.minAmount &&
      tariffFee.lt(this.bankCardFee.minAmount)
    ) {
      return this.bankCardFee.minAmount;
    }

    return tariffFee;
  }

  @computed
  get maxAmount() {
    const tariffFee = toBig(this.tariff.max);

    if (
      this.depositMethod === DepositMethodRampEnum.VOLET &&
      this.voletFee?.maxAmount &&
      tariffFee.gt(this.voletFee.maxAmount)
    ) {
      return this.voletFee.maxAmount;
    }

    if (
      this.depositMethod === DepositMethodRampEnum.BANK_CARD &&
      this.bankCardFee?.maxAmount &&
      tariffFee.gt(this.bankCardFee.maxAmount)
    ) {
      return this.bankCardFee.maxAmount;
    }

    return tariffFee;
  }

  //#endregion source

  //#region target

  @computed
  get conversionTickerInitial() {
    return this.args.conversionTicker;
  }

  @computed
  get conversionTicker() {
    return (
      this.instance?.$('conversionTicker').get('value') ??
      this.conversionTickerInitial
    );
  }

  @computed
  get conversionAmount() {
    return this.instance?.$('conversionAmount').get('value');
  }

  @computed
  get targetError() {
    return getTranslatedValidationMessage(
      this.instance?.$('conversionTicker').get('error') ||
        this.instance?.$('conversionAmount').get('error')
    );
  }

  @computed
  get hasTargetError() {
    return Boolean(this.targetError);
  }

  @action
  setConversionAmount = (value: string) => {
    const onChange = this.instance.$('conversionAmount').get('onChange');

    onChange(normalizeAmountByTicker(value, this.conversionTicker));
  };

  getAmount = computedFn(_requiredConversionAmount => {
    if (!this.rate || !this.ticker || !this.conversionTicker) {
      return null;
    }

    const conversionAmountRequired = toBig(_requiredConversionAmount);

    const {fromAmount} = calculateAllReverse(
      conversionAmountRequired,
      this.tariff.fee,
      this.rate,
      this.ticker
    );

    return fromAmount;
  });

  getAmountFormatted = computedFn(conversionAmount => {
    if (conversionAmount === '') {
      return '';
    }

    const amount = this.getAmount(conversionAmount);

    if (amount === null) {
      return '';
    }

    return amount.gt(0) ? amount.toString() : '0';
  });

  //#endregion target

  //#region pay with

  @computed get depositMethodInitial() {
    return this.args.paymentMethod;
  }

  @computed
  get depositMethod() {
    return (
      this.instance?.$('depositMethod').get('value') ??
      this.depositMethodInitial
    );
  }

  @computed
  get changeDepositMethod() {
    return this.instance?.$('depositMethod').get('onChange');
  }

  @computed
  get depositMethodType() {
    return this.depositMethodsData[this.depositMethod]?.type;
  }

  // NOTE: for positive first render we suppose that cards are available
  @observable checkoutBankCardFeeData = {enabled: true};

  @computed
  get checkoutBankCardFee() {
    return deserialize(FeeDepositResponse, this.checkoutBankCardFeeData);
  }

  // NOTE: checkoutBankCardFee data adapts to currency,
  // example: after adding 'usd' in checkout will return enabled = true for 'usd'
  @computed
  get isCheckoutBankCardFeeAvailable() {
    return Boolean(this.checkoutBankCardFee?.enabled);
  }

  @computed
  get checkoutBankCardFeePercentFormatted() {
    return this.isCheckoutBankCardFeeAvailable
      ? formatPercent(this.checkoutBankCardFee!.percent)
      : null;
  }

  @computed
  get allowCheckoutBankCard() {
    return (
      this.resources.authMeResource.products.depositFiatCheckoutBankCard
        .available && this.isCheckoutBankCardFeeAvailable
    );
  }

  @computed
  get allowCheckoutBankCardFrame() {
    const {
      depositFiatCheckoutBankCardFrame: {isEnabled},
    } = this.resources.authMeResource.products;

    return isEnabled && this.isCheckoutBankCardFeeAvailable;
  }

  @computed
  get allowApplePay() {
    if (Platform.OS === 'android') {
      return false;
    }

    return (
      this.checkoutBankCardFee &&
      this.allowCheckoutBankCardFrame &&
      PlatformPayment.canMakePayments()
    );
  }

  @computed
  get allowVolet() {
    return (
      this.resources.authMeResource.products.depositFiatAdvcash.available &&
      ['usd', 'eur'].includes(this.ticker)
    );
  }

  @computed
  get allowCoDi() {
    return this.resources.authMeResource.products.depositFiatUnlimintCoDi
      .available;
  }

  @computed
  get allowSpei() {
    return this.resources.authMeResource.products.depositFiatUnlimintSPEI
      .available;
  }

  @computed
  get allowUseYHBalance() {
    return this.youhodlerFiatTickers.includes(this.ticker);
  }

  @computed
  get allowBankWire() {
    return this.resources.authMeResource.products.depositFiatWire.available;
  }

  @computed
  get voletFee() {
    return this.resources.fees.getFee(
      'deposit',
      'advcash',
      this.ticker,
      this.ticker
    );
  }

  bankCardFeeProvider = 'checkoutBankCard';

  @computed
  get bankCardFee() {
    return this.resources.fees.getFee(
      'deposit',
      this.bankCardFeeProvider,
      this.ticker,
      this.ticker
    );
  }

  @computed
  get bankWireFees() {
    return this.resources.fees.data.filter(
      fee =>
        fee.ticker === this.ticker &&
        fee.conversionTicker === this.ticker &&
        fee.method === 'deposit' &&
        ['swift', 'sepa', 'wire'].includes(fee.provider)
    );
  }

  @computed
  get bankWireFeeFormatted() {
    return getFeeString(this.bankWireFees, this.ticker, i18n.t);
  }

  @computed
  get possibleDepositMethods(): DepositMethodRampEnum[] {
    throw new Error(`BuyCryptoForm: "possibleDepositMethods" was not set!`);
  }

  @computed
  get possibleInstantDepositMethods() {
    return this.possibleDepositMethods.filter(
      method => this.depositMethodsData[method]?.type === 'instant'
    );
  }

  @computed
  get possibleLaterDepositMethods() {
    return this.possibleDepositMethods.filter(
      method => this.depositMethodsData[method]?.type === 'later'
    );
  }

  @computed
  get depositMethodsData(): {
    [method: string]: {
      type: DepositMethodType;
      title: string;
      fee: string;
      description?: string;
      icon: React.ReactNode;
      iconName: string;
      testID: string;
      tags?: string[];
    };
  } {
    const bankCardTags = ['apple_pay', 'google_pay'];

    // NOTE: If checkout Frame is available, visa and mc payments isn't available in checkoutExternal option
    if (!this.allowCheckoutBankCardFrame) {
      bankCardTags.push('visa', 'mastercard');
    }

    return {
      [DepositMethodRampEnum.YH_BALANCE]: {
        type: 'instant',
        title: i18n.t(
          'ramp.conversion_form.fiat_deposit.item_yh_balance_title'
        ),
        fee: i18n.t('surface.wallets.fiat_deposit.item_fee_percent', {
          feePercent: 0,
        }),
        description: i18n.t(
          'ramp.conversion_form.fiat_deposit.item_yh_balance_description',
          {balance: this.balance}
        ),
        icon: (
          <Box height={24}>
            <Logo hideTextPermanently />
          </Box>
        ),
        iconName: 'yh_balance',
        testID: 'RAMP_DEPOSIT_METHOD_YH_BALANCE',
      },
      [DepositMethodRampEnum.BANK_CARD]: {
        type: 'instant',
        title: i18n.t(
          this.allowCheckoutBankCardFrame
            ? 'surface.wallets.fiat_deposit.item_apple_or_google_title'
            : 'surface.wallets.fiat_deposit.item_card_title'
        ),
        fee: i18n.t('surface.wallets.fiat_deposit.item_fee_percent', {
          feePercent: this.checkoutBankCardFeePercentFormatted,
        }),
        icon: <Icon name="card" color="$text-05" />,
        iconName: 'card',
        testID: this.allowCheckoutBankCardFrame
          ? 'FIAT_DEPOSIT_METHOD_APPLE_OR_GOOGLE_PAY'
          : 'FIAT_DEPOSIT_METHOD_BANK_CARD',
        tags: bankCardTags,
      },
      [DepositMethodRampEnum.BANK_CARD_FRAME]: {
        type: 'instant',
        title: i18n.t('surface.wallets.fiat_deposit.item_card_title'),
        fee: i18n.t('surface.wallets.fiat_deposit.item_fee_percent', {
          feePercent: this.checkoutBankCardFeePercentFormatted,
        }),
        icon: <Icon name="card" color="$text-05" />,
        iconName: 'card',
        testID: 'FIAT_DEPOSIT_METHOD_BANK_CARD_FRAME',
        tags: ['visa', 'mastercard'],
      },
      [DepositMethodRampEnum.APPLE_PAY]: {
        type: 'instant',
        title: i18n.t(
          'web_app.wallets.fiat_deposit.item_apple_pay_title.default'
        ),
        description: i18n.t(
          'web_app.wallets.fiat_deposit.item_apple_pay_duration'
        ),
        icon: <Icon name="os_mac" color="$text-05" />,
        iconName: 'os_mac',
        fee: i18n.t('surface.wallets.fiat_deposit.item_fee_percent', {
          feePercent: this.checkoutBankCardFeePercentFormatted,
        }),
        testID: 'FIAT_DEPOSIT_METHOD_APPLE_PAY',
      },
      [DepositMethodRampEnum.VOLET]: {
        type: 'instant',
        title: i18n.t('surface.wallets.fiat_deposit.item_advcash_title'),
        fee: i18n.t('surface.wallets.fiat_deposit.item_advcash_fee'),
        description: i18n.t(
          'surface.wallets.fiat_deposit.item_advcash_duration'
        ),
        icon: <LogoColored name="volet" />,
        iconName: 'volet',
        testID: 'FIAT_DEPOSIT_METHOD_ADVCASH',
      },
      [DepositMethodRampEnum.BANK_WIRE]: {
        type: 'later',
        title: i18n.t('surface.wallets.fiat_deposit.item_bank_wire_title'),
        fee: this.bankWireFeeFormatted,
        description:
          this.ticker === 'eur'
            ? i18n.t('surface.wallets.fiat_deposit.item_bank_wire_duration.eur')
            : i18n.t(
                'surface.wallets.fiat_deposit.item_bank_wire_duration.other'
              ),
        icon: <Icon name="bank_wire" />,
        iconName: 'bank_wire',
        testID: 'FIAT_DEPOSIT_METHOD_BANK_WIRE',
      },
    };
  }

  //#endregion pay with

  //#region fee

  @computed
  get tariff() {
    const tariff = this.resources.exchangeTariffs.getTariff(
      this.ticker,
      this.conversionTicker
    );

    invariant(
      tariff,
      `BuyCryptoForm: tariff not found for tickers [${this.ticker}, ${this.conversionTicker}]`
    );

    return tariff;
  }

  @computed
  get tariffReverse() {
    return this.resources.exchangeTariffs.getTariff(
      this.conversionTicker,
      this.ticker
    );
  }

  @computed
  get fee() {
    if (this.side === 'from') {
      const {feeAmount} = calculateAll(
        this.amountBig,
        this.tariff.fee,
        this.rate,
        this.ticker,
        this.conversionTicker
      );

      return feeAmount;
    } else {
      const amount = toBig(this.conversionAmount);

      const {feeAmount} = calculateAllReverse(
        amount,
        this.tariff.fee,
        this.rate,
        this.ticker
      );

      return feeAmount;
    }
  }

  //#endregion fee

  //#region rate

  @observable
  rate!: Big;

  @observable
  rateIsFixed: boolean = true;

  @observable
  rateTimeLeft: number = CURRENT_RATE_UPDATE_INTERVAL;

  @observable
  rateTimeInterval: number = 0;

  @computed
  get rateTimeIntervalName() {
    return `rate-interval-${this.rateTimeInterval}`;
  }

  @computed
  get rateTimeIntervalProgress() {
    return (
      (CURRENT_RATE_UPDATE_INTERVAL - this.rateTimeLeft) /
      (CURRENT_RATE_UPDATE_INTERVAL - 1)
    );
  }

  @action
  updateRate = () => {
    this.rateTimeInterval = this.rateTimeInterval + 1;
    this.rate = toBig(
      this.resources.ratesResource.getExchangeRate(
        this.ticker,
        this.conversionTicker
      )
    );
    this.rateTimeLeft = CURRENT_RATE_UPDATE_INTERVAL;
  };

  //#endregion rate

  //#region check

  @observable
  checkIsLoading = false;

  @observable
  checkCanceller: Canceler | undefined;

  @computed
  get checkRateShowError() {
    return Boolean(!this.hasSourceError && this.instance.$submitted);
  }

  @action
  cancelCheck = () => {
    this.checkIsLoading = true;
    this.checkCanceller?.('__CANCELLED_REQUEST__');
  };

  @action
  check = () => {
    this.cancelCheck();

    const {token, cancel} = axios.CancelToken.source();

    this.checkCanceller = cancel;

    return TRANSPORT.API.post(
      this.checkUrl,
      {
        side: this.side,
        fromTicker: this.ticker,
        toTicker: this.conversionTicker,
        fromAmount: this.amount,
        toAmount: this.conversionAmount,
        rate: this.rate,
      },
      {
        cancelToken: token,
      }
    )
      .then(({data: {isFixedRate}}) => {
        runInAction(() => {
          this.rateIsFixed = isFixedRate;
        });
      })
      .catch(error => {
        if (this.checkRateShowError) {
          runInAction(() => {
            handleFormFieldsErrors(this.instance, error);
          });
        }
      })
      .finally(() => {
        runInAction(() => {
          this.checkIsLoading = false;
        });
      });
  };

  checkDebounced = debounce(this.check, 300);

  //#endregion check

  //#region 2fa
  @computed
  get operationId() {
    return this.instance.$('operationId').get('value');
  }

  @computed
  get operationIdOnChange() {
    return this.instance.$('operationId').get('onChange');
  }

  @computed
  get code() {
    return this.instance.$('code').get('value');
  }

  @computed
  get codeOnChange() {
    return this.instance.$('code').get('onChange');
  }

  @computed
  get codeError() {
    return getTranslatedValidationMessage(this.instance.$('code').get('error'));
  }

  @action
  createTwoFactorAuthOperation = <
    T extends {operationId: string; phoneMask?: string}
  >(): Promise<void | T> => {
    const {requestToken} = __GLOBAL_RECAPTCHA__;

    return requestToken('crypto_withdrawal_tfa')
      .then(token =>
        TRANSPORT.API.post('/v1/withdrawal/authorize', {
          operationId: this.operationId,
          token,
        })
      )
      .then(({data}) => {
        runInAction(() => {
          this.operationIdOnChange(data.operationId);
        });
        return data;
      })
      .catch(response => {
        handleGeneralErrorTranslated(response?.data);
      });
  };

  //#endregion 2fa

  @action
  dispose = () => {
    this.disposers.forEach(disposer => {
      disposer?.();
    });
  };

  submitWithYHBalance() {
    return TRANSPORT.API.post(this.apiUrl, {
      fromTicker: this.ticker,
      toTicker: this.conversionTicker,
      fromAmount: this.amount,
      rate: this.rate,
      toAmount: this.conversionAmount,
      provider: 'Youhodler', //Провайдер операции пополнения кошелька с банковской карты/банковского счета. Если provider == Youhodler, то это будет обозначением, что On-ramp процесс происходит с кошелька пользователя
      operationId: this.operationId, // 2fa
      code: this.code, // 2fa
    })
      .then(() => {
        LOCAL_NOTIFICATIONS.info({
          text: i18n.t('surface.wallets.crypto_withdrawal.message.success'),
        });
      })
      .catch(e => {
        handleFormSubmitError(this.instance, e);
      });
  }

  @computed
  get voletRedirectUrls(): {
    successUrl: string;
    failUrl: string;
  } {
    throw new Error(`BuyCryptoForm: "advCashRedirectUrls" was not set!`);
  }

  submitWithVolet(onSubmit?: BuyCryptoFormAuthorizedStateBaseArgs['onSubmit']) {
    return TRANSPORT.API.post(this.apiUrl, {
      fromTicker: this.ticker,
      toTicker: this.conversionTicker,
      fromAmount: this.amount,
      // BE expects 'advCash' in lowercase
      provider: 'advcash',
    })
      .then(({data: {providerResponse: payment}}) => {
        const {successUrl, failUrl} = this.voletRedirectUrls;

        const paymentData = {
          ...payment,
          ac_success_url_method: 'GET',
          ac_fail_url_method: 'GET',
          ac_success_url: ENVIRONMENT.WEB_APP_URL + successUrl,
          ac_fail_url: ENVIRONMENT.WEB_APP_URL + failUrl,
        };

        return openBrowserAsync(
          `https://wallet.advcash.com/sci/?${stringify(paymentData)}`
        ).then(() => onSubmit?.());
      })
      .catch(e => {
        return handleFormSubmitError(this.instance, e);
      });
  }

  @computed
  get bankCardsRedirectUrls(): {
    inprocessUrl?: string;
    successUrl: string;
    declineUrl: string;
    cancelUrl: string;
  } {
    throw new Error(`BuyCryptoForm: "bankCardsRedirectUrls" was not set!`);
  }

  submitWithBankCard(
    onSubmit?: BuyCryptoFormAuthorizedStateBaseArgs['onSubmit']
  ) {
    return TRANSPORT.API.post(this.apiUrl, {
      fromTicker: this.ticker,
      toTicker: this.conversionTicker,
      fromAmount: this.amount,
      provider: this.bankCardFeeProvider,
      ...(this.depositMethod === DepositMethodRampEnum.BANK_CARD_FRAME
        ? {cardId: this.cardId}
        : {}),
      providerData: {
        method:
          this.depositMethod === DepositMethodRampEnum.BANK_CARD_FRAME
            ? 'card'
            : 'paymentPage',
        redirectUrls: this.bankCardsRedirectUrls,
      },
    })
      .then(response => {
        const {
          data: {
            providerResponse: {redirect_url},
          },
        } = response;

        openBrowserAsync(redirect_url).then(() => onSubmit?.());
      })
      .catch(e => {
        return handleFormSubmitError(this.instance, e);
      });
  }

  submitWithApplePay(
    onSubmit?: BuyCryptoFormAuthorizedStateBaseArgs['onSubmit']
  ) {
    PlatformPayment.show({
      currencyCode: this.ticker.toUpperCase(),
      paymentSummaryItems: [
        {
          label: 'Deposit',
          amount: this.amountWithoutFee.toFixed(this.tickerPrecision, 0),
        },
        {
          label: 'Fee',
          amount: this.fee.toFixed(this.tickerPrecision, 0),
        },
        {
          label: 'YouHodler',
          amount: this.amountBig.toFixed(this.tickerPrecision, 0),
        },
      ],
    })
      .then((paymentData: PaymentData) => {
        return TRANSPORT.API.post(this.apiUrl, {
          fromTicker: this.ticker,
          toTicker: this.conversionTicker,
          fromAmount: this.amount,
          provider: this.bankCardFeeProvider,
          providerData: {method: 'applepay', tokenData: paymentData},
        })
          .then(async () => {
            await PlatformPayment.complete(CompleteStatus.success);
            // NOTE: small delay to be more sure, that transaction indeed appeared in the wallet item history
            await new Promise<void>(resolve => setTimeout(resolve, 2000));
            onSubmit?.();
            SHARED_ROUTER_SERVICE.navigate('WalletsItem', {
              ticker: this.conversionTicker,
            });
            return;
          })
          .catch(error => {
            PlatformPayment.complete(CompleteStatus.failure);

            try {
              handleFormSubmitError(this.instance, error);
            } catch (_) {}
            // NOTE: Catch error to prevent double error handling
          });
      })
      .catch((error: {message: string}) => {
        if (CANCELLED_STATUSES.some(status => status === error.message)) {
          return;
        }

        SENTRY.captureMessage('show Payment Request window error', {
          source: 'BuyCryptoForm, submitWithApplePay',
          extra: {
            errorDetails: error.message || error,
          },
        });

        LOCAL_NOTIFICATIONS.error({
          text: i18n.t('common.errors.smth_went_wrong'),
        });
      });
  }

  submit(onSubmit?: BuyCryptoFormAuthorizedStateBaseArgs['onSubmit']) {
    switch (this.depositMethod) {
      case DepositMethodRampEnum.YH_BALANCE: {
        return this.submitWithYHBalance();
      }
      case DepositMethodRampEnum.VOLET: {
        return this.submitWithVolet(onSubmit);
      }
      case DepositMethodRampEnum.BANK_CARD:
      case DepositMethodRampEnum.BANK_CARD_FRAME: {
        return this.submitWithBankCard(onSubmit);
      }
      case DepositMethodRampEnum.APPLE_PAY: {
        return this.submitWithApplePay(onSubmit);
      }
      case DepositMethodRampEnum.BANK_WIRE: {
        SHARED_ROUTER_SERVICE.navigate('FiatDepositBankWire', {
          ticker: this.ticker,
        });
        return;
      }
      default: {
        throw new Error(
          `${this.depositMethod} deposit method is not currently supported`
        );
      }
    }
  }

  constructor(args: Args, resources: Resources) {
    this.args = args;
    this.resources = resources;

    const fields = {
      ticker: {
        value: this.ticker,
      },
      conversionTicker: {
        value: this.conversionTicker,
      },
      amount: {
        value: this.amount,
        handlers: {
          onChange: () => {
            this.side = 'from';
          },
        },
      },
      conversionAmount: {
        handlers: {
          onChange: () => {
            this.side = 'to';
          },
        },
      },
      depositMethod: {
        value: this.depositMethod,
      },
      operationId: {
        value: '',
      },
      code: {
        value: '',
      },
      cardId: '',
    };
    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            const amountValidator = this.useYHBalance
              ? yup.big().gt(0).lte(this.balance, messages.FUNDS_INSUFFICIENT)
              : this.depositMethod === DepositMethodRampEnum.BANK_WIRE
              ? yup.big()
              : yup
                  .big()
                  .gte(this.minAmount, () =>
                    messages.MIN_AMOUNT({
                      value: this.minAmount?.toFixed(this.tickerPrecision),
                      ticker: this.ticker.toUpperCase(),
                    })
                  )
                  .lte(this.maxAmount, () =>
                    messages.MAX_AMOUNT({
                      value: this.maxAmount?.toFixed(this.tickerPrecision),
                      ticker: this.ticker.toUpperCase(),
                    })
                  );

            const conversionAmountValidator =
              this.depositMethod === DepositMethodRampEnum.BANK_WIRE
                ? yup.big()
                : yup.big().gt(0);

            const depositMethodValidator = yup.string().required();

            const codeValidator = this.useYHBalance
              ? yup.string().required()
              : yup.string();

            const operationIdValidator = this.useYHBalance
              ? yup.string().required()
              : yup.string();

            const cardIdValidator =
              this.depositMethod === DepositMethodRampEnum.BANK_CARD_FRAME
                ? yup.string().required(messages.REQUIRED)
                : yup.string();

            return yup.object().shape({
              ticker: yup.string().required(),
              conversionTicker: yup.string().required(),
              amount: amountValidator,
              conversionAmount: conversionAmountValidator,
              // useYHBalance: yup.boolean(),
              depositMethod: depositMethodValidator,
              code: codeValidator,
              operationId: operationIdValidator,
              cardId: cardIdValidator,
            });
          }),
      }),
    };

    const hooks = {
      onSuccess: () => {
        return this.submit();
      },
    };

    const options = {
      validateOnBlur: false,
      validateOnChange: false,
      validateOnChangeAfterSubmit: true,
    };

    this.instance = new MobxReactForm(
      {fields},
      {
        plugins,
        hooks,
        options,
      }
    );
    this.disposers = [
      // set rateTimeLeft
      reaction(
        () => now(),
        () => {
          if (this.rateTimeLeft > 0) {
            this.rateTimeLeft = this.rateTimeLeft - 1;
          } else {
            this.updateRate();
          }
        }
      ),
      // set amount / conversionAmount after fields changed
      autorun(() => {
        if (this.side === 'from') {
          this.instance
            .$('conversionAmount')
            .set(this.getConversionAmountFormatted(this.amount));
        } else {
          this.instance
            .$('amount')
            .set(this.getAmountFormatted(this.conversionAmount));
        }
      }),
      // NOTE: update fees data after ticker changed
      reaction(
        () => this.ticker,
        ticker => {
          TRANSPORT.API.get('/v1/fee/deposit', {
            params: {ticker, provider: this.bankCardFeeProvider},
          })
            .then(res => {
              runInAction(() => {
                this.checkoutBankCardFeeData = res.data;
              });
            })
            .catch(error => {
              SENTRY.capture(error, {source: 'RampWidgetFormDeposit'});
              this.checkoutBankCardFeeData = {enabled: false};
            });
        },
        {
          fireImmediately: true,
        }
      ),
      // NOTE: update depositMethod if current is not supported
      autorun(() => {
        if (!this.possibleDepositMethods.includes(this.depositMethod)) {
          this.instance.$('depositMethod').set(this.possibleDepositMethods[0]);
        }
      }),
      // set rate after fields changed
      reaction(() => [this.ticker, this.conversionTicker], this.updateRate, {
        fireImmediately: true,
      }),
      // check rate after fields changed
      reaction(
        () => [
          this.side,
          this.ticker,
          this.conversionTicker,
          this.amount,
          this.conversionAmount,
          this.rate,
        ],
        () => {
          this.cancelCheck();
          this.checkDebounced();
        },
        {
          fireImmediately: true,
        }
      ),
      // pre-select of card from cards list if it isn't empty
      reaction(
        () => this.cardsList,
        cardsList => {
          if (cardsList[0]) {
            this.setCardId(cardsList[0].id);
          }
        },
        {
          fireImmediately: true,
        }
      ),
      reaction(
        () => this.depositMethod,
        method => {
          if (method === DepositMethodRampEnum.APPLE_PAY) {
            PlatformPayment.init().catch(error => {
              SENTRY.captureMessage('init Payment Request error', {
                source: 'BuyCryptoForm, reaction depositMethod',
                extra: error,
              });

              LOCAL_NOTIFICATIONS.error({
                text: i18n.t(
                  'surface.wallets.crypto_deposit.warning.try_again'
                ),
              });
            });
          }
        },
        {
          fireImmediately: true,
        }
      ),
    ];
  }
}
