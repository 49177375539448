import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, TouchableBox} from '@youtoken/ui.primitives';
import {SectionHeaderBaseProps, SectionHeaderProps} from './types';
import {getSectionColor} from '../../utils';
import {WalletsFeature} from '../../state';
import {
  SectionHeaderBundles,
  SectionHeaderHidden,
  SectionHeaderOther,
} from './components';

export const SectionHeader: React.FC<SectionHeaderBaseProps & BoxProps> =
  observer(({section, ...boxProps}) => {
    const {
      sections,
      sectionsHighlights,
      sectionsVisibilities,
      setSectionVisibility,
    } = WalletsFeature.use({});

    const list = sections[section];

    const visibility = sectionsVisibilities[section];

    const highlight = sectionsHighlights[section];

    const handlePress = React.useCallback(() => {
      if (list.length > 0) {
        setSectionVisibility(section, !visibility);
      }
    }, [section, list, visibility, setSectionVisibility]);

    return (
      <TouchableBox
        testID={`WALLETS_SECTION_TOGGLER_${section.toUpperCase()}`}
        px={24}
        py={16}
        bg={getSectionColor(section, highlight)}
        onPress={handlePress}
        {...boxProps}
      >
        <SectionHeaderContent
          section={section}
          list={list}
          visibility={visibility}
          highlight={highlight}
        />
      </TouchableBox>
    );
  });

export const SectionHeaderContent: React.FC<SectionHeaderProps> = props => {
  if (props.section === 'bundles') {
    return <SectionHeaderBundles {...props} />;
  }

  if (props.section === 'hidden') {
    return <SectionHeaderHidden {...props} />;
  }

  return <SectionHeaderOther {...props} />;
};
