import * as React from 'react';
import {Platform} from 'react-native';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {IncentivesFeature} from '@youtoken/ui.resource-wallets';
import {cell} from '@youtoken/ui.cell';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {Button} from '@youtoken/ui.buttons';

interface TradingBonusesSurfaceProps {
  onDepositPress: () => void;
  onTradePress: () => void;
  onClose: () => void;
}

export const BonusExpirationSurface: React.FC<TradingBonusesSurfaceProps> =
  cell(({onDepositPress, onTradePress, onClose}) => {
    const {t} = useTranslation();
    const {incentivesBalanceFormatted, incentivesExpirationPeriodFormatted} =
      IncentivesFeature.use({});

    const handleOpenDepositWizard = React.useCallback(() => {
      DATA_LAYER.trackStrict('incentives-expiration-deposit-click', {
        source: 'BonusExpirationSurface',
      });

      onDepositPress();
      onClose();

      SHARED_ROUTER_SERVICE.navigate('DepositWizard', {});
    }, []);

    const handleOpenMultiHODL = React.useCallback(() => {
      DATA_LAYER.trackStrict('incentives-expiration-open-mh-click', {
        source: 'BonusExpirationSurface',
      });

      onTradePress();
      onClose();
      SHARED_ROUTER_SERVICE.navigate(
        'NewMultiHODL',
        {},
        {additionalInputAmount: incentivesBalanceFormatted}
      );
    }, [incentivesBalanceFormatted]);

    return (
      <Box
        flex={1}
        alignItems="center"
        px={24}
        pb={Platform.select({default: 0, web: 24})}
        pt={{default: 0, desktop: 8}}
      >
        <Box
          width="100%"
          alignItems="center"
          borderWidth={1}
          borderRadius={12}
          borderColor="$ui-01"
          p={16}
          mt={16}
        >
          <Heading variant="$heading-03" mb={4}>
            {t('surface.incentives.expiration.action.deposit')}
          </Heading>
          <Text variant="$body-02" color="$text-02" mb={16}>
            {t('surface.incentives.expiration.text.make_deposit')}
          </Text>
          <Button
            size="large"
            icon="fiat_deposit"
            onPress={handleOpenDepositWizard}
            alignSelf="stretch"
          >
            {t('surface.incentives.expiration.action.deposit')}
          </Button>
        </Box>

        <Box
          width="100%"
          alignItems="center"
          borderWidth={1}
          borderRadius={12}
          borderColor="$ui-01"
          p={16}
          mt={16}
        >
          <Heading variant="$heading-03" mb={4}>
            {t('surface.incentives.expiration.action.trade')}
          </Heading>
          <Text variant="$body-02" color="$text-02" mb={16}>
            {t('surface.incentives.expiration.text.trade')}
          </Text>
          <Button
            size="large"
            icon="hodl"
            onPress={handleOpenMultiHODL}
            alignSelf="stretch"
          >
            {t('surface.incentives.expiration.action.trade')}
          </Button>
        </Box>

        <Text variant="$body-02" color="$text-02" textAlign="center" mt={24}>
          {t('surface.incentives.expiration.text.notice', {
            period: incentivesExpirationPeriodFormatted,
          })}
        </Text>
      </Box>
    );
  });
