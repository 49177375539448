import * as React from 'react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {NewReferralActivity} from '@youtoken/ui.resource-new-referral';
import {ModalDynamic} from '@youtoken/ui.modal';
import {SingleFriendRewardModalContent} from './SingleFriendRewardModalContent';

type SingleFriendRewardModalProps = {
  isOpen: boolean;
  onClose: () => void;
  activity: NewReferralActivity | null;
};

export const SingleFriendRewardModal: React.FC<
  SingleFriendRewardModalProps & BoxProps
> = ({isOpen, onClose, activity}) => {
  return (
    <ModalDynamic isOpen={isOpen} onClose={onClose} webMinHeight={100}>
      {activity && (
        <SingleFriendRewardModalContent p={24} activity={activity} />
      )}
    </ModalDynamic>
  );
};
