import * as React from 'react';
import {observer} from 'mobx-react';
import {modal} from '@web-app/src/stores';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {
  AdjustTpSlSurface,
  TpSlInitialPrice,
} from '@youtoken/ui.surface-hodl-positions';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const modalHodlsAdjustTpSlName = 'hodlAdjustTpSl';

type ModalHodlsAdjustTpSlProps = {
  id: string;
} & Omit<ModalDynamicProps, 'children'>;

export const ModalHodlsAdjustTpSl = observer(
  ({id, ...props}: ModalHodlsAdjustTpSlProps) => {
    const {t} = useTranslation();

    return (
      <ModalDynamic
        title={t('surface.hodls.adjust_tp.title')}
        subtitle={<TpSlInitialPrice hodlId={id} />}
        webOverlayBackgroundColor="$transparent"
        shouldShowHeaderSeparator={false}
        px={{default: 0, desktop: 8}}
        pb={24}
        {...props}
      >
        <AdjustTpSlSurface
          id={id}
          onCancel={modal.close}
          onSuccess={modal.close}
          width="100%"
        />
      </ModalDynamic>
    );
  }
);
