import * as React from 'react';
import {Animated, StyleProp, ViewStyle} from 'react-native';
import {Box, TouchableBox} from '@youtoken/ui.primitives';

import {Icon} from '@youtoken/ui.icons';
import {CONTROLS_HEIGHT} from '../constants';

export interface ChartTypeSwitchProps {
  type: 'line' | 'candle';
  onChange?: (value: 'line' | 'candle') => void;
}

export const ChartTypeSwitch: React.FC<ChartTypeSwitchProps> = React.memo(
  ({type, onChange}) => {
    // const animation = React.useRef(new Animated.Value(type === 'line' ? 0 : 1));

    // React.useEffect(() => {
    //   Animated.timing(animation.current, {
    //     toValue: type === 'line' ? 0 : 1,
    //     useNativeDriver: false,
    //     duration: 100,
    //   }).start();
    // }, [type]);

    const onPress = React.useCallback(() => {
      onChange?.(type === 'line' ? 'candle' : 'line');
    }, [type, onChange]);

    const style = React.useMemo(
      () => ({
        transform: [
          {
            translateX: type === 'line' ? 0 : 36,
          },
        ],
      }),
      [type]
    ) as Animated.AnimatedProps<StyleProp<ViewStyle>>;

    return (
      <TouchableBox
        activeOpacity={1}
        height={CONTROLS_HEIGHT}
        width={72}
        backgroundColor="$transparent"
        borderRadius={6}
        flexDirection="row"
        onPress={onPress}
        testID="CHART_TYPE_SWITCHER"
      >
        <Box
          position="absolute"
          height={CONTROLS_HEIGHT}
          width={36}
          backgroundColor="$interactive-02"
          borderRadius={6}
          // @ts-ignore
          style={style}
        />

        <Box
          height={CONTROLS_HEIGHT}
          width={36}
          justifyContent="center"
          alignItems="center"
          borderRadius={6}
        >
          <Icon
            name="chart_line"
            color={type === 'line' ? '$text-05' : '$text-02'}
          />
        </Box>
        <Box
          height={CONTROLS_HEIGHT}
          width={36}
          justifyContent="center"
          alignItems="center"
          borderRadius={6}
        >
          <Icon
            name="chart_candles"
            color={type === 'candle' ? '$text-05' : '$text-02'}
          />
        </Box>
      </TouchableBox>
    );
  }
);
