import * as React from 'react';
import {observer} from 'mobx-react';
import Big from 'big.js';
import {Box, Text} from '@youtoken/ui.primitives';
import {RatesListFeature} from '@youtoken/ui.legacy-components';
import {getCoinSymbol} from '@youtoken/ui.coin-utils';

interface ItemProps {
  ticker: string;
}

const RateDiff: React.FC<{
  value: string;
}> = ({value: _value}) => {
  const value = Big(_value);

  return (
    <Box flexDirection="row" justifyContent="center">
      <Text
        variant="$body-02"
        color={value.gte(0) ? '$success-01' : '$danger-01'}
      >
        <Text variant="$code-02">{value.gte(0) ? '+' : ''}</Text>
        {value.times(100).round(2).toFixed(2)}%
      </Text>
    </Box>
  );
};

export const Item: React.FC<ItemProps> = observer(({ticker}) => {
  const feature = RatesListFeature.use({});

  const wallet = feature.getRateObjForTicker(ticker);

  return (
    <Box
      width="100%"
      flexDirection="row"
      alignItems="center"
      // height={36}
      justifyContent="space-between"
      opacity={0.6}
    >
      <Box flexDirection="row" alignItems="center">
        <Text variant="$body-03">{ticker?.toUpperCase()}</Text>
      </Box>

      <Box
        flexDirection="row"
        alignItems="center"
        flex={2}
        justifyContent="flex-end"
        style={{
          // @ts-ignore
          fontVariantNumeric: 'tabular-nums',
        }}
      >
        <Text variant="$body-02-high-accent">
          {getCoinSymbol(wallet.toTicker)}
        </Text>
        <Text variant="$body-02-high-accent" mr={8}>
          {wallet.rate}
        </Text>
        <RateDiff value={wallet.diff} />
      </Box>
    </Box>
  );
});
