import * as React from 'react';
import {observer} from 'mobx-react';
import {TradeToUpgradeModalContent} from '@youtoken/ui.surface-account-levels';
import {type BoxProps} from '@youtoken/ui.primitives';

export type MinerGetMoreSparksContentProps = {
  noticeText?: string;
  onClose?: () => void;
};

export const MinerGetMoreSparksContent: React.FC<
  MinerGetMoreSparksContentProps & BoxProps
> = observer(({noticeText, onClose, ...boxProps}) => {
  return (
    <TradeToUpgradeModalContent
      variant="sparks"
      px={24}
      onClose={onClose}
      {...boxProps}
    />
  );
});
