import * as React from 'react';
import {observer} from 'mobx-react';
import {BundlePortfolio} from '@youtoken/ui.resource-bundles';
import {Box, Text} from '@youtoken/ui.primitives';

interface BundleSummaryPortfolioProps {
  data: BundlePortfolio;
}

export const BundleSummaryPortfolio: React.FC<BundleSummaryPortfolioProps> =
  observer(
    ({
      data: {
        difference,
        quoteTickerFormatted,
        quoteAmountFormatted,
        differenceFormatted,
      },
    }) => {
      return (
        <Box alignItems="flex-end">
          <Text variant="$body-01" color="$text-01" textAlign="right">
            {quoteAmountFormatted}{' '}
            <Text color="$text-02">{quoteTickerFormatted}</Text>
          </Text>
          <Text
            variant="$body-02"
            color={difference.gte(0) ? '$success-01' : '$danger-01'}
            textAlign="right"
          >
            {differenceFormatted}
          </Text>
        </Box>
      );
    }
  );
