import * as React from 'react';
import {DepositNowWizardChooseTypeBase} from './index.base';
import {HeaderBase} from '../../../components';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ScrollView} from 'react-native';

export const DepositNowWizardChooseType: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <HeaderBase shouldShowSeparator>
        {t('surface.wallets.deposit_wizard.choose_type.title')}
      </HeaderBase>
      <ScrollView
        contentContainerStyle={{
          paddingBottom: 24,
        }}
      >
        <DepositNowWizardChooseTypeBase />
      </ScrollView>
    </>
  );
};
