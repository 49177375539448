import * as React from 'react';
import {PropsWithChildren} from 'react';
import {observer} from 'mobx-react';
import {APPEARANCE} from '@youtoken/ui.service-global';
import {ThemeProvider} from '../ThemeProvider';

export const AppearanceContext = React.createContext({
  /** The OS theme */
  osAppearance: 'light' as 'light' | 'dark',
  /** The user preferred theme, "system" means the OS theme will be used */
  userPreferredTheme: 'system' as 'system' | 'light' | 'dark',
  /** The active theme, either the user preferred theme or the OS theme */
  activeTheme: 'light' as 'light' | 'dark',
  /** active theme type, can be either "dark" or "light";
   * We will have "dark" and "lights-out" theme, but both are considered of "dark" type */
  activeThemeType: 'light' as 'light' | 'dark',
  /** Set the user preferred theme to storage */
  setUserPreferredTheme: (theme: 'system' | 'light' | 'dark') => {},
} as const);

export const AppearanceAndThemeProvider: React.FC<PropsWithChildren> = observer(
  ({children}) => {
    const {
      osAppearance,
      activeTheme,
      activeThemeType,
      setUserPreferredTheme,
      userPreferredTheme,
    } = APPEARANCE;

    const value = React.useMemo(() => {
      return {
        osAppearance,
        userPreferredTheme,
        activeTheme,
        activeThemeType,
        setUserPreferredTheme,
      } as const;
    }, [osAppearance, userPreferredTheme, activeTheme, activeThemeType]);

    return (
      <AppearanceContext.Provider value={value}>
        <ThemeProvider themeName={value.activeTheme}>{children}</ThemeProvider>
      </AppearanceContext.Provider>
    );
  }
);

export const useAppearance = () => {
  return React.useContext(AppearanceContext);
};

export const useActiveTheme = () => {
  return useAppearance().activeTheme;
};

export const useActiveThemeType = () => {
  return useAppearance().activeThemeType;
};
