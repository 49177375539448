import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {
  createWizard,
  type StackNavigatorScreenWizardWrappedProps,
} from '@youtoken/ui.elements';
import {UmaInfoResource} from '@youtoken/ui.resources-uma-info';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {CryptoWithdrawal, getCryptoWithdrawalTitle} from './CryptoWithdrawal';
import {getUmaCreateTitle, UmaCreateCell} from '../../Uma/UmaCreateCell';
import {
  getUmaInvoiceConfirmationTitle,
  UmaInvoiceConfirmationCell,
} from '../../Uma/UmaInvoiceConfirmationCell';
import {ScanQRCode} from './ScanQRCode';
import {
  getUmaWithdrawalTitle,
  UmaWithdrawalCell,
} from '../../Uma/UmaWithdrawalCell';
import {CryptoWithdrawalMethods} from './ChooseMethod';

export enum CryptoWithdrawalWizardScreenName {
  CHOOSE_METHOD = 'chooseMethod',
  WITHDRAWAL = 'withdrawal',
  SCAN_QR_CODE = 'scanQRCode',
  UMA_CREATE = 'umaCreate',
  UMA_WITHDRAWAL = 'umaWithdrawal',
  UMA_INVOICE_CONFIRMATION = 'umaInvoiceConfirmation',
}

export interface CryptoWithdrawalWizardNavigator {
  [CryptoWithdrawalWizardScreenName.CHOOSE_METHOD]: {
    ticker: string;
  };
  [CryptoWithdrawalWizardScreenName.WITHDRAWAL]: {
    ticker: string;
  };
  [CryptoWithdrawalWizardScreenName.SCAN_QR_CODE]: {
    onQRCodeScanSuccessful: Function;
  };
  [CryptoWithdrawalWizardScreenName.UMA_CREATE]: {};
  [CryptoWithdrawalWizardScreenName.UMA_WITHDRAWAL]: {
    uma?: string;
    amount?: string;
    ticker: string;
  };
  [CryptoWithdrawalWizardScreenName.UMA_INVOICE_CONFIRMATION]: {
    uma: string;
    amount: string;
    ticker: string;
    toTicker: string;
    invoiceRequestId: string;
    requestUuid: string;
  };
}

const Wizard = createWizard<CryptoWithdrawalWizardNavigator>();

export interface CryptoWithdrawalWizardProps {
  ticker: string;
  onPressBack?: () => void;
}

export const CryptoWithdrawalWizard: React.FC<CryptoWithdrawalWizardProps> =
  cell(({ticker, onPressBack}) => {
    const {t} = useTranslation();
    const {isUmaOpened} = UmaInfoResource.use({});

    return (
      <Wizard.Navigator
        initialName={CryptoWithdrawalWizardScreenName.CHOOSE_METHOD}
        initialProps={{ticker}}
        goBack={onPressBack}
      >
        <Wizard.Screen
          name={CryptoWithdrawalWizardScreenName.CHOOSE_METHOD}
          options={{
            title: getCryptoWithdrawalTitle(ticker),
          }}
          component={CryptoWithdrawalMethods}
        />
        <Wizard.Screen
          name={CryptoWithdrawalWizardScreenName.WITHDRAWAL}
          options={{
            title: getCryptoWithdrawalTitle(ticker),
          }}
          component={CryptoWithdrawal}
        />
        <Wizard.Screen
          name={CryptoWithdrawalWizardScreenName.SCAN_QR_CODE}
          options={{
            title: t('common.action.scan_qr'),
          }}
          component={ScanQRCode}
        />
        <Wizard.Screen
          name={CryptoWithdrawalWizardScreenName.UMA_CREATE}
          options={{
            title: getUmaCreateTitle(),
          }}
          component={UmaCreateCell}
        />
        <Wizard.Screen
          name={CryptoWithdrawalWizardScreenName.UMA_WITHDRAWAL}
          options={{
            title: getUmaWithdrawalTitle(ticker, isUmaOpened),
          }}
          component={UmaWithdrawalCell}
        />
        <Wizard.Screen
          name={CryptoWithdrawalWizardScreenName.UMA_INVOICE_CONFIRMATION}
          options={{title: getUmaInvoiceConfirmationTitle(ticker)}}
          component={UmaInvoiceConfirmationCell}
        />
      </Wizard.Navigator>
    );
  });

export const CryptoWithdrawalWizardWrapped = ({
  route: {
    params: {ticker},
  },
  navigation: {goBack},
}: StackNavigatorScreenWizardWrappedProps<{ticker: string}>) => {
  return <CryptoWithdrawalWizard ticker={ticker} onPressBack={goBack} />;
};
