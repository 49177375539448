import * as React from 'react';
import {ScrollView} from 'react-native';
import {observer} from 'mobx-react';
import {
  Frames,
  CardNumber,
  ExpiryDate,
  Cvv,
  SubmitButton,
} from 'frames-react-native';
import {Label, FieldErrorMessage, useForm} from '@youtoken/ui.form-elements';
import {ValidationMessageComponent} from '@youtoken/ui.validation-messages';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {getCheckoutData} from '@youtoken/ui.env-utils';
import {Box, useTheme} from '@youtoken/ui.primitives';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {TextInput} from '@youtoken/ui.inputs';
import {CardsForm} from '../state';

interface FramesProps {
  ticker?: string;
  method?: 'withdrawal' | 'deposit';
}

export const FramesComponent: React.FC<FramesProps> = observer(
  ({ticker, method}) => {
    const {t} = useTranslation();
    const {colors, fonts} = useTheme();

    const {
      form: {
        isValidCardNumber,
        isValidExpiryDate,
        isValidCvv,
        onChangeCardHolderName,
        onCardTokenizationFail,
        onCardTokenizationSuccess,
        showCardNumberError,
        showExpiryDateError,
        showCvvError,
      },
      view: {
        cardHolderName,
        cardHolderNameError,
        onCardHolderNameBlur,
        countryCode,
        city,
        zipCode,
        addressLine1,
        onCardValidationChanged,
        onFrameValidationChanged,
      },
    } = useForm<CardsForm>();

    const fontStyle = React.useMemo(
      () => ({
        fontSize: 14,
        fontFamily: fonts.normal.regular,
        fontWeight: '500' as const,
        lineHeight: 18,
        letterSpacing: 0.28,
      }),
      [fonts]
    );

    const inputStyle = React.useMemo(
      () => ({
        backgroundColor: colors['$ui-background'],
        borderColor: colors['$ui-02'],
        color: colors['$text-01'],
        borderRadius: 6,
        height: 48,
        ...fontStyle,
      }),
      [colors, fontStyle]
    );

    const config = {
      publicKey: getCheckoutData(ENVIRONMENT.APP_ENV).token,
      cardholder: {
        name: cardHolderName,
        billingAddress: {
          country: countryCode || undefined,
          city: city || undefined,
          zip: zipCode || undefined,
          addressLine1: addressLine1 || undefined,
        },
      },
    };

    const buttonTitle: string = React.useMemo(() => {
      if (!ticker) {
        return t('surface.wallets.add_card');
      }

      switch (method) {
        case 'deposit':
          return t('surface.wallets.fiat_deposit.title', {
            ticker: ticker.toUpperCase(),
          });
        case 'withdrawal':
          return t('surface.wallets.fiat_deposit_advcash.continue_button');
        default:
          return t('surface.wallets.add_card');
      }
    }, [ticker, method]);

    return (
      <ScrollView
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          flexGrow: 1,
          padding: 24,
          backgroundColor: colors['$ui-background'],
        }}
        bounces={false}
      >
        <Frames
          config={config}
          cardValidationChanged={onCardValidationChanged}
          frameValidationChanged={onFrameValidationChanged}
          cardTokenizationFailed={onCardTokenizationFail}
          cardTokenized={onCardTokenizationSuccess}
        >
          <Box justifyContent="space-between" height="100%">
            <Box>
              <Box zIndex={100}>
                <Label mb={8} required>
                  {t('surface.wallets.bank_card.cardholder_name')}
                </Label>
                <TextInput
                  value={cardHolderName}
                  onChangeText={onChangeCardHolderName}
                  onBlur={onCardHolderNameBlur}
                  placeholderTextColor={colors['$text-03']}
                  placeholder={t('surface.form_a.field.name')}
                  testID="CARDHOLDER_NAME_INPUT"
                  autoComplete="cc-name"
                />
                <FieldErrorMessage visible={Boolean(cardHolderNameError)}>
                  <ValidationMessageComponent error={cardHolderNameError} />
                </FieldErrorMessage>
              </Box>

              <Label mt={24} mb={8} required>
                {t('surface.wallets.bank_card.card_number')}
              </Label>
              <CardNumber
                style={inputStyle}
                placeholderTextColor={colors['$text-03']}
                placeholder="0000 0000 0000 0000"
                autoComplete="cc-number"
                testID="CARD_NUMBER_INPUT"
              />
              <FieldErrorMessage
                visible={!isValidCardNumber && showCardNumberError}
              >
                {t('surface.wallets.bank_card.card_number_invalid')}
              </FieldErrorMessage>

              <Box flexDirection="row" pt={24}>
                <Box flexBasis={0} flexGrow={1} mr={16}>
                  <Label mb={8} required>
                    {t('surface.wallets.bank_card.expiration_date')}
                  </Label>
                  <ExpiryDate
                    style={inputStyle}
                    placeholderTextColor={colors['$text-03']}
                    placeholder={t(
                      'surface.wallets.bank_card.expiration_date_placeholder'
                    )}
                    testID="EXPIRY_DATE_INPUT"
                    autoComplete="cc-exp"
                  />
                  <FieldErrorMessage
                    visible={!isValidExpiryDate && showExpiryDateError}
                  >
                    {t('surface.wallets.bank_card.expiration_date_invalid')}
                  </FieldErrorMessage>
                </Box>
                <Box flexBasis={0} flexGrow={1}>
                  <Label mb={8} required>
                    {t('surface.wallets.bank_card.security_code')}
                  </Label>
                  <Cvv
                    style={inputStyle}
                    placeholderTextColor={colors['$text-03']}
                    placeholder={t(
                      'surface.wallets.bank_card.security_code_placeholder'
                    )}
                    testID="CVV_INPUT"
                    autoComplete="cc-csc"
                  />
                  <FieldErrorMessage visible={!isValidCvv && showCvvError}>
                    {t('surface.wallets.bank_card.security_code_invalid')}
                  </FieldErrorMessage>
                </Box>
              </Box>
            </Box>
            <Box flexDirection="row" height={48} mt={24}>
              <SubmitButton
                title={buttonTitle}
                activeOpacity={0.8}
                style={{
                  backgroundColor: colors['$interactive-01'],
                  borderRadius: 6,
                  flex: 1,
                }}
                textStyle={{
                  color: colors['$text-04'],
                  fontSize: 14,
                  fontFamily: fonts.normal.regular,
                  fontWeight: '500',
                }}
                testID="SUBMIT_CARD_DATA_BUTTON"
              />
            </Box>
          </Box>
        </Frames>
      </ScrollView>
    );
  }
);
