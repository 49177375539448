import * as React from 'react';
import {observer} from 'mobx-react';
import {Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import type {BundleDepositFormState} from '../../../../state';

export const ConversionAmount: React.FC = observer(() => {
  const {form} = useForm<BundleDepositFormState>();

  if (form.ticker === form.conversionTicker) {
    return null;
  }

  return (
    <Text variant="$body-01" color="$text-02">
      {form.conversionAmount} {form.conversionTickerFormatted}
    </Text>
  );
});
