import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Separator, useWizard} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';
import {CryptoWithdrawalMethodsFeature} from '../state/CryptoWithdrawalMethodsFeature';
import type {MethodData} from '../../../components/PaymentMethods/types';
import {HeaderBase, MethodItem} from '../../../components';
import {
  CryptoWithdrawalWizardScreenName,
  type CryptoWithdrawalWizardNavigator,
} from '../index';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const CryptoWithdrawalMethods: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    step: {
      props: {ticker},
    },
    navigate,
    goBack,
  } = useWizard<
    CryptoWithdrawalWizardNavigator,
    CryptoWithdrawalWizardScreenName.CHOOSE_METHOD
  >();

  const {methodsList} = CryptoWithdrawalMethodsFeature.use({ticker});

  const methodsData: Partial<
    Record<CryptoWithdrawalWizardScreenName, MethodData>
  > = React.useMemo(
    () => ({
      [CryptoWithdrawalWizardScreenName.WITHDRAWAL]: {
        title: 'Direct to wallet',
        icon: <Icon name="crypto_withdraw" color="$text-05" />,
        testID: 'CRYPTO_WITHDRAWAL_METHOD_BANK_WIRE',
      },
      [CryptoWithdrawalWizardScreenName.UMA_WITHDRAWAL]: {
        title: t('surface.wallets.uma'),
        description: t('surface.wallets.uma.withdrawal.duration'),
        icon: <Icon name="uma" color="$interactive-01" />,
        testID: 'CRYPTO_WITHDRAWAL_METHOD_UMA',
      },
    }),
    [t]
  );
  const handleChangeMethod = React.useCallback(
    (method: CryptoWithdrawalWizardScreenName) => navigate(method, {ticker}),
    [navigate]
  );

  return (
    <>
      {showHeader && (
        <>
          <HeaderBase onPressBack={goBack}>
            {t('surface.wallets.fiat_withdrawal.title', {
              ticker: ticker.toUpperCase(),
            })}
          </HeaderBase>
          <Separator />
        </>
      )}
      {methodsList.map(({method, disabled}) => (
        <MethodItem
          key={method}
          disabled={disabled}
          onPress={!disabled ? () => handleChangeMethod(method) : () => {}}
          {...methodsData[method]!}
        />
      ))}
    </>
  );
});
