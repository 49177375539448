import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {TickerAndAmountInput} from '@youtoken/ui.ticker-and-amount-input';
import {useForm, Label, FieldErrorMessage} from '@youtoken/ui.form-elements';
import type {BundleWithdrawalFormState} from '../../../../state';
import {AmountSetAllDebtLink} from './AllDebtLink';

export const Amount: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {
    form: {
      tickerItems,
      ticker,
      amount,
      sourceHasError,
      sourceError,
      sourceInfo,
      calculated: {ready, data},
      tickerOnChange,
      amountOnChange,
    },
  } = useForm<BundleWithdrawalFormState>();

  return (
    <Box {...props}>
      <Box flexDirection="row" justifyContent="space-between" mb={8}>
        <Label>{t('surface.bundles.withdrawal_form.amount')}</Label>
        <AmountSetAllDebtLink />
      </Box>

      <TickerAndAmountInput
        disabled={data?.onlyWithdrawAll}
        wallets={tickerItems}
        ticker={ticker}
        amount={amount}
        onChangeTicker={tickerOnChange}
        onChangeAmount={amountOnChange}
      />

      <Box>
        <Box opacity={Number(!sourceHasError)}>
          <Text variant="$body-02" color="$text-02" mt={8}>
            {sourceInfo}
          </Text>
        </Box>
        <Box position="absolute" left={0} right={0}>
          <FieldErrorMessage visible={sourceHasError}>
            {sourceInfo}
          </FieldErrorMessage>
        </Box>
      </Box>
    </Box>
  );
});
