import {computed} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {HODLByIdResource} from '@youtoken/ui.resource-hodl';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {getCoinDecimalPrecision, getCoinName} from '@youtoken/ui.coin-utils';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {HodlsTariffsResource} from '@youtoken/ui.resource-hodl-tariffs';
import {invariant} from '@youtoken/ui.utils';
import {toBig} from '@youtoken/ui.formatting-utils';

interface ExtendTpSlHODLFeatureArgs {
  id: string;
}

export class ExtendTpSlHODLFeature extends createFeature({
  getKey: (args: ExtendTpSlHODLFeatureArgs) =>
    `feature:extend_tp_sl_hodl:${JSON.stringify(args)}`,
  getResources: ({id}) => {
    return {
      hodl: getResourceDescriptor(HODLByIdResource, {id}),
      wallets: getResourceDescriptor(WalletsResource, {}),
      authMe: getResourceDescriptor(AuthMeResource, {}),
      tariffs: getResourceDescriptor(HodlsTariffsResource, {}),
    };
  },
}) {
  @computed get initialPriceFormatted() {
    return this.resources.hodl.data.initialPrice?.toFixed(
      this.resources.hodl.data.precision
    );
  }

  @computed get hodlInputTicker() {
    return this.resources.hodl.data.inputTicker;
  }

  @computed get hodlInputTickerFormatted() {
    return this.hodlInputTicker.toUpperCase();
  }

  @computed get hodlBaseTickerFormatted() {
    return this.resources.hodl.data.baseTicker.toUpperCase();
  }

  @computed get hodlQuoteTickerFormatted() {
    return this.resources.hodl.data.quoteTicker.toUpperCase();
  }

  @computed get inputTickerWallet() {
    const wallet = this.resources.wallets.getByTicker(this.hodlInputTicker);

    invariant(
      wallet,
      'cannot get wallet by ticker',
      {},
      {wallets: this.resources.wallets, ticker: this.hodlInputTicker}
    );

    return {
      ticker: wallet.ticker,
      tickerFormatted: wallet.ticker.toUpperCase(),
      amountFormatted: wallet.amount.toFixed(
        getCoinDecimalPrecision(wallet.ticker)
      ),
      amount: wallet.amount,
      coinName: getCoinName(wallet.ticker),
      hasAmount: wallet.amount?.gt(0),
      key: wallet.ticker,
    };
  }

  @computed get hasBalance() {
    return this.inputTickerWallet.hasAmount;
  }

  // #region incentives
  @computed get currentTariff() {
    const {baseTicker, quoteTicker, direction} = this.resources.hodl.data;
    return this.resources.tariffs.data.find(
      tariff =>
        tariff.baseTicker === baseTicker &&
        tariff.quoteTicker === quoteTicker &&
        tariff.isShort === (direction === 'down')
    );
  }

  @computed
  public get bonusesEnabledInTariff() {
    return this.currentTariff?.bonusesEnabled;
  }

  @computed
  public get incentivesEnabled() {
    return this.resources.authMe.incentivesEnabled;
  }

  @computed
  public get bonusBalance() {
    return toBig(this.resources.wallets.bonusesWallet?.amount);
  }

  @computed
  public get hasIncentives() {
    return (
      this.bonusesEnabledInTariff &&
      this.incentivesEnabled &&
      this.bonusBalance.gt(0)
    );
  }
  // #endregion incentives
}
