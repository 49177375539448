import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {ReferralsHowItWorksSmart} from '../../smarts';

export const ReferralsHowItWorksModal = observer(
  (modalProps: Omit<ModalDynamicProps, 'children'>) => {
    return (
      <ModalDynamic
        snapPoints={['90%']}
        nativeStackBehavior="push"
        {...modalProps}
      >
        <ReferralsHowItWorksSmart
          showShareRefCode={Platform.select({
            default: false,
            native: true,
          })}
        />
      </ModalDynamic>
    );
  }
);
