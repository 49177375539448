import * as React from 'react';
import {i18n} from '@youtoken/ui.service-i18n';
import {BundlesInfo, BundlesTariff} from '../components';

type ItemType = 'info' | 'tariff';

type ItemName = 'info' | 'tariff';

export interface Item {
  type: ItemType;
  name: ItemName;
  data?: any;
}

export const getBundlesSurfaceTitle = () => {
  return i18n.t('surface.bundles.title');
};

export const keyExtractor = (item: Item, index: number) =>
  item.type + item.name + index;

export const getItemType = (item: Item) => {
  return item.type;
};

export const renderItem = ({item: {name, data}}: {item: Item}) => {
  if (name === 'info') {
    return <BundlesInfo mx={12} />;
  }

  if (name === 'tariff') {
    return <BundlesTariff data={data} mt={12} mx={12} />;
  }

  return null;
};
