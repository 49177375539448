import * as React from 'react';
import {Box, Text, type TColorTokens} from '@youtoken/ui.primitives';
import {observer} from 'mobx-react';

interface CardParameterProps {
  title: string;
  textColor: keyof TColorTokens;
  children: React.ReactNode;
  zIndex?: number;
}

export const CardParameter: React.FC<CardParameterProps> = observer(
  ({title, children, textColor, zIndex}) => {
    return (
      <Box
        flexDirection="column"
        flexGrow={{default: undefined, tablet: 0.25}}
        flexShrink={{default: undefined, tablet: 0}}
        zIndex={zIndex}
      >
        <Text color={textColor} lineHeight={18} variant="$body-02">
          {title}
        </Text>
        {children}
      </Box>
    );
  }
);
