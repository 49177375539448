import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {MyFriendsSurfaceContent} from './MyFriendsSurfaceContent';
import {ActionButtonWithModal} from './components';
import type {MyFriendsSurfaceTab} from './types';

type MyFriendsSurfaceProps = {
  activeTab?: MyFriendsSurfaceTab;
  onTabChange?: (tab: MyFriendsSurfaceTab) => void;
};

export const MyFriendsSurface: React.FC<MyFriendsSurfaceProps & BoxProps> = ({
  activeTab,
  onTabChange,
  ...boxProps
}) => {
  return (
    <Box {...boxProps}>
      <MyFriendsSurfaceContent
        activeTab={activeTab}
        onTabChange={onTabChange}
      />
      <ActionButtonWithModal />
    </Box>
  );
};

export const MyFriendsSurfaceCell = cell(MyFriendsSurface);
