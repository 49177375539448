import * as React from 'react';
import {observer} from 'mobx-react';
import {ScrollView} from 'react-native';
import {i18n} from '@youtoken/ui.service-i18n';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {AccountLevelProgressBarVariant} from '../types';
import {AccountLevelsSlider} from '../../smarts';
import {AccountLevelProgressBarDetailedNextGoals} from './NextGoals';
import {AccountLevelProgressBarDetailedPerks} from './Perks';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export const getAccountLevelProgressDetailedTitle = () => {
  return i18n.t('surface.loyalty.account_level');
};

export type AccountLevelProgressDetailedProps = {
  variant?: AccountLevelProgressBarVariant;
  volume?: number;
};

export const AccountLevelProgressDetailed: React.FC<
  AccountLevelProgressDetailedProps & BoxProps
> = observer(({variant, volume, ...boxProps}) => {
  const {bottom} = useSafeAreaInsets();

  const {
    authMe: {
      products: {miner},
    },
    loyalty: {getProgressBarLevelsWidgetDetailed},
  } = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
    loyalty: getResourceDescriptor(LoyaltyResource, {}),
  });

  const progressBarLevels = getProgressBarLevelsWidgetDetailed(volume);

  const {level} = progressBarLevels[progressBarLevels.length - 1]!;

  return (
    <Box
      testID={`ACCOUNT_LEVEL_PROGRESS_DETAILED_${variant}`}
      flex={1}
      {...boxProps}
    >
      <ScrollView
        contentContainerStyle={{
          paddingBottom: bottom,
        }}
        scrollIndicatorInsets={{
          bottom,
        }}
      >
        <AccountLevelProgressBarDetailedNextGoals
          progressBarLevels={progressBarLevels}
          p={{default: 16, phone: 24}}
        />
        <AccountLevelProgressBarDetailedPerks
          showSparks={miner.available}
          variant={variant}
          level={level}
          p={{default: 16, phone: 24}}
          borderTopWidth={1}
          borderColor="$ui-01"
        />
        <AccountLevelsSlider initialIndex={level.level - 1} />
      </ScrollView>
    </Box>
  );
});
