import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {TextInput} from '@youtoken/ui.inputs';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {normalizeOnlyDigits} from '@youtoken/ui.normalizers';
import {useRecaptcha} from '@youtoken/ui.two-factor-auth-and-recaptcha';
import {FieldErrorMessage} from '../FieldError';
import {ResendCode} from './components/ResendCode';
import type {AxiosPromise} from 'axios';

interface TFAFormFieldProps {
  showTitle?: boolean;
  authType?: string;
  error?: string;
  getOperationData: () => Promise<void | {
    operationId: string;
    phoneMask?: string;
  }>;
  onChangeCode: (value: string) => void;
}

export const TFAFormField: React.FC<TFAFormFieldProps & BoxProps> = observer(
  ({
    showTitle = true,
    authType,
    error,
    getOperationData,
    onChangeCode,
    ...boxProps
  }) => {
    const {twoFactorAuthType: authTypeFromResource} = AuthMeResource.use({});
    const twoFactorAuthType = authType ?? authTypeFromResource;
    const {t} = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [operationId, setOperationId] = React.useState<string>();
    const [phoneMask, setPhoneMask] = React.useState<string | null>(null);
    const [code, setCode] = React.useState<string>('');

    const title = {
      ga: t('surface.2fa.code_sent.ga'),
      sms: t('surface.2fa.code_sent.sms', {
        phoneMask,
      }),
      email: t('surface.2fa.code_sent.email'),
    }[twoFactorAuthType];

    const showResendCode = twoFactorAuthType !== 'ga' && Boolean(operationId);

    const handleGetOperationData = React.useCallback(() => {
      setLoading(true);

      return getOperationData()
        .then(operationData => {
          if (operationData) {
            setOperationId(operationData.operationId);
            setPhoneMask(operationData.phoneMask ?? null);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }, [getOperationData]);

    const handleChangeCode = React.useCallback(
      (value: string) => {
        const normalizedValue = normalizeOnlyDigits(value).substring(0, 6);
        setCode(normalizedValue);
        onChangeCode(normalizedValue);
      },
      [onChangeCode]
    );

    React.useEffect(() => {
      if (twoFactorAuthType === 'ga') {
        handleGetOperationData();
      }
    }, []);

    return (
      <Box px={24} py={12} bg="$fill-01" {...boxProps}>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap={24}
        >
          {(showTitle || showResendCode) && (
            <Box alignItems="flex-start" flex={1}>
              {showTitle && <Text>{title}</Text>}
              {showResendCode && (
                <ResendCode onResend={handleGetOperationData} />
              )}
            </Box>
          )}

          <Box width={{tablet: 256, default: '40%'}}>
            {twoFactorAuthType === 'ga' || Boolean(operationId) ? (
              <TextInput
                placeholder={t('surface.2fa_form.code_placeholder')}
                value={code}
                onChangeText={handleChangeCode}
                autoCorrect={false}
                autoCapitalize="none"
                textContentType="oneTimeCode"
                keyboardType="number-pad"
                testID="TFA_CODE_INPUT"
              />
            ) : (
              <Button
                loading={loading}
                onPress={handleGetOperationData}
                testID="SEND_TFA_CODE_BUTTON"
              >
                {t('surface.2fa_form.send')}
              </Button>
            )}
          </Box>
        </Box>
        <FieldErrorMessage visible={Boolean(error)}>
          <Text testID="TFA_ERROR">{error}</Text>
        </FieldErrorMessage>
      </Box>
    );
  }
);
