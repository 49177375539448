import * as React from 'react';
import {Wallet} from '@youtoken/ui.resource-wallets';
import {type BoxProps, useIsDesktop, Box} from '@youtoken/ui.primitives';
import {
  WalletSummaryAndRate,
  WalletActions,
  WalletActionsAdditional,
} from '../../../../../smart';

interface SectionItemWalletProps {
  item: Wallet;
}

export const SectionItemWallet: React.FC<SectionItemWalletProps & BoxProps> = ({
  item,
  ...boxProps
}) => {
  const isDesktop = useIsDesktop();

  return (
    <Box
      flex={1}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={20}
      {...boxProps}
    >
      <WalletSummaryAndRate showSavings item={item} flex={1} />
      {isDesktop && (
        <Box flexDirection="row" mr={-20}>
          <WalletActions item={item} />
          <WalletActionsAdditional item={item} />
        </Box>
      )}
    </Box>
  );
};
