import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ProgressBar} from '@youtoken/ui.progress-bar';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {useCardContentParameters} from '../hooks';
import {Separator} from '@youtoken/ui.elements';

interface CardContentProps {
  level: LoyaltyLevel;
}

export const CardContent: React.FC<CardContentProps> = observer(({level}) => {
  const {t} = useTranslation();

  const parameters = useCardContentParameters(level);

  if (!parameters) {
    return null;
  }

  const {title, description, progress, withTooltip, textColor} = parameters;

  return (
    <Box>
      <Separator
        height={1}
        mt={16}
        mb={12}
        backgroundColor="$text-01"
        opacity={0.1}
      />

      <Text variant="$body-02" mb={3} color={textColor}>
        {title}
      </Text>

      <Box flexDirection="row" alignItems="center">
        <Text variant="$body-01-high-accent" mr={2} color={textColor}>
          {description}
        </Text>
        {withTooltip && (
          <QuestionTooltip
            content={t('surface.loyalty.required_volume_hint')}
            testID={`MINER_LEVEL_${level.level}_TOOLTIP`}
            iconColor="$text-01"
            iconSize={20}
            iconOpacity={0.3}
          />
        )}
      </Box>

      {typeof progress === 'number' && (
        <Box mt={8}>
          <ProgressBar
            color="inactive"
            progress={progress}
            testID={`MINER_LEVEL_${level.level}_PROGRESS_BAR`}
            barColor={textColor}
          />
        </Box>
      )}
    </Box>
  );
});
