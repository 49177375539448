import {computed} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {formatPercent} from '@youtoken/ui.formatting-utils';

export enum BENEFITS_TYPE {
  NEW_USER = 'new_user',
  COMMON = 'common',
  NONE = 'none',
}
export class DepositNowFeature extends createFeature({
  getKey: ({}) => {
    return `BuyCryptoWidget`;
  },
  getResources: () => {
    return {
      authme: getResourceDescriptor(AuthMeResource, {}),
      loyalty: getResourceDescriptor(LoyaltyResource, {}),
    };
  },
}) {
  @computed
  public get currentLevel() {
    return this.resources.loyalty.data.currentLevel;
  }

  @computed
  public get secondLevelData() {
    return this.resources.loyalty.getLevel(2)!;
  }

  @computed
  public get showSparksBenefits() {
    return this.resources.authme.products.miner.available;
  }

  @computed
  public get sparksBenefitsFormatted() {
    return this.secondLevelData.miner.welcomePack;
  }

  @computed
  public get showAprBenefits() {
    return this.resources.authme.products.saving4.available;
  }

  @computed
  public get aprBenefitsFormatted() {
    return formatPercent(this.secondLevelData.saving.maxTickersAPR);
  }

  @computed
  public get showIncentivesBenefits() {
    if (!this.resources.loyalty.currentLevelData.incentives) {
      return false;
    }

    const {incentivesEnabled} = this.resources.authme;

    return (
      incentivesEnabled &&
      this.resources.loyalty.currentLevelData.incentives?.maxPercent > 0
    );
  }

  @computed
  public get showBenefitsType() {
    if (this.resources.authme.products.loyalty.available) {
      // NOTE: check if we need to show benefits for new user
      if (this.currentLevel === 1) {
        return this.showSparksBenefits ||
          this.showAprBenefits ||
          this.showIncentivesBenefits
          ? BENEFITS_TYPE.NEW_USER
          : BENEFITS_TYPE.NONE;
      } else {
        // NOTE: check if we need to show benefits for user with loyalty level above 1
        return this.showIncentivesBenefits
          ? BENEFITS_TYPE.COMMON
          : BENEFITS_TYPE.NONE;
      }
    }

    return BENEFITS_TYPE.NONE;
  }

  @computed
  public get requiredDepositFormatted() {
    return this.secondLevelData.requiredDepositVisibleFormatted;
  }
}
