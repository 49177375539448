import {custom, object, optional, primitive, serializable} from 'serializr';

const _agreementsList = () => {
  return custom(
    outValue => outValue,
    inValue => {
      return Object.values(inValue).filter(
        (item: any) => item.name && item.link
      );
    }
  );
};

export class DocumentItem {
  @serializable(primitive()) name!: string;
  @serializable(primitive()) link!: string;
  @serializable(optional(primitive())) i18nName?: string;
  @serializable(optional(primitive())) i18nLink?: string;
  @serializable(optional(primitive())) template?: string;
}

export class loanAgreementsItem {
  @serializable(object(DocumentItem)) basic!: DocumentItem;
  @serializable(object(DocumentItem)) extendPdl!: DocumentItem;
  @serializable(object(DocumentItem)) increase!: DocumentItem;
  @serializable(object(DocumentItem)) reopen!: DocumentItem;
  @serializable(object(DocumentItem)) setFtp!: DocumentItem;
}

export class turboAgreementsItem {
  @serializable(optional(object(DocumentItem)))
  specificTermsConversion?: DocumentItem;
  @serializable(optional(object(DocumentItem)))
  specificTermsLending?: DocumentItem;
}

export class DocsResponse {
  @serializable(_agreementsList())
  general!: DocumentItem[];

  @serializable(_agreementsList())
  generalAgreementsForSignUp?: DocumentItem[];

  @serializable(_agreementsList())
  wallet!: DocumentItem[];

  @serializable(_agreementsList())
  bundles!: DocumentItem[];

  @serializable(_agreementsList())
  naumardDocuments!: DocumentItem[];

  @serializable(_agreementsList())
  youhodlerDocuments!: DocumentItem[];

  @serializable(_agreementsList())
  youhodlerDocumentsFull!: DocumentItem[];

  @serializable(object(loanAgreementsItem))
  loan!: loanAgreementsItem;

  @serializable(optional(_agreementsList()))
  loanCreateForm?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanCreateFormNaumard?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanCreateFormYouhodler?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanCreateFormItaly?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanCloseNowForm?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanCloseNowFormNaumard?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanCloseNowFormYouhodler?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanDecreaseForm?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanDecreaseFormNaumard?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanDecreaseFormYouhodler?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanIncreaseForm?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanIncreaseFormNaumard?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanIncreaseFormYouhodler?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanReopenForm?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanReopenFormNaumard?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanReopenFormYouhodler?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanFtpForm?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanFtpFormNaumard?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  loanFtpFormYouhodler?: DocumentItem[] = [];

  @serializable(optional(object(turboAgreementsItem)))
  turbo?: turboAgreementsItem;

  @serializable(optional(_agreementsList()))
  turboCreateForm?: DocumentItem[] = [];

  @serializable(_agreementsList())
  convert!: DocumentItem[];

  @serializable(optional(_agreementsList()))
  hodl2?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  hodlCreateForm?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  savings3?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  dual?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  dualCreateForm?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  wirexIban?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  fiatRepublicIban?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  disclosureIta?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  privacyNotice?: DocumentItem[] = [];

  @serializable(optional(_agreementsList()))
  intergiroCards?: DocumentItem[] = [];
}
