import type {AxiosResponse} from 'axios';
import {action, observable} from 'mobx';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {TRANSPORT} from '@youtoken/ui.transport';
import {i18n} from '@youtoken/ui.service-i18n';
import {SENTRY} from '@youtoken/ui.sentry';
import {CompleteStatus, type PaymentData, type ShowBaseProps} from './index';

interface ApplePayPaymentRequest extends PaymentRequest {
  prototype: PaymentRequest;

  new (
    methodData: PaymentMethodData[],
    details: PaymentDetailsInit,
    // NOTE: the paymentOptions is not a part of the PaymentRequestInit, but it is used in the ApplePayPaymentRequest
    paymentOptions: {
      requestPayerName: boolean;
      requestBillingAddress: boolean;
      requestPayerEmail: boolean;
      requestPayerPhone: boolean;
      requestShipping: boolean;
      shippingType: string;
    }
  ): ApplePayPaymentRequest;

  onmerchantvalidation?: (event: {
    validationURL: string;
    complete: (props: any) => void;
  }) => void;
  onpaymentmethodchange: (event: any) => void;
  // NOTE: The methods below are deprecated, but for some reason, they are still used in the ApplePayPaymentRequest
  onshippingoptionchange: (event: any) => void;
  onshippingaddresschange: (event: any) => void;
}

interface ShowWebProps extends ShowBaseProps {
  merchantCapabilities: string[];
  supportedNetworks: string[];
  merchantIdentifier: string;
  countryCode: string;
}

class PlatformPaymentModule {
  readonly paymentOptions = {
    requestPayerName: false,
    requestBillingAddress: false,
    requestPayerEmail: false,
    requestPayerPhone: false,
    requestShipping: false,
    shippingType: 'shipping',
  };

  @observable
  private paymentRequest: ApplePayPaymentRequest | ApplePaySession | null =
    null;

  @observable
  private paymentResponse: PaymentResponse | null = null;

  @observable
  private paymentApiVersion: 'PaymentRequest' | 'ApplePayJs' = 'PaymentRequest';

  @action public complete = async (statusIndex: CompleteStatus) => {
    if (this.paymentApiVersion === 'PaymentRequest') {
      const status =
        statusIndex === CompleteStatus.success ? 'success' : 'fail';

      await (this.paymentResponse as PaymentResponse)
        ?.complete(status)
        .catch(error => {
          SENTRY.captureMessage('Payment request complete error', {
            source: 'Payment Request',
            extra: {
              errorDetails: error.message || error,
            },
          });
        });
    }

    if (this.paymentApiVersion === 'ApplePayJs') {
      const result = {
        status:
          statusIndex === CompleteStatus.success
            ? ApplePaySession.STATUS_SUCCESS
            : ApplePaySession.STATUS_FAILURE,
      };

      (this.paymentRequest as ApplePaySession)?.completePayment(result);
    }

    if (statusIndex === CompleteStatus.failure) {
      this.dismiss();
    }

    this.paymentResponse = null;
  };

  @action public dismiss = () => {
    this.paymentRequest?.abort();
  };

  @action public canMakePayments = () => {
    try {
      if (Boolean(window.PaymentRequest)) {
        return true;
      }

      try {
        if ('ApplePaySession' in window) {
          this.paymentApiVersion = 'ApplePayJs';

          return ApplePaySession.canMakePayments();
        }
      } catch (_) {
        return false;
      }

      return false;
    } catch (error: any) {
      return false;
    }
  };

  @action public show = async (data: ShowWebProps): Promise<PaymentData> => {
    try {
      return this.paymentApiVersion === 'PaymentRequest'
        ? await this.paymentRequestMakePayment(data)
        : await this.applePayJsMakePayment(data);
    } catch (error: any) {
      this.dismiss();

      throw error;
    }
  };

  @action private validateMerchant = async (
    validationUrl: string
  ): Promise<AxiosResponse> => {
    return TRANSPORT.API.get(`/v1/deposit/apple-pay-session`, {
      params: {
        validationUrl,
        provider: 'checkoutBankCard',
      },
    });
  };

  @action private paymentRequestMakePayment = async (data: ShowWebProps) => {
    const paymentMethodData = [
      {
        supportedMethods: 'https://apple.com/apple-pay',
        data: {
          version: 3,
          merchantIdentifier: data.merchantIdentifier,
          merchantCapabilities: data.merchantCapabilities,
          supportedNetworks: data.supportedNetworks,
          countryCode: data.countryCode,
        },
      },
    ];

    // NOTE: The last item is used for the total field to maintain the same structure as native Apple Pay.
    const lastItem =
      data.paymentSummaryItems[data.paymentSummaryItems.length - 1]; // Get the last item for the "total" field
    const restItems = data.paymentSummaryItems.slice(0, -1); // Other items go to the displayItems field.

    const paymentDetails = {
      total: {
        label: lastItem!.label,
        amount: {
          value: lastItem!.amount,
          currency: data.currencyCode,
        },
      },
      // NOTE: If there is more than one item, the last one serves as "total" field, the rest are for "displayItems"
      ...(data.paymentSummaryItems.length > 1
        ? {
            displayItems: restItems.map(el => {
              return {
                label: el.label,
                amount: {
                  value: el.amount,
                  currency: data.currencyCode,
                },
              };
            }),
          }
        : {}),
    };

    const request = new (PaymentRequest as unknown as ApplePayPaymentRequest)(
      paymentMethodData,
      paymentDetails,
      this.paymentOptions
    );

    request.onmerchantvalidation = event => {
      const merchantSessionPromise = this.validateMerchant(event.validationURL)
        .then(res => {
          return res.data;
        })
        .catch(error => {
          SENTRY.captureMessage('Merchant validation error', {
            source: 'Payment Request',
            extra: {
              errorDetails: error.message || error,
            },
          });

          LOCAL_NOTIFICATIONS.error({
            text: i18n.t('common.errors.smth_went_wrong'),
          });

          throw error;
        });

      event.complete(merchantSessionPromise);
    };

    request.onpaymentmethodchange = (event: PaymentMethodChangeEvent) => {
      if (event.methodDetails.type !== undefined) {
        const paymentDetailsUpdate = {
          total: paymentDetails.total,
        };
        event.updateWith(paymentDetailsUpdate);
      }
    };

    request.onshippingoptionchange = event => {
      // Define PaymentDetailsUpdate based on the selected shipping option.
      // No updates or errors needed, pass an object with the same total.
      const paymentDetailsUpdate = {
        total: paymentDetails.total,
      };
      event.updateWith(paymentDetailsUpdate);
    };

    request.onshippingaddresschange = event => {
      // Define PaymentDetailsUpdate based on a shipping address change.
      const paymentDetailsUpdate = {};
      event.updateWith(paymentDetailsUpdate);
    };

    this.paymentRequest = request;

    const paymentResponse = await request.show();

    this.paymentResponse = paymentResponse;

    return paymentResponse.details.token.paymentData;
  };

  @action private applePayJsMakePayment = async (
    data: ShowWebProps
  ): Promise<PaymentData> => {
    return new Promise((resolve, reject) => {
      // NOTE: The last item is used for the total field to maintain the same structure as native Apple Pay.
      const lastItem =
        data.paymentSummaryItems[data.paymentSummaryItems.length - 1]; // Get the last item for the "total" field
      const restItems = data.paymentSummaryItems.slice(0, -1); // Other items go to the displayItems field.

      const request: ApplePayJS.ApplePayPaymentRequest = {
        currencyCode: data.currencyCode,
        countryCode: data.countryCode,
        supportedNetworks: data.supportedNetworks,
        merchantCapabilities:
          data.merchantCapabilities as ApplePayJS.ApplePayPaymentRequest['merchantCapabilities'],
        total: {
          label: lastItem!.label,
          amount: lastItem!.amount,
        },
        // NOTE: If there is more than one item, the last one serves as "total" field, the rest are for "displayItems"
        ...(data.paymentSummaryItems.length > 1
          ? {
              lineItems: restItems.map(el => {
                return {
                  label: el.label,
                  amount: el.amount,
                };
              }),
            }
          : {}),
      };

      const session = new ApplePaySession(10, request);
      session.begin();

      session.onvalidatemerchant = (
        event: ApplePayJS.ApplePayValidateMerchantEvent
      ) => {
        this.validateMerchant(event.validationURL)
          .then(res => {
            session.completeMerchantValidation(res.data);
          })
          .catch(error => {
            reject(error);
          });
      };

      session.onpaymentauthorized = (
        event: ApplePayJS.ApplePayPaymentAuthorizedEvent
      ) => {
        resolve(event.payment.token.paymentData);
      };

      session.oncancel = () => {
        reject(new Error('Canceled'));
      };

      this.paymentRequest = session;
    });
  };
}

export default new PlatformPaymentModule();
