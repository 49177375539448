import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {type HeaderBaseProps, HeaderBase} from '../../components';
import {getVoletWizardTitle} from './utils';

export interface HeaderProps extends HeaderBaseProps {
  ticker: string;
}

export const Header: React.FC<HeaderProps & BoxProps> = observer(
  ({ticker, ...rest}) => {
    return (
      <HeaderBase shouldShowSeparator {...rest}>
        {getVoletWizardTitle(ticker)}
      </HeaderBase>
    );
  }
);
