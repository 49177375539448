import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {ModalDynamic} from '@youtoken/ui.modal';
import {getTitle} from './utils';
import {
  type MinerGetMoreSparksContentProps,
  MinerGetMoreSparksContent,
} from './Content';
import {ModalNotice} from '../ModalNotice';

export const MinerGetMoreSparksModal: React.FC<MinerGetMoreSparksContentProps> =
  cell(({noticeText, onClose, ...modalProps}) => {
    const handleClose = () => {
      onClose?.();
    };

    return (
      <ModalDynamic
        isOpen
        title={getTitle()}
        shouldShowHeaderSeparator={false}
        onClose={handleClose}
        {...modalProps}
      >
        {Boolean(noticeText) && <ModalNotice text={noticeText!} mt={-1} />}
        <MinerGetMoreSparksContent
          noticeText={noticeText}
          onClose={onClose}
          pt={noticeText ? 24 : 0}
          pb={24}
        />
      </ModalDynamic>
    );
  });
