import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Text} from '@youtoken/ui.primitives';
import {
  InformationTable,
  InformationTableRow,
} from '@youtoken/ui.information-table';
import {useForm} from '@youtoken/ui.form-elements';
import {BundleDepositFormState} from '../../../../state';

export const DataTable: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {
    form: {calculated},
  } = useForm<BundleDepositFormState>();

  if (!calculated.data) {
    return null;
  }

  return (
    <InformationTable {...props}>
      <InformationTableRow
        left={t('surface.bundles.deposit_form.min_deposit')}
        right={
          <Text variant="$body-02" color="$text-02">
            {calculated.data.minDepositFormatted}{' '}
            {calculated.data.tickerFormatted}
          </Text>
        }
      />
      <InformationTableRow
        isLast={true}
        left={t('surface.bundles.deposit_form.fee')}
        right={
          <Text variant="$body-02" color="$text-02">
            {calculated.data.feeFormatted} {calculated.data.tickerFormatted}
          </Text>
        }
      />
    </InformationTable>
  );
});
