import * as React from 'react';
import {ScrollView} from 'react-native';
import {ActivityIndicator, Separator} from '@youtoken/ui.elements';
import {
  HodlHistoryResource,
  HodlOperationType,
} from '@youtoken/ui.resource-hodl';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {ModalDynamic} from '@youtoken/ui.modal';
import {cell} from '@youtoken/ui.cell';
import {HODLDetailsButton} from '@youtoken/ui.hodl-elements';
import {HeaderSections, InformationTableSections} from './sections';
import {HowHODLWorksInHistorySurfaceSmart} from '../HowHODLWorks';

interface HodlHistorySurfaceProps extends BoxProps {
  hodlId: string;
}

export const HodlHistorySurface: React.FC<HodlHistorySurfaceProps> = cell(
  ({hodlId, ...boxProps}) => {
    const {t} = useTranslation();
    const {data} = HodlHistoryResource.use({hodlId});
    const [isDetailsModalOpen, setIsDetailsModalOpen] = React.useState(false);
    const [hodlHistoryItemId, setHodlHistoryItemId] = React.useState<
      string | null
    >(null);

    const handleDetailsModalOpen = React.useCallback((id: string) => {
      setHodlHistoryItemId(id);
      setIsDetailsModalOpen(true);
    }, []);

    const handleDetailsModalClose = React.useCallback(() => {
      setHodlHistoryItemId(null);
      setIsDetailsModalOpen(false);
    }, []);

    React.useEffect(() => {
      DATA_LAYER.trackStrict('hodl-history', {hodlId: hodlId});
    }, []);

    return (
      <>
        <ScrollView
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}
          bounces={false}
        >
          <Box {...boxProps} position="relative">
            {data.map((el, i) => {
              const isLast = i === data.length - 1;
              const shouldShowDetails =
                el.operationType !== HodlOperationType.CLOSED &&
                el.operationType !== HodlOperationType.CANCELED;

              return (
                <Box key={i}>
                  <Box my={24} px={24}>
                    <HeaderSections
                      status={el.operationType}
                      date={el.historyItemDateFormatted}
                    />
                    <InformationTableSections historyItem={el} />
                    {shouldShowDetails && (
                      <HODLDetailsButton
                        onPress={() => handleDetailsModalOpen(el.id)}
                        testID={`HOW_IT_WORKS_BUTTON_${i + 1}`}
                        text={t('surface.hodls.details')}
                      />
                    )}
                  </Box>
                  {!isLast && <Separator />}
                </Box>
              );
            })}
          </Box>
        </ScrollView>
        <ModalDynamic
          isOpen={isDetailsModalOpen}
          onClose={handleDetailsModalClose}
          snapPoints={['90%']}
          title={t('surface.hodls.mh_details')}
          nativeStackBehavior="push"
          shouldBeScrollable
          borderRadius={14}
          maxWidth={590}
        >
          {Boolean(hodlHistoryItemId) && (
            <HowHODLWorksInHistorySurfaceSmart
              hodlId={hodlId}
              hodlHistoryItemId={hodlHistoryItemId!}
            />
          )}
        </ModalDynamic>
      </>
    );
  },
  {
    // NOTE: In order to make the loader look better (it was stuck to a header separator)
    SuspenseFallback: () => (
      <Box pt={32}>
        <ActivityIndicator />
      </Box>
    ),
  }
);
