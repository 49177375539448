import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {
  ActiveHODLsPaginationFeature,
  HODLsActiveBlock,
  HODLsListFeature,
  HODLsPendingBlock,
  EmptyState,
  NoActivePositionsState,
  OpenNewHODLButton,
  HODLsRecentlyClosedBlock,
} from '@youtoken/ui.surface-hodl-positions';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';

export const HODLsPortfolioPageContent: React.FC = cell(() => {
  const {
    queryParams: {pageActive},
  } = RouterResource.use({});
  const {
    subscribeToUpdates,
    isEmpty,
    hasActivePositions,
    shouldShowActiveHODLs,
    shouldShowPendingHODLs,
    shouldShowRecentlyClosedHODLs,
  } = HODLsListFeature.use({});
  const {setPage: setPageActive} = ActiveHODLsPaginationFeature.use({});

  //NOTE: subscribe to WS updates, subscribeToUpdates contains return function
  React.useEffect(subscribeToUpdates, []);

  // NOTE: scroll to top mostly after redirecting on this page
  React.useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  //NOTE: update pagination in feature
  React.useEffect(() => {
    setPageActive(pageActive ? Number(pageActive) : 1);
  }, [pageActive]);

  return (
    <>
      {isEmpty ? (
        <EmptyState />
      ) : (
        <>
          {hasActivePositions ? (
            <OpenNewHODLButton />
          ) : (
            <NoActivePositionsState py={24} />
          )}
          {shouldShowActiveHODLs && <HODLsActiveBlock mt={24} />}
          {shouldShowPendingHODLs && <HODLsPendingBlock mt={24} />}
          {shouldShowRecentlyClosedHODLs && (
            <HODLsRecentlyClosedBlock mt={24} />
          )}
        </>
      )}
    </>
  );
});
