import {observable} from 'mobx';
import {
  BundleDepositFormStateArgs,
  BundleDepositFormStateResources,
} from './types';
import {Form} from './Form';

export class BundleDepositFormState {
  @observable
  public form: Form;

  constructor(
    args: BundleDepositFormStateArgs,
    resources: BundleDepositFormStateResources
  ) {
    this.form = new Form(args, resources);
  }
}
