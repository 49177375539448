import {IReactionDisposer, reaction} from 'mobx';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  BundleTariffOverviewResourceResponse,
  bundleTariffOverviewResourceResourceResponseSchema,
} from './BundleTariffOverviewResourceResponse';

export interface BundleTariffOverviewResourceArgs {
  id: string;
}

export class BundleTariffOverviewResource extends createResource<
  BundleTariffOverviewResourceArgs,
  BundleTariffOverviewResourceResponse
>({
  getKey: ({id}: BundleTariffOverviewResourceArgs) => {
    return `BundleTariffOverview(${id})`;
  },
  getData: ({id}: BundleTariffOverviewResourceArgs) => {
    const quoteTicker = AuthMeResource.getInstanceSafely({})?.mainCurrency;

    return TRANSPORT.API.get<
      typeof bundleTariffOverviewResourceResourceResponseSchema
    >(
      `/v1/bundle/tariff/overview?tariffId=${id}&quoteTicker=${quoteTicker}`
    ).then(response => {
      bundleTariffOverviewResourceResourceResponseSchema.parse(response.data);

      return deserialize(BundleTariffOverviewResourceResponse, response.data);
    });
  },
  skipRefreshOnVisible: false,
}) {
  disposers: Array<IReactionDisposer> = [];

  constructor(
    args: BundleTariffOverviewResourceArgs,
    response: BundleTariffOverviewResourceResponse
  ) {
    super(args, response);

    this.disposers.push(
      reaction(
        () => AuthMeResource.getInstanceSafely({})?.mainCurrency,
        () => {
          this.refetch();
        }
      )
    );
  }

  onDestroy(): void {
    super.onDestroy();
    this.disposers.forEach(disposer => disposer?.());
  }
}
