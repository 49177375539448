export type ChangeEventPayload = {
  value: string;
};

export type PlatformPaymentViewProps = {
  name: string;
};

export type MerchantCapability =
  | 'supports3DS'
  | 'supportsEMV'
  | 'supportsCredit'
  | 'supportsDebit';

export type PaymentNetwork =
  | 'JCB'
  | 'amex'
  | 'cartesBancaires'
  | 'chinaUnionPay'
  | 'discover'
  | 'eftpos'
  | 'electron'
  | 'elo'
  | 'idCredit'
  | 'interac'
  | 'mada'
  | 'maestro'
  | 'masterCard'
  | 'privateLabel'
  | 'quicPay'
  | 'suica'
  | 'vPay'
  | 'visa'
  | 'barcode'
  | 'girocard'
  | 'mir'
  | 'nanaco'
  | 'waon'
  | 'dankort'
  | 'bancomat'
  | 'bancontact'
  | 'postFinance';

export enum CompleteStatus {
  success = 0, // success - Merchant auth'd (or expects to auth) the transaction successfully.
  failure = 1, // failure - Merchant failed to auth the transaction.
}

export type PaymentData = {
  data: string;
  header: {
    ephemeralPublicKey: string;
    publicKeyHash: string;
    transactionId: string;
  };
  paymentNetwork: string;
  signature: string;
  version: string;
};
