import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {useForm, SubmitButton} from '@youtoken/ui.form-elements';
import type {BundleWithdrawalFormState} from '../../state';

export const Footer: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {
    form: {
      resultAmountFormatted,
      tickerFormatted,
      calculated: {loading},
    },
  } = useForm<BundleWithdrawalFormState>();

  return (
    <Box
      p={{
        default: 16,
        phone: 24,
      }}
      gap={24}
      {...props}
    >
      <Text variant="$body-02" color="$text-02">
        {t('surface.bundles.withdrawal_form.result', {
          ticker: tickerFormatted,
          amount: resultAmountFormatted,
        })}
      </Text>
      <SubmitButton loading={loading}>
        {t('surface.bundles.withdrawal_form.submit')}
      </SubmitButton>
    </Box>
  );
});
