import {
  action,
  computed,
  IReactionDisposer,
  observable,
  runInAction,
  reaction,
} from 'mobx';
//@ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
//@ts-ignore
import MobxReactForm from 'mobx-react-form';
import {now} from 'mobx-utils';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {TRANSPORT} from '@youtoken/ui.transport';
import * as yupPackage from '@youtoken/ui.yup';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  getTranslatedValidationMessage,
  handleGeneralErrorTranslated,
  messages,
} from '@youtoken/ui.validation-messages';
import {
  UmaInvoiceConfirmationFormStateArgs,
  type UmaInvoiceConfirmationFormStateResource,
} from './index';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {__GLOBAL_RECAPTCHA__} from '@youtoken/ui.two-factor-auth-and-recaptcha';

export class Form {
  @observable
  resources: UmaInvoiceConfirmationFormStateResource;

  @observable
  args: UmaInvoiceConfirmationFormStateArgs;

  @observable
  instance: MobxReactForm;

  @observable
  public rateTimeLeft: number;

  @observable
  rateTimeInterval: number = 0;

  @observable disposers: IReactionDisposer[] = [];

  constructor(
    args: UmaInvoiceConfirmationFormStateArgs,
    resources: UmaInvoiceConfirmationFormStateResource
  ) {
    this.resources = resources;
    this.args = args;

    // NOTE: Set initial rate time left
    this.rateTimeLeft = resources.umaInvoiceResource.data.expirationInterval;

    this.disposers.push(
      reaction(
        () => now(),
        () => {
          if (this.rateTimeLeft > 0) {
            this.rateTimeLeft = this.rateTimeLeft - 1;
          } else {
            if (!this.resources.umaInvoiceResource.isLoading) {
              this.updateRate();
            }
          }
        }
      ),
      reaction(
        () => this.resources.umaInvoiceResource.data.expiresAtTimestamp,
        () => {
          this.rateTimeLeft =
            this.resources.umaInvoiceResource.data.expirationInterval;
        }
      )
    );

    this.instance = new MobxReactForm(
      {
        fields: {
          invoiceRequestId: {
            value: this.resources.umaInvoiceResource.data.invoiceRequestId,
          },
          twoFactorAuthOperationId: {
            value: this.twoFactorAuthOperationId,
          },
          twoFactorAuthOperationCode: {
            value: this.twoFactorAuthOperationCode,
          },
        },
      },
      {
        hooks: {
          onSuccess: (form: MobxReactForm) => {
            const values = form.values();

            return TRANSPORT.API.post('/v1/uma/transaction', {
              invoiceRequestId: values.invoiceRequestId,
              operationId: values.twoFactorAuthOperationId,
              code: values.twoFactorAuthOperationCode,
            })
              .then(() => {
                SHARED_ROUTER_SERVICE.navigate('__CloseModal');
                SHARED_ROUTER_SERVICE.navigate('WalletsItem', {
                  ticker: this.args.ticker,
                });
              })
              .catch(e => {
                handleFormSubmitError(form, e, {
                  operationId: 'twoFactorAuthOperationId',
                  code: 'twoFactorAuthOperationCode',
                });
              });
          },
          onError: () => {
            LOCAL_NOTIFICATIONS.error({
              text: i18n.t('validation.VALIDATION'),
            });
          },
        },
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                let twoFactorAuthOperationSchema = yup.string();

                if (this.twoFactorAuthType) {
                  twoFactorAuthOperationSchema =
                    twoFactorAuthOperationSchema.required(
                      messages.TFA_REQUIRED
                    );
                }

                const schema = {
                  invoiceRequestId: yup.string().required(),
                  twoFactorAuthOperationId: twoFactorAuthOperationSchema,
                  twoFactorAuthOperationCode: twoFactorAuthOperationSchema,
                };

                return yup.object().shape(schema);
              });
            },
          }),
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );
  }

  @computed
  public get invoiceData() {
    return this.resources.umaInvoiceResource.data;
  }

  //#region interval rate

  @computed
  public get rateTimeIntervalName() {
    return `rate-interval-${this.resources.umaInvoiceResource.data.expiresAtTimestamp}`;
  }

  // NOTE: return value from 0 to 1
  @computed
  get rateTimeIntervalProgress() {
    const progressPercent =
      (this.resources.umaInvoiceResource.data.expirationInterval -
        this.rateTimeLeft) /
      (this.resources.umaInvoiceResource.data.expirationInterval - 1);

    if (progressPercent < 0) {
      return 0;
    }

    return progressPercent;
  }

  @action
  updateRate = () => {
    this.args.onRateTimerEnd();
  };

  @action
  onSubmit = () => {
    this.instance.onSubmit({
      preventDefault: () => {},
    });
  };

  //#endregion interval rate

  //#region twoFactorAuth

  @computed
  get twoFactorAuthType() {
    return this.resources.authMe.twoFactorAuthType;
  }

  @computed
  get twoFactorAuthOperationId() {
    return (
      this.instance?.$('twoFactorAuthOperationId').get('value') ?? undefined
    );
  }

  @computed
  get twoFactorAuthOperationCode() {
    return this.instance?.$('twoFactorAuthOperationCode').get('value') ?? '';
  }

  @computed
  get twoFactorAuthOperationCodeError() {
    return getTranslatedValidationMessage(
      this.instance.$('twoFactorAuthOperationId').get('error') ??
        this.instance.$('twoFactorAuthOperationCode').get('error')
    );
  }

  @action
  twoFactorAuthOperationIdOnChange = (value: string) => {
    this.instance.$('twoFactorAuthOperationId').get('onChange')(value);
  };

  @action
  twoFactorAuthOperationCodeOnChange = (value: string) => {
    this.instance.$('twoFactorAuthOperationCode').get('onChange')(value);
  };

  @action
  createTwoFactorAuthOperation = (): Promise<{
    operationId: string;
    phoneMask?: string;
  }> => {
    return __GLOBAL_RECAPTCHA__
      .requestToken('crypto_withdrawal_tfa')
      .then(token =>
        TRANSPORT.API.post('/v1/uma/transaction/authorize', {
          operationId: this.twoFactorAuthOperationId,
          token,
        })
      )
      .then(({data}) => {
        runInAction(() => {
          this.twoFactorAuthOperationIdOnChange(data.operationId);
        });
        return data;
      })
      .catch(response => {
        handleGeneralErrorTranslated(response?.data);
      });
  };

  //#endregion twoFactorAuth

  @action
  dispose = () => {
    this.disposers.forEach(disposer => {
      disposer?.();
    });
  };
}
