import React from 'react';
import {observer} from 'mobx-react';
import {Platform} from 'react-native';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {AmountBadge, ValidationMessage} from '@youtoken/ui.hodl-elements';
import {AmountAndPercentInput} from '@youtoken/ui.amount-and-percent-input';
import {AdjustTpSlFormClass} from '../form';

export const MarginCallBlock: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const testIdSection = 'STOP_LOSS';

  const {
    view: {
      stopLossValue,
      stopLossPercent,
      stopLossPercentMin,
      stopLossPercentMax,
      stopLossLimitMessage,
      setStopLossLimit,
      slError,
      slErrorIsClickable,
      maxLoss,
      maxTicker,
    },
    form: {updateStopLossValue, updateStopLossPercent, isShort, precision},
  } = useForm<AdjustTpSlFormClass>();

  const validationComponent = React.useMemo(() => {
    return (
      <ValidationMessage
        validationMessage={stopLossLimitMessage}
        onValidationClick={setStopLossLimit}
        validationError={slError}
        isValidationClickable={slErrorIsClickable}
        testIdSection={testIdSection}
      />
    );
  }, [stopLossLimitMessage, setStopLossLimit, slError, slErrorIsClickable]);

  return (
    <Box {...boxProps}>
      <Box
        position="relative"
        zIndex={1}
        width="100%"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box flexDirection="row" alignItems="center">
          <Text variant="$body-01-high-accent">
            {t('surface.hodls.adjust_tp.stop_loss')}
          </Text>
          <QuestionTooltip
            content={t('surface.hodls.adjust_tp.stop_loss_tooltip')}
            bodyHorizontalOffset={60}
            width={270}
            position={Platform.select({default: 'bottom', web: undefined})}
          />
        </Box>

        <AmountBadge amount={maxLoss} ticker={maxTicker} />
      </Box>

      <AmountAndPercentInput
        amountLabel={t('surface.hodls.adjust_tp.level')}
        percentLabel={t('surface.hodls.adjust_tp.change')}
        amountValue={stopLossValue}
        percentValue={stopLossPercent}
        precisionAmount={precision}
        percentRangeMin={stopLossPercentMin}
        percentRangeMax={stopLossPercentMax}
        onChangeAmount={updateStopLossValue}
        onChangePercent={updateStopLossPercent}
        sliderKey="stopLoss"
        percentSignIsActive={true}
        validationComponent={validationComponent}
        shouldReverseSlider={!isShort}
        testIdSection={testIdSection}
        mt={8}
        pb={8}
      />
    </Box>
  );
});
