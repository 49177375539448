import * as React from 'react';
import {ScrollView} from 'react-native';
import {cell} from '@youtoken/ui.cell';
import {UpgradeYourLevelSurfaceCell} from '@youtoken/ui.surface-account-levels';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {modal} from '../../../stores';

export const modalUpgradeYourLevelName = 'modalUpgradeYourLevel';

export const ModalUpgradeYourLevel = cell<Omit<ModalDynamicProps, 'children'>>(
  () => {
    const {t} = useTranslation();
    const {opened, close} = modal;

    return (
      <ModalDynamic
        isOpen={opened?.name === modalUpgradeYourLevelName}
        onClose={close}
        title={t('surface.loyalty.next_level.upgrade')}
        shouldShowHeaderSeparator={false}
      >
        <ScrollView showsVerticalScrollIndicator={false}>
          <UpgradeYourLevelSurfaceCell p={24} />
        </ScrollView>
      </ModalDynamic>
    );
  }
);
