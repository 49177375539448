import * as React from 'react';
import {observer} from 'mobx-react';
import {formatPercent} from '@youtoken/ui.formatting-utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BundleTariff} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {BundleIcon} from '../../../../../../components';

export interface BundlesTariffSummaryProps {
  data: BundleTariff;
}

export const BundlesTariffSummary: React.FC<
  BundlesTariffSummaryProps & BoxProps
> = observer(
  ({
    data: {
      design: {iconUrl, title, description},
      priceDiffIsPositive,
      priceDiffFormatted,
    },
    ...boxProps
  }) => {
    const {t} = useTranslation();

    return (
      <Box gap={16} {...boxProps}>
        <Box gap={8}>
          <Box flexDirection="row" alignItems="center" gap={8}>
            <BundleIcon uri={iconUrl} size={24} />
            <Box flex={1}>
              <Text variant="$body-01-high-accent" color="$text-01">
                {title}
              </Text>
            </Box>
          </Box>
          <Box>
            <Text variant="$body-02" color="$text-01">
              {description}
            </Text>
          </Box>
        </Box>
        <Box>
          <Text variant="$body-02" color="$text-01">
            {t('surface.bundles.tariff.anual_average')}
          </Text>
          <Box flexDirection="row" alignItems="center" gap={4}>
            <Icon
              name={priceDiffIsPositive ? 'up' : 'down'}
              color={priceDiffIsPositive ? '$success-01' : '$danger-01'}
              size={20}
            />
            <Text
              variant="$body-01-medium-accent"
              color={priceDiffIsPositive ? '$success-01' : '$danger-01'}
            >
              {priceDiffFormatted}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  }
);
