import * as React from 'react';
import {observer} from 'mobx-react';
import {ScrollView} from 'react-native';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {LevelIcon} from '@youtoken/ui.loyalty-miner-components';
import {invariant} from '@youtoken/ui.utils';
import {useSafeArea} from '@youtoken/ui.hooks';
import {AccountLevelAllBenefitsLevelStatusView} from './LevelStatusView';
import {LevelInfoSavings} from './Savings';
import {LevelInfoMarketAnalytics} from './MarketAnalytics';
import {LevelInfoFootbalTickets} from './FootbalTickets';
import {LevelInfoMiner} from './Miner';
import {LevelInfoConversion} from './Conversion';
import {AccountLevelAllBenefitsRequirements} from './Requirements';

const benefitsItemBoxProps: BoxProps = {
  p: 16,
  backgroundColor: '$ui-background',
  borderRadius: 12,
};

export interface AccountLevelAllBenefitsProps {
  level: number;
}

export const AccountLevelAllBenefits: React.FC<
  AccountLevelAllBenefitsProps & BoxProps
> = observer(({level, ...boxProps}) => {
  const {bottom} = useSafeArea();

  const {
    authMe: {
      products: {
        saving4: {isEnabled: isSaving4Enabled},
        miner: {isEnabled: showMinerInfo},
        exchange: {
          settings: {enableConvertsLoyalty: _showExchangeInfo},
        },
      },
    },
    loyalty: {getLevel, levels},
  } = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
    loyalty: getResourceDescriptor(LoyaltyResource, {}),
    rates: getResourceDescriptor(RatesResource, {}),
  });

  const levelItem = getLevel(level);

  invariant(
    levelItem,
    `cannot get saving info for level ${level}`,
    {},
    {levels, level}
  );

  const {
    name,
    cardIconName,
    cardColor,
    saving: {shouldShowTariff},
  } = levelItem;

  const showPromoBenefits = level > 4;

  const showExchangeInfo = _showExchangeInfo && level > 2;

  const showSavingsInfo = isSaving4Enabled && shouldShowTariff;

  React.useEffect(() => {
    DATA_LAYER.trackStrict('loyalty-all-benefits-opened', {
      id: level.toString(),
    });
  }, []);

  return (
    <Box
      testID="ACCOUNT_LEVEL_ALL_BENEFITS"
      flex={1}
      backgroundColor={cardColor}
      borderRadius={12}
      {...boxProps}
    >
      <AccountLevelAllBenefitsLevelStatusView
        level={level}
        left={16}
        right="auto"
      />
      <Box alignItems="center" my={24}>
        <LevelIcon name={cardIconName} />
        <Text
          testID="ACCOUNT_LEVEL_ALL_BENEFITS_NAME"
          color="$text-01"
          variant="$body-01-high-accent"
          mt={12}
        >
          {name}
        </Text>
      </Box>
      <ScrollView
        contentContainerStyle={{
          paddingBottom: bottom,
          gap: 24,
        }}
        scrollIndicatorInsets={{
          bottom,
        }}
      >
        <Box px={{default: 16, phone: 24}} gap={16}>
          {showMinerInfo && (
            <LevelInfoMiner level={level} {...benefitsItemBoxProps} />
          )}
          {showPromoBenefits && (
            <>
              <LevelInfoMarketAnalytics {...benefitsItemBoxProps} />
              <LevelInfoFootbalTickets
                level={level}
                {...benefitsItemBoxProps}
              />
            </>
          )}
          {showExchangeInfo && (
            <LevelInfoConversion level={level} {...benefitsItemBoxProps} />
          )}
          {showSavingsInfo && (
            <LevelInfoSavings level={level} {...benefitsItemBoxProps} />
          )}
        </Box>
        <Box justifyContent="center" alignItems="center" mb={bottom ? 0 : 24}>
          <AccountLevelAllBenefitsRequirements id={level} />
        </Box>
      </ScrollView>
    </Box>
  );
});
