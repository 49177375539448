import * as React from 'react';
import {AuthMeResource, LegalEntity} from '@youtoken/ui.resource-auth-me';
import {Heading, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

export type DisclosureConversionsProps = {};

export const DisclosureConversions: React.FC<
  DisclosureConversionsProps
> = () => {
  const {legalEntity} = AuthMeResource.use({});

  const {t} = useTranslation();

  if (legalEntity === LegalEntity.ITA) {
    return null;
  }

  return (
    <>
      <Heading variant="$heading-03" textTransform="uppercase" mb={16}>
        {t('surface.profile.legal_info.subtitle.products_list')}
      </Heading>

      <Text variant="$body-02" color="$text-02" mb={16}>
        {t('surface.profile.legal_info.products_list.p1')}
      </Text>
      <Text variant="$body-02" color="$text-02" mb={16}>
        {t('surface.profile.legal_info.products_list.p2')}
      </Text>
      <Text variant="$body-02" color="$text-02" mb={16}>
        {t('surface.profile.legal_info.products_list.p3')}
      </Text>
      <Text variant="$body-02" color="$text-02" mb={16}>
        {t('surface.profile.legal_info.products_list.p4')}
      </Text>
    </>
  );
};
