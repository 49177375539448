import {object, primitive, serializable} from 'serializr';

/** To enable new feature (experiment), add it to the enum below */
export enum Features {
  FLIP_ORDER_AVAILABLE = 'FLIP_ORDER_AVAILABLE', // NOTE: deprecated
  SAVINGS_WIDGET = 'SAVINGS_WIDGET',
  STORIES_HIDDEN = 'STORIES_HIDDEN2',
  NATIVE_SUMSUB = 'NATIVE_SUMSUB',
  TRADE_WITHOUT_KYC = 'TRADE_WITHOUT_KYC',
}

export class FeatureItem {
  @serializable(primitive())
  enabled!: boolean;

  @serializable(primitive())
  name?: string;

  @serializable(primitive())
  variant?: string;
}

export class FeaturesResponse implements Record<Features, FeatureItem> {
  @serializable(object(FeatureItem))
  '*'?: FeatureItem;

  [Features.FLIP_ORDER_AVAILABLE]!: FeatureItem;
  [Features.SAVINGS_WIDGET]!: FeatureItem;
  [Features.STORIES_HIDDEN]!: FeatureItem;
  [Features.NATIVE_SUMSUB]!: FeatureItem;
  [Features.TRADE_WITHOUT_KYC]!: FeatureItem;
}
