import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Heading} from '@youtoken/ui.primitives';
import {HODLsListFeature} from '../../../features';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HODLsList} from '../../../components';

export const HODLsPendingBlock: React.FC<BoxProps> = observer(boxProps => {
  const {pending: pendingHODLs} = HODLsListFeature.use({});
  const {t} = useTranslation();

  return (
    <Box {...boxProps}>
      <Box mb={18} px={{default: 20, desktop: 0}}>
        <Heading variant="$heading-01">
          {t('surface.hodls.portfolio.pending_title')}
        </Heading>
      </Box>
      <HODLsList hodlsList={pendingHODLs} />
    </Box>
  );
});
