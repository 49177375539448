import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useForm} from '@youtoken/ui.form-elements';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {type CreateHODLFormClass} from '@youtoken/ui.surface-hodl-create';
import {type ExtendTpSlForm} from '../../ExtendTpSlBottomSheet/form';

export const UseBonusesContent: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    form: {maxBonusesPercent, additionalInputAmount},
  } = useForm<CreateHODLFormClass | ExtendTpSlForm>();

  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      {...boxProps}
    >
      <Box flex={1}>
        <Text variant="$body-02" color="$text-02">
          {t('surface.hodls.item.use_bonuses_section.limit_description', {
            percent: maxBonusesPercent,
          })}
        </Text>
      </Box>

      <Box backgroundColor="$interactive-02" py={4} px={8} borderRadius={8}>
        <Text variant="$body-01-medium-accent" color="$text-05">
          {t('surface.hodls.item.use_bonuses_section.to_use', {
            amount: additionalInputAmount,
          })}
        </Text>
      </Box>
    </Box>
  );
});
