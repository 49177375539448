import toBlob from 'b64-to-blob';
import {AxiosResponse} from 'axios';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {i18n} from '@youtoken/ui.service-i18n';
import {TRANSPORT} from '@youtoken/ui.transport';
import {SENTRY} from '@youtoken/ui.sentry';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {History} from 'history';
import {modal} from '@web-app/src/stores';
import {isWebView} from '@web-app/src/utils';
import {
  modalAddWalletsName,
  modalBankCardAddMoneyName,
  modalBankCardTransactionsName,
  modalBankCardTransferName,
  modalSignUpAdditionalName,
  modalUpgradeYourLevelName,
  modalIncentivesDetailsName,
} from '@web-app/src/components/modals';

/** parse response to pdf blob */
export const parseAgreementResponse = (
  // data is base64 string of the pdf
  response: AxiosResponse<{data: string}>
) => {
  return URL.createObjectURL(toBlob(response.data.data, 'application/pdf'));
};

/**  download agreement (for ledger Live) */
const handleAgreementUrlForLedgerLive = (url: string) => {
  if (isWebView()) {
    return alert(i18n.t('ledger_app.error.download_from_webview_denied'));
  }

  const newLink = document.createElement('a');

  TRANSPORT.API.get(url)
    .then(response => {
      newLink.href = parseAgreementResponse(response);
      newLink.download = `Agreement`;
      newLink.click();
    })
    .catch(error => {
      SENTRY.capture(error, {
        source: 'handleAgreementUrlForLedgerLive',
      });
    })
    .finally(() => {
      newLink.remove();
    });

  return;
};

/** open agreement in new tab for web-app */
const handleAgreementUrlForWebApp = (url: string) => {
  const newTab = window.open('', '_blank');

  if (newTab?.document?.location) {
    TRANSPORT.API.get(url).then(response => {
      if (newTab?.document?.location) {
        newTab.document.title = `Agreement`;
        newTab.document.location.href = parseAgreementResponse(response);
      } else {
        LOCAL_NOTIFICATIONS.error({
          text: i18n.t('common.errors.smth_went_wrong'),
        });
      }
    });
  } else {
    LOCAL_NOTIFICATIONS.error({text: i18n.t('common.errors.smth_went_wrong')});
  }
};

/** open agreement in new tab or download it */
const handleAgreementUrl = (url: string) => {
  if (ENVIRONMENT.WEB_APP_ENV === 'ledger-app') {
    handleAgreementUrlForLedgerLive(url);
  }

  if (ENVIRONMENT.WEB_APP_ENV === 'web-app') {
    handleAgreementUrlForWebApp(url);
  }
};

SHARED_ROUTER_SERVICE.provideHandlers(
  {
    NotFound: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    Explorer: (name, {url}) => {
      window.open(url, '_blank');
    },
    Agreement: (_name, {url}) => {
      handleAgreementUrl(url);
    },
    MultiHODL: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    MultiHODLInstruments: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    NewMultiHODL: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    HODLInstrument: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    HodlExtendTpSl: (_, params) => {
      modal.open('hodlExtendTpSl', params);
    },
    HodlCloseNow: (name, params) => {
      modal.open('hodlClose', params);
    },
    HodlFlip: (_, params) => {
      modal.open('hodlFlip', params);
    },
    MultiHODLPortfolio: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    MultiHODLsClosed: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    HodlHistory: (_, params) => {
      modal.open('hodlHistory', params);
    },
    HODLItemDetailed: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    AddWallets: (name, {initialFilter}) => {
      modal.open(modalAddWalletsName, {initialFilter});
    },
    WalletsList: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    WalletsItem: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    WalletsSavings: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    WalletsSavingsSelect: () => {
      modal.open('walletsSavingsSelect');
    },
    HistoryItemNew: (name, params) => {
      modal.open('historyItemDetails', params);
    },
    EarnMore: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    Verification: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    TrustedDevices: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    SecurityActivity: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    AntiPhishing: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    LegalAndInfo: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    RewardsMain: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    RewardsReferrals: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    UmaCreate: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    UmaSettings: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    DeleteAccount: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    PaymentMethods: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    AddPaymentMethod: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    More: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    RestorePassword: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    SignIn: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    SignUp: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    VerifyEmail: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    DeviceConfirm: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    SignInConfirm: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    AccountLevels: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },

    //#regionstart miner
    Miner: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    //#endregion miner

    //#regionstart loans (Regular and Turbo)
    Loans: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    LoanCreate: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    LoanItem: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    LoanPay: (name, params) => {
      modal.open('loanPay', {loan: {mode: 'regular', ...params}});
    },
    LoanCloseNow: (name, params) => {
      modal.open('closeNow', {loan: {mode: 'regular', ...params}});
    },
    LoanIncrease: (name, params) => {
      modal.open('increaseLtv', {loan: {mode: 'regular', ...params}});
    },
    LoanDecrease: (name, params) => {
      modal.open('decreaseLtv', {loan: {mode: 'regular', ...params}});
    },
    LoanReopen: (name, params) => {
      modal.open('reopenLoan', {loan: {mode: 'regular', ...params}});
    },
    LoanFTP: (name, params) => {
      modal.open('setClosePrice', {loan: {mode: 'regular', ...params}});
    },
    Turbocharge: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    TurbochargeCreate: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    TurbochargeDetails: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    TurbochargePay: (name, params) => {
      modal.open('loanPay', {loan: {mode: 'turbo', ...params}});
    },
    TurbochargeCloseNow: (name, params) => {
      modal.open('closeNow', {loan: {mode: 'turbo', ...params}});
    },
    TurbochargeFTP: (name, params) => {
      modal.open('setClosePrice', {loan: {mode: 'turbo', ...params}});
    },
    //#endregion loans

    // modals
    RewardDetails: (_, params) => {
      modal.open('rewardDetails', {id: params.id});
    },
    FormAVerificationModal: () => {
      modal.open('formA');
    },
    DeviceModal: (_, params) => {
      modal.open('deviceDetails', params);
    },
    SessionModal: (_, params) => {
      modal.open('sessionDetails', params);
    },
    ActivityDetailsModal: (_, params) => {
      modal.open('securityActivityDetails', params);
    },
    DisclosureWalletsModal: (_, params) => {
      modal.open('disclosureWallets', params);
    },
    Coindrop: () => {
      modal.open('coindrop');
    },
    DisclosureHodlsModal: () => {
      modal.open('disclosureHodls');
    },
    DisclosureLoansModal: () => {
      modal.open('disclosureLoans');
    },
    DisclosureTurboModal: () => {
      modal.open('disclosureTurbo');
    },
    MultiHodlSpecification: () => {
      modal.open('commissions');
    },
    Exchange: (name, {fromTicker, toTicker, initialAmount, dl_source}) => {
      modal.open('conversion', {
        ticker: fromTicker,
        toTicker,
        initialAmount,
        dl_source,
      });
    },
    CryptoBuy: (name, {source, ticker}) => {
      modal.open('cryptoBuy', {source, ticker});
    },
    DepositWizard: (name, {source, ticker}) => {
      modal.open('depositWizard', {source, ticker});
    },
    FiatDeposit: (name, {ticker, defaultStep}) => {
      modal.open('fiatDeposit', {ticker, defaultStep});
    },
    FiatDepositBankWire: (name, {ticker}) => {
      modal.open('fiatDeposit', {ticker, defaultStep: 'bankWire'});
    },
    FiatDepositVolet: (name, {ticker}) => {
      modal.open('fiatDeposit', {ticker, defaultStep: 'volet'});
    },
    FiatDepositCard: (name, {ticker}) => {
      modal.open('fiatDeposit', {ticker, defaultStep: 'bankCard'});
    },
    FiatDepositApplePay: (name, {ticker}) => {
      modal.open('fiatDeposit', {ticker, defaultStep: 'applePay'});
    },
    FiatDepositCoDi: (name, {ticker}) => {
      modal.open('fiatDeposit', {ticker, defaultStep: 'coDi'});
    },
    FiatDepositSPEI: (name, {ticker}) => {
      modal.open('fiatDeposit', {ticker, defaultStep: 'spei'});
    },
    CryptoDeposit: (name, {ticker}) => {
      modal.open('cryptoDeposit', {ticker});
    },
    FiatWithdrawal: (name, {ticker}) => {
      modal.open('fiatWithdrawal', {ticker});
    },
    FiatWithdrawalBankWire: (name, {ticker}) => {
      modal.open('fiatWithdrawal', {ticker, defaultStep: 'bankWire'});
    },
    FiatWithdrawalBankCard: (
      _,
      {ticker},
      {conversionTicker, cardId, amount, withError}
    ) => {
      modal.open('fiatWithdrawal', {
        ticker,
        conversionTicker,
        cardId,
        amount,
        withError,
        defaultStep: 'bankCard',
      });
    },
    CryptoWithdrawal: (name, {ticker}) => {
      modal.open('cryptoWithdrawal', {ticker});
    },
    BankCards: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    BankCardAddMoney: (name, params) => {
      modal.open(modalBankCardAddMoneyName, params);
    },
    BankCardTransactions: (name, params) => {
      modal.open(modalBankCardTransactionsName, params);
    },
    BankCardTransfer: (name, params) => {
      modal.open(modalBankCardTransferName, params);
    },
    AppearanceSettings: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    PleaseVerify: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    AcquisitionSurvey: () => {
      modal.open('acquisitionSurvey');
    },
    ReSignDisclosures: () => {
      modal.open('reSignDisclosures');
    },
    TradingQuiz: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    AccountStats: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    TwoFactorSetup: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    TwoFactorAuthenticationGADisable: () => {
      modal.open('twoFactorAuthenticationGADisable');
    },
    TwoFactorAuthenticationGAEnable: () => {
      modal.open('twoFactorAuthenticationGAEnable');
    },
    TwoFactorAuthenticationSMSDisable: () => {
      modal.open('twoFactorAuthenticationSMSDisable');
    },
    SignUpAdditional: (_, params) => {
      modal.open(modalSignUpAdditionalName, params);
    },
    UpgradeYourLevel: () => {
      modal.open(modalUpgradeYourLevelName);
    },
    UpgradeToLevel: (name, params) => {
      modal.open(name, params);
    },
    AccountLevelAllBenefits: (name, params) => {
      modal.push(name, params);
    },
    AccountLevelProgressDetailed: (name, params) => {
      modal.push(name, params);
    },
    InviteFriends: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    MyFriends: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    FriendRewards: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    IncentivesDetailsModal: (_, params) => {
      modal.open(modalIncentivesDetailsName, params);
    },
    SignUpCorporate: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    MinerGetMoreSparksModal: (_, params) => {
      modal.open('MinerGetMoreSparks', params);
    },
    MinerResetBlocksModal: (_, params) => {
      modal.open('MinerResetBlocks', params);
    },
    Bundles: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    Bundle: (...args) => {
      push(SHARED_ROUTER_SERVICE.routeToUrl(...args));
    },
    BundleDeposit: (name, params) => {
      modal.open(name, params);
    },
    BundleWithdrawal: (name, params) => {
      modal.open(name, params);
    },
    __CloseModal: () => {
      modal.close();
    },
  },
  () => goBack()
);

const historyRef: {
  current?: History;
} = {
  current: undefined,
};

export const setHistoryRef = (history: History) => {
  historyRef.current = history;
};

export const push = (url: string) => {
  historyRef.current?.push(url);
};

export const goBack = () => {
  historyRef.current?.goBack();
};
