import {useSafeAreaInsets} from 'react-native-safe-area-context';

export const useSafeArea = () => {
  const {bottom, ...rest} = useSafeAreaInsets();

  return {
    bottom: Math.max(bottom, 24),
    ...rest,
  };
};
