import * as React from 'react';
import {Platform} from 'react-native';
import {makeForm, Form} from '@youtoken/ui.form-elements';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {ModalDynamic} from '@youtoken/ui.modal';
import {cell} from '@youtoken/ui.cell';
import {acquisitionSurveyData} from '../assets/acquisitionSurveyData';
import {SurveyQuestions, SuccessComponent} from './components';
import {SurveyForm} from './form';

interface AcquisitionSurveyBottomSheetProps {
  onCLose?: () => void;
}

const IS_WEB = Platform.select({web: true, native: false});

export const AcquisitionSurveyBottomSheet: React.FC<AcquisitionSurveyBottomSheetProps> =
  cell(({onCLose}) => {
    const form = makeForm(
      () =>
        new SurveyForm({
          data: acquisitionSurveyData,
        })
    );
    const {
      form: {isSuccessful, submitForm},
      view: {shouldShowHeader},
    } = form;

    const [isSurveyOpen, setIsSurveyOpen] = React.useState(true);

    const handleClose = React.useCallback(() => {
      if (!isSuccessful) {
        submitForm();
      }
      setIsSurveyOpen(false);

      if (onCLose) {
        onCLose();
      }
    }, [submitForm, isSuccessful]);

    React.useEffect(() => {
      if (isSuccessful && isSurveyOpen) {
        setTimeout(() => {
          setIsSurveyOpen(false);

          // NOTE: on native handleClose automatically called when on dismiss fired, so we don't need to call onCLose here
          if (onCLose && IS_WEB) {
            onCLose();
          }
        }, 1500);
      }
    }, [isSuccessful, isSurveyOpen]);

    React.useEffect(() => {
      DATA_LAYER.trackStrict('product-survey-open', {
        source: 'platform',
        surveyTitle: 'Acquisition sources survey',
      });
    }, []);

    return (
      <ModalDynamic
        isOpen={isSurveyOpen}
        onClose={handleClose}
        borderRadius={14}
        closeIconColor={
          shouldShowHeader || isSuccessful ? '$text-04' : '$text-01'
        }
        backgroundColor={isSuccessful ? '$fill-03' : '$ui-background'}
        webOverlayBackgroundColor="$transparent"
      >
        <Form form={form}>
          {isSuccessful ? <SuccessComponent /> : <SurveyQuestions />}
        </Form>
      </ModalDynamic>
    );
  });
