import {BoxProps} from '@youtoken/ui.primitives';
import {ISection, ISections} from '../types';

export const getSectionWalletGroupBoxProps = (
  sections: ISection[],
  section: ISection,
  sectionVisibility: boolean,
  sectionItems: ISections[keyof ISections],
  sectionIndex: number
): BoxProps => {
  const isOpenedSection = sectionVisibility && sectionItems.length > 0;

  const isFirstSection = sectionIndex === 0;
  const isLastSection = sectionIndex === sections.length - 1;

  return {
    pb: isOpenedSection ? 8 : 16,
    borderColor: '$ui-01',
    borderTopWidth: 1,
    borderBottomWidth: isLastSection && !isOpenedSection ? 1 : 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderTopLeftRadius: {
      default: 0,
      desktop: isFirstSection ? 10 : 0,
    },
    borderTopRightRadius: {
      default: 0,
      desktop: isFirstSection ? 10 : 0,
    },
    borderBottomLeftRadius: {
      default: 0,
      desktop: isLastSection && !isOpenedSection ? 10 : 0,
    },
    borderBottomRightRadius: {
      default: 0,
      desktop: isLastSection && !isOpenedSection ? 10 : 0,
    },
  };
};
