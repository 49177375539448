import * as React from 'react';
import {BundleTariff} from '@youtoken/ui.resource-bundles';
import {useResponsive, Box} from '@youtoken/ui.primitives';
import {BundlesTariff} from '../Tariff';

interface BundlesTariffsProps {
  list: BundleTariff[];
}

export const BundlesTariffs: React.FC<BundlesTariffsProps> = ({list}) => {
  const columnsCount = useResponsive({
    default: 1,
    desktop: 2,
  })!;

  const listColumns = React.useMemo(() => {
    const columns = Array.from({length: columnsCount}).map(() => {
      return [] as BundleTariff[];
    });

    list.forEach((pie, index) => {
      columns[index % columnsCount]!.push(pie);
    });

    return columns;
  }, [columnsCount, list]);

  const renderListColumnItem = React.useCallback(
    (bundleTariff: BundleTariff) => {
      return <BundlesTariff key={bundleTariff.id} data={bundleTariff} />;
    },
    []
  );

  return (
    <Box flexDirection="row" flexWrap="wrap" gap={24}>
      {listColumns.map(listColumnItems => {
        return (
          <Box flex={1} gap={24}>
            {listColumnItems.map(renderListColumnItem)}
          </Box>
        );
      })}
    </Box>
  );
};
