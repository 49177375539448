import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {IncentivesFeature} from '@youtoken/ui.resource-wallets';
import {BoostYourTradingSurface} from '../BoostYourTradingSurface';
import {TradingBonusesSurface} from '../TradingBonusesSurface';
import {Box, type BoxProps} from '@youtoken/ui.primitives';

export type IncentivesDetailedSurfaceProps = {
  onClose: () => void;
};

export const IncentivesDetailedSurface: React.FC<
  IncentivesDetailedSurfaceProps & BoxProps
> = cell(({onClose, ...boxProps}) => {
  const {incentivesBalanceIsPositive} = IncentivesFeature.use({});

  return (
    <Box {...boxProps}>
      {incentivesBalanceIsPositive ? (
        <TradingBonusesSurface onClose={onClose} />
      ) : (
        <BoostYourTradingSurface onClose={onClose} />
      )}
    </Box>
  );
});
