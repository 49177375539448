import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {BundlePortfolio, BundleTariff} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';

interface BundleGeneralActionsProps {
  data: {
    tariff: BundleTariff;
    userPortfolio: BundlePortfolio | null;
  };
}

export const BundleGeneralActions: React.FC<
  BundleGeneralActionsProps & BoxProps
> = observer(
  ({
    data: {
      tariff: {id},
      userPortfolio,
    },
    ...boxProps
  }) => {
    const {t} = useTranslation();

    const handlePressWithdrawal = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('BundleWithdrawal', {
        id,
      });
    }, [id]);

    const handlePressDeposit = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('BundleDeposit', {
        id,
      });
    }, [id]);

    if (userPortfolio) {
      return (
        <Box flexDirection="row" gap={16} {...boxProps}>
          <Button flex={1} type="secondary" onPress={handlePressWithdrawal}>
            {t('surface.bundle.actions.withdraw')}
          </Button>
          <Button flex={1} onPress={handlePressDeposit}>
            {t('surface.bundle.actions.buy_more')}
          </Button>
        </Box>
      );
    }

    return (
      <Box {...boxProps}>
        <Button onPress={handlePressDeposit}>
          {t('surface.bundle.actions.buy')}
        </Button>
      </Box>
    );
  }
);
