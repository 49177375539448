import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {AgreementsList} from '@youtoken/ui.elements';
import {SubmitButton, useForm} from '@youtoken/ui.form-elements';
import {BundleDepositFormState} from '../../state';

export const Footer: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {
    form: {
      resources: {
        docs: {
          documents: {bundles},
        },
      },
      calculated: {loading},
      tickerFormatted,
      resultAmountFormatted,
      submitAction,
      wallet,
    },
  } = useForm<BundleDepositFormState>();

  return (
    <Box
      p={{
        default: 16,
        phone: 24,
      }}
      gap={24}
      {...props}
    >
      <Box gap={16}>
        <Text variant="$body-01" lineHeight={28} color="$text-02">
          <Trans
            t={t}
            components={{
              Strong: <Text variant="$heading-02" color="$text-01" />,
            }}
            values={{
              amount: resultAmountFormatted,
              ticker: tickerFormatted,
            }}
            i18nKey="surface.bundles.deposit_form.to_receive"
          />
        </Text>
        <AgreementsList
          beforeListText={t(
            'agreements.documents.without_checkbox.start_text',
            {
              call_to_action: t(
                submitAction === 'deposit-wallet'
                  ? 'surface.bundles.deposit_form.submit_deposit'
                  : 'surface.bundles.deposit_form.submit'
              ),
            }
          )}
          items={bundles}
        />
      </Box>
      <SubmitButton
        loading={loading}
        icon={
          submitAction === 'deposit-wallet'
            ? wallet.isFiat
              ? 'fiat_deposit'
              : 'crypto_deposit'
            : undefined
        }
      >
        {t(
          submitAction === 'deposit-wallet'
            ? 'surface.bundles.deposit_form.submit_deposit'
            : 'surface.bundles.deposit_form.submit'
        )}
      </SubmitButton>
    </Box>
  );
});
