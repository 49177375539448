import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {RewardsHowItWorks} from '../../components';

export const RewardsHowItWorksModal = observer(
  (modalProps: Omit<ModalDynamicProps, 'children'>) => {
    const {t} = useTranslation();

    return (
      <ModalDynamic
        title={t('surface.referral_program.rewards.how_it_works.title')}
        {...modalProps}
      >
        <RewardsHowItWorks />
      </ModalDynamic>
    );
  }
);
