import * as React from 'react';
import {observer} from 'mobx-react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HeaderBase, MethodItem} from '../../../../components';
import {DepositMethodType, DepositMethodRampEnum} from '../../types';

export type ChooseMethodProps = {
  possibleDepositMethods: DepositMethodRampEnum[];
  depositMethodsData: {
    [method: string]: {
      type: DepositMethodType;
      title: string;
      fee: string;
      description?: string;
      icon: React.ReactNode;
      iconName: string;
      testID: string;
      tags?: string[];
    };
  };
  depositMethod: string;
  changeDepositMethod: (method: string) => void;
  handleClose: () => void;
};

export const ChooseMethod: React.FC<ChooseMethodProps> = observer(
  ({
    possibleDepositMethods,
    depositMethodsData,
    depositMethod,
    changeDepositMethod,
    handleClose,
  }) => {
    const {t} = useTranslation();

    if (possibleDepositMethods.length === 0) {
      return null;
    }

    return (
      <>
        <HeaderBase>{t('ramp.conversion_form.label.pay_with')}</HeaderBase>
        {possibleDepositMethods.map((method, index) => (
          <MethodItem
            key={method}
            hasActive
            isActive={depositMethod == method}
            isLast={index === possibleDepositMethods.length - 1}
            showIconBadge={method === DepositMethodRampEnum.BANK_WIRE}
            onPress={() => {
              DATA_LAYER.trackStrict('buy-wizard-payment-method-chosen', {
                method,
              });

              changeDepositMethod(method);
              handleClose();
            }}
            showSeparator={index !== possibleDepositMethods.length - 1}
            {...depositMethodsData[method]!}
          />
        ))}
      </>
    );
  }
);
