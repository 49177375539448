import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {Box, type BoxProps, useIsMobile} from '@youtoken/ui.primitives';
import {LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';
import {typeKeys} from '@youtoken/ui.resource-history-new';
import {ModalDynamic} from '@youtoken/ui.modal';
import {AppliedFilter, AppliedFiltersWrapper} from './components';
import {allTickers} from './constants';
import {FiltersMenu} from './FiltersMenu';
import {FiltersFooter} from './FiltersFooter';
import {
  FiltersProps,
  TransactionType,
  TickersFilter,
  TypesFilter,
} from './types';
import {createFilterObject} from './utils';

export const Filters: React.FC<FiltersProps & BoxProps> = observer(
  ({
    tickers = allTickers,
    selectedTickers,
    types = [],
    selectedTypes = [],
    applyFilters,
    showTitles = true,
    scrollOffset = 24,
    getTickerFormatted,
    testIdBase = '',
    ...boxProps
  }) => {
    const {t} = useTranslation();

    const [isOpen, setIsOpen] = React.useState(false);
    const isMobile = useIsMobile();

    const shouldShowButtonLabel = React.useMemo(
      () =>
        !isMobile ||
        (selectedTickers.length === 0 && selectedTypes.length === 0),
      [isMobile, selectedTickers, selectedTypes]
    );

    const openModal = () => {
      setIsOpen(true);
    };

    const closeModal = () => {
      setIsOpen(false);
    };

    const tickersFilter = React.useMemo<TickersFilter>(
      () => createFilterObject(tickers, selectedTickers),
      [tickers, selectedTickers]
    );

    const typesFilter = React.useMemo<TypesFilter>(
      () => createFilterObject(types, selectedTypes),
      [types, selectedTypes]
    );
    const toggleTicker = (ticker: string) => {
      const updatedTickers = selectedTickers.includes(ticker)
        ? selectedTickers.filter(t => t !== ticker)
        : [...selectedTickers, ticker];
      applyFilters({selectedTickers: updatedTickers, selectedTypes});
    };

    const toggleType = (type: TransactionType) => {
      const updatedTypes = selectedTypes.includes(type)
        ? selectedTypes.filter(t => t !== type)
        : [...selectedTypes, type];
      applyFilters({selectedTickers, selectedTypes: updatedTypes});
    };

    const handleClear = () => {
      applyFilters({selectedTickers: [], selectedTypes: []});
    };

    const isAnyFilterSelected =
      selectedTickers.length > 0 || selectedTypes.length > 0;

    return (
      <>
        <Box
          flexDirection="row"
          justifyContent="flex-start"
          flexWrap="wrap"
          {...boxProps}
        >
          <AppliedFiltersWrapper scrollOffset={scrollOffset}>
            <Button
              icon="filter"
              type="secondary"
              size="small"
              mr={8}
              mb={8}
              onPress={openModal}
              testID={`${testIdBase}_APPLIED_FILTERS_BUTTON_FILTERS`}
            >
              {shouldShowButtonLabel
                ? t('surface.wallets.account_stats.filters')
                : null}
            </Button>

            {selectedTickers.map(ticker => {
              const testID = `${testIdBase}_APPLIED_FILTERS_TICKER_${ticker.toUpperCase()}`;
              const tickerFormatted = getTickerFormatted(ticker);
              return (
                <AppliedFilter
                  icon={
                    <LogoColored
                      name={ticker as LogoColoredIconName}
                      size={20}
                      testID={`${testID}_ICON`}
                    />
                  }
                  label={tickerFormatted}
                  onClose={() => {
                    toggleTicker(ticker);
                  }}
                  testID={testID}
                  key={`applied-filter-${ticker}`}
                />
              );
            })}

            {selectedTypes.map(type => (
              <AppliedFilter
                // @ts-ignore
                label={t(typeKeys[type], {
                  defaultValue: type.toLowerCase(),
                })}
                onClose={() => toggleType(type)}
                testID={`${testIdBase}_APPLIED_FILTERS_TYPE_${type}`}
                key={`applied-filter-${type}`}
              />
            ))}
          </AppliedFiltersWrapper>
        </Box>

        <ModalDynamic
          isOpen={isOpen}
          onClose={closeModal}
          title={t('surface.wallets.account_stats.filters')}
          footerComponent={
            <FiltersFooter
              onApply={closeModal}
              onClear={handleClear}
              isAnyFilterSelected={isAnyFilterSelected}
            />
          }
          webHeight={700}
          shouldBeScrollable
          testID={`${testIdBase}_FILTERS`}
        >
          <FiltersMenu
            tickersFilter={tickersFilter}
            typesFilter={typesFilter}
            onTickerClick={toggleTicker}
            onTypeClick={toggleType}
            onAllTypesClick={handleClear}
            getTickerFormatted={getTickerFormatted}
            testIdBase={testIdBase}
            showTitles={showTitles}
          />
        </ModalDynamic>
      </>
    );
  }
);
