import {z} from 'zod';
import {serializable, object} from 'serializr';
import {bundleTariffSchema, BundleTariff} from '../BundleTariff';
import {bundlePortfolioSchema, BundlePortfolio} from '../BundlePortfolio';

export const bundleTariffOverviewResourceResourceResponseSchema = z.object({
  tariff: bundleTariffSchema,
  userPortfolio: z.nullable(bundlePortfolioSchema),
});

export class BundleTariffOverviewResourceResponse {
  @serializable(object(BundleTariff))
  tariff!: BundleTariff;

  @serializable(object(BundlePortfolio))
  userPortfolio!: BundlePortfolio | null;
}
