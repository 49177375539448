import * as React from 'react';
import {FlashList, ListRenderItemInfo} from '@shopify/flash-list';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {NewReferralActivity} from '@youtoken/ui.resource-new-referral';
import {InvitedListItem} from './InvitedListItem';
import {PendingFriendModal} from './PendingFriendModal';
import {useCloseModalOnBackButton} from '@youtoken/ui.hooks';

type InvitedListProps = {
  activities: NewReferralActivity[];
};

export const InvitedList: React.FC<InvitedListProps & BoxProps> = ({
  activities,
  ...boxProps
}) => {
  const [modalActivity, setModalActivity] =
    React.useState<NewReferralActivity | null>(null);

  const handlePressActivity = (activity: NewReferralActivity) => {
    setModalActivity(activity);
  };

  useCloseModalOnBackButton(Boolean(modalActivity), () => {
    setModalActivity(null);
    return true;
  });

  const renderItem = ({
    item,
    index,
  }: ListRenderItemInfo<NewReferralActivity>) => {
    return (
      <InvitedListItem
        activity={item}
        mb={8}
        onPress={handlePressActivity}
        testID={`INVITED_ITEM_${index}`}
      />
    );
  };

  return (
    <Box {...boxProps}>
      <FlashList
        data={activities}
        renderItem={renderItem}
        estimatedListSize={{height: 740, width: 670}}
        estimatedItemSize={64}
      />

      <PendingFriendModal
        activity={modalActivity}
        isOpen={Boolean(modalActivity)}
        onClose={() => setModalActivity(null)}
      />
    </Box>
  );
};
