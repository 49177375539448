import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Heading, Text} from '@youtoken/ui.primitives';

interface PaySumRowProps extends BoxProps {
  value: string;
  ticker: string;
  text: string;
}

export const PaySumRow: React.FC<PaySumRowProps> = observer(
  ({value, ticker, text, ...props}) => {
    return (
      <Box flexDirection="row" alignItems="baseline" mb={24} {...props}>
        <Heading>{value} </Heading>
        <Text>
          {ticker} {text}
        </Text>
      </Box>
    );
  }
);
