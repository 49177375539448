import * as React from 'react';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {RewardsReview} from '../../components';

export const RewardsReviewModal = (
  modalProps: Omit<ModalDynamicProps, 'children'>
) => {
  return (
    <ModalDynamic
      shouldShowHeaderSeparator={false}
      webWidth={590}
      webHeight={200}
      {...modalProps}
    >
      <RewardsReview />
    </ModalDynamic>
  );
};
