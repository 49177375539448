import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {type UpgradeToLevelSmartProps, UpgradeToLevelSmart} from '../smarts';

type UpgradeToLevelModalProps = UpgradeToLevelSmartProps &
  Omit<ModalDynamicProps, 'children'>;

export const UpgradeToLevelModal = cell<UpgradeToLevelModalProps>(
  ({levelNumber, onPressAction, ...modalProps}) => {
    return (
      <ModalDynamic testID="UPGRADE_TO_LEVEL_MODAL" {...modalProps}>
        <UpgradeToLevelSmart
          levelNumber={levelNumber}
          onPressAction={onPressAction ?? modalProps.onClose}
        />
      </ModalDynamic>
    );
  }
);
