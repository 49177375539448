import {type HistoryItemStatus, type HistoryItemType} from './types';

export const typeKeys: Record<HistoryItemType, string> = {
  DEPOSIT: 'surface.history.item.type_name.deposit',
  WITHDRAWAL: 'surface.history.item.type_name.withdrawal',
  BORROW: 'surface.history.item.type_name.borrow',
  LOAN_PAYMENT: 'surface.history.item.type_name.loan_payment',
  REF_BONUS: 'surface.history.item.type_name.ref_bonus',
  REWARD_EARN: 'surface.history.item.type_name.reward_earn',
  HOLD: 'surface.history.item.type_name.hold',
  UNHOLD: 'surface.history.item.type_name.unhold',
  EXCHANGE: 'surface.history.item.type_name.exchange',
  COLLATERAL_SOLD: 'surface.history.item.type_name.collateral_sold',
  FINE: 'surface.history.item.type_name.fine',
  DAILY_INTEREST: 'surface.history.item.type_name.daily_interest',
  REOPEN: 'surface.history.item.type_name.reopen',
  SAVING_RELEASE: 'surface.history.item.type_name.saving_release',
  SAVING_ADD: 'surface.history.item.type_name.saving_add',
  SAVING_EARN: 'surface.history.item.type_name.saving_earn',
  SAVING_EARN_BONUS: 'surface.history.item.type_name.saving_earn_bonus',
  SPENT: 'surface.history.item.type_name.spent',
  REFUND: 'surface.history.item.type_name.refund',
  MINER_CLAIM_BLOCK: 'surface.history.item.type_name.mining_claim_block',
  CARD_FEE: 'surface.history.item.type_name.card_fee',
  DEPOSIT_BONUS: 'surface.history.item.type_name.deposit_bonus',
  BURNT: 'surface.history.item.type_name.burnt',
  TAP_REWARD: 'surface.history.item.type_name.tap_reward',
  BUNDLE_DEPOSIT: 'surface.history.item.type_name.bundle_deposit',
  BUNDLE_WITHDRAWAL: 'surface.history.item.type_name.bundle_withdrawal',
};

export const statusKeys: Record<HistoryItemStatus, string> = {
  PENDING: 'surface.history.item.status_label.processing',
  PROCESSING: 'surface.history.item.status_label.processing',
  RISKY: 'surface.history.item.status_label.processing',
  SUCCESS: 'surface.history.item.status_label.success',
  FAIL: 'surface.history.item.status_label.failed',
  DECLINED: 'surface.history.item.status_label.failed',
};
