import {useMemo} from 'react';
import {LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import type {CardContentParameters} from '../types';

export const useCardContentParameters = (
  level: LoyaltyLevel
): CardContentParameters | null => {
  const {t} = useTranslation();
  const {
    currentVolume,
    currentVolumeFormatted,
    currentDeposit,
    currentDepositFormatted,
    currentLevel,
  } = LoyaltyResource.use({});

  const textColor = level.level < currentLevel ? '$text-03' : '$text-01';

  return useMemo(() => {
    if (level.level === 1) {
      // Newbie
      return null;
    }

    if (level.level === 2) {
      // Basic
      if (currentLevel >= 2) {
        return null;
      }

      // only show content if Basic is the next level
      return {
        title: t('surface.miner.cards.required_deposit'),
        description: t('surface.miner.cards.required_volume.value.basic', {
          currentAmount: currentDepositFormatted,
          maxAmount: level.requiredDepositVisibleFormatted,
        }),
        progress: currentDeposit / level.requiredDeposit,
        textColor,
      };
    }

    // for Jumpstart level, show required actions
    if (level.level === 3) {
      return {
        title: t('surface.miner.cards.required_actions'),
        description: t('surface.miner.cards.required_actions.value'),
        textColor,
      };
    }

    // for future levels, show required volume
    if (level.level > currentLevel + 1) {
      return {
        title: t('surface.miner.cards.required_volume'),
        description: level.requiredVolumeFormatted,
        withTooltip: true,
        textColor,
      };
    }

    // for current and past levels, which are neither Basic nor Jumpstart, show required volume with progress
    return {
      title: t('surface.miner.cards.required_volume'),
      description: t('surface.miner.cards.required_volume.value', {
        currentAmount: currentVolumeFormatted || 0,
        maxAmount: level.requiredVolumeFormatted,
      }),
      progress: level.requiredVolume ? currentVolume / level.requiredVolume : 0,
      withTooltip: true,
      textColor,
    };
  }, [level, currentLevel, t]);
};
