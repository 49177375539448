import * as React from 'react';
import {i18n} from '@youtoken/ui.service-i18n';
import {Box, Heading} from '@youtoken/ui.primitives';

export const CLOSED_HEADER_ID = 'closed_hodls_heading';

export const getTitle = () => {
  return (
    <Box
      mb={30}
      ml={{
        default: 16,
        phone: 24,
        desktop: 0,
      }}
    >
      <Heading
        variant="$heading-01-responsive"
        color="$text-01"
        nativeID={CLOSED_HEADER_ID}
      >
        {i18n.t('surface.hodls.portfolio.closed_hodls.title')}
      </Heading>
    </Box>
  );
};
