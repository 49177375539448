import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {
  type AccountLevelAllBenefitsProps,
  AccountLevelAllBenefits,
} from '../smarts';

type AccountLevelAllBenefitsModalProps = AccountLevelAllBenefitsProps &
  Omit<ModalDynamicProps, 'children'>;

export const AccountLevelAllBenefitsModal =
  cell<AccountLevelAllBenefitsModalProps>(({level, ...modalProps}) => {
    return (
      <ModalDynamic
        testID="ACCOUNT_LEVEL_ALL_BENEFITS_MODAL"
        pb={0}
        {...modalProps}
      >
        <AccountLevelAllBenefits level={level} />
      </ModalDynamic>
    );
  });
