import * as React from 'react';
import {Platform, ScrollView} from 'react-native';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Amount, DataTable} from './components';

export const Content: React.FC<BoxProps> = props => {
  return (
    <Box minHeight={200} {...props}>
      <ScrollView
        scrollEnabled={Platform.select({default: true, web: false})}
        keyboardDismissMode="interactive"
        keyboardShouldPersistTaps="handled"
        style={{
          flexGrow: 1,
          overflow: 'visible',
        }}
        contentContainerStyle={{
          flexGrow: 1,
        }}
      >
        <Amount zIndex={1} />
        <DataTable flex={1} />
      </ScrollView>
    </Box>
  );
};
