import * as React from 'react';
import {BundleTariffOverviewResource} from '@youtoken/ui.resource-bundles';
import {cell} from '@youtoken/ui.cell';
import {ModalDynamic, ModalDynamicProps} from '@youtoken/ui.modal';
import {
  type BundleWithdrawalFormProps,
  getBundleWithdrawalFormTitle,
  BundleWithdrawalForm,
} from '../../forms';

type BundleWithdrawalModalProps = BundleWithdrawalFormProps &
  Omit<ModalDynamicProps, 'children'>;

export const BundleWithdrawalModal = cell<BundleWithdrawalModalProps>(
  ({id, onClose, ...modalProps}) => {
    const {
      data: {
        tariff: {
          design: {title},
        },
      },
    } = BundleTariffOverviewResource.use({
      id,
    });

    return (
      <ModalDynamic
        isOpen
        title={getBundleWithdrawalFormTitle(title)}
        onClose={onClose}
        shouldBeScrollable
      >
        <BundleWithdrawalForm id={id} onClose={onClose} />
      </ModalDynamic>
    );
  }
);
