import * as React from 'react';
import {observer} from 'mobx-react';
import {ClosedHODLsPaginationAndFilterFeature} from '@youtoken/ui.surface-hodl-positions';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Pagination} from '@youtoken/ui.elements';

type ClosedPaginationProps = {
  handlePageChange: (pageNumber: number) => void;
};

export const ClosedPagination: React.FC<ClosedPaginationProps & BoxProps> =
  observer(({handlePageChange, ...boxProps}) => {
    const {activePage: closedHodlActivePage, totalPages: closedHodlTotalPages} =
      ClosedHODLsPaginationAndFilterFeature.use({});

    return (
      <Box {...boxProps}>
        <Pagination
          activePage={closedHodlActivePage}
          totalPages={closedHodlTotalPages}
          onPageChange={handlePageChange}
        />
      </Box>
    );
  });
