import * as React from 'react';
import {Keyboard} from 'react-native';
import Axios, {type CancelTokenSource} from 'axios';
import {Box, Text, TouchableText} from '@youtoken/ui.primitives';
import {OTPInput} from '@youtoken/ui.inputs';
import {SUPPORT} from '@youtoken/ui.support';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  extractErrorFromResponse,
  getTranslatedValidationMessage,
} from '@youtoken/ui.validation-messages';
import {__GLOBAL_RECAPTCHA__} from '../../../Recaptcha';
import {TwoFactorAuthParams} from '../../types';
import {ResendCode} from './ResendCode';

export const TwoFactorAuthForm = React.forwardRef<
  any,
  {
    OTPInputComponent?: React.FC<React.ComponentProps<typeof OTPInput>>;
    autoFocus?: boolean;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
  } & TwoFactorAuthParams
>(
  (
    {
      OTPInputComponent = OTPInput,
      operationId,
      phoneMask,
      twoFactorAuthType,
      autoFocus,
      onSuccess,
      onError,
    },
    ref
  ) => {
    const {confirmOperation, signInConfirmSuccess} = AuthMeResource.use({});
    const [isChecking, setIsChecking] = React.useState(false);
    const [errorText, setErrorText] = React.useState('');
    const [value, setValue] = React.useState('');

    const {t} = useTranslation();

    const handleChange = React.useCallback((nextValue: string) => {
      setValue(nextValue);
    }, []);

    const handleSubmit = React.useCallback(
      (code: string, source: CancelTokenSource) => {
        setIsChecking(true);

        __GLOBAL_RECAPTCHA__
          .requestToken('confirm_operation')
          .then(token => {
            return confirmOperation({
              operationId,
              token,
              code,
              cancelToken: source.token,
            });
          })
          .then(payload => {
            if (payload?.data) {
              signInConfirmSuccess(payload);
              Keyboard.dismiss();
            }
          })
          .then(() => {
            onSuccess?.();
          })
          .catch(error => {
            const _error = extractErrorFromResponse(
              error.response?.data,
              '_error'
            );
            const _errorTranslated = getTranslatedValidationMessage(_error);
            setErrorText(_errorTranslated);
            onError?.(error);
            setValue('');
          })
          .finally(() => {
            setIsChecking(false);
          });
      },
      [onSuccess, operationId, setIsChecking, setErrorText, setValue]
    );

    React.useEffect(() => {
      const CancelToken = Axios.CancelToken;
      const source = CancelToken.source();

      if (value.length > 0) {
        setErrorText('');
      }

      if (value.length === 6) {
        handleSubmit(value, source);
      }
      return () => source.cancel('Operation canceled by the user.');
    }, [value]);

    return (
      <Box width="100%">
        <Box py={24} justifyContent="center" alignItems="center">
          {twoFactorAuthType === 'sms' && (
            <Text>{t('surface.2fa.code_sent.sms', {phoneMask})}</Text>
          )}
          {twoFactorAuthType === 'email' && (
            <Text>{t('surface.2fa.code_sent.email')}</Text>
          )}
          {twoFactorAuthType === 'ga' && (
            <Text>{t('surface.2fa.code_sent.ga')}</Text>
          )}
        </Box>

        <Box py={8} justifyContent="center" alignItems="center">
          <Text variant="$body-02-high-accent">{t('surface.2fa.code')}</Text>
        </Box>

        <Box pb={24} pt={16}>
          <OTPInputComponent
            value={value}
            onChange={handleChange}
            errorText={errorText}
            isChecking={isChecking}
            autoFocus={autoFocus}
            ref={ref}
          />
        </Box>

        {twoFactorAuthType !== 'ga' && <ResendCode operationId={operationId} />}

        <Box alignItems="center" pt={24} pb={24}>
          <Text variant="$body-02">
            {t('surface.2fa.to_restore')}{' '}
            <TouchableText
              color="$interactive-01"
              onPress={() => SUPPORT.openSupportChat()}
              testID="TWO_FACTOR_AUTH_CONTACT_SUPPORT"
            >
              {t('surface.2fa.contact_support')}
            </TouchableText>
          </Text>
        </Box>
      </Box>
    );
  }
);
