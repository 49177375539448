import * as React from 'react';
import {nanoid} from 'nanoid';
import {ScrollView} from 'react-native';
import {handleGeneralErrorTranslated} from '@youtoken/ui.validation-messages';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Banner, Separator} from '@youtoken/ui.elements';
import {HodlStatus} from '@youtoken/ui.resource-hodl';
import {cell} from '@youtoken/ui.cell';
import {useSafeArea} from '@youtoken/ui.hooks';
import {DataTableSection, FooterSection, GetBackSection} from '../sections';
import {HODLByIdFeature} from '../../features';
import {Box} from '@youtoken/ui.primitives';

interface CloseNowModalProps {
  hodlId: string;
  onCloseAction: () => void;
}

export const HODLCloseNowSurface: React.FC<CloseNowModalProps> = cell(
  ({hodlId, onCloseAction}) => {
    const requestIdRef = React.useRef(nanoid());
    const {
      hodl,
      showTotalInBaseTicker,
      closeNowAmountFormatted,
      closeNowTickerFormatted,
    } = HODLByIdFeature.use({
      id: hodlId,
    });
    const {t} = useTranslation();

    const {bottom} = useSafeArea();

    const [isClosing, setIsClosing] = React.useState(false);

    const handleCloseHodl = React.useCallback(() => {
      setIsClosing(true);

      hodl
        .closeHodl({requestId: requestIdRef.current})
        .then(onCloseAction)
        .catch((e: any) => {
          handleGeneralErrorTranslated(e.response?.data);
        })
        .finally(() => {
          setIsClosing(false);
        });
    }, [hodl, onCloseAction, requestIdRef.current]);

    React.useEffect(() => {
      if (
        hodl.status === HodlStatus.CLOSED ||
        hodl.status === HodlStatus.CLOSING
      ) {
        onCloseAction();
      }
    }, [hodl.status, onCloseAction]);

    if (!hodl) {
      return null;
    }

    return (
      <ScrollView
        contentContainerStyle={{
          flex: 1,
          paddingBottom: bottom,
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Banner>{t('surface.hodls.close_now.title_alert')}</Banner>
          <DataTableSection item={hodl} px={24} pb={16} />
        </Box>
        <Box>
          <Separator />
          <GetBackSection
            amountFormatted={hodl.baseTickerOutputAmountFormatted}
            tickerFormatted={hodl.outputTickerUpperCase}
            additionalAmountFormatted={hodl.additionalOutputAmountFormatted}
            additionalTickerFormatted={`${hodl.additionalInputTickerUI} ${t(
              'surface.hodls.close_now.bonuses'
            )}`}
          />
          <Separator />
          <FooterSection
            onCLoseButtonPress={handleCloseHodl}
            isClosing={isClosing}
            amountFormatted={closeNowAmountFormatted}
            tickerFormatted={closeNowTickerFormatted}
            isApproximate={showTotalInBaseTicker}
          />
        </Box>
      </ScrollView>
    );
  },
  {
    displayName: 'HODLCloseNowSurface',
  }
);
