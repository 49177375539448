import {computed} from 'mobx';
import {ResourceInstance} from '@youtoken/ui.data-storage';
import {ChartResource} from '../types';

export class BundleLineLiveData {
  resource!: ResourceInstance<ChartResource.BundleArgs, ChartResource.Data>;

  constructor(
    resource: ResourceInstance<ChartResource.BundleArgs, ChartResource.Data>
  ) {
    this.resource = resource;
  }

  @computed get data(): ChartResource.Line.Data {
    return {
      type: 'line',
      data: this.resource.data.data as ChartResource.Line.Point[],
    };
  }
}
