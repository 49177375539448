import React from 'react';
import {modal} from '../../../stores';
import {Modal} from '../_common';
import {
  FiatWithdrawalWizard,
  type FiatWithdrawalWizardScreenName,
} from '@youtoken/ui.surfaces-wallets';

interface ModalFiatWithdrawalProps extends React.ComponentProps<typeof Modal> {
  ticker: string;
  conversionTicker?: string;
  cardId?: string;
  amount?: string;
  withError?: string;
  defaultStep?: FiatWithdrawalWizardScreenName;
}

export const ModalFiatWithdrawal: React.FC<ModalFiatWithdrawalProps> = ({
  ticker,
  conversionTicker,
  cardId,
  amount,
  withError,
  defaultStep,
  ...otherProps
}) => {
  return (
    <Modal minHeight={200} {...otherProps}>
      <FiatWithdrawalWizard
        ticker={ticker}
        conversionTicker={conversionTicker}
        cardId={cardId}
        amount={amount}
        withError={withError}
        defaultStep={defaultStep}
        onPressBack={modal.close}
      />
    </Modal>
  );
};
