import * as React from 'react';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {ShareRefCodeSmart} from '../../smarts';

export const ShareRefCodeModal = (
  modalProps: Omit<ModalDynamicProps, 'children'>
) => {
  return (
    <ModalDynamic nativeStackBehavior="push" {...modalProps}>
      <ShareRefCodeSmart />
    </ModalDynamic>
  );
};
