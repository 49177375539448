import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {MyFriendsSurfaceCell} from '@youtoken/ui.surface-new-referral';
import {WebPageWrapper} from '@youtoken/ui.screen-components';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';

export const MyFriendsPage: React.FC = observer(() => {
  const {queryParams, setQueryParams} = RouterResource.use({});
  const handleTabChange = (tab: string) => {
    setQueryParams({tab});
  };

  const {t} = useTranslation();

  return (
    <InnerPageShell>
      <WebPageWrapper
        hasBackButton
        onBackPress={() => SHARED_ROUTER_SERVICE.goBack()}
        windowTitle={t('surface.new_referral.friend_list.title')}
      >
        <MyFriendsSurfaceCell
          activeTab={queryParams.tab}
          onTabChange={handleTabChange}
          px={24}
          py={32}
          borderWidth={1}
          borderColor="$ui-01"
          borderRadius={10}
        />
      </WebPageWrapper>
    </InnerPageShell>
  );
});
