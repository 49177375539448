import {observable} from 'mobx';
import {serializable, primitive, object, optional} from 'serializr';
import type {TFuncKey} from 'react-i18next';

// region Disclosures

export class Disclosure {
  @observable
  @serializable(primitive())
  i18nLink!: TFuncKey;

  @observable
  @serializable(primitive())
  i18nName!: TFuncKey;

  @observable
  @serializable(primitive())
  link!: string;

  @observable
  @serializable(primitive())
  name!: string;
}

export class WalletDisclosures {
  @observable
  @serializable(optional(object(Disclosure)))
  addendumNaumard?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  amlNaumard?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  amlYouhodler?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  complaintsProcess?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  privacyYouhodler?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  refundNaumard?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  specificTermsConversion?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  termsNaumard?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  termsYouhodler?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  privacyYouhodlerIta?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  terms?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  refundPolicy?: Disclosure;
}

export class WirexDisclosures {
  @observable
  @serializable(optional(object(Disclosure)))
  basic?: Disclosure;
}

export class PrivacyNotice {
  @observable
  @serializable(optional(object(Disclosure)))
  basic?: Disclosure;
}

export class YouhodlerDocuments {
  @observable
  @serializable(optional(object(Disclosure)))
  complaintsProcess?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  specificTermsConversion?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  specificTermsLending?: Disclosure;
}

export class GeneralDisclosures {
  @observable
  @serializable(optional(object(Disclosure)))
  privacyNotice?: Disclosure;
}

// endregion Disclosures

// region LegalEntities

export class LegalInfo {
  @observable
  @serializable(optional(object(Disclosure)))
  legalOpinion?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  legalOpinionSavingsReward?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  pawnbroker?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  sroPolyreg?: Disclosure;
}

export class LegalEntity {
  @observable
  @serializable(primitive())
  address!: string;

  @observable
  @serializable(primitive())
  email!: string;

  @observable
  @serializable(object(LegalInfo))
  legalInfo!: LegalInfo;

  @observable
  @serializable(primitive())
  name!: string;

  @observable
  @serializable(primitive())
  pawnbrokerAuthNumber!: string | null;

  @observable
  @serializable(primitive())
  phone!: string | null;

  @observable
  @serializable(primitive())
  site!: string | null;
}

export class LegalEntities {
  @observable
  @serializable(optional(object(LegalEntity)))
  naumard?: LegalEntity;

  @observable
  @serializable(optional(object(LegalEntity)))
  wirex_yhdl_sa?: LegalEntity;

  @observable
  @serializable(optional(object(LegalEntity)))
  youhodler?: LegalEntity;

  @observable
  @serializable(optional(object(LegalEntity)))
  youhodler_ita?: LegalEntity;
}

export class MHXDisclosures {
  @observable
  @serializable(optional(object(Disclosure)))
  basic?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  specificTermsConversion?: Disclosure;

  @observable
  @serializable(optional(object(Disclosure)))
  specificTermsLending?: Disclosure;
}

// endregion LegalEntities

// region DisclosuresGroups

export class AvailableGroups {
  @observable
  @serializable(optional(object(WalletDisclosures)))
  wallet?: WalletDisclosures;

  @observable
  @serializable(optional(object(WirexDisclosures)))
  wirexIban?: WirexDisclosures;

  @observable
  @serializable(optional(object(YouhodlerDocuments)))
  youhodlerDocuments?: YouhodlerDocuments;

  @observable
  @serializable(optional(object(GeneralDisclosures)))
  general?: GeneralDisclosures;

  @observable
  @serializable(optional(object(PrivacyNotice)))
  privacyNotice?: PrivacyNotice;

  @observable
  @serializable(optional(object(MHXDisclosures)))
  disclosureMHX?: MHXDisclosures;
}

// endregion DisclosuresGroups

export class AllDisclosuresResponse {
  @observable
  @serializable(object(AvailableGroups))
  availableGroups!: AvailableGroups;

  @observable
  @serializable(primitive())
  countryGroup!: string;

  @observable
  @serializable(object(LegalEntities))
  legalEntities!: LegalEntities;
}
