import {i18n} from '@youtoken/ui.service-i18n';

export const getVoletWizardTitle = (ticker: string) => {
  return i18n.t('surface.wallets.fiat_deposit.adv_cash.title', {
    ticker: ticker.toUpperCase(),
  });
};

export const getVoletWizardOptions = ({
  route: {
    params: {ticker},
  },
}: {
  route: {
    params: {
      ticker: string;
    };
  };
}) => {
  return {
    title: getVoletWizardTitle(ticker),
  };
};
