import * as React from 'react';
import {observer} from 'mobx-react';
import {Platform} from 'react-native';
import {ModalDynamic} from '@youtoken/ui.modal';
import {
  type OnboardingStep,
  type TPerkItem,
} from '@youtoken/ui.resource-onboarding-step-perks';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {NotificationsResource} from '@youtoken/ui.resource-notifications';
import {CongratsBanner} from './components';
import {OnboardingStepPerksCongratsSurface} from './OnboardingStepPerksCongratsSurface';

type OnboardingStepPerksCongratsModalProps = {
  data: TPerkItem[];
  variant: OnboardingStep;
  notificationId: string;
  onClose: () => void;
};

export const OnboardingStepPerksCongratsModal: React.FC<OnboardingStepPerksCongratsModalProps> =
  observer(({data: items, variant, notificationId, onClose}) => {
    const isWeb = Platform.OS === 'web';

    const {remove} = NotificationsResource.use({});

    const handleMinerPress = () => {
      // to try to avoid race condition and stale state on navigation
      remove(notificationId);
      onClose();
      SHARED_ROUTER_SERVICE.navigate('Miner');
    };

    const handlePortfolioPress = (ticker?: string) => {
      onClose();
      if (ticker) {
        SHARED_ROUTER_SERVICE.navigate('WalletsItem', {ticker});
      } else {
        SHARED_ROUTER_SERVICE.navigate('WalletsList', {});
      }
    };

    return (
      <ModalDynamic
        isOpen
        onClose={onClose}
        shouldShowHeaderSeparator={false}
        shouldBeScrollable
        testID="FIRST_ACTION_PERKS_CONGRATS_MODAL"
      >
        <CongratsBanner type={variant} mt={isWeb ? 40 : 12} />
        <OnboardingStepPerksCongratsSurface
          items={items}
          variant={variant}
          onGoToPortfolioPress={handlePortfolioPress}
          onGoToMinerPress={handleMinerPress}
          p={24}
        />
      </ModalDynamic>
    );
  });
