import {z} from 'zod';
import {serializable, list, object} from 'serializr';
import {Bundle} from '../Bundle';
import {bundleTariffSchema} from '../BundleTariff';
import {bundlePortfolioSchema} from '../BundlePortfolio';

const bundleOverviewResourceResponseSchemaItem = z.object({
  tariff: bundleTariffSchema,
  userPortfolio: bundlePortfolioSchema,
});

export const bundleOverviewResourceResponseSchema = z.object({
  portfolios: z.array(bundleOverviewResourceResponseSchemaItem),
});

export class BundleOverviewResourceResponse {
  @serializable(list(object(Bundle)))
  portfolios!: Bundle[];
}
