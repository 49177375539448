import {observable} from 'mobx';
import {
  VoletCreatePaymentFormArgs,
  VoletCreatePaymentFormResources,
} from './types';
import {Form} from './Form';
import {View} from './View';

export class VoletCreatePaymentFormState {
  @observable
  form: Form;

  @observable
  view: View;

  constructor(
    args: VoletCreatePaymentFormArgs,
    resources: VoletCreatePaymentFormResources
  ) {
    this.form = new Form(args, resources);
    this.view = new View(this.form);
  }
}
