import * as React from 'react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Bundle} from '@youtoken/ui.resource-bundles';
import {Wallet} from '@youtoken/ui.resource-wallets';
import {type BoxProps, TouchableBox} from '@youtoken/ui.primitives';
import {ISection} from '../../types';
import {getSectionItemColor} from '../../utils';
import {SectionItemBundle, SectionItemWallet} from './components';

export interface SectionItemProps {
  section: ISection;
  item: Bundle | Wallet;
}

export const SectionItem: React.FC<SectionItemProps & BoxProps> = ({
  section,
  item,
  ...boxProps
}) => {
  const handlePress = React.useCallback(() => {
    if (section === 'bundles') {
      return SHARED_ROUTER_SERVICE.navigate('Bundle', {
        id: (item as Bundle).tariff.id,
      });
    }

    DATA_LAYER.trackStrict('wallet-open', {ticker: (item as Wallet).ticker});
    SHARED_ROUTER_SERVICE.navigate('WalletsItem', {
      ticker: (item as Wallet).ticker,
    });
  }, [section, item]);

  return (
    <TouchableBox
      testID={getSectionItemTestId(section, item)}
      onPress={handlePress}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      height={{default: 64, desktop: 72}}
      px={20}
      gap={20}
      bg={getSectionItemColor(section)}
      {...boxProps}
    >
      <SectionItemContent section={section} item={item} />
    </TouchableBox>
  );
};

export const SectionItemContent: React.FC<SectionItemProps & BoxProps> = ({
  section,
  item,
  ...boxProps
}) => {
  if (section === 'bundles') {
    return <SectionItemBundle item={item as Bundle} {...boxProps} />;
  }

  return <SectionItemWallet item={item as Wallet} {...boxProps} />;
};

const getSectionItemTestId = (section: ISection, item: Bundle | Wallet) => {
  if (section === 'bundles') {
    return `PORTFOLIO_LIST_ITEM_BUNDLE_${(
      item as Bundle
    ).tariff.id.toUpperCase()}`;
  }

  return `WALLETS_LIST_ITEM_${(item as Wallet).ticker.toUpperCase()}`;
};
