import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {
  PredefinedAmountButtons,
  TickerAndAmountInput,
} from '@youtoken/ui.ticker-and-amount-input';
import {useForm, Label, FieldErrorMessage} from '@youtoken/ui.form-elements';
import type {BundleDepositFormState} from '../../../../state';
import {ConversionAmount} from './ConversionAmount';

export const Amount: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {
    form: {
      tickerItems,
      tariffAmountPreset,
      ticker,
      amount,
      sourceHasError,
      sourceError,
      tickerOnChange,
      amountOnChange,
    },
  } = useForm<BundleDepositFormState>();

  return (
    <Box {...props}>
      <Box flexDirection="row" justifyContent="space-between" mb={8}>
        <Label mb={8}>{t('surface.bundles.deposit_form.amount')}</Label>
        <ConversionAmount />
      </Box>
      <TickerAndAmountInput
        wallets={tickerItems}
        ticker={ticker}
        amount={amount}
        onChangeTicker={tickerOnChange}
        onChangeAmount={amountOnChange}
      />
      <FieldErrorMessage visible={sourceHasError}>
        {sourceError}
      </FieldErrorMessage>
      <PredefinedAmountButtons
        data={tariffAmountPreset}
        currentAmount={amount}
        onPressAmount={amount => {
          amountOnChange(amount);
        }}
        buttonsSize="small"
        mt={8}
      />
    </Box>
  );
});
