import * as React from 'react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  LocaliseResourceNamespace,
  LocaliseResource,
} from '@youtoken/ui.resource-lokalise';
import {BundleTariffListResource} from '@youtoken/ui.resource-bundles';
import {cell} from '@youtoken/ui.cell';
import {Box} from '@youtoken/ui.primitives';
import {WebPageWrapper} from '@youtoken/ui.screen-components';
import {BundlesSlider, BundlesInfo, BundlesTariffs} from './components';
import {getBundlesSurfaceTitle} from './utils';
import {BundlesSurfaceTitleWeb} from './TitleWeb';

export {getBundlesSurfaceTitle};

export const BundlesSurface: React.FC = cell(() => {
  const {
    authMe: {bundleWelcomePageEnabled, setBundleWelcomePageEnabled},
  } = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
  });

  const handleComplete = React.useCallback(() => {
    setBundleWelcomePageEnabled(false);
  }, [setBundleWelcomePageEnabled]);

  return (
    <WebPageWrapper
      hasBackButton
      windowTitle={getBundlesSurfaceTitle()}
      title={<BundlesSurfaceTitleWeb />}
      onBackPress={SHARED_ROUTER_SERVICE.goBack}
    >
      {bundleWelcomePageEnabled ? (
        <BundlesSlider
          mx={{default: 16, phone: 24, desktop: 0}}
          onComplete={handleComplete}
        />
      ) : (
        <BundlesSurfaceTariffs />
      )}
    </WebPageWrapper>
  );
});

export const BundlesSurfaceTariffs: React.FC = cell(() => {
  const {
    bundleTariffList: {
      data: {tariffs},
    },
  } = useResources({
    lokalise: getResourceDescriptor(LocaliseResource, {
      namespace: LocaliseResourceNamespace.BUNDLE,
    }),
    bundleTariffList: getResourceDescriptor(BundleTariffListResource, {}),
  });

  return (
    <Box
      px={{
        default: 16,
        phone: 24,
        desktop: 0,
      }}
      gap={12}
    >
      <BundlesInfo />
      <BundlesTariffs list={tariffs} />
    </Box>
  );
});
