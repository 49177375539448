import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {
  BankCardAddMoneySurface,
  getBankCardAddMoneyTitle,
} from '@youtoken/ui.surface-wallets-bank-cards';
import {modal} from '../../../stores';

export const modalBankCardAddMoneyName = 'bankCardAddMoney';

type ModalBankCardAddMoneyProps = {
  cardId: string;
} & Omit<ModalDynamicProps, 'children'>;

export const ModalBankCardAddMoney = cell<ModalBankCardAddMoneyProps>(
  ({cardId}) => {
    const {opened, close} = modal;

    return (
      <ModalDynamic
        isOpen={opened?.name === modalBankCardAddMoneyName}
        onClose={close}
        title={getBankCardAddMoneyTitle()}
        webOverlayBackgroundColor="$transparent"
      >
        <BankCardAddMoneySurface cardId={cardId} onSuccess={close} pb={24} />
      </ModalDynamic>
    );
  }
);
