import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {HistoryItemData} from '@youtoken/ui.resource-history-new';
import {AmountsSection} from './AmountsSection';
import {ButtonsSection} from './ButtonsSection';
import {HeaderSection} from './HeaderSection';

type HistoryItemDetailsProps = {
  item: HistoryItemData;
  onClose?: () => void;
};

export const HistoryItemDetails: React.FC<HistoryItemDetailsProps & BoxProps> =
  observer(({item, onClose, ...props}) => {
    const {
      agreementUrl,
      invoiceUrl,
      explorerUrl,
      amountStringFormatted,
      conversionTicker,
      ticker,
    } = item;

    const handleNavigateToAgreement = React.useCallback(() => {
      onClose?.();
      SHARED_ROUTER_SERVICE.navigate('Agreement', {
        url: agreementUrl,
      });
    }, [agreementUrl]);

    const handleDownloadInvoice = React.useCallback(() => {
      onClose?.();
      SHARED_ROUTER_SERVICE.navigate('Agreement', {
        url: invoiceUrl,
      });
    }, [invoiceUrl]);

    const handlePressExplorer = React.useCallback(() => {
      onClose?.();
      if (explorerUrl) {
        SHARED_ROUTER_SERVICE.navigate('Explorer', {url: explorerUrl});
      }
    }, [explorerUrl]);

    const handleRepeatConvertPress = () => {
      onClose?.();
      SHARED_ROUTER_SERVICE.navigate('Exchange', {
        fromTicker: ticker,
        toTicker: conversionTicker,
        initialAmount: amountStringFormatted,
        dl_source: 'repeat-from-history',
      });
    };

    return (
      <Box
        backgroundColor="$ui-background"
        pb={Platform.select({default: 0, web: 24})}
        {...props}
      >
        <HeaderSection item={item} />
        <AmountsSection item={item} />
        <ButtonsSection
          item={item}
          onAgreementClick={handleNavigateToAgreement}
          onInvoiceClick={handleDownloadInvoice}
          onExplorerClick={handlePressExplorer}
          onRepeatClick={handleRepeatConvertPress}
        />
      </Box>
    );
  });
