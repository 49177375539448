import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {HODLItem} from '@youtoken/ui.resource-hodl';
import {ModalDynamic} from '@youtoken/ui.modal';
import {AdjustPendingButton} from './AdjustPendingButton';
import {AdjustPendingOrderSurface} from '../../../AdjustPendingOrderSurface';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import type {subtitleProps} from './types';

interface AdjustPendingOrderSectionProps {
  hodl: HODLItem;
}

export const AdjustPendingOrderSection: React.FC<AdjustPendingOrderSectionProps> =
  observer(({hodl}) => {
    const {t} = useTranslation();
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [subtitleProps, setSubtitleProps] = React.useState<subtitleProps>({
      baseTicker: '',
      quoteTicker: '',
      currentPrice: '',
    });

    const openModal = () => {
      setIsModalOpen(true);
    };

    const closeModal = () => {
      setIsModalOpen(false);
    };

    const handleSubtitlePropsChange = (props: subtitleProps) => {
      setSubtitleProps(props);
    };

    return (
      <Box px={24} py={8}>
        <AdjustPendingButton onPress={openModal} />
        <ModalDynamic
          title={t('surface.hodls.adjust_pending.title')}
          subtitle={
            Boolean(subtitleProps.currentPrice) && (
              <Trans
                i18nKey="surface.hodls.adjust_pending.current_price"
                values={{
                  baseTicker: subtitleProps.baseTicker,
                  quoteTicker: subtitleProps.quoteTicker,
                  price: subtitleProps.currentPrice,
                }}
                components={{
                  Strong: <Text color="$text-01" />,
                }}
                parent={props => (
                  <Text
                    variant="$body-02"
                    color="$text-02"
                    textAlign="center"
                    {...props}
                  />
                )}
                defaults="Current {{baseTicker}} price is <Strong>{{price}}</Strong> {{quoteTicker}}"
              />
            )
          }
          isOpen={isModalOpen}
          onClose={closeModal}
          shouldBeScrollable
          shouldShowHeaderSeparator={false}
        >
          <AdjustPendingOrderSurface
            hodl={hodl}
            onCancel={closeModal}
            onSuccess={closeModal}
            onSubtitlePropsChange={handleSubtitlePropsChange}
          />
        </ModalDynamic>
      </Box>
    );
  });
