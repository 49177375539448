import * as React from 'react';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {MarketsResource} from '@youtoken/ui.resource-markets';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {ExchangeTariffsResource} from '@youtoken/ui.resource-exchange-tariffs';
import {FeeAllResource} from '@youtoken/ui.resource-fee-all';
import {CardsResource} from '@youtoken/ui.resource-cards';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {createWizard} from '@youtoken/ui.elements';
import {makeForm, Form} from '@youtoken/ui.form-elements';
import {BuyCryptoFormAuthorizedYouHodlerState} from '../../../state';
import {NotEnoughData} from '../../NotEnoughData';
import {BuyCryptoFormAuthorizedYouHodlerMain} from './Main';
import {CardDepositAddCard, CardDepositPaymentMethods} from '../common';

export type BuyCryptoFormAuthorizedYouHodlerWizardNavigatorParams = {
  Main: {
    source?: 'FORK' | 'WALLET';
    ticker?: string;
  };
  ChooseMethod: {
    source?: 'FORK' | 'WALLET';
    ticker?: string;
  };
  PaymentMethods: {};
  AddCard: {};
};

const Wizard =
  createWizard<BuyCryptoFormAuthorizedYouHodlerWizardNavigatorParams>();

interface BuyCryptoFormAuthorizedYouHodlerFormProps {
  conversionTicker?: string;
  onPressBack?: () => void;
  onSubmit?: () => void;
}

const BuyCryptoFormAuthorizedYouHodlerForm: React.FC<BuyCryptoFormAuthorizedYouHodlerFormProps> =
  cell(({conversionTicker, onPressBack, onSubmit}) => {
    const {t} = useTranslation();
    const {residenceOrCountry} = AuthMeResource.use({});

    const resources = useResources({
      authMeResource: getResourceDescriptor(AuthMeResource, {}),
      verificationResource: getResourceDescriptor(VerificationResource, {}),
      docsResource: getResourceDescriptor(DocsResource, {
        country: residenceOrCountry,
      }),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
      marketsResource: getResourceDescriptor(MarketsResource, {}),
      ratesResource: getResourceDescriptor(RatesResource, {}),
      exchangeTariffs: getResourceDescriptor(ExchangeTariffsResource, {}),
      fees: getResourceDescriptor(FeeAllResource, {}), // for deposit method
      cardsResource: getResourceDescriptor(CardsResource, {}),
    });

    const form = makeForm(
      () =>
        new BuyCryptoFormAuthorizedYouHodlerState(
          {
            conversionTicker,
            onSubmit,
          },
          resources
        )
    );

    return (
      <Form form={form}>
        <Wizard.Navigator initialName="Main" goBack={onPressBack}>
          <Wizard.Screen
            name="Main"
            options={{
              title: t('surface.wallets.buy_crypto.title'),
            }}
            component={BuyCryptoFormAuthorizedYouHodlerMain}
          />
          <Wizard.Screen
            name="PaymentMethods"
            options={{title: t('surface.wallets.payment_methods')}}
            component={CardDepositPaymentMethods}
          />
          <Wizard.Screen
            name="AddCard"
            options={{title: t('surface.wallets.add_card')}}
            component={CardDepositAddCard}
          />
        </Wizard.Navigator>
      </Form>
    );
  });

export const BuyCryptoFormAuthorizedYouHodler: typeof BuyCryptoFormAuthorizedYouHodlerForm =
  props => {
    if (!props.conversionTicker) {
      return <NotEnoughData />;
    }

    return <BuyCryptoFormAuthorizedYouHodlerForm {...props} />;
  };
