import * as React from 'react';
import {type ImageSourcePropType, Image} from 'react-native';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Heading, Text} from '@youtoken/ui.primitives';

interface BundlesSliderSlideProps {
  img: ImageSourcePropType;
  title: string;
  description: string;
}

const BundlesSliderSlide: React.FC<BundlesSliderSlideProps & BoxProps> =
  React.memo(({img, title, description, ...boxProps}) => {
    return (
      <Box
        justifyContent="center"
        alignItems="center"
        height="100%"
        p={32}
        {...boxProps}
      >
        <Image
          source={img}
          style={{
            width: 200,
            height: 200,
            resizeMode: 'contain',
          }}
        />
        <Box mt={24}>
          <Heading textAlign="center" mb={8}>
            {title}
          </Heading>
          <Text variant="$body-01" color="$text-02" textAlign="center">
            {description}
          </Text>
        </Box>
      </Box>
    );
  });

export const BundlesSliderSlide1: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <BundlesSliderSlide
      img={require('../assets/slide-1.png')}
      title={t('surface.bundles.slider.slide1.title')}
      description={t('surface.bundles.slider.slide1.description')}
    />
  );
});

export const BundlesSliderSlide2: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <BundlesSliderSlide
      img={require('../assets/slide-2.png')}
      title={t('surface.bundles.slider.slide2.title')}
      description={t('surface.bundles.slider.slide2.description')}
    />
  );
});

export const BundlesSliderSlide3: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <BundlesSliderSlide
      img={require('../assets/slide-3.png')}
      title={t('surface.bundles.slider.slide3.title')}
      description={t('surface.bundles.slider.slide3.description')}
    />
  );
});
