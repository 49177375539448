import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Pagination} from '@youtoken/ui.elements';
import {Box, type BoxProps, Heading} from '@youtoken/ui.primitives';
import {smoothScroll} from '@youtoken/ui.utils';
import {ActiveHODLsPaginationFeature} from '../../../features';
import {HODLsList} from '../../../components';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export const ACTIVE_HEADER_ID = 'active_hodls_heading';

export const HODLsActiveBlock: React.FC<BoxProps> = observer(
  ({...boxProps}) => {
    const {t} = useTranslation();

    const {activePage, totalPages, hodlsWithLimit} =
      ActiveHODLsPaginationFeature.use({});

    const handlePageChange = React.useCallback((pageNumber: number) => {
      SHARED_ROUTER_SERVICE.navigate(
        'MultiHODLPortfolio',
        {},
        {
          pageActive: pageNumber.toString(),
        }
      );
      setTimeout(() => {
        smoothScroll(ACTIVE_HEADER_ID);
      }, 0);
    }, []);

    return (
      <Box {...boxProps}>
        <Box nativeID={ACTIVE_HEADER_ID} mb={16} px={{default: 20, desktop: 0}}>
          <Heading variant="$heading-01">
            {t('surface.hodls.portfolio.active_title')}
          </Heading>
        </Box>
        <HODLsList hodlsList={hodlsWithLimit} />
        {totalPages > 1 && (
          <Box px={{default: 20, desktop: 0}} my={24}>
            <Pagination
              activePage={activePage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </Box>
        )}
      </Box>
    );
  }
);
