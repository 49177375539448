import * as React from 'react';
import {invariant} from '@youtoken/ui.utils';

export type ChallengeResponse = {
  challenge: {
    type: 'sumsub';
    payload: {
      level: string;
    };
  };
};

export type CallbackWithToken<T extends any = any> = (
  recaptchaToken: string
) => Promise<T>;

/** transport layer provider as service */
export class ChallengesService {
  initialize() {}

  refs = {
    sumsub: React.createRef<{
      launch: (levelName: string, onDismiss?: () => void) => Promise<void>;
    }>(),
    recaptcha: React.createRef<{
      requestTokenV2: (action: string) => Promise<string>;
      requestTokenV3: (action: string) => Promise<string>;
      requestToken: (action: string) => Promise<string>;
    }>(),
  };

  sumsub(level: string, onDismiss?: () => void) {
    invariant(this.refs.sumsub?.current, 'SumSubVerification not initialized');
    return this.refs.sumsub?.current?.launch(level, onDismiss);
  }

  recaptcha(action: string) {
    invariant(this.refs.recaptcha?.current, 'Recaptcha not initialized');
    return this.refs.recaptcha?.current?.requestToken(action);
  }

  // for now only sumsub challenges are supported
  canProcessChallenge(response: ChallengeResponse) {
    return response.challenge.type === 'sumsub';
  }

  processChallenge(response: ChallengeResponse): Promise<void> {
    invariant(
      this.canProcessChallenge(response),
      'Unsupported challenge type',
      {
        type: response.challenge.type,
      },
      {
        response,
      }
    );

    if (response.challenge.type === 'sumsub') {
      return this.sumsub(response.challenge.payload.level);
    }

    return Promise.reject();
  }
}
