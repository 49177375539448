import * as React from 'react';
import {observer} from 'mobx-react';
import {AgreementsList, Switch} from '@youtoken/ui.elements';
import {privacyNoticeUnified} from '@youtoken/ui.resource-documents';
import {Box, BoxProps, TouchableBox} from '@youtoken/ui.primitives';
import {FieldErrorMessage, useForm} from '@youtoken/ui.form-elements';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {type SignUpCorporateForm} from '../state';

export const TermsField: React.FC<BoxProps> = observer(boxProps => {
  const {
    form: {countryValue, agreeValue, onAgreeChange, hasAgreeError, agreeError},
  } = useForm<SignUpCorporateForm>();

  React.useEffect(() => {
    if (!countryValue) {
      return;
    }

    onAgreeChange(false);
  }, [countryValue]);

  return (
    <Box minHeight={34} position="relative" {...boxProps}>
      <TouchableBox
        flexDirection="row"
        justifyContent="space-between"
        testID="PRIVACY_CHECKBOX"
        onPress={() => onAgreeChange(!agreeValue)}
      >
        <AgreementsList items={[privacyNoticeUnified]} pr={12} />
        <Switch
          onValueChange={onAgreeChange}
          value={agreeValue}
          hasError={hasAgreeError}
        />
      </TouchableBox>

      <Box mt={4}>
        <FieldErrorMessage visible={hasAgreeError} placement="right">
          {getTranslatedValidationMessage(agreeError)}
        </FieldErrorMessage>
      </Box>
    </Box>
  );
});
