import {z} from 'zod';
import {Big} from 'big.js';
import {orderBy} from 'lodash';
import {computed} from 'mobx';
import {
  serializable,
  alias,
  object,
  primitive,
  createSimpleSchema,
  list,
  optional,
  deserialize,
} from 'serializr';
import {
  ChartDataItem,
  chartData,
  number,
  bigNumber,
} from '@youtoken/ui.utils-serializr';
import {formatPercent} from '@youtoken/ui.formatting-utils';
import {
  LocaliseResourceNamespace,
  LocaliseResource,
} from '@youtoken/ui.resource-lokalise';
import {type TColorTokensNames} from '@youtoken/ui.primitives';

const bundleTariffDesignSchema = z.object({
  type: z.string(),
  title: z.string(),
  description: z.string(),
  iconUrl: z.string(),
  backgroundColor: z.string(),
  backgroundImageUrl: z.string(),
});

const bundleTariffConditionsSchema = z.object({
  presets: z.record(z.array(z.number())),
  buyTickers: z.array(z.string()),
  sellTickers: z.array(z.string()),
});

const chartDataItemSchema = z.object({
  time: z.number(),
  value: z.number(),
});

export const bundleTariffSchema = z.object({
  id: z.string(),
  design: bundleTariffDesignSchema,
  tickers: z.record(z.number()),
  conditions: bundleTariffConditionsSchema,
  priceDiff: z.optional(z.string()),
  chartData: z.optional(z.array(chartDataItemSchema)),
});

export class BundleTariffDesign {
  @computed
  get lokalise() {
    return LocaliseResource.getInstanceSafely({
      namespace: LocaliseResourceNamespace.BUNDLE,
    });
  }

  @serializable(alias('type', primitive()))
  _type!: string;

  @computed
  get type() {
    return this.lokalise?.translate(this._type);
  }

  @serializable(alias('title', primitive()))
  _title!: string;

  @computed
  get title() {
    return this.lokalise?.translate(this._title);
  }

  @serializable(alias('description', primitive()))
  _description!: string;

  @computed
  get description() {
    return this.lokalise?.translate(this._description);
  }

  @serializable(primitive())
  iconUrl!: string;

  @serializable(primitive())
  backgroundColor!: TColorTokensNames;

  @serializable(primitive())
  backgroundImageUrl!: string;
}

export class BundleTariffConditions {
  @serializable(
    object(
      createSimpleSchema({
        '*': list(number()),
      })
    )
  )
  presets!: Record<string, number[]>;

  @serializable(list(primitive()))
  buyTickers!: string[];

  @serializable(list(primitive()))
  sellTickers!: string[];
}

export class BundleTariffTickersListItem {
  @serializable(primitive())
  ticker!: string;

  @computed
  get tickerFormatted() {
    return this.ticker.toUpperCase();
  }

  @serializable(number())
  percent!: number;

  @computed
  get percentFormatted() {
    return formatPercent(this.percent) + '%';
  }
}

export class BundleTariff {
  @serializable(primitive())
  id!: string;

  @serializable(object(BundleTariffDesign))
  design!: BundleTariffDesign;

  @serializable(
    object(
      createSimpleSchema({
        '*': number(),
      })
    )
  )
  tickers!: Record<string, number>;

  @computed
  get tickersList() {
    return orderBy(
      Object.keys(this.tickers).reduce((acc, ticker) => {
        acc.push(
          deserialize(BundleTariffTickersListItem, {
            ticker,
            percent: this.tickers[ticker],
          })
        );

        return acc;
      }, [] as BundleTariffTickersListItem[]),
      'percent',
      'desc'
    );
  }

  @serializable(object(BundleTariffConditions))
  conditions!: BundleTariffConditions;

  // OPTIONAL

  @serializable(optional(bigNumber()))
  priceDiff?: Big;

  @computed
  get priceDiffIsPositive() {
    return Boolean(this.priceDiff?.gte(0));
  }

  @computed
  get priceDiffFormatted() {
    return formatPercent(this.priceDiff) + '%';
  }

  @serializable(optional(alias('chart', chartData())))
  chartData?: ChartDataItem[];
}
