export const TICKERS_SIZE = 20;
export const TICKERS_BORDER_SIZE = 2;

export const KEYS = {
  bundles: 'surface.wallets.section.bundles',
  fiat: 'surface.wallets.section.fiat',
  stable: 'surface.wallets.section.stable',
  crypto: 'surface.wallets.section.crypto',
  hidden: 'surface.wallets.wallets_list.hidden_wallets',
} as const;
