import * as React from 'react';
import {observer} from 'mobx-react';
import {KeyboardScrollView, useWizard} from '@youtoken/ui.elements';
import {Box} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AddPaymentMethodSurface} from '../../../CardPaymentMethods';
import {HeaderBase} from '../../../components';
import {
  CardDepositWizardNavigator,
  CardDepositWizardScreenName,
} from '../index';
import {Platform} from 'react-native';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const CardDepositAddCard: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    goBack,
    step: {
      name,
      props: {onSubmit, ticker},
    },
  } = useWizard<
    CardDepositWizardNavigator,
    CardDepositWizardScreenName.ADD_CARD_WITH_PAYMENT
  >();

  return (
    <Box flex={1}>
      {showHeader && (
        <HeaderBase onPressBack={goBack} shouldShowSeparator>
          {name === 'addCardWithPayment'
            ? t('surface.wallets.fiat_deposit_card.title', {
                ticker: ticker.toUpperCase(),
              })
            : t('surface.wallets.add_card')}
        </HeaderBase>
      )}
      <KeyboardScrollView
        insideTabs={false}
        wrapInSafeArea
        scrollViewProps={{
          keyboardDismissMode: 'interactive',
          style: {flex: 1},
          contentContainerStyle: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
      >
        <AddPaymentMethodSurface
          method="deposit"
          onPressCancel={goBack}
          onSubmit={onSubmit}
          ticker={ticker}
        />
      </KeyboardScrollView>
    </Box>
  );
});
