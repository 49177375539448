import {useMemo, useCallback} from 'react';
import {LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {LoyaltyIconName} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import type {CardVisualProps, CardVariant} from '../types';
import {CARD_HEIGHTS, CARDS_GAP} from '../constants';

export const useCards = (
  levels: LoyaltyLevel[],
  currentLevel: number
): CardVisualProps[] => {
  const {t} = useTranslation();

  const getCardVariant = useCallback(
    (index: number): CardVariant => {
      const levelNumber = index + 1;
      let variant: CardVariant = 'future';
      switch (levelNumber) {
        case currentLevel:
          variant = 'current';
          break;
        case currentLevel + 1:
          variant = 'next';
          break;
        default:
          if (levelNumber < currentLevel) {
            variant = 'prev';
          }
      }
      return variant;
    },
    [currentLevel]
  );

  const calculateCardHeight = useCallback(
    (index: number, variant: CardVariant) => {
      if (index === 0) {
        // newbie
        return 64;
      }
      if (index === 1) {
        // basic
        return {
          prev: 64,
          current: 64,
          next: 144,
          future: 0, // never happens
        }[variant];
      }
      if (index === 2) {
        // jumpstart
        return {
          prev: 129,
          current: 129,
          next: 144,
          future: 131,
        }[variant];
      }
      return {
        // other levels
        prev: 144,
        current: 144,
        next: 144,
        future: 131,
      }[variant];
    },
    []
  );

  const calculateOffsetTopStart = useCallback(
    // offset from top of the block when the list is collapsed
    (index: number, variant: CardVariant): number => {
      if (variant === 'future') {
        // future cards are hidden below the next level card, with a piece of each card visible at the bottom
        return (
          CARDS_GAP * (index - currentLevel) +
          (CARD_HEIGHTS.NEXT - CARD_HEIGHTS.FUTURE)
        );
      }
      // other cards are not shown
      return 0;
    },
    [currentLevel]
  );

  const calculateOffsetTopEnd = useCallback(
    // offset from top of the block when the list is expanded
    (index: number): number => {
      const allGaps = CARDS_GAP * index;
      const heightOfAllPrevious = levels.reduce((acc, _, i) => {
        if (i < index) {
          acc += calculateCardHeight(i, getCardVariant(i));
        }
        return acc;
      }, 0);

      return heightOfAllPrevious + allGaps;
    },
    [currentLevel, levels]
  );

  const calculateZIndex = useCallback(
    (index: number, variant: CardVariant): number => {
      return {
        prev: 1,
        current: 10 - index,
        next: 10,
        future: 10 - index,
      }[variant];
    },
    []
  );

  const calculateScale = useCallback(
    (index: number, variant: CardVariant): number => {
      return variant === 'future' ? 1 - (index - currentLevel) * 0.02 : 1;
    },
    [currentLevel]
  );

  const getLevelTitle = useCallback(
    (index: number, variant: CardVariant): string => {
      if (variant === 'current') {
        return t('surface.miner.loyalty.your_level');
      }
      if (variant === 'next') {
        return t('surface.miner.cards.next_level');
      }
      return t('surface.miner.cards.level_of', {
        number: index + 1,
        total: levels.length,
      });
    },
    [levels, t]
  );

  return useMemo(() => {
    return levels.map((level, index) => {
      const variant = getCardVariant(index);

      return {
        height: calculateCardHeight(index, variant),
        offsetTopStart: calculateOffsetTopStart(index, variant),
        offsetTopEnd: calculateOffsetTopEnd(index),
        zIndex: calculateZIndex(index, variant),
        scale: calculateScale(index, variant),
        icon: `${level.iconName}${
          variant === 'prev' ? '_grayscale' : ''
        }` as LoyaltyIconName,
        backgroundColor: variant === 'prev' ? '$ui-01' : level.cardColor,
        textColor: variant === 'prev' ? '$text-03' : '$text-01',
        levelTitle: getLevelTitle(index, variant),
      };
    });
  }, [levels, currentLevel, t]);
};
