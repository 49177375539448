import React from 'react';
import Big from 'big.js';
import {Text} from '@youtoken/ui.primitives';
import {Badge} from '@youtoken/ui.elements';
import {formatByTicker} from '@youtoken/ui.formatting-utils';

interface AmountBadgeProps {
  amount: Big;
  ticker: string;
}

export const AmountBadge: React.FC<AmountBadgeProps> = ({amount, ticker}) => {
  const amountFormatted = formatByTicker(amount.abs(), ticker);
  const amountSign = amount.gte(0) ? '+' : '-';

  return (
    <Badge
      alignSelf="center"
      variant={amountSign === '+' ? 'success' : 'danger'}
      size="small"
      type="secondary"
      py={3}
    >
      <Text
        color={amountSign === '+' ? '$success-01' : '$danger-01'}
        variant="$body-01-high-accent"
      >
        {'≈ ' + amountSign}
        {amountFormatted} <Text variant="$body-01">{ticker.toUpperCase()}</Text>
      </Text>
    </Badge>
  );
};
