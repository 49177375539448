import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {formatPercent} from '@youtoken/ui.formatting-utils';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  SavingsOverviewStatus,
  SavingsOverviewResource,
} from '@youtoken/ui.resource-savings';
import {Text, TouchableText} from '@youtoken/ui.primitives';
import {CurvedLine} from '../CurvedLine';
import {
  useAccountVerificationChecker,
  VerificationItemAccountCode,
} from '@youtoken/ui.resource-verification';
import {WalletsItemFeature} from '../../../../../../features/WalletsItemFeature';
import {ModalDynamic} from '@youtoken/ui.modal';
import {SavingsSignAgreementsForm} from '@youtoken/ui.surface-savings';

interface WalletsItemGeneralCurvedLineAprProps {
  ticker: string;
}

const savingsVerifications = [
  VerificationItemAccountCode.FORM_A,
  VerificationItemAccountCode.IDENTITY,
];

export const WalletsItemGeneralCurvedLineApr: React.FC<WalletsItemGeneralCurvedLineAprProps> =
  observer(({ticker}) => {
    const {savingVersion, savings4Active} = AuthMeResource.use({
      ticker,
    });

    if (savingVersion === 4) {
      // NOTE: BLOCK REQUEST TO OVERVIEW, IF AGREEMENT DIDN'T SIGN
      if (savings4Active) {
        return <WalletsItemGeneralCurvedLineAprV4 ticker={ticker} />;
      }

      return null;
    }

    return <WalletsItemGeneralCurvedLineAprLegacy ticker={ticker} />;
  });

export const WalletsItemGeneralCurvedLineAprV4: React.FC<WalletsItemGeneralCurvedLineAprProps> =
  observer(({ticker}) => {
    const {t} = useTranslation();

    const {
      data: {status, tickers, getEarnedByTicker},
    } = SavingsOverviewResource.use({});

    const saving = tickers?.[ticker];

    if (status !== SavingsOverviewStatus.OPEN || !saving) {
      return null;
    }

    const {apr} = saving;
    const {earnedInBaseTickerFormatted} = getEarnedByTicker(ticker);

    return (
      <CurvedLine>
        <Text variant="$body-02" color="$interactive-01">
          {t('surface.wallets.wallet_detail.earned_at', {
            amount: earnedInBaseTickerFormatted,
            ticker: ticker.toUpperCase(),
            apr: formatPercent(apr),
          })}
        </Text>
      </CurvedLine>
    );
  });

export const WalletsItemGeneralCurvedLineAprLegacy: React.FC<WalletsItemGeneralCurvedLineAprProps> =
  observer(({ticker}) => {
    const {t} = useTranslation();

    const {
      hasApr,
      hasSavings,
      aprFormatted,
      tickerFormatted,
      earnedFormatted,
      shouldSignSavings,
    } = WalletsItemFeature.use({
      ticker,
    });
    const hasDataToShow = shouldSignSavings || hasSavings;

    const [isModalOpen, setModalOpen] = React.useState(false);

    const handleOpenModal = React.useCallback(() => {
      setModalOpen(true);
    }, []);

    const handleCloseModal = React.useCallback(() => {
      setModalOpen(false);
    }, []);

    const handlePressSignSavings = useAccountVerificationChecker(
      savingsVerifications,
      handleOpenModal
    );

    if (!hasApr || !hasDataToShow) {
      return null;
    }

    return (
      <>
        <CurvedLine>
          {shouldSignSavings && (
            <TouchableText
              variant="$body-02"
              color="$interactive-01"
              onPress={handlePressSignSavings as () => void | Promise<void>}
            >
              {t('surface.wallets.wallet_detail.sign_agreement', {
                ticker: tickerFormatted,
                apr: aprFormatted,
              })}
            </TouchableText>
          )}
          {hasSavings && (
            <Text variant="$body-02" color="$interactive-01">
              {t('surface.wallets.wallet_detail.earned_at', {
                amount: earnedFormatted,
                ticker: tickerFormatted,
                apr: aprFormatted,
              })}
            </Text>
          )}
        </CurvedLine>
        <ModalDynamic
          isOpen={isModalOpen}
          shouldShowHeaderSeparator={false}
          onClose={handleCloseModal}
          testID="SIGN_SAVINGS_MODAL"
        >
          <SavingsSignAgreementsForm onClose={handleCloseModal} />
        </ModalDynamic>
      </>
    );
  });
