import {computed} from 'mobx';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {BundleOperation} from '../BundleOperation';
import {
  BundleOperationsResourceResponse,
  bundleOperationsResourceResponseSchema,
} from './BundleOperationsResourceResponse';

export type HistoryListSection = {
  title: string;
  operations: BundleOperation[];
};

export interface BundleOperationsResourceArgs {
  id: string;
}

export class BundleOperationsResource extends createResource<
  BundleOperationsResourceArgs,
  BundleOperationsResourceResponse
>({
  getKey: ({id}: BundleOperationsResourceArgs) => {
    return `BundleOperations(${id})`;
  },
  getData: ({id}: BundleOperationsResourceArgs) => {
    return TRANSPORT.API.get<typeof bundleOperationsResourceResponseSchema>(
      `/v1/bundle/operations?tariffId=${id}`
    ).then(response => {
      bundleOperationsResourceResponseSchema.parse(response.data);

      return deserialize(BundleOperationsResourceResponse, response.data);
    });
  },
  skipRefreshOnVisible: false,
}) {
  @computed get sections(): HistoryListSection[] {
    const mapByDay = this.data.rows.reduce((acc, item) => {
      const day = item.createdAtFormatted;
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day]!.push(item);
      return acc;
    }, {} as Record<string, BundleOperation[]>);

    return Object.entries(mapByDay).map(([title, operations]) => ({
      title,
      operations,
    }));
  }
}
