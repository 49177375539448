import React from 'react';
import {observer} from 'mobx-react';
import {BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {FeatureList} from '@youtoken/ui.elements';
import {MinerOverviewResource} from '@youtoken/ui.resources-miner';
import {type IconName} from '@youtoken/ui.icons/src/Icon';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';

export const HODLsFeatureListSmart: React.FC<BoxProps> = observer(
  ({...boxProps}) => {
    const {t} = useTranslation();
    const {
      data: {enableMiner},
      incentivesEnabled,
    } = AuthMeResource.use({});
    const minerOverviewResource = enableMiner
      ? MinerOverviewResource.use({})
      : null;
    const {settings: minerSettings} = minerOverviewResource?.data ?? {};

    const items: {
      icon: IconName;
      title?: string;
      children?: React.ReactNode;
    }[] = React.useMemo(() => {
      const items = [];

      items.push({
        icon: 'fiat_deposit' as IconName,
        title: t('surface.hodls.portfolio.empty.feat_universal_input.title'),
        children: t('surface.hodls.portfolio.empty.feat_universal_input.text'),
      });

      if (minerSettings) {
        items.push({
          icon: 'spark' as IconName,
          title: t('surface.hodls.portfolio.empty.feat_sparks.title'),
          children: t('surface.hodls.portfolio.empty.feat_sparks.text', {
            sparks_amount: minerSettings.hodlSparksAmount,
            usd_amount: minerSettings.hodlSparksRequiredVolume,
          }),
        });
      }

      if (incentivesEnabled) {
        items.push({
          icon: 'hodl' as IconName,
          title: t('surface.hodls.portfolio.empty.feat_bonuses.title'),
          children: t('surface.hodls.portfolio.empty.feat_bonuses.text'),
        });
      }

      return items;
    }, [minerSettings, incentivesEnabled]);

    return <FeatureList items={items} {...boxProps} />;
  }
);
