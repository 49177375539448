import * as React from 'react';
import {Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {IconName} from '@youtoken/ui.icons/src/Icon';

export const PASSCODE_BUTTON_SIZE = 72;

export type PasscodeButtonProps = {
  type?: 'secondary' | 'ghost';
  disabled?: boolean;
  onPress: () => void;
};

const PasscodeButton: React.FC<PasscodeButtonProps> = ({
  type = 'secondary',
  disabled = false,
  onPress,
  children,
}) => {
  return (
    <TouchableBox
      width={72}
      height={72}
      borderRadius={36}
      justifyContent="center"
      alignItems="center"
      opacity={disabled ? 0.6 : 1}
      activeOpacity={disabled ? 0 : 0.8}
      onPress={onPress}
      backgroundColor={
        type === 'secondary' ? '$interactive-02' : '$ui-background'
      }
      disabled={disabled}
    >
      {children}
    </TouchableBox>
  );
};

export const PasscodNumberButton: React.FC<
  Omit<PasscodeButtonProps, 'onPress'> & {
    number: number;
    onPress: (n: number) => void;
  }
> = ({number, onPress, ...props}) => {
  const handlePress = React.useCallback(() => {
    onPress(number);
  }, [onPress, number]);

  return (
    <PasscodeButton onPress={handlePress} {...props}>
      <Text
        fontSize={32}
        lineHeight={72}
        color="$interactive-01"
        variant="$body-01-high-accent"
        fontVariant={['tabular-nums', 'proportional-nums']}
      >
        {number}
      </Text>
    </PasscodeButton>
  );
};

export const PasscodeIconButton: React.FC<
  PasscodeButtonProps & {
    icon: IconName;
  }
> = ({icon, ...props}) => {
  return (
    <PasscodeButton {...props}>
      <Icon name={icon} size={32} color="$interactive-01" />
    </PasscodeButton>
  );
};
