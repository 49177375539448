import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {FieldErrorMessage, Label, useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AmountInTickerTextInput} from '@youtoken/ui.inputs';
import {
  BuyCryptoFormAuthorizedOnRampState,
  BuyCryptoFormAuthorizedYouHodlerState,
} from '../state';

export const ConversionPairTarget: React.FC<{isDisabled?: boolean} & BoxProps> =
  observer(({isDisabled, ...boxProps}) => {
    const {t} = useTranslation();

    const {
      form: {
        conversionTicker,
        conversionAmount,
        hasTargetError,
        targetError,
        setConversionAmount,
      },
    } = useForm<
      BuyCryptoFormAuthorizedOnRampState | BuyCryptoFormAuthorizedYouHodlerState
    >();

    return (
      <Box {...boxProps}>
        <Box
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          mb={10}
        >
          <Label>{`${t('ramp.conversion_form.label.target')} `}</Label>
        </Box>
        <AmountInTickerTextInput
          ticker={conversionTicker}
          onChangeText={setConversionAmount}
          value={conversionAmount}
          hasError={hasTargetError}
          testID="FIELD_INPUT"
          disabled={isDisabled}
        />
        <FieldErrorMessage testID="FIELD_ERROR" visible={hasTargetError}>
          {targetError}
        </FieldErrorMessage>
      </Box>
    );
  });
