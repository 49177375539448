import React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Heading, Box, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Illustration} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {BorderBoxWrapper} from '@youtoken/ui.screen-components';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {HODLsFeatureListSmart} from '@youtoken/ui.hodl-elements';

const handleOpenMH = () => {
  DATA_LAYER.trackStrict('hodl-create-position-click', {source: 'empty-state'});
  SHARED_ROUTER_SERVICE.navigate('NewMultiHODL', {}, {});
};

export const EmptyState: React.FC = cell(({...boxProps}) => {
  const {t} = useTranslation();

  return (
    <BorderBoxWrapper
      flex={1}
      pt={24}
      borderBottomWidth={{default: 0, desktop: 1}}
      {...boxProps}
    >
      <Box alignSelf="center" height={210}>
        <Illustration name="illustration-16" />
      </Box>

      <Box mt={-88} px={24}>
        <Heading color="$text-01" textAlign="center" mb={8}>
          {t('surface.hodls.portfolio.empty.title')}
        </Heading>
        <Text color="$text-02" textAlign="center" mb={24}>
          {t('surface.hodls.portfolio.empty.text')}
        </Text>
        <Button size="large" onPress={handleOpenMH}>
          <Text>{t('surface.hodls.portfolio.empty.action')}</Text>
        </Button>
      </Box>

      <HODLsFeatureListSmart px={0} mx={24} my={32} />
    </BorderBoxWrapper>
  );
});
