import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';

export const ExtendHODLButton: React.FC<
  React.ComponentProps<typeof Button>
> = ({...buttonProps}) => {
  const {t} = useTranslation();

  return (
    <Button
      type="secondary"
      icon="increase_position"
      flexBasis={{default: 'auto', desktop: 0}}
      flexGrow={1}
      {...buttonProps}
    >
      {t('surface.hodls.extend_tp.action_title')}
    </Button>
  );
};
