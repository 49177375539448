import * as React from 'react';
import {observer} from 'mobx-react';
import {ScrollView} from 'react-native';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {
  formatPercent,
  formatNumberToOrder,
} from '@youtoken/ui.formatting-utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Images} from '@youtoken/ui.images';
import {LoansListItemFeature, LoansListItemFeatureArgs} from '../../../state';

export interface DetailedChainLoansProps
  extends LoansListItemFeatureArgs,
    BoxProps {}

export const DetailedChainLoans: React.FC<DetailedChainLoansProps> = observer(
  ({mode, id, ...rest}) => {
    const {t} = useTranslation();

    const loanItemFeature = LoansListItemFeature.use({
      mode,
      id,
    });

    const handleMap = React.useCallback(
      (loan: any, index: number, list: {}[]) => {
        return (
          <Box position="relative" key={index} zIndex={list.length - index}>
            <Box
              justifyContent="center"
              width={120}
              height={100}
              px={16}
              mr={8}
              bg="$ui-background"
              borderRadius={8}
            >
              <Text
                variant="$body-02-high-accent"
                color={
                  loanItemFeature.isClosingOrClosed
                    ? '$text-01'
                    : '$interactive-01'
                }
              >
                {formatNumberToOrder(index + 1)}{' '}
                {t('surface.loans.item.loans.loan')}
              </Text>
              <Text variant="$body-02" color="$text-01">
                <Text variant="$body-02-high-accent">
                  {loan.collateralAmount}
                </Text>{' '}
                {loan.collateralTicker.toUpperCase()}
              </Text>
              <Text variant="$body-02" color="$text-01">
                <Text variant="$body-02-high-accent">
                  {loan.borrowedAmount}
                </Text>{' '}
                {loan.borrowedTicker.toUpperCase()}
              </Text>
              <Text variant="$body-02" color="$text-01">
                <Text variant="$body-02-high-accent">
                  {formatPercent(loan.interest)}%
                </Text>{' '}
                {t('surface.loans.item.loans.fee')}
              </Text>
            </Box>
            {list.length > 1 && index < list.length - 1 && (
              <Box position="absolute" right={0} top="50%" mr={-9} mt={-11}>
                {loanItemFeature.isClosingOrClosed ? (
                  <Images.arrSnake width={24} height={22} />
                ) : (
                  <Images.arrSnake width={24} height={22} />
                )}
              </Box>
            )}
          </Box>
        );
      },
      [t, loanItemFeature.isClosingOrClosed]
    );

    return (
      <Box
        p={20}
        backgroundColor={
          loanItemFeature.isClosingOrClosed ? '$ui-01' : '$interactive-02'
        }
        {...rest}
      >
        <Box flexDirection="row" justifyContent="space-between">
          <Text variant="$body-01-high-accent">
            {t('surface.loans.item.loans')}
          </Text>
          <Box
            alignItems="center"
            justifyContent="center"
            backgroundColor={
              loanItemFeature.isClosingOrClosed ? '$ui-02' : '$interactive-03'
            }
            borderRadius={5}
            px={8}
            height={20}
            minWidth={26}
          >
            <Text
              variant="$body-02"
              color={
                loanItemFeature.isClosingOrClosed
                  ? '$text-02'
                  : '$interactive-01'
              }
            >
              x{loanItemFeature.turbo}
            </Text>
          </Box>
        </Box>
        <ScrollView
          horizontal
          snapToInterval={120}
          decelerationRate="fast"
          contentContainerStyle={{
            paddingVertical: 20,
          }}
        >
          <Box flexDirection="row">{loanItemFeature.loans!.map(handleMap)}</Box>
        </ScrollView>
      </Box>
    );
  }
);
