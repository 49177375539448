import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, Heading, type BoxProps} from '@youtoken/ui.primitives';
import {ProgressBar} from '@youtoken/ui.progress-bar';
import {Timer} from '@youtoken/ui.elements';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {useAccountLevelInfo} from '../LoyaltyMiner';
import {Description} from './Description';

export const AccountLevelInfo: React.FC<BoxProps> = observer(boxProps => {
  const info = useAccountLevelInfo();
  const {t} = useTranslation();

  const {
    refetch,
    data: {timeLeftUntilLevelDowngrade, downgradeLevel},
    getLevel,
  } = LoyaltyResource.use({});

  const downgradeLevelName = getLevel(downgradeLevel)?.name;

  if (!info) {
    return null;
  }

  const {textColor, titleKey, descriptionKey, progress, progressText} = info;

  return (
    <Box
      flexDirection="column"
      borderWidth={1}
      borderColor="$ui-03"
      borderRadius={16}
      {...boxProps}
    >
      <Heading
        variant="$heading-02"
        color={textColor}
        mb={8}
        testID="MINER_LEVEL_INFO_TITLE"
      >
        <Trans
          t={t}
          i18nKey={titleKey}
          components={{
            Timer: (
              <Timer
                variant="$heading-02"
                color={textColor}
                onTimerEnd={refetch}
                timeLeft={timeLeftUntilLevelDowngrade}
              />
            ),
          }}
          values={{level: downgradeLevelName}}
        />
      </Heading>

      <Description
        i18nKey={descriptionKey}
        color={textColor}
        timeLeft={timeLeftUntilLevelDowngrade}
      />

      {typeof progress === 'number' && (
        <Box mt={8}>
          <Text
            color={textColor}
            variant="$body-01-high-accent"
            mb={2}
            testID="MINER_LEVEL_INFO_PROGRESS_TEXT"
          >
            {progressText}
          </Text>
          <ProgressBar
            testID="MINER_LEVEL_INFO_PROGRESS_BAR"
            color="attention"
            progress={progress}
          />
        </Box>
      )}
    </Box>
  );
});
