import {z} from 'zod';
import {serializable, list, object} from 'serializr';
import {bundleTariffSchema, BundleTariff} from '../BundleTariff';

export const bundleTariffListResourceResponseSchema = z.object({
  tariffs: z.array(bundleTariffSchema),
});

export class BundleTariffListResourceResponse {
  @serializable(list(object(BundleTariff)))
  tariffs!: BundleTariff[];
}
