import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BundleTariffTickersListItem} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box, Heading} from '@youtoken/ui.primitives';

interface BundleTickersHeaderProps {
  items: BundleTariffTickersListItem[];
}

export const BundleTickersHeader: React.FC<
  BundleTickersHeaderProps & BoxProps
> = observer(({items, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      px={{default: 16, phone: 24, desktop: 0}}
      {...boxProps}
    >
      <Heading variant="$heading-02" color="$text-01">
        {t('surface.bundle.tickers')}
      </Heading>
      <Heading variant="$heading-02" color="$text-03">
        {t('surface.bundle.tickers_assets', {count: items.length})}
      </Heading>
    </Box>
  );
});
