import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {
  BankCardTransactionsSurface,
  getBankCardTransactionsTitle,
} from '@youtoken/ui.surface-wallets-bank-cards';
import {modal} from '../../../stores';

export const modalBankCardTransactionsName = 'bankCardTransactions';

type ModalBankCardTransactionsProps = {
  cardId: string;
} & Omit<ModalDynamicProps, 'children'>;

export const ModalBankCardTransactions = cell<ModalBankCardTransactionsProps>(
  ({cardId}) => {
    const {opened, close} = modal;

    return (
      <ModalDynamic
        isOpen={opened?.name === modalBankCardTransactionsName}
        onClose={close}
        title={getBankCardTransactionsTitle()}
        webOverlayBackgroundColor="$transparent"
        webHeight={576}
      >
        <BankCardTransactionsSurface cardId={cardId} />
      </ModalDynamic>
    );
  }
);
