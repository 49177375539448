import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, Heading, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation, Trans} from '@youtoken/ui.service-i18n';
import {Link} from '@youtoken/ui.elements';
import {Form, SubmitButton, makeForm} from '@youtoken/ui.form-elements';
import {SignUpCorporateForm} from './state';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  CountrySelect,
  EmailField,
  PasswordField,
  TermsField,
} from './components';

interface SignUpCorporateSurfaceProps {
  onChangeCountry: (country: string) => void;
  showTitle?: boolean;
}

export const SignUpCorporateSurface: React.FC<
  SignUpCorporateSurfaceProps & BoxProps
> = observer(({onChangeCountry, showTitle = true, ...boxProps}) => {
  const {t} = useTranslation();

  const resources = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
  });

  const form = makeForm(() => new SignUpCorporateForm({}, resources));

  return (
    <Box {...boxProps}>
      <Box mb={24}>
        {showTitle && (
          <Heading variant="$heading-01-responsive" mb={24}>
            {t('surface.sign_up_corporate.title')}
          </Heading>
        )}
        <Trans
          t={t}
          i18nKey="surface.sign_up_corporate.description"
          style={{flexWrap: 'wrap'}}
          parent={props => (
            <Text
              color="$text-02"
              testID="SIGN_UP_BUSINESS_DESCRIPTION"
              {...props}
            />
          )}
          components={{
            Link: (
              <Link
                url="https://www.youhodler.com/corporate-account"
                color="$text-05"
                testID="SIGN_UP_BUSINESS_DESCRIPTION_LINK"
              />
            ),
          }}
        />
      </Box>

      <Form form={form}>
        <Box
          flex={1}
          justifyContent={{tablet: 'flex-start', default: 'space-between'}}
        >
          <Box>
            <CountrySelect mb={12} zIndex={10} onChange={onChangeCountry} />
            <EmailField mb={24} />
            <PasswordField mb={24} />
          </Box>

          <Box>
            <TermsField mb={24} />
            <SubmitButton size="large" testID="SIGN_UP_BUTTON">
              {t('surface.sign_up_corporate.submit')}
            </SubmitButton>
          </Box>
        </Box>
      </Form>
    </Box>
  );
});
