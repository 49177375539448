import React from 'react';
import {observer} from 'mobx-react';
import {Button} from '@youtoken/ui.buttons';
import {Text, Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

const handleOpenMH = () => {
  DATA_LAYER.trackStrict('hodl-create-position-click', {
    source: 'active-positions-state',
  });
  SHARED_ROUTER_SERVICE.navigate('NewMultiHODL', {}, {});
};

export const OpenNewHODLButton: React.FC<BoxProps> = observer(
  ({...boxProps}) => {
    const {t} = useTranslation();

    return (
      <Box px={{default: 20, desktop: 0}} {...boxProps}>
        <Button
          onPress={handleOpenMH}
          size="large"
          type="secondary"
          testID="OPEN_NEW_MH_BUTTON"
        >
          <Text>{t('surface.hodls.portfolio.create_mh_button.action')}</Text>
        </Button>
      </Box>
    );
  }
);
