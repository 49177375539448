import React from 'react';
import {cell} from '@youtoken/ui.cell';
import {PageSwitcherValue} from '@youtoken/ui.legacy-components';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {PageMenu} from './PageMenu';
import {HODLsInstrumentsPageContent} from './HODLsInstrumentsPageContent';
import {HODLsPortfolioPageContent} from './HODLsPortfolioPageContent';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {MultiplyYourCryptoModal} from '@youtoken/ui.surface-hodl-instruments';

export interface HODLsMainPageContentProps {
  innerPage: PageSwitcherValue;
}

export const HODLsMainPageContent = cell<HODLsMainPageContentProps>(
  ({innerPage}) => {
    const {
      data: {
        clientSettings: {multiplyYourCryptoModalShown},
      },
      setMultiplyYourCryptoModalShown,
    } = AuthMeResource.use({});

    const [isModalOpen, setIsModalOpen] = React.useState(
      !multiplyYourCryptoModalShown
    );

    const handleOnModalClose = React.useCallback(() => {
      setIsModalOpen(false);
      setMultiplyYourCryptoModalShown();
    }, [setMultiplyYourCryptoModalShown]);

    React.useEffect(() => {
      if (innerPage === PageSwitcherValue.TRADING) {
        DATA_LAYER.trackStrict('hodl-what-to-trade', {});
      }
      if (innerPage === PageSwitcherValue.PORTFOLIO) {
        DATA_LAYER.trackStrict('hodl-portfolio', {});
      }
    }, [innerPage]);

    return (
      <>
        <PageMenu page={innerPage} />
        {innerPage === PageSwitcherValue.TRADING && (
          <HODLsInstrumentsPageContent />
        )}
        {innerPage === PageSwitcherValue.PORTFOLIO && (
          <HODLsPortfolioPageContent />
        )}
        <MultiplyYourCryptoModal
          isOpen={isModalOpen}
          onClose={handleOnModalClose}
        />
      </>
    );
  }
);
