import * as React from 'react';
import {
  BackHandler,
  type LayoutChangeEvent,
  type LayoutRectangle,
  StatusBar,
  Dimensions,
} from 'react-native';
import {Box, type BoxProps, useIsDesktop} from '@youtoken/ui.primitives';
import {ModalLegacy} from '@youtoken/ui.modal';
import {StoryListItem} from './StoryListItem';
import {StoryPreviewList} from './StoryPreviewList';
import {StoryListItemNavigator} from './StoryListItemNavigator';
import {isNullOrWhitespace} from '../helpers';
// @ts-ignore JS import
import CubeEffect from './CubeEffect';
import {
  type IUserStory,
  type NextOrPrevious,
  type StoryNavigation,
  type StoryProps,
} from '../interfaces';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {observer} from 'mobx-react';
import {InstaStoriesResource} from '@youtoken/ui.resource-insta-stories';
import {SizeAdaptor} from './SizeAdaptor';

const STORY_WIDTH = 390;
const STORY_HEIGHT = 750;

export const Story: React.FC<StoryProps & BoxProps> = observer(
  ({
    stories,
    unPressedBorderColor,
    pressedBorderColor,
    unPressedPreviewTextColor,
    pressedPreviewTextColor,
    onStart,
    onClose,
    onStorySeen,
    onStoryShown,
    duration,
    previewSize,
    showPreviewText = true,
    previewTextStyle,
    loadedAnimationBarStyle,
    unloadedAnimationBarStyle,
    animationBarContainerStyle,
    storyImageStyle,
    previewImageStyle,
    previewWrapperStyle,
    previewFlatListProps,
    ...props
  }) => {
    const {activeStoryIndex, updateActiveStoryIndex, setAutoOpenActive} =
      InstaStoriesResource.use({});

    const isDesktop = useIsDesktop();
    // NOTE: for handleSeen effect call only on change
    const isFirstRun = React.useRef(true);

    const [size, setSize] = React.useState<Partial<LayoutRectangle> | null>(
      null
    );
    const {height} = Dimensions.get('screen');

    const handleSetSize = React.useCallback(
      ({nativeEvent: {layout}}: LayoutChangeEvent) => {
        setSize({
          width: layout.width,
          height: isDesktop ? STORY_HEIGHT : height,
        });
      },
      [isDesktop, height]
    );
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(
      activeStoryIndex !== -1
    );

    const [currentStoryIndex, setCurrentStoryIndex] = React.useState<number>(
      activeStoryIndex !== -1 ? activeStoryIndex : 0
    );

    React.useEffect(() => {
      if (activeStoryIndex !== -1) {
        setIsModalOpen(true);
        setCurrentStoryIndex(activeStoryIndex);
      }
    }, [activeStoryIndex]);

    const storiesRefs: StoryNavigation[] = React.useMemo(() => {
      return stories.map(() => React.createRef());
    }, [stories]);
    const currentStoryRef = storiesRefs[currentStoryIndex]!;

    const cube = React.useRef<CubeEffect>();

    React.useEffect(() => {
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        () => {
          if (isModalOpen) {
            // todo do we need call onClose() ?
            handleCloseModal();
            setAutoOpenActive(false);
            return true; // to prefent default behaviour
          }

          return false;
        }
      );

      return () => backHandler.remove();
    }, [isModalOpen]);

    const resetStatusBarStyle = () => {
      StatusBar.setBarStyle('default', true);
    };

    // Component Functions
    const handleStoryPreviewPress = React.useCallback(
      (item: IUserStory, index: number) => {
        DATA_LAYER.trackStrict('story-preview-open', {
          storyId: item.id,
          storySlug: item.slug,
          storyTitle: item.title,
          storyNumberOfSlides: item.slides?.length,
          storySeenBefore: item.shown,
          storyIndex: index + 1,
        });

        onStart?.(item);
        setCurrentStoryIndex(index);
        setIsModalOpen(true);
        StatusBar.setBarStyle('light-content', true);
      },
      [onStart]
    );

    const handleSeen = React.useCallback(
      (currentStoryIndex: number) => {
        // NOTE: mark as seen that stories, which were not clocked, but seen automatically
        if (!stories[currentStoryIndex]?.seen) {
          onStorySeen?.(stories[currentStoryIndex]!.slug);
        }
      },
      [stories]
    );

    React.useEffect(() => {
      if (isFirstRun.current) {
        isFirstRun.current = false;
        return;
      }

      handleSeen(currentStoryIndex);
    }, [currentStoryIndex]);

    const handleCloseModal = React.useCallback(() => {
      // NOTE: это общая функция, срабатывает на закрытие. Тут не должно быть событий
      // Ранее называлась handleCloseFromModal
      setIsModalOpen(false);
      setAutoOpenActive(false);
      updateActiveStoryIndex();
      resetStatusBarStyle();
    }, [stories, currentStoryIndex]);

    // NOTE: will show previous or next story automatically, if they exists
    const onStoryFinish = React.useCallback(
      (state: NextOrPrevious) => {
        if (!isNullOrWhitespace(state)) {
          if (state === 'next') {
            const newPage = currentStoryIndex + 1;
            if (newPage < stories.length) {
              setCurrentStoryIndex(newPage);
              cube?.current?.scrollTo(newPage);
            } else {
              onClose?.(stories[stories.length - 1]);
              setCurrentStoryIndex(0);
              setIsModalOpen(false);
              setAutoOpenActive(false);
            }
          } else if (state === 'previous') {
            const newPage = currentStoryIndex - 1;
            if (newPage < 0) {
              onClose?.(stories[0]);
              setCurrentStoryIndex(0);
              setIsModalOpen(false);
              setAutoOpenActive(false);
            } else {
              setCurrentStoryIndex(newPage);
              cube?.current?.scrollTo(newPage);
            }
          }
        }
      },
      [currentStoryIndex, stories, cube?.current]
    );

    const handleCloseFromItem = React.useCallback(
      (story: IUserStory) => {
        DATA_LAYER.trackStrict('story-closed', {
          storyId: story.id,
          storySlug: story.slug,
          storyTitle: story.title,
          storyNumberOfSlides: story.slides.length,
          // todo add "current slide number"
        });
        onClose?.(stories[currentStoryIndex]);
        handleCloseModal();
        setAutoOpenActive(false);
      },
      [onClose]
    );

    const onSwipeCallback = React.useCallback(
      (value: boolean) => {
        if (!value) {
          return currentStoryRef.current?.resume();
        }

        return currentStoryRef.current?.pause();
      },
      [currentStoryRef.current]
    );

    const afterSwipeCallback = React.useCallback(
      (storyIndex: number) => {
        if (storyIndex !== currentStoryIndex) {
          setCurrentStoryIndex(storyIndex);
        }
        // NOTE: 'story-skipped' event should check the direction in case we have stories before
        if (storyIndex > currentStoryIndex) {
          const currentStory = stories[currentStoryIndex]!;
          DATA_LAYER.trackStrict('story-skipped', {
            storyId: currentStory?.id,
            storySlug: currentStory?.slug,
            storyTitle: currentStory?.title,
            storyNumberOfSlides: currentStory?.slides?.length,
            // todo add "current slide number"
          });
        }
      },
      [currentStoryIndex, stories[currentStoryIndex]]
    );
    //#endregion Component Functions

    const renderStoryList = () =>
      stories.map((story, i) => {
        return (
          <StoryListItem
            ref={storiesRefs[i]}
            duration={duration * 1000}
            key={i}
            story={story}
            currentPage={currentStoryIndex}
            onFinish={onStoryFinish}
            showClose={!isDesktop}
            onPressClose={handleCloseFromItem}
            onPressCTA={handleCloseModal}
            index={i}
            onStoryShown={onStoryShown}
            onStorySeen={onStorySeen}
            unloadedAnimationBarStyle={unloadedAnimationBarStyle}
            animationBarContainerStyle={animationBarContainerStyle}
            loadedAnimationBarStyle={loadedAnimationBarStyle}
            storyImageStyle={storyImageStyle}
          />
        );
      });

    const renderCube = (size: Partial<LayoutRectangle>) => {
      return (
        <CubeEffect
          ref={cube as React.LegacyRef<CubeEffect>}
          size={size}
          currentPage={currentStoryIndex}
          callbackOnSwipe={onSwipeCallback}
          callBackAfterSwipe={afterSwipeCallback}
        >
          {renderStoryList()}
        </CubeEffect>
      );
    };

    return (
      <>
        <Box {...props}>
          <StoryPreviewList
            handleStoryPreviewPress={handleStoryPreviewPress}
            data={stories}
            previewSize={previewSize}
            unPressedBorderColor={unPressedBorderColor}
            pressedBorderColor={pressedBorderColor}
            unPressedPreviewTextColor={unPressedPreviewTextColor}
            pressedPreviewTextColor={pressedPreviewTextColor}
            showText={showPreviewText}
            previewTextStyle={previewTextStyle}
            previewWrapperStyle={previewWrapperStyle}
            previewImageStyle={previewImageStyle}
            previewFlatListProps={previewFlatListProps}
          />
        </Box>
        <ModalLegacy
          closePosition="outside"
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          snapPoints={['100%']}
          nativeBackgroundStyle={{
            backgroundColor: 'transparent',
          }}
          webOverlayBackgroundColor="$blackout-02"
          nativeActiveOffsetX={100}
          nativeActiveOffsetY={100}
          position={{default: 'absolute', desktop: 'relative'}}
          top={0}
          bottom={0}
          left={0}
          right={0}
          width={{
            default: '100%',
            desktop: STORY_WIDTH,
          }}
          height={{
            default: '100%',
            desktop: STORY_HEIGHT,
          }}
          maxWidth={undefined}
          maxHeight={undefined}
          pt={0}
          pb={0}
          pl={0}
          pr={0}
          bg="$transparent"
          overflow="visible"
        >
          <StoryListItemNavigator storyRef={currentStoryRef} />
          <SizeAdaptor handleSetSize={handleSetSize}>
            {size && renderCube(size)}
          </SizeAdaptor>
        </ModalLegacy>
      </>
    );
  }
);
