import {computed, observable} from 'mobx';
import {
  createSimpleSchema,
  list,
  object,
  primitive,
  serializable,
} from 'serializr';
import {Requirements} from '../types';

const Settings = createSimpleSchema({
  '*': primitive(),
});

export class Product {
  @observable
  @serializable(list(primitive()))
  requirements: Requirements[] = [];

  @observable
  @serializable(primitive())
  kyc: boolean = false;

  @observable
  @serializable(primitive())
  agreement: boolean = false;

  // Доступность продукта юзеру в данный момент
  @observable
  @serializable(primitive())
  available: boolean = false;

  @observable
  @serializable(object(Settings))
  settings = {};

  @serializable(primitive())
  @computed
  get shouldSign() {
    return (
      !this.requirements.includes(Requirements.SUPPORT) &&
      this.requirements.includes(Requirements.AGREEMENT)
    );
  }

  // Можно ли показывать продукт в платформе игнорируя его доступность (available)
  @computed
  get isEnabled() {
    // SUPPORT is a special requirement that means that the product is not available under any circumstances
    return !this.requirements.includes(Requirements.SUPPORT);
  }
}

export class HodlProduct extends Product {
  @observable
  @serializable(object(Settings))
  settings!: {
    enableHodl3: boolean;
  };
}

export class LoanProduct extends Product {
  @observable
  @serializable(object(Settings))
  settings!: {
    enableExtendPDL: boolean;
  };
}

export class TurboProduct extends Product {}
export class ExchangeProduct extends Product {
  @observable
  @serializable(object(Settings))
  settings!: {
    version: number;
    enableConvertsLoyalty: boolean;
  };
}
export class Saving3Product extends Product {
  @observable
  @serializable(object(Settings))
  settings!: {
    enableSaving2: boolean;
  };
}
export class Saving4Product extends Product {
  @observable
  @serializable(object(Settings))
  settings!: {
    enableSaving2: boolean;
    enableSaving3: boolean;
  };
}
export class DepositCryptoProduct extends Product {}
export class DepositFiatWireProduct extends Product {
  @observable
  @serializable(object(Settings))
  settings!: {
    enableBankWireDeposit_USD: boolean;
    enableBankWireDeposit_EUR: boolean;
    enableBankWireDeposit_GBP: boolean;
    enableBankWireDeposit_CHF: boolean;
    disableBankWireDepositButton: boolean;
  };
}
export class DepositFiatUnlimintApplePayProduct extends Product {}
export class DepositFiatBankCardProduct extends Product {}
export class DepositFiatUnlimintCoDiProduct extends Product {}
export class DepositFiatUnlimintSPEIProduct extends Product {}
export class DepositFiatAdvcashProduct extends Product {}
export class DepositFiatUnlimitProduct extends Product {}

export class DepositBuyCrypto extends Product {}

export class WithdrawalCryptoProduct extends Product {
  @observable
  @serializable(object(Settings))
  settings!: {
    withdrawalManualApprove: boolean;
    skipFraudWithdrawalDetection: boolean;
  };
}

export class WithdrawalFiatProduct extends Product {
  @observable
  @serializable(object(Settings))
  settings!: {
    withdrawalManualApprove: boolean;
    skipFraudWithdrawalDetection: boolean;
    enabledBankCard: boolean;
  };
}

export class MinerProduct extends Product {}
export class StroriesProduct extends Product {}
export class OnboardingWidgetProduct extends Product {}
export class RewardsProduct extends Product {}
export class RewardsChallengeProduct extends Product {}
export class IncentivesProduct extends Product {}
export class QuizProduct extends Product {}
export class DemoProduct extends Product {}
export class WirexProduct extends Product {
  @observable
  @serializable(primitive())
  isAllowThirdParty: boolean = false;

  @observable
  @serializable(object(Settings))
  settings!: {
    enableWirexIBAN: boolean;
    enableWirexIBAN_EUR: boolean;
    enableWirexIBAN_GBP: boolean;
  };
}

export class LoyaltyProduct extends Product {}

export class IntergiroProduct extends Product {
  @observable
  @serializable(object(Settings))
  settings!: {
    enable: boolean;
    enablePhysicalBankCard: boolean;
    enableVirtualBankCard: boolean;
    enableSkipCardOrderFee: boolean;
  };
}

export class FiatRepublicProduct extends Product {
  @observable
  @serializable(primitive())
  isAllowThirdParty: boolean = false;

  @observable
  @serializable(object(Settings))
  settings!: {
    enableIBAN: boolean;
    enableIBAN_EUR: boolean;
    enableIBAN_GBP: boolean;
    skipFee: boolean;
  };
}

export class CoindropProduct extends Product {
  @observable
  @serializable(object(Settings))
  settings!: {
    enabled: boolean;
  };
}

export class Uma extends Product {
  @observable
  @serializable(object(Settings))
  settings!: {
    enable: boolean;
    enableWithdrawal: boolean;
    enableDeposit: boolean;
  };
}

export class BundlesProduct extends Product {}
