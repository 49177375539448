import {serializable, object} from 'serializr';
import {BundleTariff} from './BundleTariff';
import {BundlePortfolio} from './BundlePortfolio';

export class Bundle {
  @serializable(object(BundleTariff))
  tariff!: BundleTariff;

  @serializable(object(BundlePortfolio))
  userPortfolio!: BundlePortfolio;
}
