import React from 'react';
import {observer} from 'mobx-react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {
  Box,
  type BoxProps,
  TouchableBox,
  useIsMobile,
} from '@youtoken/ui.primitives';
import {HODLItemBriefStatus} from '../HODLItemBriefStatus';
import {HODLItemInitialProperties} from '../HODLItemInitialProperties';
import {HODLItemStatusButton} from '../HODLItemStatusButton';
import {OpenNowButton} from '../OpenNowButton';
import {HODLItemChartPreview} from './elements';
import {type HODLItem} from '@youtoken/ui.resource-hodl';
import {LayoutChangeEvent} from 'react-native';
import {MarginCallIndicator} from '@youtoken/ui.hodl-elements';

export interface HODLsListItemProps {
  hodl: HODLItem;
}

export const HODLsListItem: React.FC<HODLsListItemProps & BoxProps> = observer(
  ({hodl, ...boxProps}) => {
    const {id, hasAlertByMC} = hodl;

    const isMobile = useIsMobile();

    const [showDirectionTitle, setShowDirectionTitle] = React.useState(false);

    const handleLayout = React.useCallback((event: LayoutChangeEvent) => {
      const width = event.nativeEvent.layout.width;
      setShowDirectionTitle(width > 400);
    }, []);

    const handlePressHodlItem = () => {
      SHARED_ROUTER_SERVICE.navigate('HODLItemDetailed', {id});
    };

    return (
      <TouchableBox
        onPress={handlePressHodlItem}
        testID={`HODL_ITEM_${id}`}
        {...boxProps}
      >
        <Box
          pt={{default: 18, tablet: 20}}
          pb={{default: 9, tablet: 20}}
          position="relative"
        >
          <MarginCallIndicator hasAlertByMC={hasAlertByMC} />
          <Box
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            px={20}
          >
            <Box
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              flexGrow={1}
              onLayout={handleLayout}
            >
              <HODLItemInitialProperties
                showDirectionTitle={showDirectionTitle}
                hodl={hodl}
                testID="PAIRS_LABEL"
              />
              <HODLItemBriefStatus
                hodl={hodl}
                testID="HODL_ITEM_BRIEF_STATUS"
              />
            </Box>
            {!isMobile && (
              <Box width={210} ml={16}>
                {hodl.isPending ? (
                  <OpenNowButton
                    hodl={hodl}
                    testID="PENDING_OPEN_NOW_BUTTON"
                    size="small"
                  />
                ) : (
                  <HODLItemStatusButton
                    hodl={hodl}
                    size="small"
                    testID="OPEN_CLOSE_AND_GET_MODAL_BUTTON"
                  />
                )}
              </Box>
            )}
          </Box>
          <Box pt={{default: 7, tablet: 21}} pb={{default: 1, tablet: 5}}>
            <HODLItemChartPreview hodl={hodl} height={isMobile ? 71 : 100} />
          </Box>
        </Box>
      </TouchableBox>
    );
  }
);
