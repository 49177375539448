import {cell} from '@youtoken/ui.cell';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import React from 'react';
import {Helmet} from 'react-helmet';
import {
  ClosedHODLsPaginationAndFilterFeature,
  PositionsClosedSurfaceCell,
} from '@youtoken/ui.surface-hodl-positions';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export const HODLsClosedPage: React.FC = cell(() => {
  const {
    queryParams: {pageClosed, filterClosed},
  } = RouterResource.use({});

  const {applyFilters, setPage: setPageClosed} =
    ClosedHODLsPaginationAndFilterFeature.use({});

  React.useEffect(() => {
    let _filterClosed = filterClosed;
    if (typeof filterClosed === 'string') {
      _filterClosed = [filterClosed];
    }
    applyFilters({selectedTickers: _filterClosed ?? []});
    setPageClosed(pageClosed ? Number(pageClosed) : 1);
  }, [pageClosed, filterClosed]);

  const handleNavigate = React.useCallback(
    (newParams: {}) => {
      SHARED_ROUTER_SERVICE.navigate(
        'MultiHODLsClosed',
        {},
        {
          pageClosed,
          filterClosed,
          ...newParams,
        }
      );
    },
    [pageClosed, filterClosed]
  );
  return (
    <InnerPageShell>
      <Helmet title="YouHodler | MultiHODL™" />
      <PositionsClosedSurfaceCell handleNavigate={handleNavigate} />
    </InnerPageShell>
  );
});
