import * as React from 'react';
import {Platform} from 'react-native';
import {LoanItemRegularResource} from '@youtoken/ui.resource-loans';
import {cell} from '@youtoken/ui.cell';
import {BoxProps} from '@youtoken/ui.primitives';
import {Box} from '@youtoken/ui.primitives';
import {
  LoanItemActions,
  LoanItemActionsAdditionals,
  LoanItemAgreement,
  LoanItemAlertClientMC,
  LoanItemAlertPenalty,
  LoanItemChart,
  LoanItemDataTable,
  LoanItemHeader,
} from '../components';

type LoanItemRegularSurfaceProps = {
  id: string;
};

export const LoanItemRegularSurface: React.FC<
  LoanItemRegularSurfaceProps & BoxProps
> = cell(({id, ...boxProps}) => {
  const {data, refetch} = LoanItemRegularResource.use({id});

  return (
    <Box {...boxProps}>
      <Box position="relative" borderBottomWidth={1} borderColor="$ui-01">
        <LoanItemHeader item={data} p={24} />
        <LoanItemAlertClientMC item={data} />
      </Box>
      <Box>
        <LoanItemChart item={data} />
        <LoanItemAlertPenalty item={data} />
        <LoanItemActionsAdditionals
          item={data}
          p={24}
          borderTopWidth={1}
          borderColor="$ui-01"
        />
        <LoanItemDataTable
          item={data}
          p={24}
          borderTopWidth={1}
          borderBottomWidth={1}
          borderColor="$ui-01"
        />
        <Box p={24} pb={Platform.select({default: 0, web: 24})}>
          <LoanItemAgreement item={data} />
          <LoanItemActions item={data} onCancelled={refetch} />
        </Box>
      </Box>
    </Box>
  );
});
