import {Box} from '@youtoken/ui.primitives';
import * as React from 'react';
import {LayoutChangeEvent} from 'react-native';

type SizeAdaptorProps = {
  handleSetSize: (event: LayoutChangeEvent) => void;
};
export const SizeAdaptor: React.FC<SizeAdaptorProps> = ({
  handleSetSize,
  children,
}) => {
  return (
    <Box flex={1} overflow="hidden" borderRadius={24} onLayout={handleSetSize}>
      {children}
    </Box>
  );
};
