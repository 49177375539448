import * as React from 'react';
import {Bundle} from '@youtoken/ui.resource-bundles';
import {type BoxProps} from '@youtoken/ui.primitives';
import {BundleSummary} from '@youtoken/ui.surface-bundles';

interface SectionItemBundleProps {
  item: Bundle;
}

export const SectionItemBundle: React.FC<SectionItemBundleProps & BoxProps> = ({
  item,
  ...boxProps
}) => {
  return <BundleSummary flex={1} data={item} {...boxProps} />;
};
