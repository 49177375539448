import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {BorderBoxWrapper, WebPageWrapper} from '@youtoken/ui.screen-components';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {
  InstrumentHODLSurface,
  type InstrumentHODLSurfaceProps,
} from './InstrumentHODLSurface';
import {getTitle} from './index.meta';

export const InstrumentHODLSurfaceCell = cell(InstrumentHODLSurface, {
  displayName: 'InstrumentHODLSurface',
  CellWrap: ({children}) => (
    <WebPageWrapper
      windowTitle={getTitle()}
      hasBackButton
      onBackPress={SHARED_ROUTER_SERVICE.goBack}
    >
      <BorderBoxWrapper flex={1}>{children}</BorderBoxWrapper>
    </WebPageWrapper>
  ),
}) as React.FC<InstrumentHODLSurfaceProps> & {getTitle: () => string};

InstrumentHODLSurfaceCell.getTitle = getTitle;
