import * as React from 'react';
import {ModalDynamic} from '@youtoken/ui.modal';
import {ModalContentSmart} from './ModalContentSmart';
import {Platform} from 'react-native';

interface MultiplyYourCryptoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const isNative = Platform.select({default: true, web: false});

export const MultiplyYourCryptoModal: React.FC<MultiplyYourCryptoModalProps> =
  React.memo(({isOpen, onClose}) => {
    return (
      <ModalDynamic
        isOpen={isOpen}
        onClose={onClose}
        shouldBeScrollable
        shouldShowHeaderSeparator={false}
      >
        <ModalContentSmart
          onClose={onClose}
          mt={32}
          mx={{tablet: 8, default: 0}}
          mb={isNative ? 6 : 32}
        />
      </ModalDynamic>
    );
  });
