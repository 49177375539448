import * as React from 'react';
import {type TFuncKey} from 'react-i18next';
import {useCountdown} from '@youtoken/ui.hooks';
import {Text, type TextProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {intervalToDuration, formatDuration} from '@youtoken/ui.date-fns';

interface DescriptionProps extends Pick<TextProps, 'color'> {
  i18nKey: TFuncKey;
  timeLeft: number;
}

export const Description: React.FC<DescriptionProps> = ({
  i18nKey,
  timeLeft,
  color,
}) => {
  const [millisecondsLeft, {start}] = useCountdown(timeLeft, 1000);

  React.useEffect(() => {
    start(timeLeft);
  }, [timeLeft]);

  const interval = React.useMemo(() => {
    const interval = intervalToDuration({
      start: 0,
      end: millisecondsLeft,
    });
    return formatDuration(interval, {
      format: ['days', 'hours', 'minutes'],
    }).replace(/ /g, ' '); // NOTE: replace space with non-breaking space
  }, [millisecondsLeft]);

  const {t} = useTranslation();

  return (
    <Text testID="MINER_LEVEL_INFO_DESCRIPTION" color={color}>
      {t(i18nKey, {interval})}
    </Text>
  );
};
