import * as React from 'react';
import {Box, TouchableText} from '@youtoken/ui.primitives';

export interface HandlerProps {
  dismissError: () => void;
  retriesCount: number;
}

export const Handler: React.FC<{onPress: () => void}> = ({
  children,
  onPress,
}) => {
  return (
    <Box>
      <TouchableText
        mt={12}
        color="$interactive-01"
        variant="$body-01-medium-accent"
        onPress={onPress}
      >
        {children}
      </TouchableText>
    </Box>
  );
};
