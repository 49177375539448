import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const MastercardIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg width={size} height={size} viewBox="0 0 32 32" {...props}>
        <G fill="none" fillRule="evenodd">
          <Path fill="#FF5F00" d="M11.636 8.143h8.727v15.286h-8.727z" />
          <Path
            fill="#EB001B"
            fillRule="nonzero"
            d="M12.309 15.811a9.82 9.82 0 0 0 3.72 7.713 9.66 9.66 0 0 1-6.02 2.098c-5.38 0-9.742-4.393-9.742-9.811S4.628 6 10.009 6c2.272 0 4.364.784 6.02 2.098a9.82 9.82 0 0 0-3.72 7.713"
          />
          <Path
            fill="#F79E1B"
            fillRule="nonzero"
            d="M31.764 15.811c0-5.419-4.362-9.811-9.742-9.811A9.66 9.66 0 0 0 16 8.098a9.82 9.82 0 0 1 3.722 7.713A9.82 9.82 0 0 1 16 23.524a9.66 9.66 0 0 0 6.022 2.098c5.38 0 9.742-4.393 9.742-9.811"
          />
        </G>
      </Svg>
    );
  }
);
