import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {WebPageWrapper} from '@youtoken/ui.screen-components';
import {AppearanceSurfaceWrapper} from '@youtoken/ui.surface-more';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';

export const AppearancePage: React.FC = observer(() => {
  const {t} = useTranslation();

  const handleBackPress = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('More', {});
  }, []);

  return (
    <InnerPageShell>
      <WebPageWrapper
        hasBackButton
        onBackPress={handleBackPress}
        windowTitle={t('surface.appearance.title')}
        title={t('surface.appearance.title')}
      >
        <AppearanceSurfaceWrapper.component />
      </WebPageWrapper>
    </InnerPageShell>
  );
});
