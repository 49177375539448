import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Box, BoxProps, Heading, Text} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {SecurityDeviceData} from '@youtoken/ui.state-security';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {ScreenOptions} from '@youtoken/ui.screen-components';

export interface SessionModalProps extends BoxProps {
  item: SecurityDeviceData;
  handleEndSession: (id: string) => Promise<any>;
}

export const SessionModal: React.FC<SessionModalProps> = observer(
  ({item, handleEndSession}) => {
    const {t} = useTranslation();

    const {
      id,
      lastIp,
      displayLocation,
      deviceType,
      browserName,
      browserOs,
      displayName,
      versionOs,
    } = item;

    const [isBusy, setIsBusy] = React.useState(false);

    const onEndSession = React.useCallback(() => {
      setIsBusy(true);
      handleEndSession(id).finally(() => {
        setIsBusy(false);
        SHARED_ROUTER_SERVICE.navigate('__CloseModal');
      });
    }, [id, handleEndSession]);

    return (
      <Box
        alignItems="center"
        px="$screen-default-offset"
        flex={1}
        justifyContent="space-between"
        pb={Platform.select({default: 0, web: 24})}
      >
        <Box flex={1} gap={8} pt={16} width="100%">
          {Platform.OS === 'web' ? (
            <Heading
              variant="$heading-02-responsive"
              textAlign="center"
              testID="SESSION_MODAL_TITLE"
            >
              {displayName}
            </Heading>
          ) : (
            <ScreenOptions title={displayName} />
          )}

          <Box alignSelf="flex-start">
            <Text variant="$body-02" color="$text-02" mb={8}>
              {t('modal.session.table_title.device_browser')}
            </Text>
            {deviceType === 'web' && (
              <>
                <Text
                  variant="$body-01"
                  color="$text-01"
                  mb={2}
                  testID="SESSION_MODAL_BROWSER"
                >
                  {browserName}
                </Text>
                <Text
                  variant="$body-01"
                  color="$text-02"
                  mb={8}
                  testID="SESSION_MODAL_OS"
                >
                  {browserOs}
                </Text>
              </>
            )}

            {deviceType !== 'web' && (
              <>
                <Text
                  variant="$body-01"
                  color="$text-01"
                  mb={2}
                  testID="SESSION_MODAL_APP"
                >
                  App
                </Text>
                <Text
                  variant="$body-01"
                  color="$text-02"
                  mb={8}
                  testID="SESSION_MODAL_OS"
                >
                  {deviceType === 'ios' ? 'iOs' : 'Android'} {versionOs}
                </Text>
              </>
            )}
          </Box>

          <Box alignSelf="flex-start">
            <Text variant="$body-02" color="$text-02" mb={8}>
              {t('modal.session.table_title.location')}
            </Text>
            <Text
              variant="$body-01"
              color="$text-01"
              mb={2}
              testID="SESSION_MODAL_LOCATION"
            >
              {displayLocation}
            </Text>
            <Text
              variant="$body-01"
              color="$text-02"
              mb={8}
              testID="SESSION_MODAL_IP"
            >
              {lastIp}
            </Text>
          </Box>
        </Box>
        <Box gap={16} mt={16}>
          <Text
            variant="$body-01"
            textAlign="center"
            testID="SESSION_MODAL_DESCRIPTION"
          >
            {t('modal.session.description')}
          </Text>
          <Button
            size="large"
            onPress={onEndSession}
            loading={isBusy}
            testID="SESSION_MODAL_END_BUTTON"
          >
            {t('modal.session.action_text')}
          </Button>
        </Box>
      </Box>
    );
  }
);
