import * as React from 'react';
import {RefreshControl} from '@youtoken/ui.elements';
import {useObserver} from 'mobx-react';

export type Feature = {
  isLoadingManually: boolean;
  refetchManually: () => Promise<any>;
};

// accepts N features (and resources for that matter)
// and return refresh control binded to to this features refresh
export const useAttachPullToRefreshToFeature = (
  ...features: Feature[]
): {refreshControl: React.ReactElement} => {
  if (features.length === 0) {
    throw new Error(
      `useAttachPullToRefreshToFeature requires at least one feature!`
    );
  }

  const isLoadingManually = useObserver(() => {
    return features.reduce(
      (acc, {isLoadingManually}) => acc || isLoadingManually,
      false
    );
  });

  const onRefresh = () => Promise.all(features.map(f => f.refetchManually()));

  return {
    refreshControl: (
      <RefreshControl refreshing={isLoadingManually} onRefresh={onRefresh} />
    ),
  };
};
