import * as React from 'react';
import {useChartCursorState, BundleChart} from '../Charts';
import {cell} from '@youtoken/ui.cell';
import {BundleChartDataResource} from '../state/Chart/BundleChartDataResource';
import {ChartWithControlsSuspenseFallback} from './SuspenseFallbacks';
import {ChartSmartCells} from './utils/types';
import {CellWrap} from './utils/CellWrap';
import {PanResponder} from '../PanResponder';

export const BundleChartSmartCell =
  cell<ChartSmartCells.BundleChartSmartCellProps>(
    ({source, sourceId, width, height, ChartPanComponent = PanResponder}) => {
      const {setPointIndex, setCursorX, cursorX} = useChartCursorState();

      const {isLoading, chartData, hasData, refetch} =
        BundleChartDataResource.use({
          source,
          sourceId,
        });

      return (
        <ChartPanComponent
          width={width}
          height={height}
          onCursorChange={setCursorX}
        >
          <BundleChart
            displayActivityIndicator
            isFetching={isLoading}
            data={chartData}
            height={height}
            width={width}
            refetch={refetch}
            hasData={hasData}
            cursorX={hasData ? cursorX : undefined}
            onCursorIndexChange={setPointIndex}
          />
        </ChartPanComponent>
      );
    },
    {
      displayName: 'BundleChartSmartCell',
      SuspenseFallback: ChartWithControlsSuspenseFallback,
      CellWrap: CellWrap,
    }
  );
