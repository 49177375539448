import * as React from 'react';
import {BundlePortfolio, BundleTariff} from '@youtoken/ui.resource-bundles';
import {type BoxProps} from '@youtoken/ui.primitives';
import {
  useResizableBox,
  ResizableBox,
  BundleChartSmartCell,
} from '@youtoken/ui.legacy-charts';

interface BundleGeneralChartProps {
  data: {
    tariff: BundleTariff;
    userPortfolio: BundlePortfolio | null;
  };
}

export const BundleGeneralChart: React.FC<
  BundleGeneralChartProps & BoxProps
> = ({data: {tariff, userPortfolio}, ...boxProps}) => {
  const [
    {
      size: {width, height},
    },
    bind,
  ] = useResizableBox();

  return (
    <ResizableBox height={298} {...boxProps} {...bind}>
      {Boolean(width && height) && (
        <BundleChartSmartCell
          source={userPortfolio ? 'bundle' : 'tariff'}
          sourceId={userPortfolio ? userPortfolio.id : tariff.id}
          width={width!}
          height={height!}
        />
      )}
    </ResizableBox>
  );
};
