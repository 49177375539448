import React from 'react';
import {observer} from 'mobx-react';
import {Heading, Box, Text, BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Illustration} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {BorderBoxWrapper} from '@youtoken/ui.screen-components';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

const handleOpenMH = () => {
  DATA_LAYER.trackStrict('hodl-create-position-click', {
    source: 'no-active-positions-state',
  });
  SHARED_ROUTER_SERVICE.navigate('NewMultiHODL', {}, {});
};

export const NoActivePositionsState: React.FC<BoxProps> = observer(
  ({...boxProps}) => {
    const {t} = useTranslation();

    return (
      <BorderBoxWrapper
        flex={1}
        borderBottomWidth={{default: 0, desktop: 1}}
        {...boxProps}
      >
        <Box alignSelf="center" height={210}>
          <Illustration name="illustration-16" />
        </Box>

        <Box mt={-88} px={24}>
          <Heading color="$text-01" textAlign="center" mb={8}>
            {t('surface.hodls.portfolio.no_active_positions.title')}
          </Heading>
          <Text color="$text-02" textAlign="center" mb={24}>
            {t('surface.hodls.portfolio.no_active_positions.text')}
          </Text>
          <Button size="large" onPress={handleOpenMH}>
            <Text>{t('surface.hodls.portfolio.create_mh_button.action')}</Text>
          </Button>
        </Box>
      </BorderBoxWrapper>
    );
  }
);
