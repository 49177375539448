export const createFilterObject = <T extends string>(
  items: T[],
  selectedItems: T[]
): Record<T, boolean> =>
  items.reduce(
    (acc, item) => ({
      ...acc,
      [item]: selectedItems.includes(item),
    }),
    {} as Record<T, boolean>
  );
