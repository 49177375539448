import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {CardHeader} from '../CardHeader';
import {CardContent} from '../CardContent';
import {type CardInnerProps} from '../../types';

export const CardInner: React.FC<CardInnerProps & BoxProps> = ({
  level,
  height,
  icon,
  textColor,
  levelTitle,
  currentLevel,
  ...boxProps
}) => {
  return (
    <Box
      borderRadius={{default: 16, tablet: 12}}
      borderColor="$ui-background"
      borderWidth={1}
      height={height}
      px={{default: 20, tablet: 16}}
      pt={{default: 16, tablet: 12}}
      testID={`MINER_LEVEL_CARD_${level.level}_INNER`}
      {...boxProps}
    >
      <CardHeader
        icon={icon}
        textColor={textColor}
        levelTitle={levelTitle}
        level={level}
      />

      <CardContent level={level} />
    </Box>
  );
};
