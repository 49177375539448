import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BundleTariff, BundleOperation} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {BundleIcon} from '../../../../components';

const keys = {
  DEPOSIT: 'surface.history.item.type_name.bundle_deposit',
  WITHDRAW: 'surface.history.item.type_name.bundle_withdrawal',
} as const;

interface BundleOperationsItemProps {
  tariff: BundleTariff;
  operation: BundleOperation;
}

export const BundleOperationsItem: React.FC<
  BundleOperationsItemProps & BoxProps
> = observer(
  ({
    tariff: {
      design: {iconUrl},
    },
    operation: {operationType, quoteSumFormatted, quoteTickerFormatted},
    ...boxProps
  }) => {
    const {t} = useTranslation();

    return (
      <Box
        flexDirection="row"
        alignItems="center"
        px={24}
        py={16}
        gap={8}
        {...boxProps}
      >
        <BundleIcon uri={iconUrl} size={24} />
        <Box flex={1}>
          <Text variant="$body-01-high-accent" color="$text-01">
            {t(keys[operationType], {
              defaultValue: operationType.toLowerCase(),
            })}
          </Text>
        </Box>
        <Box alignItems="flex-end">
          <Text variant="$body-01-high-accent" color="$text-01">
            {operationType === 'DEPOSIT' ? '+' : '-'}
            {quoteSumFormatted}{' '}
            <Text variant="$body-01" color="$text-02">
              {quoteTickerFormatted}
            </Text>
          </Text>
        </Box>
      </Box>
    );
  }
);
