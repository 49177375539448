import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Label, useForm, FieldErrorMessage} from '@youtoken/ui.form-elements';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {TextInput} from '@youtoken/ui.inputs';
import {LogoColored, LogoColoredIconName} from '@youtoken/ui.icons';
import {VoletCreatePaymentFormState} from '../state';

export const AmountSet: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    form: {sourceHasError, ticker, amount, sourceError, amountOnChange},
    view: {tickerFormatted},
  } = useForm<VoletCreatePaymentFormState>();

  return (
    <Box {...boxProps}>
      <Box flexDirection="row" justifyContent="space-between" mb={8}>
        <Label>{t('surface.wallets.fiat_deposit.adv_cash.amount')}</Label>
      </Box>
      <TextInput
        testID="FIAT_DEPOSIT_ADVCASH_AMOUNT"
        size="large"
        value={amount}
        onChangeText={amountOnChange}
        textAlign="right"
        LeftPartComponent={
          <Box flexDirection="row" alignItems="center" height="100%" px={16}>
            <LogoColored name={ticker as LogoColoredIconName} size={20} />
            <Text
              ml={8}
              mr={2}
              variant="$body-01-medium-accent"
              color="$text-01"
            >
              {tickerFormatted}
            </Text>
          </Box>
        }
      />
      <FieldErrorMessage visible={sourceHasError}>
        {sourceError}
      </FieldErrorMessage>
    </Box>
  );
});
