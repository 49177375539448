import {Platform} from 'react-native';

const WEB_FONT_FAMILY =
  '-apple-system, BlinkMacSystemFont, Roboto, system-ui, sans-serif';

const IOS_FONT_FAMILY = 'System';

export const FONTS = {
  normal: {
    regular: Platform.select({
      ios: IOS_FONT_FAMILY,
      android: 'Roboto-Regular',
      web: WEB_FONT_FAMILY,
    })!,
    medium: Platform.select({
      ios: IOS_FONT_FAMILY,
      android: 'Roboto-Medium',
      web: WEB_FONT_FAMILY,
    })!,
    bold: Platform.select({
      ios: IOS_FONT_FAMILY,
      android: 'Roboto-Bold',
      web: WEB_FONT_FAMILY,
    })!,
  },
  mono: {
    regular: Platform.select({
      android: 'UbuntuMono-Regular',
      ios: 'Ubuntu Mono',
      web: '"Ubuntu Mono", monospace',
    })!,
    bold: Platform.select({
      android: 'UbuntuMono-Bold',
      ios: 'Ubuntu Mono',
      web: '"Ubuntu Mono", monospace',
    })!,
  },
};
