import * as React from 'react';
import {
  useOnSubmit,
  useInitialValues,
  useValidationSchema,
} from '@web-app/src/hooks';
import {SubmitButton} from '@web-app/src/components/elements/Forms';
import {Box, Heading} from '@youtoken/ui.primitives';
import {FormikCustom} from '@web-app/src/components/elements/Forms';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {EmailInput, RestoreAccessLine} from '../../Elements';
import {Label} from '@youtoken/ui.form-elements';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {__GLOBAL_RECAPTCHA__} from '@youtoken/ui.two-factor-auth-and-recaptcha';

export type FormValues = {
  email: string;
};

export const Form: React.FC = () => {
  const {passwordRestoreRequest} = AuthMeResource.use({});

  const initialValues = useInitialValues<FormValues>({
    email: '',
  });

  const {t} = useTranslation();

  const onSubmit = useOnSubmit<FormValues>(
    values => {
      return __GLOBAL_RECAPTCHA__
        .requestToken('restore_password')
        .then(token => passwordRestoreRequest({...values, token}));
    },
    {
      displayErrorMessage: false,
    }
  );

  const validationSchema = useValidationSchema<FormValues>(yup => {
    return {
      email: yup.string().max(128).required().email(),
    };
  });

  return (
    <>
      <Heading variant="$heading-01-responsive" mb={25}>
        {t('forms.reset_password.title')}
      </Heading>

      <FormikCustom
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        render={() => {
          return (
            <Box alignItems="stretch" testID="RESET_PASSWORD_FORM">
              <Label>{t('forms.sign_in.fields.email.label')}</Label>
              <Box mb={23} mt={10}>
                <EmailInput />
              </Box>
              <SubmitButton size="large" mb={30}>
                {t('forms.reset_password.action_button')}
              </SubmitButton>
              <RestoreAccessLine />
            </Box>
          );
        }}
      />
    </>
  );
};
