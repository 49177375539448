import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {TouchableBox, Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

interface TitleTooltipProps {}

export const TitleTooltip: React.FC<TitleTooltipProps> = () => {
  const {t} = useTranslation();

  const [isTooltipOpen, setTooltipOpen] = React.useState(false);

  const handleToggleTooltip = React.useCallback(() => {
    setTooltipOpen(state => !state);
  }, []);

  const handleCloseTooltip = React.useCallback(() => {
    setTooltipOpen(false);
  }, []);

  return (
    <Box
      zIndex={1}
      style={{
        // @ts-ignore
        verticalAlign: 'middle',
        marginTop: -2,
        marginLeft: 8,
      }}
    >
      <TouchableBox onPress={handleToggleTooltip}>
        <Icon
          name="question_outlined"
          color={isTooltipOpen ? '$ui-04' : '$text-01'}
          size={28}
        />
      </TouchableBox>
      {isTooltipOpen && (
        <>
          <TouchableBox
            width="100vw"
            height="100vh"
            position="fixed"
            top={0}
            bottom={0}
            left={0}
            right={0}
            onPress={handleCloseTooltip}
          />
          <Box
            position="absolute"
            top={45}
            left={-140 + 14}
            backgroundColor="$interactive-01"
            width={280}
            p={20}
            borderRadius={16}
            zIndex={10}
          >
            <Box
              backgroundColor="$interactive-01"
              position="absolute"
              top={-6}
              left={132}
              width={16}
              height={16}
              borderRadius={4}
              style={{
                transform: [{rotate: '45deg'}],
              }}
            />
            <Text variant="$body-01" color="$text-04">
              {t('surface.bundles.title_tooltip')}
            </Text>
          </Box>
        </>
      )}
    </Box>
  );
};
