import * as React from 'react';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {BundleTariffOverviewResource} from '@youtoken/ui.resource-bundles';
import {BundleDepositFormState} from './state';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {cell} from '@youtoken/ui.cell';
import {type BoxProps} from '@youtoken/ui.primitives';
import {Content, Footer} from './components';
import {BundleDepositFormStateResources} from './state/types';

export {getBundleDepositFormTitle} from './utils';

export interface BundleDepositFormProps {
  id: string;
  onClose: () => void;
}

export const BundleDepositForm: React.FC<BundleDepositFormProps & BoxProps> =
  cell(({id, onClose, ...boxProps}) => {
    //

    const {rates, authMe, wallets, bundleTariffOverview} = useResources({
      rates: getResourceDescriptor(RatesResource, {}),
      authMe: getResourceDescriptor(AuthMeResource, {}),
      wallets: getResourceDescriptor(WalletsResource, {}),
      bundleTariffOverview: getResourceDescriptor(
        BundleTariffOverviewResource,
        {
          id,
        }
      ),
    });

    const docs = DocsResource.use({country: authMe.residenceOrCountry});

    const resources = {
      rates,
      authMe,
      wallets,
      bundleTariffOverview,
      docs,
    } as BundleDepositFormStateResources;

    //

    const state = makeForm(() => {
      return new BundleDepositFormState({}, resources);
    });

    return (
      <Form form={state}>
        <Content zIndex={1} flex={1} p={24} />
        <Footer />
      </Form>
    );
  });
