import {computedFn, now} from 'mobx-utils';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {debounce} from 'lodash';
import axios, {type Canceler} from 'axios';
import Big from 'big.js';
import {
  action,
  autorun,
  comparer,
  computed,
  observable,
  reaction,
  runInAction,
  type IReactionDisposer,
} from 'mobx';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {calculateAll, calculateAllReverse} from '@youtoken/converts-calculator';
import {formatByTicker, toBig} from '@youtoken/ui.formatting-utils';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {handleFormFieldsErrors} from '@youtoken/ui.form-utils';
import {normalizeAmount} from '@youtoken/ui.normalizers';
import {TRANSPORT} from '@youtoken/ui.transport';
import {invariant} from '@youtoken/ui.utils';
import type {
  ExchangeUnauthorizedFormArgs,
  ExchangeUnauthorizedFormResources,
} from './types';

export const CURRENT_RATE_UPDATE_INTERVAL = 15;

export class Form {
  @observable
  args: ExchangeUnauthorizedFormArgs;

  @observable
  resources: ExchangeUnauthorizedFormResources;

  @observable
  instance: MobxReactForm;

  @observable
  disposers: IReactionDisposer[] = [];

  @observable
  side: 'from' | 'to' = 'from';

  //#region swap

  @observable
  swap: boolean = false;

  @computed
  get swapEnable() {
    return false; // Boolean(this.tariffReverse);
  }

  @action
  swapOnPress = () => {
    // do nothing
  };

  //#endregion swap

  //#region balance

  @computed
  get balance() {
    return toBig(0);
  }

  @computed
  get hasBalance() {
    return false;
  }

  @computed
  get balancePercent() {
    return 0;
  }

  @action
  setAmountByBalance = () => {
    // do nothing
  };

  @action
  setAmountByPercentOfBalance = () => {
    // do nothing
  };

  //#endregion

  //#region source

  @computed({
    equals: comparer.shallow,
  })
  get tickers() {
    return this.resources.exchangeTariffs.tickers;
  }

  @computed
  get tickerInitial() {
    if (this.tickers.includes(this.args.ticker!)) {
      return this.args.ticker;
    }

    return this.tickers.includes('eur') ? 'eur' : this.tickers[0];
  }

  @computed
  get ticker() {
    return this.instance?.$('ticker').get('value') ?? this.tickerInitial;
  }

  @computed
  get amount() {
    return this.instance?.$('amount').get('value');
  }

  @computed
  get amountWithoutFee() {
    let res = toBig(this.amount).minus(this.fee);

    if (res.lt(0)) {
      return toBig(0);
    }

    return res;
  }

  @computed
  get sourceError() {
    return getTranslatedValidationMessage(
      this.instance?.$('ticker').get('error') ||
        this.instance?.$('amount').get('error')
    );
  }

  @computed
  get hasSourceError() {
    return Boolean(this.sourceError);
  }

  @action
  setTicker = (value: string) => {
    const onChange = this.instance.$('ticker').get('onChange');

    onChange(value);
  };

  @action
  setAmount = (value: string) => {
    const onChange = this.instance.$('amount').get('onChange');

    onChange(normalizeAmount(value));
  };

  getConversionAmount = computedFn(_amount => {
    const amount = toBig(_amount);

    const {toAmount} = calculateAll(
      amount,
      this.tariff.fee,
      this.rate,
      this.ticker,
      this.conversionTicker
    );

    return toAmount;
  });

  getConversionAmountFormatted = computedFn(amount => {
    if (amount === '') {
      return '';
    }

    const conversionAmount = this.getConversionAmount(amount);

    return conversionAmount.gt(0)
      ? formatByTicker(conversionAmount, this.conversionTicker, 0)
      : '0';
  });

  //#endregion source

  //#region target

  @computed({
    equals: comparer.shallow,
  })
  get conversionTickers() {
    return (
      this.resources.exchangeTariffs.getConversionTickers(this.ticker) ?? []
    );
  }

  @computed
  get conversionTickerInitial() {
    if (
      this.args.conversionTicker &&
      this.conversionTickers.includes(this.args.conversionTicker)
    ) {
      return this.args.conversionTicker;
    }

    return this.conversionTickers[0];
  }

  @computed
  get conversionTicker() {
    return (
      this.instance?.$('conversionTicker').get('value') ??
      this.conversionTickerInitial
    );
  }

  @computed
  get conversionAmount() {
    return this.instance?.$('conversionAmount').get('value');
  }

  @computed
  get targetError() {
    return getTranslatedValidationMessage(
      this.instance?.$('conversionTicker').get('error') ||
        this.instance?.$('conversionAmount').get('error')
    );
  }

  @computed
  get hasTargetError() {
    return Boolean(this.targetError);
  }

  @action
  setConversionTicker = (value: string) => {
    const onChange = this.instance.$('conversionTicker').get('onChange');

    onChange(value);
  };

  @computed
  get conversionTickerPrecision() {
    return getCoinDecimalPrecision(this.conversionTicker);
  }

  @action
  setConversionAmount = () => {
    // do nothing
  };

  getAmount = computedFn(_requiredConversionAmount => {
    const conversionAmountRequired = toBig(_requiredConversionAmount);

    const {fromAmount} = calculateAllReverse(
      conversionAmountRequired,
      this.tariff.fee,
      this.rate,
      this.ticker
    );

    return fromAmount;
  });

  getAmountFormatted = computedFn(conversionAmount => {
    if (conversionAmount === '') {
      return '';
    }

    const amount = this.getAmount(conversionAmount);

    return amount.gt(0) ? amount.toString() : '0';
  });

  //#endregion target

  //#region fee

  @computed
  get tariff() {
    const tariff = this.resources.exchangeTariffs.getTariff(
      this.ticker,
      this.conversionTicker
    );

    invariant(
      tariff,
      'Tariff not found',
      {},
      {ticker: this.ticker, conversionTicker: this.conversionTicker}
    );

    return tariff;
  }

  @computed
  get tariffReverse() {
    return this.resources.exchangeTariffs.getTariff(
      this.conversionTicker,
      this.ticker
    );
  }

  @computed
  get fee() {
    const amount = toBig(this.amount);

    const {feeAmount} = calculateAll(
      amount,
      this.tariff.fee,
      this.rate,
      this.ticker,
      this.conversionTicker
    );

    return feeAmount;
  }

  //#endregion fee

  //#region rate

  @observable
  rate: Big = toBig(1);

  @observable
  rateIsFixed: boolean = true;

  @observable
  rateTimeLeft: number = CURRENT_RATE_UPDATE_INTERVAL;

  @observable
  rateTimeInterval: number = 0;

  @computed
  get rateTimeIntervalName() {
    return `rate-interval-${this.rateTimeInterval}`;
  }

  @computed
  get rateTimeIntervalProgress() {
    return (
      (CURRENT_RATE_UPDATE_INTERVAL - this.rateTimeLeft) /
      (CURRENT_RATE_UPDATE_INTERVAL - 1)
    );
  }

  @action
  updateRate = () => {
    this.rateTimeInterval = this.rateTimeInterval + 1;
    this.rate = toBig(
      this.resources.ratesResource.getRate(this.ticker, this.conversionTicker)
    );
    this.rateTimeLeft = CURRENT_RATE_UPDATE_INTERVAL;
  };

  //#endregion rate

  //#region check

  @observable
  checkIsLoading = false;

  @observable
  checkCanceller?: Canceler;

  @computed
  get checkRateShowError() {
    return Boolean(!this.hasSourceError && this.instance.$submitted);
  }

  @action
  cancelCheck = () => {
    this.checkIsLoading = true;
    this.checkCanceller?.('__CANCELLED_REQUEST__');
  };

  @action
  check = () => {
    this.cancelCheck();

    const {token, cancel} = axios.CancelToken.source();

    this.checkCanceller = cancel;

    return TRANSPORT.API.post(
      '/v2/converts/check-public',
      {
        side: this.side,
        fromTicker: this.ticker,
        toTicker: this.conversionTicker,
        fromAmount: this.amount,
        toAmount: this.conversionAmount,
        rate: this.rate,
      },
      {
        cancelToken: token,
      }
    )
      .then(({data: {isFixedRate}}) => {
        runInAction(() => {
          this.rateIsFixed = isFixedRate;
        });
      })
      .catch(error => {
        if (this.checkRateShowError) {
          runInAction(() => {
            handleFormFieldsErrors(this.instance, error);
          });
        }
      })
      .finally(() => {
        runInAction(() => {
          this.checkIsLoading = false;
        });
      });
  };

  checkDebounced = debounce(this.check, 300);

  //#endregion check

  @action
  submit = () => {
    SHARED_ROUTER_SERVICE.navigate('SignUp');

    return Promise.resolve();
  };

  constructor(
    args: ExchangeUnauthorizedFormArgs,
    resources: ExchangeUnauthorizedFormResources
  ) {
    this.args = args;
    this.resources = resources;
    const defaultTickerAmount = args.amount ?? '1';

    this.instance = new MobxReactForm(
      {
        fields: {
          ticker: {
            value: this.ticker,
          },
          conversionTicker: {
            value: this.conversionTicker,
          },
          amount: {
            value: defaultTickerAmount,
            handlers: {
              onChange: () => {
                this.side = 'from';
              },
            },
          },
          conversionAmount: {
            value: this.getConversionAmountFormatted(defaultTickerAmount),
            handlers: {
              onChange: () => {
                this.side = 'to';
              },
            },
          },
        },
      },
      {
        hooks: {
          onSuccess: this.submit,
        },
      }
    );
    this.disposers = [
      // set rateTimeLeft
      reaction(
        () => now(),
        () => {
          if (this.rateTimeLeft > 0) {
            this.rateTimeLeft = this.rateTimeLeft - 1;
          } else {
            this.updateRate();
          }
        }
      ),
      // set amount / conversionAmount after fields changed
      autorun(() => {
        if (this.side === 'from') {
          this.instance
            .$('conversionAmount')
            .set(this.getConversionAmountFormatted(this.amount));
        } else {
          this.instance
            .$('amount')
            .set(this.getAmountFormatted(this.conversionAmount));
        }
      }),
      // set conversionTicker after fields changed
      reaction(
        () => [this.ticker],
        () => {
          if (this.swap) {
            this.swap = false;
          } else {
            if (this.conversionTickers.includes(this.conversionTicker)) {
              return;
            }
            // NOTE: conversionTicker should be constant (comes from args),
            // but this case for form continue working if tariff with current conversionTicker doesn't exist
            this.instance.$('conversionTicker').set(this.conversionTickers[0]);
          }
        }
      ),
      // set rate after fields changed
      reaction(() => [this.ticker, this.conversionTicker], this.updateRate, {
        fireImmediately: true,
      }),
      // check rate after fields changed
      reaction(
        () => [
          this.side,
          this.ticker,
          this.conversionTicker,
          this.amount,
          this.conversionAmount,
          this.rate,
        ],
        () => {
          this.cancelCheck();
          this.checkDebounced();
        },
        {
          fireImmediately: true,
        }
      ),
    ];
  }

  @action
  dispose = () => {
    this.disposers.forEach(disposer => {
      disposer?.();
    });
  };
}
