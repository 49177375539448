import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {
  type StackNavigatorScreenWizardWrappedProps,
  transparentScreenOptions,
  createWizard,
} from '@youtoken/ui.elements';
import {CryptoDepositWizardChooseTicker} from './ChooseTicker';
import {
  getCryptoDepositWizardDepositOptions,
  CryptoDepositWizardDeposit,
} from './Deposit';
import {useTranslation} from '@youtoken/ui.service-i18n';

export enum CryptoDepositWizardScreenName {
  CHOOSE_TICKER = 'chooseTicker',
  DEPOSIT = 'deposit',
}

export interface CryptoDepositWizardNavigator {
  [CryptoDepositWizardScreenName.CHOOSE_TICKER]: {};
  [CryptoDepositWizardScreenName.DEPOSIT]: {
    ticker: string;
  };
}

const Wizard = createWizard<CryptoDepositWizardNavigator>();

export interface CryptoDepositWizardProps {
  ticker?: string;
  onPressBack?: () => void;
}

export const CryptoDepositWizard: React.FC<CryptoDepositWizardProps> = cell(
  ({ticker, onPressBack}) => {
    const {t} = useTranslation();

    return (
      <Wizard.Navigator
        initialName={
          ticker
            ? CryptoDepositWizardScreenName.DEPOSIT
            : CryptoDepositWizardScreenName.CHOOSE_TICKER
        }
        initialProps={{ticker}}
        goBack={onPressBack}
      >
        <Wizard.Screen
          name={CryptoDepositWizardScreenName.CHOOSE_TICKER}
          options={() => {
            return {
              title: t(
                'surface.wallets.deposit_wizard.select_ticker_crypto.title'
              ),
            };
          }}
          component={CryptoDepositWizardChooseTicker}
        />
        <Wizard.Screen
          name={CryptoDepositWizardScreenName.DEPOSIT}
          options={getCryptoDepositWizardDepositOptions}
          component={CryptoDepositWizardDeposit}
        />
      </Wizard.Navigator>
    );
  }
);

export const CryptoDepositWizardWrapped = ({
  route: {
    params: {ticker},
  },
  navigation: {goBack},
}: StackNavigatorScreenWizardWrappedProps<{ticker: string}>) => {
  return <CryptoDepositWizard ticker={ticker} onPressBack={goBack} />;
};
