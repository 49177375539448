import {
  action,
  computed,
  type IReactionDisposer,
  observable,
  reaction,
  runInAction,
} from 'mobx';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {deserialize} from 'serializr';
import {nanoid} from 'nanoid';
import Big, {BigSource} from 'big.js';
import {getCoinDecimalPrecision, getCoinName} from '@youtoken/ui.coin-utils';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {
  messages,
  ValidationMessageLocalized,
} from '@youtoken/ui.validation-messages';
import {normalizeAmountWithPrecision} from '@youtoken/ui.normalizers';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {DocumentItem} from '@youtoken/ui.resource-documents';
import {TRANSPORT} from '@youtoken/ui.transport';
import * as yupPackage from '@youtoken/ui.yup';
import {i18n} from '@youtoken/ui.service-i18n';
import {formatByTicker, toBig} from '@youtoken/ui.formatting-utils';
import type {ExtendTpSlFormArgs, ExtendTpSlFormResources} from './types';
import {invariant} from '@youtoken/ui.utils';
import {HodlHistoryResource} from '@youtoken/ui.resource-hodl';
import {additionalInputTickerUI} from '@youtoken/ui.incentives-utils';
import {getAmountButtons} from '@youtoken/ui.ticker-and-amount-input';
import {CalculateExtendResult} from './CalculateExtendResult';

export class Form {
  @observable
  public instance: MobxReactForm;

  @observable
  public resources: ExtendTpSlFormResources;

  @observable
  public requestId: string = '';

  @observable
  public isLoading: boolean = false;

  @observable
  public calculatedRawData: null | CalculateExtendResult = null;

  @observable
  rateInputTickerUsd!: Big;

  disposers: Array<IReactionDisposer>;

  public constructor(
    resources: ExtendTpSlFormResources,
    {hodlId, onSuccess}: ExtendTpSlFormArgs
  ) {
    const {
      collateralTicker: hodlInputTicker,
      _inputAmount: inputAmountBig,
      inputAmount: hodlInputAmount,
    } = resources.hodl.data;

    const inputTickerWalletAmount =
      resources.walletsResource.getByTicker(hodlInputTicker)?.amount ?? 0;

    this.resources = resources;
    this.requestId = nanoid();

    const fields = {
      inputTicker: {
        value: hodlInputTicker,
      },
      inputAmount: {
        value: inputAmountBig.gt(inputTickerWalletAmount)
          ? formatByTicker(inputTickerWalletAmount, hodlInputTicker)
          : hodlInputAmount,
      },
      useBonusesActive: {
        value: false,
        hooks: {
          onChange: () => {
            this.instance.$('inputAmount').validate({showErrors: true});
          },
        },
      },
      additionalInputAmount: {
        value: this.minBonusesAmount,
      },
      id: {
        value: hodlId,
      },
    };

    const hooks = {
      onSuccess: (form: MobxReactForm) => {
        const data = form.values();
        this.isLoading = true;

        DATA_LAYER.trackStrict('hodl-add-collateral-attempt', {
          hodlId: data.id,
          inputAmount: this.inputAmount,
          inputTicker: this.inputTicker,
          additionalAmount: this.useBonusesActive
            ? data.additionalInputAmount || '0'
            : '0',
          additionalTicker: this.additionalInputTickerBE,
        });

        return TRANSPORT.API.post('/v3/hodl/addCollateral', {
          id: data.id,
          requestId: this.requestId,
          amount: this.inputAmount || '0',
          ticker: this.inputTicker,
          additionalAmount: this.useBonusesActive
            ? data.additionalInputAmount || '0'
            : '0',
          additionalTicker: this.additionalInputTickerBE,
        })
          .then(() => {
            LOCAL_NOTIFICATIONS.info({
              text: i18n.t('surface.hodls.extend_tp.message.extended'),
            });
            const historyResource = HodlHistoryResource.getInstanceSafely({
              hodlId: data.id,
            });
            historyResource?.refetch();
            onSuccess();
          })
          .catch(e => {
            handleFormSubmitError(form, e, {});
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    };

    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            const inputAmountWithoutBonusesValidator = yup
              .big()
              .gt(0)
              .lte(toBig(this.inputTickerWallet.amount));

            const inputAmountWithBonusesValidator = yup
              .string()
              .test('isEmpty', value => {
                if (value === '') {
                  return true;
                }
                return yup
                  .big()
                  .lte(toBig(this.inputTickerWallet.amount))
                  .isValidSync(value);
              });

            const inputAmountValidator = this.useBonusesActive
              ? inputAmountWithBonusesValidator
              : inputAmountWithoutBonusesValidator;

            const additionalInputAmountValidator = this.useBonusesActive
              ? yup
                  .big()
                  .required()
                  .lte(this.maxBonusesAmount)
                  .lte(this.bonusBalance, messages.FUNDS_INSUFFICIENT)
              : yup.big();

            return yup.object().shape({
              id: yup.string().required(),
              inputTicker: yup.string().required(),
              inputAmount: inputAmountValidator,
              useBonusesActive: yup.boolean(),
              additionalInputAmount: additionalInputAmountValidator,
            });
          }),
      }),
    };

    const options = {
      validateOnBlur: false,
      validateOnChange: true,
      validateOnChangeAfterSubmit: true,
      showErrorsOnReset: false,
    };

    this.instance = new MobxReactForm(
      {fields},
      {
        options,
        plugins,
        hooks,
      }
    );

    this.disposers = [
      reaction(
        () => [
          this.inputAmount,
          this.additionalInputAmount,
          this.useBonusesActive,
        ],
        () => {
          TRANSPORT.API.post('/v3/hodl/calculateExtend', {
            id: this.hodlId,
            amount: this.inputAmount || '0',
            ticker: this.inputTicker,
            additionalAmount: this.useBonusesActive
              ? this.additionalInputAmount || '0'
              : '0',
            additionalTicker: this.additionalInputTickerBE,
          })
            .then(res => {
              runInAction(() => {
                this.calculatedRawData = deserialize(
                  CalculateExtendResult,
                  res.data
                );
              });
            })
            .catch(() => {
              return;
            });
        },
        {
          fireImmediately: true,
          delay: 300,
        }
      ),

      // update additionalInputAmount after inputAmount changed by user
      reaction(
        () => this.inputAmount,
        () => {
          this.setAdditionalInputAmount(this.maxBonusesAmount);
        },
        {
          fireImmediately: false,
        }
      ),

      // update additionalInputAmount after inputAmountUsd changed because of rates
      reaction(
        () => this.inputAmountUsd,
        () => {
          if (
            Number(this.additionalInputAmount) > Number(this.maxBonusesAmount)
          ) {
            this.setAdditionalInputAmount(this.maxBonusesAmount);
          }
        },
        {
          fireImmediately: true,
        }
      ),

      // NOTE: update additionalInputAmount after useBonusesActive flag changed by user
      reaction(
        () => this.useBonusesActive,
        () => {
          this.useBonusesActive &&
            this.setAdditionalInputAmount(this.maxBonusesAmount);
        },
        {
          fireImmediately: false,
        }
      ),

      // Update rateInputTickerUsd after inputTicker changed
      reaction(
        () => this.inputTicker,
        () => {
          this.rateInputTickerUsd = Big(
            this.resources.rates.getRate('usd', this.inputTicker)
          );
        },
        {
          fireImmediately: true,
        }
      ),
    ];
  }

  dispose() {
    this.disposers.forEach(disposer => disposer?.());
  }

  @computed
  public get inputAmount() {
    return this.instance?.$('inputAmount').get('value');
  }

  @computed
  public get inputTicker() {
    return this.instance?.$('inputTicker').get('value');
  }

  @computed get predefinedAmountsData() {
    return getAmountButtons({
      rates: this.resources.rates,
      wallets: this.resources.walletsResource,
      authMe: this.resources.authMe,
      equivalentTicker: this.inputTicker,
    });
  }

  @computed
  public get hodlId() {
    return this.instance?.$('id').get('value');
  }

  @computed.struct get inputTickerWallet() {
    if (!this.inputTicker) {
      return {};
    }

    const wallet = this.resources.walletsResource.getByTicker(
      this.inputTicker
    )!;

    invariant(
      wallet,
      'cannot get wallet by input ticker',
      {},
      {wallet, inputTicker: this.inputTicker}
    );

    return {
      ticker: wallet.ticker,
      tickerFormatted: wallet.ticker.toUpperCase(),
      amountFormatted: wallet.amount.toFixed(
        getCoinDecimalPrecision(wallet.ticker)
      ),
      amount: wallet.amount,
      coinName: getCoinName(wallet.ticker),
      hasAmount: wallet.amount?.gt(0),
      key: wallet.ticker,
    };
  }

  @computed
  public get maxInputAmount() {
    const precision = getCoinDecimalPrecision(this.inputTickerWallet.ticker);
    return Number(this.inputTickerWallet?.amount?.toFixed(precision));
  }

  @computed get inputAmountError(): ValidationMessageLocalized {
    return this.instance.$('inputAmount').get('error');
  }

  @computed get agreementItem(): DocumentItem[] {
    const documentItem = (
      this.resources.docs.documents.youhodlerDocuments ||
      this.resources.docs.documents.youhodlerDocumentsFull
    ).find(el => el.name === 'Specific Terms for Lending Services');

    return documentItem ? [documentItem] : [];
  }

  @computed get isHodlExpired() {
    return (
      this.resources.hodl.data.isClosing || this.resources.hodl.data.isClosed
    );
  }

  @action setInputTicker = (value: string) => {
    this.instance.$('inputTicker').set('value', value);
  };

  @action setInputAmount = (value: string) => {
    const normalizedValue = normalizeAmountWithPrecision(
      value,
      getCoinDecimalPrecision(this.inputTicker)
    );

    this.instance.$('inputAmount').set('value', normalizedValue);
  };

  @computed
  get inputAmountUsd() {
    return this.toUsd(this.inputAmount || 0);
  }

  @action setMaxAmount = () => {
    this.setInputAmount(this.inputTickerWallet?.amount?.toString() ?? '0');
  };

  @computed
  public get hasBalance() {
    return toBig(this.inputTickerWallet.amount).gt(0);
  }

  //#region incentives
  minBonusesAmount = 0.01;
  additionalInputTickerBE = 'bonus';

  //#region inc. setup
  @computed get bonusesEnabled() {
    return this.currentTariff?.bonusesEnabled;
  }

  @computed get currentTariff() {
    const {baseTicker, quoteTicker, direction} = this.resources.hodl.data;
    return this.resources.tariffs.data.find(
      tariff =>
        tariff.baseTicker === baseTicker &&
        tariff.quoteTicker === quoteTicker &&
        tariff.isShort === (direction === 'down')
    );
  }

  @computed
  public get bonusBalance() {
    return toBig(this.resources.walletsResource.bonusesWallet?.amount);
  }

  @computed
  public get bonusBalanceFormatted() {
    const balanceFormatted = this.bonusBalance.eq(0)
      ? '0'
      : formatByTicker(this.bonusBalance, this.additionalInputTickerBE);

    return `$${balanceFormatted}`;
  }

  @computed
  public get hasEnoughBonuses() {
    return this.bonusBalance.gt(this.minBonusesAmount);
  }
  //#endregion inc. setup

  //#region inc. useBonuses
  @computed
  public get useBonusesActive(): boolean {
    return this.instance?.$('useBonusesActive').get('value');
  }

  @computed
  public get setUseBonusesActive() {
    return this.instance?.$('useBonusesActive').get('onChange');
  }
  //#endregion inc. useBonuses

  //#region inc. main amounts

  /**  Logic description:
   *
   * The amount of bonuses a user can add is limited by the following:
   *  1. How much more bonuses they could have used for the INITIAL main input amount,
        but using the current (not the initial) percentage limit from BE.
   *  2. How much bonuses they can use for their NEW main input amount,
   *    using the current percentage limit.
   *  3. maxBonusesAmount from BE, if present.
   *  4. How much bonuses they have.
   *
   * So, the max amount for incentives input is the sum of #1 and #2, cut off by #3 and #4.
   * All calculations should be done in USD.
   *
   * Example:

   * Initial maxBonusesPercent: 80%.
   * Initial mainInputAmount: 1000 USD.
   * Initial max bonuses amount: 80% * 1000 = 800.
   * Bonuses used: 400 of old max 800.
   *
   * Current maxBonusesPercent: 50%.
   * Current (new) input amount: 280 USD.
   * Current max bonuses amount: 50% * 280 = 140.
   *
   * maxBonusesAmount from BE not specified.
   * Bonus balance: 234.
   *
   * 1. With current percentage, old max would have been 1000 * 50% = 500, so the user could have used
   *  500-400 = 100 more.
   * 2. New mainInputAmount: 280 USD.
   *  So, on the new input amount, the maximum of
   *    280 * 50% = 140 bonuses can be used.
   * 3. User has 234 bonuses in their balance.
   *
   * Result: The user can now use 100 + 140 = 240 more bonuses at maximum,
   *  but they only have 234, so the resulting maximum is 234.
   */

  @computed get previousMainInputAmount(): Big {
    return toBig(
      this.resources.hodl.data.mainInputAmount ||
        this.resources.hodl.data.inputAmount
    );
  }

  @computed get previousMainInputAmountUsd(): Big {
    return this.toUsd(this.previousMainInputAmount);
  }

  @computed get totalMainInputAmount(): Big {
    return toBig(this.previousMainInputAmount).plus(this.inputAmount || 0);
  }

  @computed get totalMainInputAmountUsd(): Big {
    return this.toUsd(this.totalMainInputAmount);
  }
  //#endregion inc. main amounts

  //#region inc. maximums
  @computed get maxBonusesPercent() {
    if (!this.bonusesEnabled) {
      return undefined;
    }

    return this.currentTariff?.maxBonusesPercent;
  }

  @computed get totalMaxBonusesAmountByInputs(): Big {
    const percentRestrictedMax = this.totalMainInputAmountUsd
      .mul(this.maxBonusesPercent || 0)
      .div(100);

    const amountRestrictedMax = toBig(this.currentTariff?.maxBonusesAmount);

    return percentRestrictedMax.lt(amountRestrictedMax)
      ? percentRestrictedMax
      : amountRestrictedMax;
  }

  @computed get availableMaxBonusesAmountByInputs() {
    const computedValue = this.totalMaxBonusesAmountByInputs.minus(
      this.resources.hodl.data.additionalInputAmount || 0
    );

    return computedValue.gt(0) ? computedValue : toBig(0);
  }

  @computed
  public get maxBonusesAmount() {
    const lesserAmount = this.availableMaxBonusesAmountByInputs.lt(
      this.bonusBalance
    )
      ? this.availableMaxBonusesAmountByInputs
      : this.bonusBalance;

    return formatByTicker(lesserAmount, this.additionalInputTickerBE);
  }
  //#endregion inc. maximums

  //#region inc. additional amounts
  @computed get additionalInputAmount() {
    return this.instance?.$('additionalInputAmount').get('value');
  }

  @computed get additionalInputAmountError() {
    return this.instance?.$('additionalInputAmount').error;
  }

  @action setAdditionalInputAmount = (value: string) => {
    const normalizedValue = normalizeAmountWithPrecision(
      value,
      getCoinDecimalPrecision(additionalInputTickerUI)
    );

    this.instance.$('additionalInputAmount').set('value', normalizedValue);
  };
  //#endregion inc. additional amounts

  toUsd = (amount: BigSource): Big => {
    const {getRate} = this.resources.rates;
    const rate = getRate(this.inputTicker, 'usd');
    return toBig(amount).times(toBig(rate));
  };

  //#endregion incentives

  @action
  public submitForm = () => {
    this.instance.submit();
  };
}
