import * as React from 'react';
import {observer} from 'mobx-react';
import {ModalDynamic} from '@youtoken/ui.modal';
import {useCloseModalOnBackButton} from '@youtoken/ui.hooks';
import {Box} from '@youtoken/ui.primitives';
import {FreeBitcoinsContent} from './FreeBitcoinsContent';

interface FreeBitcoinsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const FreeBitcoinsModal: React.FC<FreeBitcoinsModalProps> = observer(
  ({isOpen, onClose}) => {
    useCloseModalOnBackButton(isOpen, () => {
      onClose();
      return true;
    });

    return (
      <ModalDynamic
        isOpen={isOpen}
        onClose={onClose}
        backgroundColor="$interactive-01"
        enableDynamicSizing
        borderTopLeftRadius={40}
        borderTopRightRadius={40}
        nativeBackgroundStyle={{backgroundColor: '$transparent'}}
      >
        <Box pt={36} px={40}>
          <FreeBitcoinsContent onClose={onClose} />
        </Box>
      </ModalDynamic>
    );
  }
);
