import * as React from 'react';
import {NewReferralActivity} from '@youtoken/ui.resource-new-referral';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {PendingFriendModalContent} from './PendingFriendModalContent';

type PendingFriendModalProps = {
  onClose: () => void;
  activity: NewReferralActivity | null;
} & Omit<ModalDynamicProps, 'children'>;

export const PendingFriendModal = ({
  activity,
  onClose,
}: PendingFriendModalProps) => {
  return (
    <ModalDynamic isOpen={Boolean(activity)} onClose={onClose}>
      {activity && <PendingFriendModalContent p={24} activity={activity} />}
    </ModalDynamic>
  );
};
