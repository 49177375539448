import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {modal} from '../../../stores';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {
  AddWalletsModal,
  WalletsChooseTickerType,
} from '@youtoken/ui.surfaces-wallets';

export const modalAddWalletsName = 'addWallets';

type ModalAddWalletsProps = {
  initialFilter?: string;
} & Omit<ModalDynamicProps, 'children'>;

export const ModalAddWallets = cell<ModalAddWalletsProps>(
  ({initialFilter, ...modalProps}) => {
    const {opened, close} = modal;

    return (
      <ModalDynamic
        webOverlayBackgroundColor="$transparent"
        isOpen={opened?.name === modalAddWalletsName}
        onClose={close}
        testID="ADD_WALLETS_MODAL"
      >
        <AddWalletsModal
          initialFilter={initialFilter as WalletsChooseTickerType}
          {...modalProps}
        />
      </ModalDynamic>
    );
  }
);
