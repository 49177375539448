import {AppState, type AppStateStatus, Platform} from 'react-native';
import {
  _allowStateChangesInsideComputed,
  action,
  computed,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  reaction,
  runInAction,
  transaction,
} from 'mobx';
import {computedFn} from 'mobx-utils';
import Big from 'big.js';
import {debounce, without} from 'lodash';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {warning} from '@youtoken/ui.utils';

/** returns throttle wait time for give amount of subs
 *
 * main reason for this is to avoid too many updates on frontend;
 * in case with have very few subs, we want to update rates as fast as possible.
 * in case with have many subs, we want to update rates once in 5 seconds or so.
 *
 * for now it's the same value as it was before, but after we will check it live on device
 * we will be able to calculate optimal wait time for given platform/number of subs;
 *
 *
 * @example
 * 2 subs => wait time = 1 seconds
 * 50 subs => wait time = 5 seconds
 * 100 subs => wait time = 10 second
 */
const getRatesThrottleWaitTime = (
  _numberOfRatesToSubscribe: number
): number => {
  return Platform.select({native: 1000, web: 1000})!;
};

type SocketRate = {
  fromTicker: string;
  toTicker: string;
  price: number;
  bid: number;
  ask: number;
  diff: number;
  diff24h: number;
};

const getKeyPair = (from: string, to: string) => {
  return `${from}/${to}`;
};

const identityRate = {rate: 1, bid: 1, ask: 1, diff24h: 0};

export type RatesResourceArgs = {
  product?: 'hodl' | 'convert' | 'default';
};

export type RateObject = {
  rate: number;
  bid: number;
  ask: number;
  diff24h: number;
};

type Rates = Record<string, RateObject>;

export class RatesResource extends createResource<RatesResourceArgs, Rates>({
  getKey: ({product = 'default'}) => `RatesResource:${product}`,
  getData: ({product = 'default'}) =>
    TRANSPORT.API.get<Record<string, Record<string, RateObject>>>(
      `/v3/rates/extended?product=${product}`
    ).then(res => {
      return Object.keys(res.data).reduce(
        (acc, fromTicker: keyof typeof res.data) => {
          return Object.keys(res.data[fromTicker]!).reduce((acc, toTicker) => {
            acc[getKeyPair(fromTicker, toTicker)] =
              res.data[fromTicker]![toTicker]!;
            return acc;
          }, acc);
        },
        {} as Rates
      );
    }),
  shouldBeDeletedOnCleanup: false,
}) {
  @observable data!: Rates;

  @observable subs: string[] = [];

  @observable appState: AppStateStatus = AppState.currentState;

  constructor(args: RatesResourceArgs, data: Rates) {
    super(args, data);

    TRANSPORT.SOCKET.on(this.eventName, this.reactOnSocketMessage);
    TRANSPORT.SOCKET.on('disconnect', () => {});
    TRANSPORT.SOCKET.on('connect', () => {
      this.subscribeRates(this.subs);
    });

    Object.keys(data).map(key => {
      this.addPairToSubs(key);
    });

    reaction(() => this.subs, this.reactOnSubsChanged, {
      fireImmediately: true,
    });

    AppState.addEventListener('change', state => {
      this.appState = state;
    });
  }

  onDestroy() {
    super.onDestroy();

    TRANSPORT.SOCKET.off(this.eventName, this.reactOnSocketMessage);
    TRANSPORT.SOCKET.off('connect', () => this.subscribeRates(this.subs));
  }

  @action assignData = (data: Rates) => {
    if (this.appState !== 'active') {
      return;
    }

    transaction(() => {
      let symbol: keyof Rates;
      for (symbol in data) {
        let prop: keyof Rates[''];
        for (prop in data[symbol]) {
          if (this.data[symbol]![prop] !== data[symbol]![prop]) {
            this.data[symbol]![prop] = data[symbol]![prop];
          }
        }
      }
    });
  };

  @action addPairToSubs = (pair: string) => {
    onBecomeObserved(this.data, pair, () => {
      _allowStateChangesInsideComputed(() => {
        if (!this.subs.includes(pair)) {
          runInAction(() => {
            this.subs = [...this.subs, pair];
          });
        }
      });
    });

    onBecomeUnobserved(this.data, pair, () => {
      _allowStateChangesInsideComputed(() => {
        if (this.subs.includes(pair)) {
          runInAction(() => {
            this.subs = without(this.subs, pair);
          });
        }
      });
    });
  };

  @computed get subscriptionArgs() {
    if (this.args.product === 'hodl') {
      return {name: 'product-rates', product: 'hodl'};
    }

    if (this.args.product === 'convert') {
      return {name: 'product-rates', product: 'convert'};
    }

    return {name: 'rates-selected'};
  }

  @computed get eventName() {
    return ['hodl', 'convert'].includes(this.args.product ?? 'default')
      ? 'product-rates'
      : 'rates-selected';
  }

  subscribeRates = (subs: string[]) => {
    TRANSPORT.SOCKET.emit('sub', {
      ...this.subscriptionArgs,
      symbols: subs,
      throttle: getRatesThrottleWaitTime(subs.length),
    });
  };

  @action reactOnSubsChanged = debounce(this.subscribeRates, 1000);

  @action reactOnSocketMessage = (data: SocketRate[]) => {
    if (this.appState !== 'active') {
      return;
    }

    transaction(() => {
      data.forEach(rate => {
        const symbol = getKeyPair(rate.fromTicker, rate.toTicker);

        if (this.data[symbol]) {
          if (this.data[symbol]!.rate !== rate.price) {
            this.data[symbol]!.rate = rate.price;
          }
          if (this.data[symbol]!.bid !== rate.bid) {
            this.data[symbol]!.bid = rate.bid;
          }
          if (this.data[symbol]!.ask !== rate.ask) {
            this.data[symbol]!.ask = rate.ask;
          }
          if (this.data[symbol]!.diff24h !== rate.diff24h) {
            this.data[symbol]!.diff24h = rate.diff24h;
          }
        }
      });
    });
  };

  getRateObj = computedFn((from: string, to: string) => {
    const rate = this.data[getKeyPair(from, to)];

    return rate ?? identityRate;
  });

  getRate = computedFn((from: string, to: string) => {
    return this.getRateObj(from, to).rate;
  });

  getBidAskRate = computedFn(
    (from: string, to: string, type: 'ask' | 'bid') => {
      return this.getRateObj(from, to)[type];
    }
  );

  getExchangeRate = computedFn((from: string, to: string) => {
    return this.getRateObj(from, to).bid;
  });

  getDiff24 = computedFn((from: string, to: string) => {
    return this.getRateObj(from, to).diff24h;
  });

  /** returns diff percent related to actual price as Big number
   *
   * @example
   * getDiffPercent('btc', 'usd') => Big(123)
   */
  getDiffPercent = computedFn((from: string, to: string): Big => {
    const rate = Big(this.getRate(from, to));
    const diffInRate = Big(this.getDiff24(from, to));

    return diffInRate.div(rate).times(100);
  });

  /** returns diff percent related to price 24h ago as Big number
   *
   * @example
   * getDiffPercent('btc', 'usd') => Big(123)
   */
  getDiff24Percent = computedFn((from: string, to: string): Big => {
    const rate = Big(this.getRate(from, to));
    const diffInRate = Big(this.getDiff24(from, to));

    const rate24hAgo = rate.minus(diffInRate);

    return diffInRate.div(rate24hAgo);
  });

  /** returns diff direction - up or down
   * @example
   * getDiffDirection('btc', 'usd') => 'up'
   */
  getDiffDirection = computedFn((from: string, to: string): 'up' | 'down' => {
    return this.getDiffPercent(from, to).gte(0) ? 'up' : 'down';
  });
}
