import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {ModalDynamic} from '@youtoken/ui.modal';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {Icon} from '@youtoken/ui.icons';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {InfoItem} from './InfoItem';

type OnboardingNoticeModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onContinuePress: () => void;
};

export const OnboardingNoticeModal: React.FC<OnboardingNoticeModalProps> =
  observer(({isOpen, isLoading, onClose, onContinuePress}) => {
    const {t} = useTranslation();

    React.useEffect(() => {
      if (isOpen) {
        DATA_LAYER.trackStrict('card-pre-kyc-page-visited', {
          provider: 'InterGiro',
        });
      }
    }, [isOpen]);

    return (
      <ModalDynamic isOpen={isOpen} onClose={onClose}>
        <Box pt={32} px={24} pb={Platform.select({native: 0, web: 24})}>
          <Box
            width={64}
            height={64}
            borderRadius={32}
            alignItems="center"
            justifyContent="center"
            alignSelf="center"
            backgroundColor="$success-03"
          >
            <Icon name="id_verification" color="$success-01" size={32} />
          </Box>
          <Box mt={16}>
            <Heading variant="$heading-02" textAlign="center">
              {t('surface.bank_cards.landing.modal.title')}
            </Heading>

            <Box mt={8} px={8}>
              <Text variant="$body-02" color="$text-02" textAlign="center">
                {t('surface.bank_cards.landing.modal.provide_documents.v2')}
              </Text>
            </Box>

            <Box mt={24} px={8}>
              <InfoItem
                icon="verified"
                title={t('surface.bank_cards.landing.modal.info_one.title.v2')}
                text={t('surface.bank_cards.landing.modal.info_one.text.v2')}
                textProps={{variant: '$body-02', color: '$text-02'}}
              />
              <InfoItem
                icon="card"
                title={t('surface.bank_cards.landing.modal.info_two.title')}
                text={t('surface.bank_cards.landing.modal.info_two.text')}
                textProps={{variant: '$body-02', color: '$text-02'}}
                mt={24}
              />
            </Box>

            <Button
              size="large"
              mt={24}
              loading={isLoading}
              onPress={onContinuePress}
            >
              {t('surface.bank_cards.landing.modal.button')}
            </Button>
          </Box>
        </Box>
      </ModalDynamic>
    );
  });
