import {deserialize} from 'serializr';
import {action, observable, runInAction} from 'mobx';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {HODLItem} from '../HODLItem';
import {HODLItemData} from '../HODLItemData';

export interface HODLByIdArgs {
  id: string;
}

export class HODLByIdResource extends createResource<HODLByIdArgs, HODLItem>({
  skipRefreshOnVisible: false,
  getKey: ({id}: HODLByIdArgs) => `hodlsById:${id}`,
  getData: ({id}) =>
    TRANSPORT.API.get(`/v3/hodl/${id}`).then(res => {
      return deserialize(HODLItem, {data: res.data});
    }),
}) {
  onInit() {
    super.onInit();

    TRANSPORT.SOCKET.on('hodl-opened', this.handleHodlUpdate);
    TRANSPORT.SOCKET.on('hodl-closed', this.handleHodlUpdate);
    TRANSPORT.SOCKET.on('connect', this.handleWsConnection);
  }

  onDestroy() {
    super.onDestroy();

    TRANSPORT.SOCKET.off('hodl-opened', this.handleHodlUpdate);
    TRANSPORT.SOCKET.off('hodl-closed', this.handleHodlUpdate);
    TRANSPORT.SOCKET.off('connect', this.handleWsConnection);
  }

  @action.bound
  handleHodlUpdate = (hodl: HODLItemData) => {
    if (hodl.id !== this.data.id) {
      return;
    }

    runInAction(() => {
      this.data = deserialize(HODLItem, {data: hodl});
    });
  };

  @observable
  subscribed = false;

  @action.bound
  handleWsConnection = () => {
    if (!this.subscribed) {
      return;
    }

    this.subscribeToUpdates();
  };

  @action subscribeToUpdates = () => {
    TRANSPORT.SOCKET.emit('sub', {
      name: 'hodls:new',
    });

    this.subscribed = true;

    return () => {
      TRANSPORT.SOCKET.emit('unsub', {
        name: 'hodls:new',
      });

      runInAction(() => {
        this.subscribed = false;
      });
    };
  };
}
