import {Big} from 'big.js';
import {observable, action, runInAction, computed} from 'mobx';
import {serializable, deserialize, primitive} from 'serializr';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import {TRANSPORT} from '@youtoken/ui.transport';

export class Calculated {
  @observable
  loading: boolean = false;

  @observable
  ready: boolean = false;

  @observable
  data?: CalculatedResponse;

  @action
  load = ({
    bundleId,
    quoteTicker,
    quoteAmount,
    withdrawAll,
  }: {
    bundleId: string;
    quoteTicker: string;
    quoteAmount: string;
    withdrawAll: boolean;
  }) => {
    this.loading = true;
    this.ready = false;

    return TRANSPORT.API.get(
      `/v1/bundle/withdraw/calculate?bundleId=${bundleId}&quoteTicker=${quoteTicker}&quoteAmount=${Number(
        quoteAmount
      )}&withdrawAll=${withdrawAll}`
    )
      .then(response => {
        runInAction(() => {
          this.ready = true;
          this.data = deserialize(CalculatedResponse, response.data);
        });
      })
      .finally(() => {
        this.loading = false;
      });
  };
}

export class CalculatedResponse {
  @serializable(primitive())
  onlyWithdrawAll!: boolean;

  @serializable(primitive())
  ticker!: string;

  @computed
  get tickerFormatted() {
    return this.ticker.toUpperCase();
  }

  @serializable(bigNumber())
  fee!: Big;

  @computed
  get feeFormatted() {
    return formatByTicker(this.fee, this.ticker);
  }

  @serializable(bigNumber())
  minWithdrawal!: Big;

  @computed
  get minWithdrawalFormatted() {
    return formatByTicker(this.minWithdrawal, this.ticker);
  }

  @serializable(bigNumber())
  maxWithdrawal!: Big;

  @computed
  get maxWithdrawalFormatted() {
    return formatByTicker(this.maxWithdrawal, this.ticker);
  }

  @serializable(bigNumber())
  totalBundleAmount!: Big;

  @computed
  get totalBundleAmountFormatted() {
    return formatByTicker(this.totalBundleAmount, this.ticker);
  }

  @serializable(bigNumber())
  afterWithdrawalBalance!: Big;

  @computed
  get afterWithdrawalBalanceFormatted() {
    return formatByTicker(this.afterWithdrawalBalance, this.ticker);
  }
}
