import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ModalDynamic} from '@youtoken/ui.modal';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Paragraph} from './Paragraph';
import {PrizeTable} from './PrizeTable';

type LearnMoreModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const LearnMoreModal: React.FC<LearnMoreModalProps> = observer(
  ({isOpen, onClose}) => {
    const {t} = useTranslation();
    const {
      data: {enableSkipCardOrderFee},
    } = AuthMeResource.use({});

    return (
      <ModalDynamic
        isOpen={isOpen}
        onClose={onClose}
        shouldBeScrollable
        title={t('surface.bank_cards.landing.learn_more.title')}
        shouldShowHeaderSeparator={Platform.select({
          default: false,
          web: true,
        })}
      >
        <Box
          width="100%"
          p={24}
          pt={Platform.select({
            default: 0,
            web: 24,
          })}
        >
          {!enableSkipCardOrderFee && (
            <Paragraph
              title={t(
                'surface.bank_cards.landing.learn_more.conditions.title'
              )}
              text={t('surface.bank_cards.landing.learn_more.conditions.text')}
              asteriskCount={1}
            />
          )}

          <Paragraph
            title={t('surface.bank_cards.landing.learn_more.prizes.title')}
            text={t('surface.bank_cards.landing.learn_more.prizes.text')}
            asteriskCount={2}
            mt={enableSkipCardOrderFee ? 0 : 24}
          />
          <PrizeTable mt={24} />
          <Paragraph
            text={t('surface.bank_cards.landing.learn_more.prizes.text2')}
            mt={8}
          />

          <Paragraph
            title={t('surface.bank_cards.landing.learn_more.drop_date.title')}
            text={t('surface.bank_cards.landing.learn_more.drop_date.text')}
            mt={24}
          />
        </Box>
      </ModalDynamic>
    );
  }
);
