import * as React from 'react';
import {observer} from 'mobx-react';
import {ModalDynamic} from '@youtoken/ui.modal';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useCloseModalOnBackButton} from '@youtoken/ui.hooks';
import {HowItWorksSurface} from './HowItWorksSurface';

type HowItWorksModalProps = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
};

export const HowItWorksModal: React.FC<HowItWorksModalProps> = observer(
  ({title, isOpen, onClose}) => {
    const {t} = useTranslation();

    useCloseModalOnBackButton(isOpen, () => {
      onClose();
      return true;
    });

    return (
      <ModalDynamic
        isOpen={isOpen}
        onClose={onClose}
        shouldBeScrollable
        title={title}
      >
        <HowItWorksSurface />
      </ModalDynamic>
    );
  }
);
