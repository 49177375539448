import {IReactionDisposer, reaction} from 'mobx';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  BundleOverviewResourceResponse,
  bundleOverviewResourceResponseSchema,
} from './BundleOverviewResourceResponse';

export interface BundleOverviewResourceArgs {}

export class BundleOverviewResource extends createResource<
  BundleOverviewResourceArgs,
  BundleOverviewResourceResponse
>({
  getKey: (_args: BundleOverviewResourceArgs) => {
    return 'BundleOverview';
  },
  getData: (_args: BundleOverviewResourceArgs) => {
    const quoteTicker = AuthMeResource.getInstanceSafely({})?.mainCurrency;

    return TRANSPORT.API.get<typeof bundleOverviewResourceResponseSchema>(
      `/v1/bundle/overview?quoteTicker=${quoteTicker}`
    ).then(response => {
      bundleOverviewResourceResponseSchema.parse(response.data);

      return deserialize(BundleOverviewResourceResponse, response.data);
    });
  },
  skipRefreshOnVisible: false,
}) {
  disposers: Array<IReactionDisposer> = [];

  constructor(
    args: BundleOverviewResourceArgs,
    response: BundleOverviewResourceResponse
  ) {
    super(args, response);

    this.disposers.push(
      reaction(
        () => AuthMeResource.getInstanceSafely({})?.mainCurrency,
        () => {
          this.refetch();
        }
      )
    );
  }

  onDestroy(): void {
    super.onDestroy();
    this.disposers.forEach(disposer => disposer?.());
  }
}
