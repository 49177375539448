import {action, computed, observable, reaction, runInAction} from 'mobx';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import {computedFn, type IDisposer} from 'mobx-utils';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import type {AxiosPromise} from 'axios';
import Big from 'big.js';
import {formatByTicker, toBig} from '@youtoken/ui.formatting-utils';
import {normalizeAmountByTicker} from '@youtoken/ui.normalizers';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {invariant, openBrowserAsync} from '@youtoken/ui.utils';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {TRANSPORT} from '@youtoken/ui.transport';
import * as yupPackage from '@youtoken/ui.yup';
import {
  getTranslatedValidationMessage,
  handleGeneralErrorTranslated,
  messages,
} from '@youtoken/ui.validation-messages';
import {
  handleFormFieldsErrors,
  handleFormSubmitError,
} from '@youtoken/ui.form-utils';
// import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {getReturnUrls} from '../../utils';
import type {
  CardWithdrawalFormArgs,
  CardWithdrawalFormResources,
} from './types';
import {__GLOBAL_RECAPTCHA__} from '@youtoken/ui.two-factor-auth-and-recaptcha';

export class Form {
  @observable
  public args: CardWithdrawalFormArgs;

  @observable
  public resources: CardWithdrawalFormResources;

  @observable
  public instance: MobxReactForm;

  @observable
  disposers: IDisposer[] = [];

  @observable
  currentRequest = null;

  @observable
  public isThereCard3dsError = false;

  @observable
  public isCardVerified = false;

  @observable
  isLoading: boolean = false;

  @observable
  isValidating: boolean = false;

  // TODO: Discuss whether the riskJs script is needed, and set the value below to false if it is
  @observable
  public isRiskJsScriptLoaded: boolean = true;

  public constructor(
    args: CardWithdrawalFormArgs,
    resources: CardWithdrawalFormResources
  ) {
    this.args = args;
    this.resources = resources;

    if (args.withError === '3ds') {
      this.isThereCard3dsError = true;
    }

    const getCardId =
      resources.cards.cardsList.length && args.cardId
        ? resources.cards.cardsList.find(card => card.id === args.cardId)?.id
        : resources.cards.cardsList[0]?.id;

    const fields = {
      sum: {
        name: 'sum',
        label: 'Sum',
        value: args.amount || '',
      },
      deviceSessionId: {value: ''},
      cardId: {value: getCardId || ''},
      twoFactorAuthOperationCode: {value: ''},
      twoFactorAuthOperationId: {value: ''},
    };

    const hooks = {
      onSuccess: (form: MobxReactForm) => {
        // NOTE: It's preventing multiple submissions
        if (this.isLoading) {
          return;
        }

        this.isLoading = true;
        const {
          deviceSessionId,
          cardId,
          twoFactorAuthOperationId,
          twoFactorAuthOperationCode,
        } = form.values();

        // NOTE: User data is required for both validation and the withdrawal itself
        const requestWithData = async (url: string) => {
          return TRANSPORT.API.post(url, {
            provider: `checkoutBankCard`,
            amount: this.sumValue,
            ticker: this.ticker,
            conversionTicker: this.conversionTicker,
            cardId,
            providerData: {
              redirectUrls: getReturnUrls(
                this.ticker,
                this.selectedCardId,
                this.sumValue.toString()
              ),
            },
            operationId: twoFactorAuthOperationId,
            code: twoFactorAuthOperationCode,
            ...(deviceSessionId ? {deviceSessionId} : {}),
          });
        };

        // NOTE: Prepares a function for a withdrawal operation
        const performWithdraw = async () => {
          await requestWithData('/v1/withdrawal/cards')
            // NOTE: If successful, redirect a user to the history page after small delay
            .then(async () => {
              await new Promise<void>(resolve =>
                setTimeout(() => {
                  resolve();
                  this.args.onSubmit?.();
                  SHARED_ROUTER_SERVICE.navigate('WalletsItem', {
                    ticker: this.args.ticker,
                  });
                }, 2000)
              );
            })
            .catch(error => {
              handleFormSubmitError(form, error, {
                operationId: 'twoFactorAuthOperationId',
                code: 'twoFactorAuthOperationCode',
              });
            });
        };

        // NOTE: If the card is already verified, just tries to withdraw funds
        if (this.isCardVerified) {
          return performWithdraw().finally(() => {
            this.isLoading = false;
          });
        }

        // NOTE: Send a second cards/withdraw/validation request to ensure URLs are active or get new ones if already redirected to 3DS
        return (
          requestWithData('/v1/withdrawal/cards/validation')
            .then(async response => {
              // NOTE: Getting the 3DS url from the response
              const providerUrl = response.data.providerResponse?.redirect_url;

              if (providerUrl) {
                DATA_LAYER.trackStrict('card-save-verify', {
                  provider: 'CheckoutBankCard',
                });

                // NOTE: Opens 3DS in a browser window
                await openBrowserAsync(providerUrl).then(async () => {
                  await new Promise<void>(resolve =>
                    // NOTE: Delay to wait until BE change status
                    setTimeout(async () => {
                      // NOTE: Check validation again to change status of the card
                      await requestWithData('/v1/withdrawal/cards/validation')
                        .then(response => {
                          this.isCardVerified =
                            response.data.payoutStatus === 'verified';
                        })
                        .catch(error => {
                          throw error;
                        });
                      resolve();
                    }, 2000)
                  );
                });
              } else {
                // NOTE: If 3DS was already passed, just tries to withdraw funds
                // NOTE: Catch error because it's already handled in the performWithdraw
                await performWithdraw().catch(() => {});
              }
            })
            // NOTE: If validation wasn't passed, just shows an error below the card selector
            .catch(error => {
              handleFormFieldsErrors(this.instance, error);
            })
            .finally(() => {
              this.isLoading = false;
            })
        );
      },
    };

    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            return yup.object().shape({
              sum: yup
                .big()
                .gte(this.minAmount.toNumber(), () =>
                  messages.MIN_AMOUNT({
                    value: this.minAmount.toFixed(this.tickerPrecision),
                    ticker: this.ticker.toUpperCase(),
                  })
                )
                .lte(this.maxAmount.toNumber(), () =>
                  messages.MAX_AMOUNT({
                    value: this.maxAmount.toFixed(this.tickerPrecision),
                    ticker: this.ticker.toUpperCase(),
                  })
                )
                .lte(this.allSourceAmountFormatted, messages.FUNDS_INSUFFICIENT)
                .required(messages.REQUIRED),
              deviceSessionId: yup.mixed().notRequired(),
              cardId: yup.string().required(messages.REQUIRED),
              twoFactorAuthOperationId: yup.string(),
              twoFactorAuthOperationCode: yup.string(),
            });
          }),
      }),
    };

    const options = {
      validateOnBlur: false,
      validateOnChange: true,
      validateOnChangeAfterSubmit: true,
      showErrorsOnReset: false,
    };

    this.disposers = [
      // pre-select of card from cards list if it isn't empty
      reaction(
        () => this.cardsList,
        cardsList => {
          if (!cardsList.length) {
            this.setCardId('');
            return;
          }

          if (this.selectedCardId) {
            return;
          }

          if (cardsList[0]) {
            this.setCardId(cardsList[0].id);
          }
        },
        {
          fireImmediately: true,
        }
      ),
      // NOTE: Check if the card is verified and change status to show a proper button title/message
      reaction(
        () => this.selectedCardId,
        selectedCardId => {
          // NOTE: check !isLoading because otherwise it doubles the validation request in the add card with payment flow
          if (selectedCardId && !this.isLoading) {
            this.isValidating = true;

            TRANSPORT.API.post('/v1/withdrawal/cards/validation', {
              provider: `checkoutBankCard`,
              amount: this.sumValue,
              ticker: this.ticker,
              conversionTicker: this.conversionTicker,
              providerData: {
                redirectUrls: getReturnUrls(
                  this.ticker,
                  this.selectedCardId,
                  this.sumValue.toString()
                ),
              },
              cardId: this.selectedCardId,
              ...(this.deviceSessionId
                ? {deviceSessionId: this.deviceSessionId}
                : {}),
            })
              .then(response => {
                this.isCardVerified = response.data.payoutStatus === 'verified';
              })
              .catch(error => {
                handleFormSubmitError(this.instance, error);
              })
              .finally(() => {
                this.isValidating = false;
              });
          }
        },
        {
          fireImmediately: true,
        }
      ),
    ];

    this.instance = new MobxReactForm({fields}, {plugins, hooks, options});
  }

  //#region sum field

  @computed
  public get sumField() {
    return this.instance.$('sum');
  }

  @computed
  get sumValue(): Big {
    return toBig(this.sumField.value);
  }

  @computed
  public get sumFormatted(): string {
    return formatByTicker(this.sumValue, this.ticker);
  }

  @computed
  public get hasSumError() {
    return Boolean(this.sumField.error);
  }

  @computed
  public get sumError() {
    return this.sumField.error;
  }

  @computed
  get sumInUSD(): Big {
    const {getRate} = this.resources.rates;
    const rate = getRate(this.ticker, 'usd');
    return this.sumValue.times(toBig(rate));
  }

  @computed
  public get sumInUSDFormatted(): string {
    return formatByTicker(this.sumInUSD, 'usd');
  }

  @computed
  get sumInConversionTicker(): Big | null {
    if (!this.args.conversionTicker) {
      return null;
    }
    const {getRate} = this.resources.rates;
    const rate = getRate(this.ticker, this.args.conversionTicker);
    return this.sumValue.times(toBig(rate));
  }

  @computed
  get sumInConversionTickerWithFee(): Big | null {
    if (!this.conversionTicker || !this.sumInConversionTicker) {
      return null;
    }

    return this.sumInConversionTicker.minus(this.feeValueInConversionTicker);
  }

  @computed
  get sumInConversionTickerWithFeeFormatted(): string | null {
    if (
      !this.args.conversionTicker ||
      !this.sumInConversionTicker ||
      !this.sumInConversionTickerWithFee
    ) {
      return null;
    }

    return formatByTicker(
      this.sumInConversionTickerWithFee,
      this.args.conversionTicker
    );
  }

  @action
  onSumChange = (value: string) => {
    const onChange = this.sumField.get('onChange');
    onChange(normalizeAmountByTicker(value, this.ticker));
  };

  @computed.struct
  public get currentWallet() {
    return this.resources.wallets.fiatWallets.find(
      w => w.ticker === this.ticker
    );
  }

  @computed
  public get allSourceAmountFormatted() {
    return this.currentWallet?.amountFormatted ?? '0';
  }

  @action
  setAllAmountToWithdraw = () => {
    this.onSumChange(this.allSourceAmountFormatted);
  };
  //#endregion sum field

  //#region ticker
  @computed
  get ticker() {
    return this.args.ticker;
  }

  @computed
  get conversionTicker() {
    return this.args.conversionTicker;
  }

  @computed
  get tickerPrecision() {
    return getCoinDecimalPrecision(this.ticker);
  }

  @computed
  get conversionTickerPrecision() {
    return getCoinDecimalPrecision(this.conversionTicker);
  }

  //#endregion ticker

  //#region fees

  @computed
  public get tickerFees() {
    return this.resources.feeAll.getFeeForWithdraw(
      this.ticker,
      'checkoutBankCard'
    );
  }

  @computed
  public get feePercent() {
    return this.tickerFees?.percent;
  }

  @computed
  public get isMinCommissionEstablished() {
    return !this.tickerFees?.min.eq(0);
  }

  @computed
  public get isMaxCommissionEstablished() {
    return !this.tickerFees?.max.eq(0);
  }

  private getCalculatedFee = computedFn((fee: Big) => {
    if (
      this.isMinCommissionEstablished &&
      this.tickerFees &&
      fee.lte(this.tickerFees.min)
    ) {
      return this.tickerFees.min;
    }

    if (
      this.isMaxCommissionEstablished &&
      this.tickerFees &&
      fee.gte(this.tickerFees.max)
    ) {
      return this.tickerFees.max;
    }

    return fee;
  });

  @computed
  public get feeValue() {
    invariant(
      this.tickerFees,
      'tickerFees should be defined',
      {},
      {tickerFees: this.tickerFees}
    );

    const calculatedFee = toBig(this.sumValue.mul(this.tickerFees.percent));

    return this.getCalculatedFee(calculatedFee);
  }

  @computed
  public get feeValueInConversionTicker() {
    invariant(
      this.tickerFees,
      'tickerFees should be defined',
      {},
      {tickerFees: this.tickerFees}
    );

    const calculatedFee = toBig(
      this.sumInConversionTicker?.mul(this.tickerFees.percent)
    );

    return this.getCalculatedFee(calculatedFee);
  }

  //#endregion fees

  //#region amount limits

  @computed
  public get minAmount() {
    invariant(
      this.tickerFees,
      'tickerFees should be defined',
      {},
      {tickerFees: this.tickerFees}
    );
    return this.tickerFees.minAmount;
  }

  @computed
  public get maxAmount() {
    invariant(
      this.tickerFees,
      'tickerFees should be defined',
      {},
      {tickerFees: this.tickerFees}
    );
    return this.tickerFees.maxAmount;
  }

  //#endregion amount limits

  //#region cards

  @computed
  public get cardsList() {
    return this.resources.cards.cardsList ?? [];
  }

  @computed
  public get isCardsListEmpty() {
    return !this.cardsList.length;
  }

  @computed
  public get selectedCardId() {
    return this.instance.$('cardId').value;
  }

  @computed
  public get isSubmitDisabled() {
    if (this.isCardsListEmpty) {
      // NOTE: if there are no cards, the submit button would lead to the Add Card screen and shouldn't be disabled
      return false;
    }

    return !this.isRiskJsScriptLoaded || !this.selectedCardId;
  }

  @computed
  public get isSubmitLoading() {
    return !this.isRiskJsScriptLoaded || this.isLoading || this.isValidating;
  }

  @action
  public setCardId = (value: string) => {
    if (this.isThereCard3dsError && value !== this.selectedCardId) {
      this.isThereCard3dsError = false;
    }

    this.instance.$('cardId').set('value', value.toString());
  };

  //#endregion cards

  //#region risk script

  @computed
  public get deviceSessionId() {
    return this.instance.$('deviceSessionId').get('value');
  }

  @action
  public setDeviceSessionId = (value: string) => {
    this.instance.$('deviceSessionId').set('value', value);
    this.finishRiskJsScriptLoading();
  };

  @action finishRiskJsScriptLoading = () => {
    this.isRiskJsScriptLoaded = true;
  };

  //#endregion risk script

  //#region twoFactorAuth

  @computed
  get twoFactorAuthType() {
    return this.resources.authMe.twoFactorAuthType;
  }

  @computed
  get twoFactorAuthOperationId() {
    return (
      this.instance?.$('twoFactorAuthOperationId').get('value') ?? undefined
    );
  }

  @computed
  get twoFactorAuthOperationCode() {
    return this.instance?.$('twoFactorAuthOperationCode').get('value') ?? '';
  }

  @computed
  get twoFactorAuthOperationCodeError() {
    return getTranslatedValidationMessage(
      this.instance.$('twoFactorAuthOperationId').get('error') ??
        this.instance.$('twoFactorAuthOperationCode').get('error')
    );
  }

  @action
  twoFactorAuthOperationIdOnChange = (value: string) => {
    this.instance.$('twoFactorAuthOperationId').set('value', value);
  };

  @action
  twoFactorAuthOperationCodeOnChange = (value: string) => {
    this.instance.$('twoFactorAuthOperationCode').set('value', value);
  };

  @action
  createTwoFactorAuthOperation = <
    T extends {operationId: string; phoneMask?: string}
  >(): Promise<void | T> => {
    return __GLOBAL_RECAPTCHA__
      .requestToken('card_withdrawal_tfa')
      .then(token => {
        return TRANSPORT.API.post('/v1/withdrawal/authorize', {
          operationId: this.twoFactorAuthOperationId,
          token,
        });
      })

      .then(({data}) => {
        runInAction(() => {
          this.twoFactorAuthOperationIdOnChange(data.operationId);
        });
        return data;
      })
      .catch(response => {
        handleGeneralErrorTranslated(response?.data);
      });
  };

  //#endregion twoFactorAuth

  @action
  dispose = () => {
    this.disposers.forEach(disposer => {
      disposer?.();
    });
  };
}
