import * as React from 'react';
import {Helmet} from 'react-helmet';
import {Button} from '@youtoken/ui.buttons';
import {Box, Text} from '@youtoken/ui.primitives';
import {LanguageSwitch} from '@youtoken/ui.language-switch';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {SignUpCorporateSurface} from '@youtoken/ui.auth-surfaces';
import {LedgerInfo} from '@youtoken/ui.elements';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';
import {AppShell, OuterPageShell} from '@web-app/src/components/layout/Shell';
import {Header} from '@web-app/src/components/layout/Shell/AppShell/Header';
import {Logo} from '@web-app/src/components/layout/Shell/AppShell/Header/elements';
import {AuthFormWrapper} from '@web-app/src/components/layout/Shell/AppShell/AuthFormWrapper';

export const SignUpCorporatePage: React.FC = () => {
  const {t} = useTranslation();

  const [country, setCountry] = React.useState<string | undefined>();

  const {queryParams} = RouterResource.use({});

  const renderHeader = React.useCallback(() => {
    return (
      <Header
        renderLeft={() => {
          return <Logo />;
        }}
        renderRight={() => {
          return (
            <Box flexDirection="row" alignItems="center" flexShrink={1}>
              <Text variant="$body-02" color="$text-02" mr={10}>
                {t('surface.auth.have_account')}
              </Text>
              <Box>
                <Button
                  size="small"
                  testID="SIGN_IN_LINK"
                  onPress={() => {
                    SHARED_ROUTER_SERVICE.navigate('SignIn', {}, queryParams);
                  }}
                  type="secondary"
                >
                  {t('surface.auth.actions.sign_in')}
                </Button>
              </Box>

              <LanguageSwitch
                variant="icon"
                size="small"
                width={96}
                dropdownWidth={116}
                boxProps={{ml: 16}}
                testID="LANGUAGE_LIST"
              />
            </Box>
          );
        }}
      />
    );
  }, [t, queryParams]);

  const renderFooter = React.useCallback(() => null, []);

  return (
    <AppShell
      country={country}
      renderHeader={renderHeader}
      renderFooter={renderFooter}
    >
      <OuterPageShell>
        <Helmet title={t('surface.sign_up.tab_title')} />
        <AuthFormWrapper>
          <SignUpCorporateSurface onChangeCountry={setCountry} />
          <LedgerInfo visible={{default: true, tablet: false}} mt={24} />
        </AuthFormWrapper>
      </OuterPageShell>
    </AppShell>
  );
};
