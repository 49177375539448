import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {WebPageWrapper} from '@youtoken/ui.screen-components';
import {i18n} from '@youtoken/ui.service-i18n';
import {MinerTitleWeb} from './TitleWeb';
import {MinerSurface} from './MinerSurface';

export const MinerSurfaceCell: React.FC = cell(
  () => {
    return <MinerSurface />;
  },
  {
    displayName: 'MinerSurface',
    CellWrap: ({children}) => (
      <WebPageWrapper
        windowTitle={i18n.t('surface.menu.cloud_miner')}
        title={<MinerTitleWeb />}
      >
        {children}
      </WebPageWrapper>
    ),
  }
);
