import React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {Slider as SliderComponent} from './SliderComponent';
import {SliderButton} from './SliderButton';
import {Platform} from 'react-native';

const isNative = Platform.select({default: true, web: false});
const defaultHeight = isNative ? 40 : 16;
const thumbSize = isNative ? 26 : 16;

export interface SliderButtonItem {
  label: string;
  value: number;
}

export interface SliderProps {
  value: number;
  minimumValue: number;
  maximumValue: number;
  label?: string;
  renderLabel?: ({value}: {value: number}) => React.ReactNode;
  showLegend?: boolean;
  legendPosition?: 'top' | 'bottom';
  buttons?: SliderButtonItem[];
  buttonsSize?: 'large' | 'medium' | 'small' | 'tiny';
  onChange?: (value: number) => void;
  key?: string;
  mode?: 'light' | 'middle';
  disabled?: boolean;
  step?: number;
  /**
   * maxSteps for the slider legend
   *
   * @default 50
   */
  maxLegendSteps?: number;
  testID?: string;
  shouldBeReversed?: boolean;
}

export const Slider: React.FC<SliderProps> = ({
  value,
  minimumValue,
  maximumValue,
  label,
  renderLabel,
  showLegend = true,
  legendPosition = 'bottom',
  buttons,
  buttonsSize,
  onChange,
  mode = 'light',
  disabled = false,
  step = 1,
  testID,
  shouldBeReversed,
  ...rest
}) => {
  const minValue = minimumValue ?? 0;
  const maxValue = maximumValue ?? 1 - minValue;
  // we need at least 2 points
  const showSlider = React.useMemo(
    () => (maxValue - minValue) / step > 1,
    [maxValue, minValue, step]
  );

  const [localValue, setLocalValue] = React.useState(value);

  React.useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <Box width="100%">
      {(label || renderLabel) && (
        <Box>
          {renderLabel ? (
            renderLabel({value: localValue})
          ) : (
            <Text variant="$body-01-high-accent">{label}</Text>
          )}
        </Box>
      )}
      {showSlider ? (
        <Box flexDirection="row" flexGrow={1} mt={8} testID={testID}>
          <SliderComponent
            style={{width: '100%', height: 16}}
            value={value}
            minimumValue={minValue}
            maximumValue={maxValue}
            showLegend={showLegend}
            legendPosition={legendPosition}
            onValueChange={setLocalValue}
            onSlidingComplete={onChange}
            disabled={disabled}
            step={step}
            shouldBeReversed={shouldBeReversed}
            {...rest}
          />
        </Box>
      ) : (
        <Box
          height={defaultHeight} // todo check Android slider dot height
          mt={8}
          flexDirection="row"
          alignItems="center"
          position="relative"
        >
          <Box width="100%" height={4} backgroundColor="$ui-02" />
          <Box
            position={'absolute'}
            left={0}
            top={(defaultHeight - thumbSize) / 2}
            width={thumbSize}
            height={thumbSize}
            borderRadius={thumbSize / 2}
            backgroundColor="$ui-02"
          />
        </Box>
      )}
      {buttons !== undefined && buttons?.length > 0 && (
        <Box flexDirection="row" mt={8}>
          {buttons.map((item, index) => (
            <SliderButton
              key={`${item.value}_${index}`}
              {...item}
              onPress={onChange}
              mr={index === buttons.length - 1 ? 0 : 8}
              type={item.value === localValue ? 'primary' : 'secondary'}
              size={buttonsSize}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
