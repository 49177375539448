import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {createWizard} from '@youtoken/ui.elements';
import {IVoletData} from './types';
import {getVoletWizardOptions} from './utils';
import {VoletCreatePayment} from './CreatePayment';
import {VoletPayment} from './Payment';

export enum VoletWizardScreenName {
  MAIN = 'main',
  PAYMENT = 'payment',
}

export interface VoletWizardNavigator {
  [VoletWizardScreenName.MAIN]: {
    ticker: string;
    amount?: string;
  };
  [VoletWizardScreenName.PAYMENT]: IVoletData;
}

const Wizard = createWizard<VoletWizardNavigator>();

export interface VoletWizardProps {
  ticker: string;
  amount?: string;
  onPressBack?: () => void;
}

export const VoletWizard: React.FC<VoletWizardProps> = cell(
  ({ticker, amount, onPressBack}) => {
    return (
      <Wizard.Navigator
        initialName={VoletWizardScreenName.MAIN}
        initialProps={{
          ticker,
          amount,
        }}
        goBack={onPressBack}
      >
        <Wizard.Screen
          name={VoletWizardScreenName.MAIN}
          component={VoletCreatePayment}
          options={getVoletWizardOptions}
        />
        <Wizard.Screen
          name={VoletWizardScreenName.PAYMENT}
          component={VoletPayment}
          options={getVoletWizardOptions}
        />
      </Wizard.Navigator>
    );
  }
);
