import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {RewardsHistoryFlatListSmart} from '../../smarts';

export const RewardsHistoryModal = observer(
  (modalProps: Omit<ModalDynamicProps, 'children'>) => {
    const {t} = useTranslation();

    return (
      <ModalDynamic
        title={t(
          'surface.referral_program.referrals.recent_bonuses_subheading'
        )}
        snapPoints={['90%']}
        nativeStackBehavior="push"
        {...modalProps}
      >
        <RewardsHistoryFlatListSmart />
      </ModalDynamic>
    );
  }
);
