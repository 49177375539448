import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Label, useForm} from '@youtoken/ui.form-elements';
import {
  Box,
  type BoxProps,
  Text,
  useIsSmallMobile,
} from '@youtoken/ui.primitives';
import {Switch} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import {ModalDynamic} from '@youtoken/ui.modal';
import {BoostYourTradingSurface} from '@youtoken/ui.surfaces-wallets/src/Incentives/BoostYourTradingSurface';
import {CreateHODLFormClass} from '@youtoken/ui.surface-hodl-create';
import {ExtendTpSlForm} from '../../ExtendTpSlBottomSheet/form';
import {UseBonusesContent} from './UseBonusesContent';

export const UseBonuses: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const isSmallMobile = useIsSmallMobile();

  const {
    form: {
      useBonusesActive,
      setUseBonusesActive,
      bonusBalanceFormatted,
      hasEnoughBonuses,
    },
  } = useForm<CreateHODLFormClass | ExtendTpSlForm>();

  const [isModalOpen, setModalOpen] = React.useState(false);

  const handleOpenModal = React.useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleCloseModal = React.useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <Box {...boxProps}>
      <Box flexDirection="row">
        <Box
          flexDirection="row"
          alignItems="center"
          flexGrow={1}
          flexShrink={1}
          mr={20}
        >
          <Icon name="first_trade" size={20} />
          <Label ml={8} mb={0}>
            {t('surface.hodls.item.use_bonuses_section.title')}
          </Label>
        </Box>
        <Box flexDirection="row" alignItems="center">
          <Box mr={16}>
            <Text variant="$body-01" color="$text-02">
              {t('surface.hodls.item.use_bonuses_section.balance', {
                balance: bonusBalanceFormatted,
              })}
            </Text>
          </Box>

          {hasEnoughBonuses ? (
            <Switch
              value={useBonusesActive}
              onValueChange={setUseBonusesActive}
              testID="USE_BONUSES_SWITCHER"
            />
          ) : (
            <>
              <Button
                type="secondary"
                size="small"
                icon="fiat_deposit"
                onPress={handleOpenModal}
              >
                {!isSmallMobile && t('surface.incentives.action.get_bonuses')}
              </Button>

              <ModalDynamic
                isOpen={isModalOpen}
                shouldBeScrollable
                shouldShowHeaderSeparator={false}
                onClose={handleCloseModal}
                nativeStackBehavior="push"
              >
                <BoostYourTradingSurface onClose={handleCloseModal} />
              </ModalDynamic>
            </>
          )}
        </Box>
      </Box>

      {useBonusesActive && <UseBonusesContent mt={20} />}
    </Box>
  );
});
