import {z} from 'zod';
import {serializable, list, object} from 'serializr';
import {bundleOperationSchema, BundleOperation} from '../BundleOperation';

export const bundleOperationsResourceResponseSchema = z.object({
  rows: z.array(bundleOperationSchema),
});

export class BundleOperationsResourceResponse {
  @serializable(list(object(BundleOperation)))
  rows!: BundleOperation[];
}
