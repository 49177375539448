import * as React from 'react';
import {PropsWithChildren} from 'react';
import {TwoFactorAuthVerification} from './TwoFactorAuthVerification';
import {TwoFactorAuthParams} from './types';
import {InvariantError} from '@youtoken/ui.errors';

export const __TWO_FACTOR_AUTH_REF__ =
  React.createRef<TwoFactorAuthVerification>();

export const __GLOBAL_TWO_FACTOR_AUTH__ = {
  requestTwoFactorAuth: (params: TwoFactorAuthParams): Promise<null> => {
    if (!__TWO_FACTOR_AUTH_REF__.current?.requestTwoFactorAuth) {
      return Promise.reject(
        new InvariantError(
          `TwoFactorAuth not initialized, method: {requestTwoFactorAuth}`
        )
      );
    }

    return __TWO_FACTOR_AUTH_REF__.current.requestTwoFactorAuth(params);
  },
};

export const TwoFactorAuthContext = React.createContext(
  __GLOBAL_TWO_FACTOR_AUTH__
);

export const TwoFactorAuthContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <TwoFactorAuthContext.Provider value={__GLOBAL_TWO_FACTOR_AUTH__}>
      <TwoFactorAuthVerification ref={__TWO_FACTOR_AUTH_REF__} />
      {children}
    </TwoFactorAuthContext.Provider>
  );
};

export const useTwoFactorAuth = () => {
  const context = React.useContext(TwoFactorAuthContext);

  if (!context) {
    throw new Error(
      'useTwoFactorAuth must be used within a TwoFactorAuthContextProvider'
    );
  }

  return context;
};
