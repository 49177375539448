import * as React from 'react';
import {Platform} from 'react-native';
import {KeyboardScrollView, useWizard} from '@youtoken/ui.elements';
import {FiatWithdrawal} from '@youtoken/ui.surface-bank-wire';
import {i18n} from '@youtoken/ui.service-i18n';
import {Box} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {useSafeArea} from '@youtoken/ui.hooks';
import {HeaderBase} from '../../../components';
import {
  type FiatWithdrawalWizardNavigator,
  FiatWithdrawalWizardScreenName,
} from '../index';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const getFiatWithdrawalBankWireTitle = (ticker: string) => {
  return i18n.t('surface.wallets.withdrawal_bank_wire_form.title', {
    ticker: ticker.toUpperCase(),
  });
};

export const FiatWithdrawalBankWire: React.FC = cell(
  () => {
    const {
      step: {
        props: {ticker},
      },
      goBack,
    } = useWizard<
      FiatWithdrawalWizardNavigator,
      FiatWithdrawalWizardScreenName.BANK_WIRE
    >();

    const {bottom} = useSafeArea();

    return (
      <>
        {showHeader && (
          <HeaderBase onPressBack={goBack}>
            {getFiatWithdrawalBankWireTitle(ticker)}
          </HeaderBase>
        )}

        <KeyboardScrollView
          insideTabs={false}
          scrollViewProps={{
            keyboardDismissMode: 'interactive',
            style: {flex: 1},
            contentContainerStyle: {
              paddingTop: 0,
              paddingBottom: bottom,
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
        >
          <FiatWithdrawal ticker={ticker} />
        </KeyboardScrollView>
      </>
    );
  },
  {
    CellWrap: ({children}) => <Box flex={1}>{children}</Box>,
  }
);
