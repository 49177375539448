import * as React from 'react';
import {Switch, Route, type RouteComponentProps} from 'react-router-dom';
import * as Linking from 'expo-linking';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {cell} from '@youtoken/ui.cell';
import {SwitchWithNotFound} from './SwitchWithNotFound';
import {
  SignUpPage,
  SignInPage,
  SignInConfirmPage,
  DeviceConfirmPage,
  PasswordRestoreRequestPage,
  NewPasswordPage,
  NewDeviceResultPage,
  VerifyEmailPage,
  ConsentFinalScreen,
  AppleAuthRedirectPage,
  SignUpCorporatePage,
} from '@web-app/src/components/pages';
import {StatusScreen} from '@youtoken/ui.surface-status-screen';
import {RootRedirect} from './RootRedirect';
import {alreadyAuthorized} from './alreadyAuthorized';
import {RouterResource} from './RouterResource';
import {AppRoutes} from './AppRoutes';
import {AppLedgerUnauthorizedRoutes} from './LedgerLiveRoutes/AppLedgerUnauthorizedRoutes';

const SignInWrapped = alreadyAuthorized(SignInPage);
const SignInConfirmWrapped = alreadyAuthorized(SignInConfirmPage);
const DeviceConfirmWrapped = alreadyAuthorized(DeviceConfirmPage);
const SignUpWrapped = alreadyAuthorized(SignUpPage);
const PasswordRestoreRequestWrapped = alreadyAuthorized(
  PasswordRestoreRequestPage
);
const NewPasswordWrapped = alreadyAuthorized(NewPasswordPage);
const VerifyEmailPageWrapped = alreadyAuthorized(VerifyEmailPage);
const SignUpCorporateWrapped = alreadyAuthorized(SignUpCorporatePage);

const RouteSyncer: React.FC<RouteComponentProps> = cell(props => {
  const routerResource = RouterResource.use({});
  routerResource.setRouterProps(props);

  return null;
});

const DeviceConfirmResultAllowed = alreadyAuthorized(() => (
  <NewDeviceResultPage status="allow" />
));
const DeviceConfirmResultRejected = alreadyAuthorized(() => (
  <NewDeviceResultPage status="decline" />
));
const DeviceConfirmResultError = alreadyAuthorized(() => (
  <NewDeviceResultPage status="error" />
));

const StatusScreenWithQuery = (
  props: React.ComponentProps<typeof StatusScreen>
) => {
  const {
    queryParams: {method},
  } = RouterResource.use({});

  return <StatusScreen {...props} method={method} />;
};

const StatusScreenSuccess = () => <StatusScreenWithQuery status="success" />;
const StatusScreenProcessing = () => (
  <StatusScreenWithQuery status="processing" />
);
const StatusScreenCanceled = () => <StatusScreenWithQuery status="canceled" />;
const StatusScreenFailed = () => <StatusScreenWithQuery status="failed" />;

export const useLinkIdTracker = () => {
  const url = Linking.useURL();

  React.useEffect(() => {
    if (url) {
      DATA_LAYER.trackUrl(url);
    }
  }, [url]);
};

const SwitchWithTrackingLinkId: React.FC = ({children}) => {
  useLinkIdTracker();

  return <Switch>{children}</Switch>;
};

/** Application routes */
const Routes: React.FC = () => {
  return (
    <>
      <Route path="*" component={RouteSyncer} />
      <SwitchWithNotFound>
        {/* Main route redirect */}
        <Route exact path="/" component={RootRedirect} />
        <SwitchWithTrackingLinkId>
          {/* Outer pages, for sign-in, restore password, etc */}
          <Route path="/welcome" component={AppLedgerUnauthorizedRoutes} />
          <Route exact path="/sign-in" component={SignInWrapped} />
          <Route
            exact
            path="/sign-in-confirm"
            component={SignInConfirmWrapped}
          />
          <Route
            exact
            path="/device-confirm"
            component={DeviceConfirmWrapped}
          />
          <Route
            exact
            path="/new-device-allowed"
            component={DeviceConfirmResultAllowed}
          />
          <Route
            exact
            path="/new-device-rejected"
            component={DeviceConfirmResultRejected}
          />
          <Route
            exact
            path="/new-device-error"
            component={DeviceConfirmResultError}
          />
          <Route exact path="/sign-up" component={SignUpWrapped} />
          <Route
            exact
            path="/sign-up/business"
            component={SignUpCorporateWrapped}
          />
          <Route
            exact
            path="/verify-email"
            component={VerifyEmailPageWrapped}
          />
          <Route
            exact
            path="/restore-password"
            component={PasswordRestoreRequestWrapped}
          />
          <Route exact path="/new-password" component={NewPasswordWrapped} />

          {/* Unlimint deposit status pages */}
          <Route exact path="/status/success" component={StatusScreenSuccess} />
          <Route exact path="/status/fail" component={StatusScreenFailed} />
          <Route exact path="/status/cancel" component={StatusScreenCanceled} />
          <Route
            exact
            path="/status/processing"
            component={StatusScreenProcessing}
          />

          {/* Intergiro status pages for bank cards */}
          <Route
            exact
            path="/wallets/cards/consent-final"
            component={ConsentFinalScreen}
          />

          {/* Redirect for Apple sign up */}
          <Route
            exact
            path="/apple/callback"
            component={AppleAuthRedirectPage}
          />

          {/* Actual application pages */}
          <Route path="*" component={AppRoutes} />
        </SwitchWithTrackingLinkId>
      </SwitchWithNotFound>
    </>
  );
};

export default Routes;
