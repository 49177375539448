import React from 'react';
import {ModalDynamic} from '@youtoken/ui.modal';
import {cell, DefaultSuspenseFallback} from '@youtoken/ui.cell';
import SumSubWebSdk from '@sumsub/websdk-react';
import {i18n} from '@youtoken/ui.service-i18n';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {SumSubResource} from '@youtoken/ui.resource-sumsub';
import {useActiveThemeType, useIsMobile, Box} from '@youtoken/ui.primitives';
import {SumSubVerificationProps} from '../types';
import {sumSubTrack} from '../sumSubTrack';
import type {TSumSubVerificationImperativeMethods} from '../../types';

// NOTE: SumSub widget issues:
// 1) Loading SumSub has fixed height
// 2) First screen country selector has incorrect height

const SumSubSurface = cell<SumSubVerificationProps>(({levelName}) => {
  const theme = useActiveThemeType();

  const {authMe, sumSub} = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
    sumSub: getResourceDescriptor(SumSubResource, {
      type: 'accessToken',
      levelName: levelName,
      lang: i18n.language,
    }),
  });

  // NOTE: Fix first SumSub issue
  const [initialized, setInitialized] = React.useState(false);

  const handleMessage = React.useCallback<typeof sumSubTrack>(
    (method, payload) => {
      sumSubTrack(method, payload);

      if (method === 'idCheck.onInitialized') {
        setInitialized(true);
      }
    },
    []
  );

  return (
    <>
      {!initialized && <DefaultSuspenseFallback />}
      <Box visible={initialized}>
        <SumSubWebSdk
          key={`${theme}-${i18n.language}`} // NOTE: just to rerender sumsub, they hardly cache their component
          accessToken={(sumSub.data as {token: string}).token}
          expirationHandler={() => {
            return sumSub.refetch().then(({token}) => {
              return token;
            });
          }}
          config={{
            theme,
            lang: i18n.language,
            // @ts-ignore undocumented, but exist in options need to clarify
            adaptIframeHeight: true,
            documentDefinition: {
              IDENTITY: {
                country: authMe.residenceOrCountry,
              },
              IDENTITY2: {
                country: authMe.residenceOrCountry,
              },
              IDENTITY3: {
                country: authMe.residenceOrCountry,
              },
              IDENTITY4: {
                country: authMe.residenceOrCountry,
              },
            },
          }}
          onMessage={handleMessage}
        />
      </Box>
    </>
  );
});

export const SumSubVerificationComponent = React.forwardRef<
  TSumSubVerificationImperativeMethods,
  {}
>((_props, ref) => {
  const isMobile = useIsMobile();

  const [levelName, setLevelName] = React.useState<string | null>(null);
  const onDismissRef = React.useRef<() => void>();

  React.useImperativeHandle(
    ref,
    () => ({
      launch: (levelName, onDismiss) => {
        setLevelName(levelName);
        onDismissRef.current = onDismiss;

        return Promise.resolve();
      },
    }),
    []
  );

  const onClose = React.useCallback(() => {
    setLevelName(null);
    onDismissRef.current?.();
  }, []);

  return (
    <ModalDynamic
      shouldBeScrollable
      closePosition="outside"
      webWidth={isMobile ? undefined : 512}
      isOpen={Boolean(levelName)}
      onClose={onClose}
      webModalZIndex={500}
    >
      {/* NOTE: Modal bug - each second close, call rerender child */}
      {levelName && <SumSubSurface levelName={levelName} />}
    </ModalDynamic>
  );
});
