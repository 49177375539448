import {Box} from '@youtoken/ui.primitives';
import * as React from 'react';
import {Content} from './Content';

export const BuyCryptoFormAuthorizedYouHodlerMain: React.FC = props => {
  return (
    <Box maxHeight="90vh">
      <Content {...props} />
    </Box>
  );
};
