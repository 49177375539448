import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useWizard} from '@youtoken/ui.elements';
import {Box} from '@youtoken/ui.primitives';
import {ManagePaymentMethodsSurface} from '../../../../../../CardPaymentMethods';
import type {BuyCryptoFormAuthorizedYouHodlerWizardNavigatorParams} from '../../YouHodler';
import {HeaderBase} from '../../../../../../components';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const CardDepositPaymentMethods: React.FC = observer(() => {
  const {t} = useTranslation();
  const {navigate, goBack} = useWizard<
    BuyCryptoFormAuthorizedYouHodlerWizardNavigatorParams,
    'Main'
  >();

  const handlePressAddCard = React.useCallback(() => {
    navigate('AddCard');
  }, [navigate]);

  return (
    <Box flex={1} maxHeight={720}>
      {showHeader && (
        <HeaderBase onPressBack={goBack} shouldShowSeparator>
          {t('surface.wallets.payment_methods')}
        </HeaderBase>
      )}
      <ManagePaymentMethodsSurface onAddCard={handlePressAddCard} />
    </Box>
  );
});
