import * as React from 'react';
import {
  SavingsOverviewResource,
  SavingsOverviewStatus,
} from '@youtoken/ui.resource-savings';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {cell, DefaultSuspenseFallback} from '@youtoken/ui.cell';
import {Box} from '@youtoken/ui.primitives';
import {WebPageWrapper} from '@youtoken/ui.screen-components';
import {getTitle} from './index.meta';
import {SavingsSelectedAssets} from '../../smarts';
import {SavingsAccountWelcome} from './Welcome';

const handlePressBack = () => {
  SHARED_ROUTER_SERVICE.navigate('WalletsList', {});
};

export const SavingsAccountSurface = cell(
  () => {
    const {
      data: {status},
    } = SavingsOverviewResource.use({});

    if (status === SavingsOverviewStatus.NEW) {
      return <SavingsAccountWelcome />;
    }

    if (status === SavingsOverviewStatus.WAITING) {
      return <DefaultSuspenseFallback />;
    }

    return <SavingsSelectedAssets />;
  },
  {
    displayName: 'SavingsAccountSurface',
    CellWrap: ({children}) => (
      <WebPageWrapper
        windowTitle={getTitle()}
        title={getTitle()}
        hasBackButton
        onBackPress={handlePressBack}
      >
        <Box>{children}</Box>
      </WebPageWrapper>
    ),
  }
) as React.FC & {getTitle: () => string};

SavingsAccountSurface.getTitle = getTitle;
