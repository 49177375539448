import * as React from 'react';
import {useParams} from 'react-router-dom';
import {
  HODLByIdFeature,
  HODLPageItemSurface,
} from '@youtoken/ui.surface-hodl-positions';
import {BorderBoxWrapper, WebPageWrapper} from '@youtoken/ui.screen-components';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {cell} from '@youtoken/ui.cell';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';
import {modal} from '../../../../../stores';

export const HODLPageComponent: React.FC = cell(
  () => {
    const {id} = useParams<{id: string}>();
    const {
      queryParams: {isRedirectFromFlipOrder},
    } = RouterResource.use({});

    const {hodlPageTitle, isExtendTpSlAvailable, isFlipDirectionAvailable} =
      HODLByIdFeature.use({
        id,
      });

    const handleClickCloseNow = React.useCallback(() => {
      modal.open('hodlClose', {id});
    }, [modal.open, id]);

    const handleClickAdjustHODL = React.useCallback(() => {
      modal.open('hodlAdjustTpSl', {id});
    }, [modal.open, id]);

    const handleClickExtendHODL = React.useCallback(() => {
      if (isExtendTpSlAvailable) {
        SHARED_ROUTER_SERVICE.navigate('HodlExtendTpSl', {
          id,
        });
      }
    }, [id, isExtendTpSlAvailable]);

    const handleClickFlipHODL = React.useCallback(() => {
      if (isFlipDirectionAvailable) {
        SHARED_ROUTER_SERVICE.navigate('HodlFlip', {
          id,
        });
      }
    }, [id, isFlipDirectionAvailable]);

    return (
      <WebPageWrapper
        windowTitle={hodlPageTitle}
        title={hodlPageTitle}
        hasBackButton
        onBackPress={() => SHARED_ROUTER_SERVICE.goBack()}
      >
        <BorderBoxWrapper flex={1}>
          <HODLPageItemSurface
            id={id}
            isRedirectFromFlipOrder={isRedirectFromFlipOrder}
            onClickCloseNow={handleClickCloseNow}
            onClickAdjustHODL={handleClickAdjustHODL}
            onClickExtendHODL={handleClickExtendHODL}
            onClickFlipHODL={handleClickFlipHODL}
          />
        </BorderBoxWrapper>
      </WebPageWrapper>
    );
  },
  {
    displayName: 'HODLPageComponent',
  }
);
