import * as React from 'react';
import {observer} from 'mobx-react';
import {ClosedHODLsPaginationAndFilterFeature} from '@youtoken/ui.surface-hodl-positions';
import {Filters, type ApplyFiltersProp} from '@youtoken/ui.filters';
import {type BoxProps} from '@youtoken/ui.primitives';

type ClosedFilterProps = {
  handleApplyFilters: ApplyFiltersProp;
};

export const ClosedFilter: React.FC<ClosedFilterProps & BoxProps> = observer(
  ({handleApplyFilters, ...boxProps}) => {
    const {selectedTickers, closedHodlsTickerFilters} =
      ClosedHODLsPaginationAndFilterFeature.use({});

    return (
      <Filters
        tickers={closedHodlsTickerFilters}
        selectedTickers={selectedTickers}
        applyFilters={handleApplyFilters}
        showTitles={false}
        scrollOffset={20}
        getTickerFormatted={ticker => ticker.toUpperCase()}
        testIdBase="HODLS_CLOSED"
        {...boxProps}
      />
    );
  }
);
