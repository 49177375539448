import {Platform} from 'react-native';
import * as React from 'react';
import {observer} from 'mobx-react';
import {KeyboardScrollView, useWizard} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box} from '@youtoken/ui.primitives';
import {AddPaymentMethodSurface} from '../../../CardPaymentMethods';
import {HeaderBase} from '../../../components';
import {
  CardWithdrawalWizardNavigator,
  CardWithdrawalWizardScreenName,
} from '../index';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const CardWithdrawalAddCard: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    goBack,
    step: {
      name,
      props: {onSubmit, ticker},
    },
  } = useWizard<
    CardWithdrawalWizardNavigator,
    CardWithdrawalWizardScreenName.ADD_CARD_WITH_PAYMENT
  >();

  const handleSubmit = React.useCallback(
    (cardId: string) => {
      goBack();
      onSubmit?.(cardId);
    },
    [onSubmit, goBack]
  );

  return (
    <Box flex={1}>
      {showHeader && (
        <HeaderBase onPressBack={goBack} shouldShowSeparator>
          {name === 'addCardWithPayment'
            ? t('surface.wallets.fiat_withdrawal_card.title', {
                ticker: ticker.toUpperCase(),
              })
            : t('surface.wallets.add_card')}
        </HeaderBase>
      )}
      <KeyboardScrollView
        insideTabs={false}
        wrapInSafeArea
        scrollViewProps={{
          keyboardDismissMode: 'interactive',
          style: {flex: 1},
          contentContainerStyle: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
      >
        <AddPaymentMethodSurface
          onPressCancel={goBack}
          onSubmit={handleSubmit}
          ticker={ticker}
          method="withdrawal"
        />
      </KeyboardScrollView>
    </Box>
  );
});
