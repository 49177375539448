import {z} from 'zod';
import {Big} from 'big.js';
import {serializable, primitive, object, createSimpleSchema} from 'serializr';
import {computed} from 'mobx';
import {bigNumber, number} from '@youtoken/ui.utils-serializr';
import {formatByTicker, formatPercent} from '@youtoken/ui.formatting-utils';

const bundlePortfolioTickersItemSchema = z.object({
  ticker: z.string(),
  amount: z.string(),
});

export const bundlePortfolioSchema = z.object({
  id: z.string(),
  quoteTicker: z.string(),
  quoteAmount: z.string(),
  difference: z.string(),
  tickers: z.array(bundlePortfolioTickersItemSchema),
});

export class BundlePortfolioTickersItem {
  @serializable(primitive())
  ticker!: string;

  @serializable(number())
  percent!: number;
}

export class BundlePortfolio {
  @serializable(primitive())
  id!: string;

  @serializable(primitive())
  quoteTicker!: string;

  @computed
  get quoteTickerFormatted() {
    return this.quoteTicker.toUpperCase();
  }

  @serializable(bigNumber())
  quoteAmount!: Big;

  @computed
  get quoteAmountFormatted() {
    return formatByTicker(this.quoteAmount, this.quoteTicker);
  }

  @serializable(bigNumber())
  difference!: Big;

  @computed
  get differenceFormatted() {
    return formatPercent(this.difference) + '%';
  }

  @serializable(
    object(
      createSimpleSchema({
        '*': object(BundlePortfolioTickersItem),
      })
    )
  )
  tickers!: Record<string, BundlePortfolioTickersItem>;
}
