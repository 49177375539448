import {ISection} from '../types';

export const getSectionColor = (section: ISection, highlight?: boolean) => {
  if (section === 'hidden') {
    return '$ui-01';
  }

  if (highlight) {
    return '$interactive-03';
  }

  return '$ui-background';
};
