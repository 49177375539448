import {observable, action} from 'mobx';
import {type SignUpCorporateFormResources} from './types';
import {Form} from './Form';

export class SignUpCorporateForm {
  @observable
  public form: Form;

  @action
  public submit = () => {
    return this.form.instance.submit();
  };

  constructor(args: {}, resources: SignUpCorporateFormResources) {
    this.form = new Form(args, resources);
  }
}
