import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {InviteFriendsModalContent} from './InviteFriendsModalContent';

export const InviteFriendsModal = observer(
  ({isOpen, onClose}: Omit<ModalDynamicProps, 'children'>) => {
    const {t} = useTranslation();

    return (
      <ModalDynamic
        title={t('surface.referral_program.how_it_works.invite_friends.title')}
        shouldShowHeaderSeparator={false}
        webMinHeight={100}
        isOpen={isOpen}
        onClose={onClose}
      >
        <InviteFriendsModalContent
          px={24}
          pb={Platform.select({default: 0, web: 24})}
        />
      </ModalDynamic>
    );
  }
);
