import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, TouchableBox, type BoxProps} from '@youtoken/ui.primitives';
import {LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {CoinsPair} from '@youtoken/ui.elements';
import {HistoryItemData} from '@youtoken/ui.resource-history-new';
import {StatusBadge} from '../StatusBadge';
import {isStatusFailed} from '../utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {HistoryItemModal} from '../HistoryItemModal';

type Props = {
  item: HistoryItemData;
  index?: number;
} & BoxProps;

const ICON_SIZE = 24;

export const HistoryItem: React.FC<Props> = observer(
  ({item, index, ...props}) => {
    const {
      id,
      typeSign,
      typeLabel,
      ticker,
      tickerFormatted,
      amountStringFormatted,
      conversionTicker,
      conversionTickerFormatted,
      conversionAmountStringFormatted,
      status,
      statusLabel,
      isConvert,
      badgeVariant,
      iconName,
    } = item;

    const {t} = useTranslation();

    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const handleItemClick = React.useCallback(() => {
      // this is a hack to get a simple bottom sheet here on native (modal on web)
      // and preserve url-based navigation in ramp
      if (ENVIRONMENT.WEB_APP_ENV !== WebAppEnv['ramp-app']) {
        setIsModalOpen(true);
      } else {
        SHARED_ROUTER_SERVICE.navigate('HistoryItemNew', {id});
      }
    }, [ENVIRONMENT.WEB_APP_ENV, id]);

    const closeModal = () => {
      setIsModalOpen(false);
    };

    const handleRepeatConvertPress = () => {
      SHARED_ROUTER_SERVICE.navigate('Exchange', {
        fromTicker: ticker,
        toTicker: conversionTicker,
        initialAmount: amountStringFormatted,
        dl_source: 'repeat-from-history',
      });
    };

    return (
      <>
        <TouchableBox
          width="100%"
          backgroundColor="$ui-background"
          onPress={handleItemClick}
          flexDirection="row"
          alignItems="center"
          px={24}
          height={72}
          testID={`HISTORY_ITEM_${index}`}
          {...props}
        >
          <Box>
            {isConvert ? (
              <CoinsPair
                firstTicker={ticker as LogoColoredIconName}
                secondTicker={conversionTicker as LogoColoredIconName}
                iconSize={ICON_SIZE}
                iconOffset={16}
              />
            ) : (
              <LogoColored size={ICON_SIZE} name={iconName} />
            )}
          </Box>

          <Box flex={1} alignItems="flex-start" ml={8}>
            <Text variant="$body-01-high-accent" color="$text-01">
              {typeLabel}
            </Text>
            {badgeVariant && (
              <StatusBadge
                badgeVariant={badgeVariant}
                statusLabel={statusLabel}
                mt={2}
              />
            )}
          </Box>

          <Box flexDirection="column" alignItems="flex-end" ml={8}>
            <Box flexDirection="row">
              <Text
                variant={
                  isConvert ? '$body-02-high-accent' : '$body-01-high-accent'
                }
                color={isStatusFailed(status) ? '$danger-01' : '$text-01'}
              >
                {`${typeSign}${amountStringFormatted}`}
              </Text>
              <Text
                variant={isConvert ? '$body-02' : '$body-01'}
                color="$text-02"
              >
                {' '}
                {tickerFormatted}
              </Text>
            </Box>

            {isConvert && (
              <Box flexDirection="row" mt={2}>
                <Text
                  variant="$body-02-high-accent"
                  color={isStatusFailed(status) ? '$danger-01' : '$text-01'}
                >
                  {`+${conversionAmountStringFormatted}`}
                </Text>
                <Text variant="$body-02" color="$text-02">
                  {' '}
                  {conversionTickerFormatted}
                </Text>
              </Box>
            )}
          </Box>

          {isConvert && (
            <Button
              type="secondary"
              onPress={handleRepeatConvertPress}
              size="small"
              ml={16}
              pr={12}
              pl={12}
            >
              {t('surface.history.item.repeat')}
            </Button>
          )}
        </TouchableBox>
        {isModalOpen && (
          <HistoryItemModal isOpen={isModalOpen} onClose={closeModal} id={id} />
        )}
      </>
    );
  }
);
