import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {useTranslation, Trans} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {YouhodlerInfo} from '../../DisclosureSurface/components';
import {DisclosureIbans} from './DisclosureIbans';
import {DisclosureConversions} from './DisclosureConversions';
import {DisclosureItaly} from './DisclosureItaly';
import {DisclosureIntergiro} from './DisclosureIntergiro';
import {AcknowledgeOfRisk} from './AcknowledgeOfRisk';

export const Content: React.FC<BoxProps> = observer(({...props}) => {
  const {t} = useTranslation();

  const {residenceOrCountry} = AuthMeResource.use({});
  const {
    documents: {youhodlerDocumentsFull},
  } = DocsResource.use({country: residenceOrCountry});

  return (
    <Box {...props}>
      <Box flexDirection="row" mb={24}>
        <Trans
          t={t}
          i18nKey="surface.more.legal.title"
          style={{
            flexWrap: 'wrap',
          }}
          parent={Text}
          components={{
            BoldText: (
              <Text
                variant="$body-02-high-accent"
                color="$text-01"
                textTransform="uppercase"
                mb={2}
              />
            ),
            SmallText: <Text variant="$body-02" color="$text-02" />,
          }}
          defaults="<BoldText>YOUHODLER BRAND</BoldText> <SmallText>- services are segregated by products</SmallText>"
        />
      </Box>

      <Box mb={16}>
        <AcknowledgeOfRisk />
      </Box>

      <Box mb={24}>
        <DisclosureConversions />
        <YouhodlerInfo country={residenceOrCountry} mb={12} />

        <Text variant="$body-02" textTransform="uppercase" mb={12}>
          {t('surface.profile.legal_info.subtitle.documents')}
        </Text>

        {Boolean(youhodlerDocumentsFull) &&
          youhodlerDocumentsFull.map(item => {
            const itemUrl = item.i18nLink
              ? // @ts-ignore
                t(item.i18nLink, {defaultValue: item.link})
              : item.link;
            const itemName = item.i18nName
              ? // @ts-ignore
                t(item.i18nName, {defaultValue: item.name})
              : item.name;

            return (
              <Box flexDirection="row" mb={8} key={item.link}>
                <Link
                  underlined
                  url={itemUrl}
                  variant="$body-02"
                  color="$text-02"
                >
                  {itemName}
                </Link>
              </Box>
            );
          })}
      </Box>
      <DisclosureItaly mb={24} />

      <DisclosureIbans mb={24} />
      <DisclosureIntergiro />
    </Box>
  );
});
