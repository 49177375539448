import * as React from 'react';
import {Platform} from 'react-native';
import {Box, BoxProps, Heading, Text} from '@youtoken/ui.primitives';
import {Badge, DeviceImage} from '@youtoken/ui.elements';
import {Icon, type DeviceIconName} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {observer} from 'mobx-react';
import {SecurityDeviceData} from '@youtoken/ui.state-security';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {ScreenOptions} from '@youtoken/ui.screen-components';

export interface DeviceModalProps extends BoxProps {
  item: SecurityDeviceData;
  handleRemoveDevice: (id: string) => Promise<any>;
}

export const DeviceModal: React.FC<DeviceModalProps> = observer(
  ({item, handleRemoveDevice}) => {
    const {t} = useTranslation();

    const {
      isCurrent,
      id,
      displayDeviceIcon,
      displayDeviceLogo,
      displayLastUsedRelativeTime,
      displayLocationWithIp,
      displayName,
      displayDeviceModel,
    } = item;

    const [isBusy, setIsBusy] = React.useState(false);

    const onRemoveDevice = React.useCallback(() => {
      setIsBusy(true);
      handleRemoveDevice(id).finally(() => {
        setIsBusy(false);
        SHARED_ROUTER_SERVICE.navigate('__CloseModal');
      });
    }, [id, handleRemoveDevice]);

    return (
      <Box
        alignItems="center"
        px="$screen-default-offset"
        flex={1}
        justifyContent="space-between"
        pt={16}
        pb={Platform.select({default: 0, web: 24})}
      >
        <Box flex={1} alignItems="center" gap={8}>
          {Platform.OS === 'web' ? (
            <Heading variant="$heading-02-responsive" textAlign="center">
              {displayName}
            </Heading>
          ) : (
            <ScreenOptions title={displayName} />
          )}

          <Box mt={16}>
            <DeviceImage
              deviceIcon={displayDeviceIcon as DeviceIconName}
              deviceLogo={displayDeviceLogo as any}
            />
          </Box>
          <Box flexDirection="row">
            <Icon name="location" size={16} color="$text-02" />
            <Text
              variant="$body-01"
              color="$text-02"
              ml={2}
              testID="LOCATION_WITH_IP"
            >
              {displayLocationWithIp}
            </Text>
          </Box>

          <Text variant="$body-01" color="$text-02" testID="DEVICE_MODEL">
            {displayDeviceModel}
          </Text>

          <Box mt={8}>
            {isCurrent && (
              <Badge
                variant="success"
                size="small"
                type="secondary"
                icon="check"
                testID="DEVICE_CURRENT"
              >
                {t('surface.trusted_devices.devices.active_badge')}
              </Badge>
            )}
            {!isCurrent && (
              <Text
                variant="$body-01"
                color="$text-02"
                testID="DEVICE_LAST_USED"
              >
                {t('modal.trusted_device.activity')}:{' '}
                {displayLastUsedRelativeTime}
              </Text>
            )}
          </Box>
        </Box>
        <Box width="100%" gap={16} mt={16}>
          <Text variant="$body-01" textAlign="center">
            {t('modal.trusted_device.description')}
          </Text>
          <Button
            size="large"
            onPress={onRemoveDevice}
            loading={isBusy}
            testID="REMOVE_DEVICE_BUTTON"
          >
            {t('modal.trusted_device.action_text')}
          </Button>
        </Box>
      </Box>
    );
  }
);
