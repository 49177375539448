import React from 'react';
import {ModalDynamic} from '@youtoken/ui.modal';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {TwoFactorEnableGaSurface} from '@youtoken/ui.surface-more';

export const ModalTwoFactorAuthenticationGAEnable: typeof ModalDynamic =
  props => {
    const {t} = useTranslation();

    return (
      <ModalDynamic
        title={t('surface.more.screen_title.2fa_enable')}
        {...props}
      >
        <TwoFactorEnableGaSurface />
      </ModalDynamic>
    );
  };
