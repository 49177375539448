import * as React from 'react';
import {observer} from 'mobx-react';
import {RatesListFeature} from '@youtoken/ui.legacy-components';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Item} from './Item';

export const ShownRates: React.FC<BoxProps> = observer(props => {
  const feature = RatesListFeature.use({});

  return (
    <Box mb={10} gap={8} {...props}>
      {feature.list.map(item => {
        return <Item key={item.ticker} ticker={item.ticker} />;
      })}
    </Box>
  );
});
