import * as React from 'react';
import {observer} from 'mobx-react';
import {SubmitButton, useForm} from '@youtoken/ui.form-elements';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useSafeArea} from '@youtoken/ui.hooks';
import {
  ConversionFormClass,
  ConversionUnauthorizedFormClass,
} from '../../state';
import {ExchangeFormAgreeToTerms} from '../AgreeToTerms';
import {type ExchangeFormFooterProps} from './types';

export const ExchangeFormFooterV1: React.FC<
  ExchangeFormFooterProps & BoxProps
> = observer(({showAgreements = true, ...boxProps}) => {
  const {t} = useTranslation();

  const {bottom} = useSafeArea();

  const {
    view: {conversionTickerFormatted},
  } = useForm<ConversionFormClass | ConversionUnauthorizedFormClass>();

  return (
    <Box
      p={24}
      pb={bottom}
      borderTopWidth={1}
      borderColor="$ui-01"
      {...boxProps}
    >
      {showAgreements && <ExchangeFormAgreeToTerms />}
      <SubmitButton testID="CONVERSION_SUBMIT_BUTTON">
        {t('surface.wallets.conversion_form.submit', {
          amount: conversionTickerFormatted,
        })}
      </SubmitButton>
    </Box>
  );
});
