import {action, computed} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {
  AddressStatus,
  AuthMeResource,
  KYCStatus,
} from '@youtoken/ui.resource-auth-me';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {FeeAllResource} from '@youtoken/ui.resource-fee-all';
import {formatPercent} from '@youtoken/ui.formatting-utils';
import {
  checkAccountVerifications,
  VerificationItemAccountCode,
  VerificationResource,
} from '@youtoken/ui.resource-verification';
import {i18n} from '@youtoken/ui.service-i18n';
import {FiatWithdrawalWizardScreenName} from '../../../../../smart';
import {getFeeString} from '../../../utils';

interface WithdrawalMethodsFeatureArgs {
  ticker: string;
}

export class WithdrawalMethodsFeature extends createFeature({
  getKey: (args: WithdrawalMethodsFeatureArgs) =>
    `withdrawalMethods(${JSON.stringify(args)})`,
  getResources: () => {
    return {
      authMe: getResourceDescriptor(AuthMeResource, {}),
      fees: getResourceDescriptor(FeeAllResource, {}),
      verification: getResourceDescriptor(VerificationResource, {}),
    };
  },
}) {
  @computed
  get ticker() {
    return this.args.ticker;
  }

  @computed
  get bankCardFeeFormatted() {
    const fees = this.resources.fees.getFeeForWithdraw(
      this.ticker,
      'checkoutBankCard'
    );

    if (!fees) {
      return '';
    }

    return getFeeString([fees], this.ticker.toUpperCase(), i18n.t);
  }

  @computed
  get umaFeeFormatted() {
    const umaWithdrawalFee = this.resources.fees.getFeeForWithdraw(
      this.ticker,
      'uma'
    );

    if (!umaWithdrawalFee) {
      return null;
    }

    return i18n.t('surface.wallets.fiat_deposit.item_fee_percent', {
      feePercent: formatPercent(umaWithdrawalFee!.percent),
    });
  }

  @computed
  get bankWireFeeFormatted() {
    const fees = this.resources.fees.data.filter(
      fee =>
        fee.ticker === this.ticker &&
        fee.method === 'withdraw' &&
        ['swift', 'sepa', 'wire'].includes(fee.provider)
    );

    return getFeeString(fees, this.ticker.toUpperCase(), i18n.t);
  }

  @computed
  get shouldForceAllowWithdrawals() {
    const {residenceOrCountry, kycResult, addressResult} =
      this.resources.authMe;
    return (
      residenceOrCountry === 'CAN' &&
      (kycResult === KYCStatus.KYC_RESTRICTION ||
        addressResult === AddressStatus.KYC_RESTRICTION)
    );
  }

  @computed
  get generalWithdrawalEnabled() {
    return this.resources.authMe.products.withdrawalFiat.isEnabled;
  }

  @computed
  get allowCreditCard() {
    // For now, it's only for EUR, but it can be extended to other currencies
    return (
      ['eur'].includes(this.ticker) &&
      this.resources.authMe.products.withdrawalFiat.settings.enabledBankCard &&
      this.generalWithdrawalEnabled
    );
  }

  @computed
  get allowBankWire() {
    return this.generalWithdrawalEnabled;
  }

  @computed
  get allowUma() {
    const {
      available,
      settings: {enable, enableWithdrawal},
    } = this.resources.authMe.products.uma;

    return available && enable && enableWithdrawal;
  }

  @computed
  get methodsList(): {
    method: FiatWithdrawalWizardScreenName;
    disabled?: boolean;
  }[] {
    const list: {
      method: FiatWithdrawalWizardScreenName;
      disabled?: boolean;
    }[] = [];

    if (this.allowBankWire) {
      list.push({
        method: FiatWithdrawalWizardScreenName.BANK_WIRE,
      });
    }

    if (this.allowCreditCard) {
      list.push({
        method: FiatWithdrawalWizardScreenName.BANK_CARD,
      });
    }

    if (this.allowUma) {
      list.push({
        method: FiatWithdrawalWizardScreenName.UMA_WITHDRAWAL,
      });
    }

    list.sort((a, b) => {
      if (a.disabled && !b.disabled) {
        return 1;
      } else if (!a.disabled && b.disabled) {
        return -1;
      }
      return 0;
    });

    return list;
  }

  @action
  public createHandleMethodPress =
    (
      method: FiatWithdrawalWizardScreenName,
      onChange: (method: FiatWithdrawalWizardScreenName) => void
    ) =>
    () => {
      if (this.shouldForceAllowWithdrawals) {
        onChange(method);
      } else if (
        checkAccountVerifications(this.resources.verification, [
          VerificationItemAccountCode.ADDRESS,
        ])
      ) {
        if (!this.resources.authMe.walletsDisclosureSigned) {
          SHARED_ROUTER_SERVICE.navigate('DisclosureWalletsModal', {});
        } else {
          onChange(method);
        }
      }
    };
}
