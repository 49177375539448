const getPortfolioItemKey = (item: any) => {
  if (item?.tariff) {
    return item.tariff.id;
  }

  return item?.ticker;
};

export const keyExtractor = (item: any) => {
  return `${item.type}-${item.data?.section}-${getPortfolioItemKey(
    item.data?.item
  )}`;
};
