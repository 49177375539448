import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {WebPageWrapper} from '@youtoken/ui.screen-components';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  PositionsClosedSurface,
  type PositionsClosedProps,
} from './PositionsClosedSurface';
import {getTitle} from './index.meta';

export const PositionsClosedSurfaceCell = cell(PositionsClosedSurface, {
  displayName: 'PositionsClosedSurface',
  CellWrap: ({children}) => (
    <WebPageWrapper
      windowTitle={i18n.t('surface.hodls.portfolio.closed_hodls.title')}
      title={getTitle()}
      hasBackButton
      onBackPress={() => SHARED_ROUTER_SERVICE.navigate('MultiHODLPortfolio')}
    >
      {children}
    </WebPageWrapper>
  ),
}) as React.FC<PositionsClosedProps> & {getTitle: () => React.ReactNode};
