import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Text} from '@youtoken/ui.primitives';
import {Banner} from '@youtoken/ui.elements';
import {
  FeatureCryptoDepositExternal,
  type FeatureCryptoDepositExternalArgs,
} from '../state';

export const CryptoDepositExternalExtraIdAlert: React.FC<FeatureCryptoDepositExternalArgs> =
  observer(({ticker}) => {
    const {t} = useTranslation();

    const {walletVersionExtraId, walletVersionExtraIdName} =
      FeatureCryptoDepositExternal.use({
        ticker,
      });

    if (!walletVersionExtraId) {
      return null;
    }

    return (
      <Banner variant="danger" borderRadius={8} mt={24}>
        <Trans
          t={t}
          components={{
            Strong: <Text variant="$body-01-high-accent" />,
          }}
          values={{
            name: t(
              `surface.wallets.crypto_deposit.${walletVersionExtraIdName}`
            ),
          }}
          i18nKey="surface.wallets.crypto_deposit.extra_id_alert"
        />
      </Banner>
    );
  });
