import * as React from 'react';
import {Text, Box, TouchableText} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export const NoDataState: React.FC<{refetch?: () => any}> = ({refetch}) => {
  return (
    <Box
      backgroundColor="$ui-background"
      flexGrow={1}
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Icon name="refresh" size={32} color="$text-01" />

      <Text color="$text-01" variant="$body-01" mt={4}>
        Chart data unavailable
      </Text>

      <Text color="$text-02" variant="$body-02" mt={4}>
        Please try again later
      </Text>

      <Box>
        <TouchableText
          mt={12}
          color="$interactive-01"
          variant="$body-02-medium-accent"
          onPress={() => {
            refetch?.();
          }}
        >
          Retry
        </TouchableText>
      </Box>
    </Box>
  );
};
