import * as React from 'react';
import {KeyboardScrollView, useWizard} from '@youtoken/ui.elements';
import type {BoxProps} from '@youtoken/ui.primitives';
import {type VoletWizardNavigator, VoletWizardScreenName} from '..';
import {VoletCreatePaymentForm} from './Form';
import type {IVoletData} from '../types';

export const VoletCreatePayment: React.FC<BoxProps> = () => {
  const {
    step: {
      props: {ticker, amount},
    },
    navigate,
    goBack,
  } = useWizard<VoletWizardNavigator, VoletWizardScreenName.MAIN>();

  const handleSubmit = React.useCallback(
    (data: IVoletData) => {
      navigate(VoletWizardScreenName.PAYMENT, data);
    },
    [navigate]
  );

  return (
    <KeyboardScrollView
      insideTabs={false}
      wrapInSafeArea
      scrollViewProps={{
        keyboardDismissMode: 'interactive',
        style: {flex: 1},
        contentContainerStyle: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        },
      }}
    >
      <VoletCreatePaymentForm
        ticker={ticker}
        amount={amount}
        onSubmit={handleSubmit}
        onPressBack={goBack}
      />
    </KeyboardScrollView>
  );
};
