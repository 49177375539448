import {IReactionDisposer, reaction} from 'mobx';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  BundleTariffListResourceResponse,
  bundleTariffListResourceResponseSchema,
} from './BundleTariffListResourceResponse';

export interface BundleTariffListResourceArgs {}

export class BundleTariffListResource extends createResource<
  BundleTariffListResourceArgs,
  BundleTariffListResourceResponse
>({
  getKey: (_args: BundleTariffListResourceArgs) => {
    return 'BundleTariffList';
  },
  getData: (_args: BundleTariffListResourceArgs) => {
    const quoteTicker = AuthMeResource.getInstanceSafely({})?.mainCurrency;

    return TRANSPORT.API.get<typeof bundleTariffListResourceResponseSchema>(
      `/v1/bundle/tariff/list?quoteTicker=${quoteTicker}`
    ).then(response => {
      bundleTariffListResourceResponseSchema.parse(response.data);

      return deserialize(BundleTariffListResourceResponse, response.data);
    });
  },
  skipRefreshOnVisible: false,
}) {
  disposers: Array<IReactionDisposer> = [];

  constructor(
    args: BundleTariffListResourceArgs,
    response: BundleTariffListResourceResponse
  ) {
    super(args, response);

    this.disposers.push(
      reaction(
        () => AuthMeResource.getInstanceSafely({})?.mainCurrency,
        () => {
          this.refetch();
        }
      )
    );
  }

  onDestroy(): void {
    super.onDestroy();
    this.disposers.forEach(disposer => disposer?.());
  }
}
