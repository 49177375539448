import * as React from 'react';
import {observer} from 'mobx-react';
import type {BoxProps} from '@youtoken/ui.primitives';
import {
  VerificationItemStatus,
  VerificationItemAccountCode,
  VerificationResource,
} from '@youtoken/ui.resource-verification';
import {StepItem} from '@youtoken/ui.elements';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {useVerificationItem} from './hooks';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';

export type VerificationItemProps = BoxProps & {
  code: VerificationItemAccountCode;
};

export const VerificationItem: React.FC<VerificationItemProps> = observer(
  ({code, ...boxProps}) => {
    const {
      me: {
        data: {isBusiness},
      },
      verification: {
        accountVerificationCurrent,
        getVerificationStatusByCode,
        data: {
          data: {isFileDownloadable},
        },
      },
    } = useResources({
      me: getResourceDescriptor(AuthMeResource, {}),
      verification: getResourceDescriptor(VerificationResource, {}),
    });

    const status = getVerificationStatusByCode(code);
    const isCurrent = accountVerificationCurrent === code;

    const {
      backgroundColor,
      iconColor,
      textColor,
      descriptionColor,
      icon,
      title,
      description,
      actionIcon,
      linkUp,
      linkDown,
      onPress,
    } = useVerificationItem({
      status,
      code,
      isCurrent,
      isFileDownloadable,
      isBusiness,
    });

    if (status === VerificationItemStatus.SKIP) {
      return null;
    }

    return (
      <StepItem
        // @ts-ignore smth with color typings
        backgroundColor={backgroundColor}
        iconColor={iconColor}
        textColor={textColor}
        descriptionColor={descriptionColor}
        icon={icon}
        title={title}
        description={description}
        actionIcon={actionIcon}
        linkUp={linkUp}
        linkDown={linkDown}
        onPress={onPress}
        {...boxProps}
      />
    );
  }
);
