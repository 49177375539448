import * as React from 'react';
import {observer} from 'mobx-react';
import {CoindropSurface} from '@youtoken/ui.surfaces-wallets';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ModalDynamic} from '@youtoken/ui.modal';

export const ModalCoindrop: typeof ModalDynamic = observer(props => {
  const {t} = useTranslation();

  return (
    <ModalDynamic
      title={t('banner.coindrop.title')}
      webMinHeight={200}
      maxWidth={590}
      shouldBeScrollable
      {...props}
    >
      <CoindropSurface />
    </ModalDynamic>
  );
});
