import * as React from 'react';
import {Image, PixelRatio} from 'react-native';
import {observer} from 'mobx-react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {BundleTariff} from '@youtoken/ui.resource-bundles';
import {type BoxProps, TouchableBox, Box} from '@youtoken/ui.primitives';

const RATIO = PixelRatio.get();
const DENSITY = RATIO < 1.5 ? '' : RATIO < 3 ? '@2x' : '@3x';

const useImageUriWithDensity = (uri: string) => {
  return React.useMemo(() => {
    return uri.replace(/(\.png)$/, `${DENSITY}$1`);
  }, [uri]);
};

export interface BundlesTariffCardProps {
  data: BundleTariff;
}

export const BundlesTariffCard: React.FC<BundlesTariffCardProps & BoxProps> =
  observer(
    ({
      data: {
        id,
        design: {backgroundColor, backgroundImageUrl},
      },
      children,
      ...boxProps
    }) => {
      const uri = useImageUriWithDensity(backgroundImageUrl);

      return (
        <TouchableBox
          p={4}
          pt={0}
          bg={backgroundColor}
          borderRadius={16}
          onPress={() => {
            SHARED_ROUTER_SERVICE.navigate('Bundle', {id});
          }}
          {...boxProps}
        >
          <Box alignSelf="center" alignItems="stretch" width={280} height={140}>
            <Image
              source={{uri}}
              style={{width: '100%', height: '100%', resizeMode: 'contain'}}
            />
          </Box>
          <Box flexGrow={1} backgroundColor="$ui-background" borderRadius={12}>
            {children}
          </Box>
        </TouchableBox>
      );
    }
  );
