import React from 'react';
import {ModalDynamic} from '@youtoken/ui.modal';
import {IncentivesDetailedSurface} from '@youtoken/ui.surfaces-wallets/src/Incentives';
import {modal} from '../../../stores';
import {Modal as _Modal} from '../_common';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const modalIncentivesDetailsName = 'incentivesDetails';

export const ModalIncentivesDetails: React.FC<
  React.ComponentProps<typeof _Modal>
> = () => {
  const {t} = useTranslation();
  const {opened, close} = modal;

  return (
    <ModalDynamic
      title={t('surface.incentives.bonuses.title')}
      isOpen={opened?.name === modalIncentivesDetailsName}
      onClose={close}
      shouldBeScrollable
    >
      <IncentivesDetailedSurface onClose={close} flex={1} />
    </ModalDynamic>
  );
};
