import {observable, action, runInAction, computed} from 'mobx';
import {serializable, deserialize, primitive} from 'serializr';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {Form} from './Form';

export class Calculated {
  @observable
  form: Form;

  @observable
  loading: boolean = false;

  @observable
  ready: boolean = false;

  @observable
  data?: CalculatedResponse;

  @action
  load = ({
    tariffId,
    quoteTicker,
    quoteAmount,
  }: {
    tariffId: string;
    quoteTicker: string;
    quoteAmount: string;
  }) => {
    this.loading = true;
    this.ready = false;

    return TRANSPORT.API.get(
      `/v1/bundle/deposit/calculate?tariffId=${tariffId}&quoteTicker=${quoteTicker}&quoteAmount=${Number(
        quoteAmount
      )}`,
      {}
    )
      .then(response => {
        runInAction(() => {
          this.data = deserialize(CalculatedResponse, response.data);
          this.ready = true;
        });
      })
      .finally(() => {
        this.loading = false;
      });
  };

  constructor(form: Form) {
    this.form = form;
  }
}

export class CalculatedResponse {
  @serializable(primitive())
  ticker!: string;

  @computed
  get tickerFormatted() {
    return this.ticker?.toUpperCase();
  }

  @serializable(bigNumber())
  minDeposit!: string;

  @computed
  get minDepositFormatted() {
    return formatByTicker(this.minDeposit, this.ticker);
  }

  @serializable(bigNumber())
  fee!: string;

  @computed
  get feeFormatted() {
    return formatByTicker(this.fee, this.ticker);
  }
}
