import * as React from 'react';
import {Image} from 'react-native';

export interface BundleIconProps {
  uri: string;
  size?: number;
}

export const BundleIcon: React.FC<BundleIconProps> = ({uri, size = 32}) => {
  return (
    <Image
      source={{uri}}
      style={{width: size, height: size, resizeMode: 'contain'}}
    />
  );
};
