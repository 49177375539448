import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import type {SectionHeaderProps} from '../types';
import {TICKERS_SIZE, TICKERS_BORDER_SIZE, KEYS} from '../constants';

export const SectionHeaderBundles: React.FC<SectionHeaderProps> = observer(
  ({section, list, visibility, highlight}) => {
    const {t} = useTranslation();

    const handleAddPress = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('Bundles');
    }, [section]);

    return (
      <Box
        flex={1}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          flexDirection="row"
          alignItems="center"
          // to prevent label movement during layout animation
          minHeight={TICKERS_SIZE + 2 * TICKERS_BORDER_SIZE}
        >
          <Text variant="$body-01-high-accent" color="$text-01" mr={4}>
            {t(KEYS[section])}
          </Text>
          {list.length > 0 && (
            <>
              <Icon
                color={visibility ? '$text-03' : '$text-01'}
                name={visibility ? 'collapse' : 'expand'}
                size={16}
              />
              {!visibility && (
                <Box
                  justifyContent="center"
                  alignItems="center"
                  width={20}
                  height={20}
                  backgroundColor="$interactive-02"
                  borderRadius={10}
                  ml={4}
                >
                  <Text variant="$body-03" color="$text-05">
                    {list.length}
                  </Text>
                </Box>
              )}
            </>
          )}
          {highlight && (
            <Box width={8} height={8} bg="$text-05" borderRadius={4} ml={4} />
          )}
        </Box>
        <Button
          icon="plus"
          color="interactive"
          type="secondary"
          size="small"
          testID={`ADD_WALLET_SECTION_ADD_PIE_BUTTON`}
          onPress={handleAddPress}
          pr={12}
        >
          {t('surface.wallets.wallets_list.add_bundles_button')}
        </Button>
      </Box>
    );
  }
);
