import {Platform} from 'react-native';
import {AllProps} from '@shopify/restyle';
import {Theme} from '../types';
import {FONTS} from './FONTS';

export type THeadingTokensNames =
  | '$heading-01-responsive'
  | '$heading-02-responsive'
  | '$heading-01'
  | '$heading-02'
  | '$heading-03'
  | '$heading-04';

export type THeadingTokens = Record<THeadingTokensNames, AllProps<Theme>>;

export const HEADING_TOKENS: THeadingTokens = {
  '$heading-01-responsive': {
    fontSize: {
      default: 24,
      desktop: 32,
    },
    lineHeight: {
      default: 28,
      desktop: 36,
    },
    fontFamily: FONTS.normal.bold,
    fontWeight: Platform.select({default: '700', android: undefined}),
  },
  '$heading-02-responsive': {
    fontSize: {
      default: 18,
      desktop: 24,
    },
    lineHeight: {
      default: 24,
      desktop: 28,
    },
    fontFamily: FONTS.normal.bold,
    fontWeight: Platform.select({default: '700', android: undefined}),
  },
  '$heading-01': {
    fontSize: 24,
    lineHeight: 28,
    fontFamily: FONTS.normal.bold,
    fontWeight: Platform.select({default: '700', android: undefined}),
  },
  '$heading-02': {
    fontSize: 18,
    lineHeight: 24,
    fontFamily: FONTS.normal.bold,
    fontWeight: Platform.select({default: '700', android: undefined}),
  },
  '$heading-03': {
    fontSize: 14,
    lineHeight: 18,
    fontFamily: FONTS.normal.bold,
    fontWeight: Platform.select({default: '700', android: undefined}),
  },
  '$heading-04': {
    fontSize: 12,
    lineHeight: 16,
    fontFamily: FONTS.normal.bold,
    fontWeight: Platform.select({default: '700', android: undefined}),
  },
};

export type TBoldTokensNames =
  | '$body-01'
  | '$body-01-medium-accent'
  | '$body-01-high-accent'
  | '$body-02'
  | '$body-02-medium-accent'
  | '$body-02-high-accent'
  | '$body-03'
  | '$body-03-medium-accent'
  | '$body-03-high-accent';

export type TBodyTokens = Record<TBoldTokensNames, AllProps<Theme>>;

export const BODY_TOKENS: TBodyTokens = {
  '$body-01': {
    fontSize: 14,
    lineHeight: 18,
    fontFamily: FONTS.normal.regular,
    fontWeight: Platform.select({default: '400', android: undefined}),
  },
  '$body-01-medium-accent': {
    fontSize: 14,
    lineHeight: 18,
    fontFamily: FONTS.normal.medium,
    fontWeight: Platform.select({default: '500', android: undefined}),
  },
  '$body-01-high-accent': {
    fontSize: 14,
    lineHeight: 18,
    fontFamily: FONTS.normal.bold,
    fontWeight: Platform.select({default: '700', android: undefined}),
  },
  '$body-02': {
    fontSize: 12,
    lineHeight: 16,
    fontFamily: FONTS.normal.regular,
    fontWeight: Platform.select({default: '400', android: undefined}),
  },
  '$body-02-medium-accent': {
    fontSize: 12,
    lineHeight: 16,
    fontFamily: FONTS.normal.medium,
    fontWeight: Platform.select({default: '500', android: undefined}),
  },
  '$body-02-high-accent': {
    fontSize: 12,
    lineHeight: 16,
    fontFamily: FONTS.normal.bold,
    fontWeight: Platform.select({default: '700', android: undefined}),
  },
  '$body-03': {
    fontSize: 10,
    lineHeight: 14,
    fontFamily: FONTS.normal.regular,
    fontWeight: Platform.select({default: '400', android: undefined}),
  },
  '$body-03-medium-accent': {
    fontSize: 10,
    lineHeight: 14,
    fontFamily: FONTS.normal.medium,
    fontWeight: Platform.select({default: '500', android: undefined}),
  },
  '$body-03-high-accent': {
    fontSize: 10,
    lineHeight: 14,
    fontFamily: FONTS.normal.bold,
    fontWeight: Platform.select({default: '700', android: undefined}),
  },
};

export type TCodeTokensNames = '$code-01' | '$code-02' | '$code-03';

export type TCodeTokens = Record<TCodeTokensNames, AllProps<Theme>>;

export const CODE_TOKENS: TCodeTokens = {
  '$code-01': {
    fontSize: 14,
    lineHeight: 18,
    fontFamily: FONTS.mono.regular,
    fontWeight: Platform.select({default: '400', android: undefined}),
  },
  '$code-02': {
    fontSize: 12,
    lineHeight: 16,
    fontFamily: FONTS.mono.regular,
    fontWeight: Platform.select({default: '400', android: undefined}),
  },
  '$code-03': {
    fontSize: 10,
    lineHeight: 14,
    fontFamily: FONTS.mono.regular,
    fontWeight: Platform.select({default: '400', android: undefined}),
  },
};
