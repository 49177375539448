import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {BundlesSurface, BundleSurface} from '@youtoken/ui.surface-bundles';
import {useParams} from 'react-router-dom';

export const WalletsBundlesPage: React.FC = cell(() => {
  return (
    <InnerPageShell>
      <BundlesSurface />
    </InnerPageShell>
  );
});

export const WalletsBundlePage: React.FC = cell(() => {
  const {id} = useParams<{id: string}>();

  return (
    <InnerPageShell>
      <BundleSurface id={id} />
    </InnerPageShell>
  );
});
