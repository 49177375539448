import {TRANSPORT} from '@youtoken/ui.transport';
import {ChartResource} from '../types';
import {getStartEndDates} from './getStartEndDates';

/** get line chart data from server */
export const getBundleLineData = ({
  source,
  sourceId,
  timePeriod,
}: ChartResource.BundleArgs): Promise<ChartResource.Data> => {
  const [fromDate, toDate] = getStartEndDates(timePeriod!);

  return TRANSPORT.API.get<ChartResource.Line.PointFromServer[]>(
    '/v3/rates/chart',
    {
      params: {
        product: 'bundle',
        source,
        sourceId,
        fromDate,
        toDate,
      },
    }
  ).then(response => {
    return {
      type: 'line',
      data: response.data.map(item => {
        return {
          date: new Date(item.date),
          rate: item.rate,
        };
      }),
    };
  });
};
