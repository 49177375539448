import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {BaseErrorFallback} from './BaseFallback';
import {ErrorFallbackProps} from './types';

export const MaintenanceFallback: React.FC<ErrorFallbackProps> = props => {
  return (
    <BaseErrorFallback text="Maintenance" {...props}>
      <Box mt={4}>
        <Text color="$text-02" variant="$body-02">
          In the meantime check out{' '}
          <Link
            url="https://www.youhodler.com/blog"
            variant="$body-02-medium-accent"
          >
            Blog
          </Link>
          {' and '}
          <Link
            url="https://t.me/youhodler_official"
            variant="$body-02-medium-accent"
          >
            Telegram
          </Link>
        </Text>
      </Box>
    </BaseErrorFallback>
  );
};
