import * as React from 'react';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {VerificationItem} from './VerificationItem';
import {VerificationItemComplete} from './VerificationItemComplete';

export const AccountVerifications: React.FC<BoxProps> = cell(boxProps => {
  const {accountVerificationCodes, accountVerificationComplete} =
    VerificationResource.use({});

  if (accountVerificationComplete) {
    return (
      <Box {...boxProps}>
        <VerificationItemComplete testID="VERIFICATION_COMPLETE" />
      </Box>
    );
  }

  return (
    <Box {...boxProps}>
      {accountVerificationCodes?.map(code => (
        <VerificationItem
          key={code}
          code={code}
          testID={`VERIFICATION_${code.toUpperCase()}`}
          // mb={24}
        />
      ))}
    </Box>
  );
});
