import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ModalDynamic} from '@youtoken/ui.modal';
import {TwoFactorDisableForm} from '@youtoken/ui.surface-more';

export const ModalTwoFactorAuthenticationDisable: typeof ModalDynamic =
  props => {
    const {t} = useTranslation();

    return (
      <ModalDynamic
        title={t('surface.profile.form_2fa_app_disable.title')}
        {...props}
      >
        <TwoFactorDisableForm />
      </ModalDynamic>
    );
  };
