import * as React from 'react';
import {observer} from 'mobx-react';
import {TouchableBox, type BoxProps} from '@youtoken/ui.primitives';
import {OnboardingStepPerksModal} from '../../../../OnboardingStepPerks';

export const TouchableWrapper: React.FC<BoxProps> = observer(
  ({children, ...boxProps}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const handlePress = () => {
      setIsOpen(true);
    };

    const close = () => {
      setIsOpen(false);
    };

    return (
      <>
        <TouchableBox {...boxProps} onPress={handlePress}>
          {children}
        </TouchableBox>
        <OnboardingStepPerksModal
          steps={['deposit']}
          isOpen={isOpen}
          onClose={close}
        />
      </>
    );
  }
);
