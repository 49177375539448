import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {ModalDynamic} from '@youtoken/ui.modal';
import {getTitle} from './utils';
import {
  type MinerResetBlocksContentProps,
  MinerResetBlocksContent,
} from './Content';

export const MinerResetBlocksModal: React.FC<MinerResetBlocksContentProps> =
  cell(({onClose, ...modalProps}) => {
    const handleClose = () => {
      onClose?.();
    };

    return (
      <ModalDynamic
        isOpen
        title={getTitle()}
        onClose={handleClose}
        webMinHeight={0}
        {...modalProps}
      >
        <MinerResetBlocksContent onClose={onClose} />
      </ModalDynamic>
    );
  });
