import * as React from 'react';
import {useActiveThemeType} from '@youtoken/ui.primitives';
import {iconSet, type IconName} from '../__generated/PaymentMethod';
import {createIconComponent} from '../createIcon';
import type {SvgProps} from 'react-native-svg';

export const BrandedVisaIcon = (
  props: SvgProps & {
    size?: number;
  }
) => {
  const themeType = useActiveThemeType();

  return (
    <iconSet.visa
      {...props}
      color={themeType === 'dark' ? '#fff' : '#1d33c3'}
    ></iconSet.visa>
  );
};
const _iconSet = {...iconSet, visa: BrandedVisaIcon};

export {_iconSet as iconSet};
export type {IconName};

export const Icon = createIconComponent<IconName, typeof iconSet>({
  componentName: 'Icon',
  iconSet: _iconSet,
  defaultSize: 24,
});
