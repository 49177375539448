import React from 'react';
import {cell} from '@youtoken/ui.cell';
import {WebPageWrapper} from '@youtoken/ui.screen-components';
import {Box, Heading} from '@youtoken/ui.primitives';
import {HistoryList} from '@youtoken/ui.surface-history-list';
import {DepositNow} from '@youtoken/ui.surfaces-wallets';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {WalletsItemProps} from './types/WalletsItemProps';
import {WalletsItemGeneral} from './components/WalletsItemGeneral';
import {WalletsItemFeature} from '../../features/WalletsItemFeature';
import {WalletsItemChart} from './components/WalletsItemChart';
import {WalletsItemPromoBanner} from './components/WalletsItemPromoBanner';

export const WalletsItem: React.FC<WalletsItemProps> = cell(
  ({ticker}) => {
    const {t} = useTranslation();
    const {equivalentTicker, hasChart, equivalentPrecision, isFiatTicker} =
      WalletsItemFeature.use({
        ticker,
      });

    return (
      <>
        <Box
          borderWidth={1}
          borderColor="$ui-01"
          borderRadius={{desktop: 10, default: 0}}
        >
          <WalletsItemGeneral ticker={ticker} />
          {hasChart && (
            <WalletsItemChart
              ticker={ticker}
              equivalentTicker={equivalentTicker}
              precision={equivalentPrecision}
            />
          )}
          {!isFiatTicker && (
            <DepositNow ticker={ticker} m={20} mt={hasChart ? 20 : 0} />
          )}
        </Box>
        <WalletsItemPromoBanner mt={24} />
        <HistoryList
          resourceKey="wallet"
          tickers={[ticker]}
          SectionListHeaderComponent={
            <Heading px={24} pt={24} pb={12}>
              {t('surface.wallets.wallet_detail.history_title')}
            </Heading>
          }
        />
      </>
    );
  },
  {
    displayName: 'WalletsItemScreen',
    CellWrap: ({source, children}) => {
      const {t} = useTranslation();

      // NOTE: WL-3475 - modify back func to skip the checkout payment page, which cannot be used a second time and throws error
      const handleBackPress = React.useCallback(() => {
        if (source === 'checkoutPaymentPage') {
          return SHARED_ROUTER_SERVICE.navigate('WalletsList', {});
        }

        return SHARED_ROUTER_SERVICE.goBack();
      }, [source]);

      return (
        <WebPageWrapper
          windowTitle={t('surface.wallets.tab_title')}
          hasBackButton
          onBackPress={handleBackPress}
        >
          {children}
        </WebPageWrapper>
      );
    },
  }
);
