import * as React from 'react';
import {BundleOperation} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';

interface BundleOperationsSubHeaderProps {
  item: {title: string; operations: BundleOperation[]};
}

export const BundleOperationsSubHeader: React.FC<
  BundleOperationsSubHeaderProps & BoxProps
> = ({item: {title}, ...boxProps}) => {
  return (
    <Box bg="$ui-overflow" {...boxProps}>
      <Text variant="$body-01" color="$text-01">
        {title}
      </Text>
    </Box>
  );
};
