import * as React from 'react';
import {Box, Heading, TColorTokens} from '@youtoken/ui.primitives';

type StatusPageProps = {
  text: string;
  icon?: React.ReactNode;
  color?: keyof TColorTokens;
  children?: React.ReactNode;
};

export const StatusPage: React.FC<StatusPageProps> = ({
  text,
  icon,
  color = '$text-05',
  children,
}) => {
  return (
    <Box height="100%" alignItems="center" justifyContent="center" p={24}>
      {icon}
      <Heading variant="$heading-02" color={color} mt={16} textAlign="center">
        {text}
      </Heading>
      {children}
    </Box>
  );
};
