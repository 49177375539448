import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {Separator} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  countriesListFormA,
  getCountriesListLocalized,
} from '@youtoken/ui.countries-utils';
import {ModalDynamic} from '@youtoken/ui.modal';
import {AllDisclosuresResource} from '@youtoken/ui.resource-all-disclosures';
import {AllDisclosures} from '../AllDisclosures';
import {FeatureFormA} from './state';
import {getCurrentCountry} from './state/Form';
import {
  FormAComboboxInput,
  FormADisclaimer,
  FormADisclosure,
  FormAErrors,
  FormAHeader,
  FormAInformation,
  FormANotUS,
  FormASoleOwnership,
  FormATextInput,
} from './components';

export const countriesList = getCountriesListLocalized(countriesListFormA).map(
  entry => {
    return {
      key: entry.code,
      label: entry.name,
      value: entry.code,
    };
  }
);

export interface FormAProps extends BoxProps {
  withHeader?: boolean;
  onSent?: () => void;
}

const isNative = Platform.select({
  native: true,
  default: false,
});

export const FormA: React.FC<FormAProps> = observer(
  ({withHeader = true, onSent, ...boxProps}) => {
    const [isDisclosureModalOpen, setDisclosureModalOpen] =
      React.useState(false);
    const [areDisclosuresLoading, setDisclosuresLoading] =
      React.useState(false);

    const handleOpenModal = React.useCallback(
      () => setDisclosureModalOpen(true),
      []
    );
    const handleCloseModal = React.useCallback(
      () => setDisclosureModalOpen(false),
      []
    );

    const {t} = useTranslation();

    const {
      data: {fullName, dateOfBirth, residence},
    } = AuthMeResource.use({});

    const {
      walletAgreements,
      wirexAgreements,
      youhodlerAgreements,
      needsToSignAgreements,
      getDisclosuresForCountry,
    } = AllDisclosuresResource.use({});

    const feature = FeatureFormA.use({onSent});

    React.useEffect(() => {
      const currentCountry = getCurrentCountry(residence);
      // HOTFIX: if (old)user has residence RUS - there is no country to match;
      feature.form.instance.$('nationality')?.onChange(currentCountry?.value);
      feature.form.instance.$('residence')?.onChange(currentCountry?.value);
      return () => feature.form.instance.clear();
    }, []);

    React.useEffect(() => {
      setDisclosuresLoading(true);
      getDisclosuresForCountry(feature.form.instance.$('residence').value).then(
        () => {
          setDisclosuresLoading(false);
          feature.form.instance.$('disclosure')?.onChange(false);
        }
      );
    }, [feature.form.instance.$('residence').value]);

    const handleSubmit = React.useCallback(() => {
      feature.submit();
    }, [feature]);

    const errors = feature.form.instance.errors();

    return (
      <>
        <Box
          {...boxProps}
          pointerEvents={feature.form.isSubmitting ? 'none' : 'auto'}
        >
          {withHeader && <FormAHeader />}
          <FormAErrors
            isSoleOwner={errors.isSoleOwner}
            notUS={errors.notUS}
            disclosureAccepted={errors.disclosure}
            mx={-24}
          />
          <FormAInformation fullName={fullName} dateOfBirth={dateOfBirth} />
          <Separator mt={20} mb={20} width="100%" />
          <FormAComboboxInput
            title={t('surface.form_a.field.nationality')}
            hasError={errors.nationality}
            value={feature.form.instance.$('nationality')?.value}
            onChange={feature.form.instance.$('nationality')?.onChange}
            items={countriesList}
            zIndex={100}
          />
          <FormAComboboxInput
            title={t('surface.form_a.field.residence')}
            hasError={errors.residence}
            value={feature.form.instance.$('residence')?.value}
            onChange={feature.form.instance.$('residence')?.onChange}
            items={countriesList}
            zIndex={99}
            mt={16}
          />
          <FormATextInput
            title={t('surface.form_a.field.city')}
            value={feature.form.instance.$('city')?.value}
            onChange={feature.form.instance.$('city')?.onChange}
            error={errors.city}
            mt={16}
          />
          <FormATextInput
            title={t('surface.form_a.field.post_code')}
            value={feature.form.instance.$('postalCode')?.value}
            onChange={feature.form.instance.$('postalCode')?.onChange}
            error={errors.postalCode}
            mt={16}
          />
          <FormATextInput
            title={t('surface.form_a.field.street')}
            value={feature.form.instance.$('streetAddress')?.value}
            onChange={feature.form.instance.$('streetAddress')?.onChange}
            error={errors.streetAddress}
            mt={16}
          />

          <FormANotUS
            flexDirection="row"
            mt={24}
            mb={24}
            justifyContent="space-between"
            hasError={Boolean(errors.notUS)}
            value={feature.form.instance.$('notUS')?.value}
            onChange={feature.form.instance.$('notUS')?.onChange}
          />
          <FormASoleOwnership
            isNative={isNative}
            hasError={errors.isSoleOwner}
            value={feature.form.instance.$('isSoleOwner')?.value}
            onChange={feature.form.instance.$('isSoleOwner')?.onChange}
            flexDirection="row"
            mb={24}
            mr={-10}
            justifyContent="space-between"
            width="100%"
          />
          {needsToSignAgreements && (
            <FormADisclosure
              isLoading={areDisclosuresLoading}
              value={feature.form.instance.$('disclosure')?.value}
              hasError={Boolean(errors.disclosure)}
              onChange={feature.form.instance.$('disclosure')?.onChange}
              openModal={handleOpenModal}
              flexDirection="row"
              justifyContent="space-between"
              mb={24}
            />
          )}

          <FormADisclaimer />
          <Button loading={feature.form.isSubmitting} onPress={handleSubmit}>
            {t('surface.form_a.sign')}
          </Button>
        </Box>
        <ModalDynamic
          isOpen={isDisclosureModalOpen}
          shouldShowHeaderSeparator={false}
          snapPoints={['90%']}
          onClose={handleCloseModal}
          title={t('surface.disclosure_form.title')}
          webWidth={494}
          m={20}
          testID="DISCLOSURE_FORM_MODAL"
        >
          <AllDisclosures
            px={isNative ? 0 : 24}
            pb={32}
            walletAgreements={walletAgreements}
            wirexAgreements={wirexAgreements}
            youhodlerAgreements={youhodlerAgreements}
          />
        </ModalDynamic>
      </>
    );
  }
);
