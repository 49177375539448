import * as React from 'react';
import {Separator} from '@youtoken/ui.elements';

type ModalFooterProps = {
  withSeparator?: boolean;
  footerComponent?: React.ReactNode;
};

export const ModalFooter: React.FC<ModalFooterProps> = ({
  footerComponent,
  withSeparator = true,
}) => {
  if (!footerComponent) {
    return null;
  }

  return (
    <>
      {withSeparator && <Separator />}
      {footerComponent}
    </>
  );
};
