import {computed, action} from 'mobx';
import {createResource} from '@youtoken/ui.data-storage';
import {ChartResource} from './types';
import {getBundleLineData} from './utils';
import {BundleLineLiveData} from './LiveData';

export class BundleChartDataResource extends createResource<
  ChartResource.BundleArgs,
  ChartResource.Data
>({
  skipRefreshOnVisible: false,
  getKey: ({type, source, sourceId}) => {
    return `pieChartData:${type}:${source}:${sourceId}`;
  },
  getData: args => getBundleLineData(args),
  cacheTime: 60 * 1000,
  refetchInterval: 60 * 1000,
  defaultArgs: {
    type: 'line',
    timePeriod: '24h',
  },
}) {
  lineLiveData!: BundleLineLiveData;

  @computed get type(): ChartResource.Type {
    return this.args.type!;
  }

  @computed get timePeriod() {
    return this.args.timePeriod!;
  }

  @action setTimePeriod = (timePeriod: ChartResource.TimePeriod) => {
    this.args.timePeriod = timePeriod;
  };

  @computed get chartData(): ChartResource.Data {
    return this.lineLiveData.data;
  }

  @computed get hasData() {
    return this.data.data.length >= 2;
  }

  @computed get noData() {
    return !this.hasData;
  }

  @computed get chartControls() {
    return {
      type: this.type,
      timePeriod: this.timePeriod,
      setTimePeriod: this.setTimePeriod,
    };
  }

  constructor(args: ChartResource.BundleArgs, data: ChartResource.Data) {
    super(args, data);

    this.lineLiveData = new BundleLineLiveData(this);
  }

  onDestroy() {
    super.onDestroy();
  }
}
