import {HistoryItemData} from './HistoryItemData';

export type HistoryItemResourceArgs = {
  id: string;
};

export type HistoryListResourceArgs = {
  key: 'accountStats' | `wallet`;
  limit?: number;
  offset?: number;
  ticker?: string[];
  type?: string[];
};

export type HistoryListSection = {
  title: string;
  data: HistoryItemData[];
};

export enum HistoryItemStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  RISKY = 'RISKY',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  DECLINED = 'DECLINED',
}

export enum HistoryItemType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
  BORROW = 'BORROW',
  LOAN_PAYMENT = 'LOAN_PAYMENT',
  REF_BONUS = 'REF_BONUS',
  REWARD_EARN = 'REWARD_EARN',
  HOLD = 'HOLD',
  UNHOLD = 'UNHOLD',
  COLLATERAL_SOLD = 'COLLATERAL_SOLD',
  EXCHANGE = 'EXCHANGE',
  FINE = 'FINE',
  DAILY_INTEREST = 'DAILY_INTEREST',
  REOPEN = 'REOPEN',
  SAVING_RELEASE = 'SAVING_RELEASE',
  SAVING_ADD = 'SAVING_ADD',
  SAVING_EARN = 'SAVING_EARN',
  SAVING_EARN_BONUS = 'SAVING_EARN_BONUS',
  SPENT = 'SPENT',
  REFUND = 'REFUND',
  MINER_CLAIM_BLOCK = 'MINER_CLAIM_BLOCK',
  CARD_FEE = 'CARD_FEE',
  DEPOSIT_BONUS = 'DEPOSIT_BONUS',
  BURNT = 'BURNT',
  TAP_REWARD = 'TAP_REWARD',
  BUNDLE_DEPOSIT = 'BUNDLE_DEPOSIT',
  BUNDLE_WITHDRAWAL = 'BUNDLE_WITHDRAWAL',
}
