import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Text} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {useForm} from '@youtoken/ui.form-elements';
import type {BundleWithdrawalFormState} from '../../../../state';

export const AmountSetAllDebtLink: React.FC<
  Omit<React.ComponentProps<typeof Link>, 'onPress'>
> = observer(props => {
  const {t} = useTranslation();

  const {
    form: {instance, setCalculateWithAllAmount, calculated},
  } = useForm<BundleWithdrawalFormState>();

  if (!calculated.data) {
    return null;
  }

  return (
    <Link
      onPress={() => {
        setCalculateWithAllAmount(true);
        instance
          .$('amount')
          .set('value', calculated.data!.totalBundleAmountFormatted);
      }}
      {...props}
    >
      <Text variant="$body-02" color="$text-05">
        <Trans
          t={t}
          components={{
            Strong: <Text variant="$body-02-medium-accent" />,
          }}
          values={{
            amount: calculated.data!.totalBundleAmountFormatted,
            ticker: calculated.data!.tickerFormatted,
          }}
          i18nKey="surface.bundles.withdrawal_form.amount_all"
        />
      </Text>
    </Link>
  );
});
