import * as React from 'react';
import {Box, type BoxProps, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {type DotPaginationProps} from './types';

export const DotPagination: React.FC<DotPaginationProps & BoxProps> = ({
  showChevrons: _showChevrons = true,
  count,
  activeIndex,
  onIndexChange,
  ...boxProps
}) => {
  const dots = React.useMemo(() => Array.from(Array(count)), [count]);
  const showChevrons = _showChevrons && onIndexChange !== undefined;

  const handlePressPrev = () => {
    if (activeIndex > 0) {
      onIndexChange?.(activeIndex - 1);
    }
  };

  const handlePressNext = () => {
    if (activeIndex < count - 1) {
      onIndexChange?.(activeIndex + 1);
    }
  };

  return (
    <Box
      width="100%"
      py={24}
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      gap={8}
      {...boxProps}
    >
      {showChevrons && (
        <TouchableBox onPress={handlePressPrev} mr={4}>
          <Icon name="chevron_left" color="$interactive-01" />
        </TouchableBox>
      )}
      {dots.map((_, index) => {
        return (
          <Box
            key={index}
            width={6}
            height={6}
            borderRadius={3}
            backgroundColor={
              index === activeIndex ? '$interactive-01' : '$interactive-03'
            }
          />
        );
      })}
      {showChevrons && (
        <TouchableBox onPress={handlePressNext} ml={4}>
          <Icon name="chevron_right" color="$interactive-01" />
        </TouchableBox>
      )}
    </Box>
  );
};
