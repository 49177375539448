import {Platform} from 'react-native';
import {computed, observable} from 'mobx';
import {
  alias,
  list,
  object,
  optional,
  primitive,
  raw,
  serializable,
} from 'serializr';
import {Products} from './Products';
import {
  AddressStatus,
  KYCStatus,
  LegalEntity,
  Requirements,
  type TwoFactorAuthType,
} from './types';
import {type currencyName} from '@youtoken/ui.coin-utils';

export let localClientSettings: ClientSettings | undefined;
export const updateLocalClientSettings = (next: ClientSettings) => {
  localClientSettings = next;
};

export class ClientSettingsSectionVisibilities {
  @observable
  @serializable(primitive())
  bundles: boolean = true;

  @observable
  @serializable(primitive())
  fiat: boolean = true;

  @observable
  @serializable(primitive())
  stable: boolean = true;

  @observable
  @serializable(primitive())
  crypto: boolean = true;

  @observable
  @serializable(primitive())
  hidden: boolean = true;
}

class ClientSettings {
  @observable
  @serializable(primitive())
  currency: currencyName = 'usd';

  @serializable(primitive())
  enableDemo!: boolean;

  @serializable(list(primitive()))
  @observable
  starredHODLsInstruments: string[] = [];

  @serializable(list(primitive()))
  @observable
  alertByMCHodls: string[] = [];

  @serializable(object(ClientSettingsSectionVisibilities))
  @observable
  sectionVisibilities: ClientSettingsSectionVisibilities =
    new ClientSettingsSectionVisibilities();

  @serializable(list(primitive()))
  @observable
  showedWalletTickers?: string[];

  @serializable(list(primitive()))
  @observable
  hiddenWalletTickers?: string[];

  @serializable(optional(primitive()))
  firstLoginModalShown?: boolean;

  @serializable(primitive())
  @observable
  appRatedAt?: number;

  @serializable(primitive())
  @observable
  isNeedShowAddWalletsDefault?: boolean = false;

  @serializable(primitive())
  @observable
  receiveUpdates: boolean = true;

  @serializable(primitive())
  @observable
  referralViewedDateTime!: number;

  @serializable(primitive())
  @observable
  rewardViewedDateTime!: number;

  @serializable(primitive())
  @observable
  enableHodlQuizBanner: boolean = true;

  @serializable(primitive())
  enableMinerHowItWorksBanner: boolean = true;

  @serializable(primitive())
  enableSavingsHowItWorksBanner: boolean = true;

  @serializable(primitive())
  enableLoyaltyHowItWorksBanner: boolean = true;

  @serializable(primitive())
  enableWirexIBANBanner: boolean = true;

  @serializable(primitive())
  enableIBANBanner: boolean = true;

  @serializable(primitive())
  enableAppearanceControl?: boolean = false;

  @serializable(optional(primitive()))
  enableAcquisitionSourcesSurvey?: boolean;

  @serializable(optional(primitive()))
  hasAlreadySeenCoindropModal?: boolean;

  @serializable(optional(primitive()))
  hasSeenMinerUpgradeModalFirstTime?: boolean;

  @serializable(optional(primitive()))
  newReferralBannerHidden?: boolean;

  @serializable(optional(primitive()))
  hasSeenMinerTooltipFirstTime?: boolean;

  @serializable(optional(primitive()))
  multiplyYourCryptoModalShown?: boolean;

  @serializable(optional(primitive()))
  enableBundleWelcomePage: boolean = true;
}

export class Challenge {
  @serializable(primitive())
  type!: string;

  @serializable(primitive())
  operationId?: string;

  @serializable(primitive())
  operationType?: string;

  @serializable(primitive())
  level?: string;
}

class Alerts {
  @serializable(primitive())
  sms!: boolean;

  @serializable(primitive())
  push!: boolean;

  @serializable(primitive())
  email!: boolean;
}

class Updates {
  @serializable(primitive())
  sms!: boolean;

  @serializable(primitive())
  push!: boolean;

  @serializable(primitive())
  email!: boolean;
}

export class AuthMeResponse {
  @serializable(primitive())
  id!: string;

  @serializable(raw())
  address: any;

  @serializable(
    primitive({
      beforeDeserialize: (callback, jsonValue) => {
        if (!jsonValue) {
          callback(null, KYCStatus.NOT_SET);
          return;
        }

        callback(null, jsonValue);
      },
    })
  )
  kycResult!: KYCStatus;

  @serializable(object(Products))
  products: Products = new Products();

  @serializable(
    primitive({
      beforeDeserialize: (callback, jsonValue) => {
        if (!jsonValue) {
          callback(null, AddressStatus.NOT_SET);
          return;
        }

        callback(null, jsonValue);
      },
    })
  )
  addressResult!: AddressStatus;

  @computed get bankWireDeposit() {
    return {
      usd: this.products.depositFiatWire.settings.enableBankWireDeposit_USD,
      eur: this.products.depositFiatWire.settings.enableBankWireDeposit_EUR,
      gbp: this.products.depositFiatWire.settings.enableBankWireDeposit_GBP,
      chf: this.products.depositFiatWire.settings.enableBankWireDeposit_CHF,
    };
  }

  @computed get disableBankWireDepositButton(): boolean {
    return this.products.depositFiatWire.settings.disableBankWireDepositButton;
  }

  @serializable(primitive())
  accountId!: string;

  @serializable(primitive())
  fullName?: string;

  @serializable(primitive())
  dateOfBirth!: string; // TODO date

  @serializable(primitive())
  email!: string;

  @serializable(primitive())
  phone?: string;

  @serializable(primitive())
  kycPhone?: string;

  @serializable(primitive())
  language?: string;

  @serializable(primitive())
  twoFactorAuthType!: TwoFactorAuthType;

  @serializable(object(ClientSettings))
  @observable
  clientSettings!: ClientSettings;

  @serializable(primitive())
  accountRemovalRequested = false;

  @serializable(primitive())
  hasSignedWallets = false;

  @serializable(primitive())
  hasSignedConversions = false;

  @serializable(primitive())
  hasSignedLoans = false;

  @serializable(primitive())
  hasSignedTurbo = false;

  @serializable(primitive())
  hasSignedHodl2 = false;

  // kycResult: "ACCEPT",

  @serializable(primitive())
  kycPhoneResult!: string;

  // addressResult: null,
  @serializable(primitive())
  emailResult!: string;

  @serializable(primitive())
  emailConfirmRequired!: boolean;

  @serializable(primitive())
  country!: string;

  @serializable(primitive())
  residence!: string;
  // address: {
  //   city: "Do hj",
  //   address: "Do b",
  //   country: "ALB",
  //   docType: "Utility bill (e.g. electricity)",
  //   zipCode: "Do h",
  // },
  // refPercent: 0,
  // refCode: "CS3U6EFYZQGM3X4C",
  // adviserRefCode: "1",

  @serializable(object(Alerts))
  alerts!: Alerts;

  @serializable(object(Updates))
  updates!: Updates;

  // refInfo: { registrationAmont: 2, totalRefSum: {}, loansAmount: 0 },
  // isDeposit: true,

  @serializable(alias('isDeposit', primitive()))
  @observable
  hasEverDeposited!: boolean;

  @serializable(primitive())
  intercomHash!: string;

  @serializable(primitive())
  intercomHashIos!: string;

  @serializable(primitive())
  intercomHashAndroid!: string;

  @computed get intercomHashForCurrentPlatform() {
    return Platform.select({
      ios: this.intercomHashIos,
      android: this.intercomHashAndroid,
      web: this.intercomHash,
    })!;
  }

  // isEmailVerified: false,
  // isPhoneVerified: false,

  @serializable(primitive())
  allowedMobilePush!: boolean;

  // hasLegacySavings: true,
  // hasSignedSavings: true,

  @computed get enableDemoFunds(): boolean {
    return this.products.demo.available;
  }

  @computed get enableGetLoan(): boolean {
    return this.products.loan.available;
  }

  @computed get enableTurbo(): boolean {
    return this.products.turbo.available;
  }

  @computed get enableHodl(): boolean {
    return this.products.hodl.settings.enableHodl3;
  }

  @serializable(primitive())
  hasSignedSavings = false;

  @computed get enableSaving4(): boolean {
    return this.products.saving4.available;
  }

  @serializable(primitive())
  enableTopBar = false;

  @computed get enableMiner() {
    return this.products.miner.available;
  }

  @computed get enableStories() {
    return this.products.stories.available;
  }

  @computed get enableOnboardingWidget() {
    return this.products.onboardingWidget.available;
  }

  @computed get enableWirex(): boolean {
    // NOTE: 'available' value is defining as false by BE if the agreements are nor signed
    return (
      this.products.wirex.available ||
      (!this.products.wirex.requirements.includes(Requirements.SUPPORT) &&
        this.products.wirex.requirements.includes(Requirements.AGREEMENT))
    );
  }

  @computed get enableFiatRepublic(): boolean {
    // NOTE: 'available' value is defining as false by BE if the agreements are nor signed
    return (
      this.products.fiatRepublic.available ||
      (!this.products.fiatRepublic.requirements.includes(
        Requirements.SUPPORT
      ) &&
        this.products.fiatRepublic.requirements.includes(
          Requirements.AGREEMENT
        ))
    );
  }

  @computed get isFiatRepublicThirdPartyAllowed(): boolean {
    return this.products.fiatRepublic.isAllowThirdParty;
  }

  @computed get isWirexThirdPartyAllowed(): boolean {
    return this.products.wirex.isAllowThirdParty;
  }

  @computed get enablePhysicalBankCard(): boolean {
    return (
      // 'available' shows whether all conditions are met, like PoA, etc.
      this.products.intergiro.available &&
      // 'enable' shows whether the product as a whole is enabled for the user
      this.products.intergiro.settings.enable &&
      // and this one is about the specific type of card
      this.products.intergiro.settings.enablePhysicalBankCard
    );
  }

  @computed get enableVirtualBankCard(): boolean {
    return (
      this.products.intergiro.available &&
      this.products.intergiro.settings.enable &&
      this.products.intergiro.settings.enableVirtualBankCard
    );
  }

  @computed get enableSkipCardOrderFee(): boolean {
    return this.products.intergiro.settings.enableSkipCardOrderFee;
  }

  @computed get enableWirexIBAN(): boolean {
    return this.products.wirex.settings.enableWirexIBAN;
  }

  @computed get enableFiatRepublicIBAN(): boolean {
    return this.products.fiatRepublic.settings.enableIBAN;
  }

  @computed get enableWirexIBAN_EUR(): boolean {
    return this.products.wirex.settings.enableWirexIBAN_EUR;
  }

  @computed get enableFiatRepublicIBAN_EUR(): boolean {
    return this.products.fiatRepublic.settings.enableIBAN_EUR;
  }

  @computed get enableWirexIBAN_GBP(): boolean {
    return this.products.wirex.settings.enableWirexIBAN_GBP;
  }

  @computed get enableFiatRepublicIBAN_GBP(): boolean {
    return this.products.fiatRepublic.settings.enableIBAN_GBP;
  }

  @computed get hasCoindrops(): boolean {
    return this.products.drops.settings.enabled;
  }

  @serializable(primitive())
  hasSignedWirexIBAN = false;

  @serializable(primitive())
  hasSignedFiatRepublicIBAN = false;

  @serializable(primitive())
  savingVersion?: number = 4;

  @serializable(primitive())
  rewardVersion: number = 1;

  @serializable(primitive())
  phoneMask!: string;

  // kycPhoneMask: "+79...0367",
  // iat: 1601644117,
  // ip: "::ffff:127.0.0.1",

  @serializable(primitive())
  kycProvider: 'IDM' | 'SUMSUB' = 'IDM';

  @serializable(primitive())
  antiPhishingCode!: string | null;

  @serializable(primitive())
  referralCode: string = '';

  @computed get enableRewards(): boolean {
    return this.products.rewards.available;
  }

  @serializable(primitive())
  legalEntity!: LegalEntity;

  @serializable(list(object(Challenge)))
  challenges?: Challenge[];

  @serializable(primitive())
  isNewDevice!: boolean;

  @serializable(primitive())
  token?: string;

  @computed get enableHodlQuiz(): boolean {
    return this.products.quiz.available;
  }

  @serializable(primitive())
  forceIdentify?: boolean;

  @serializable(primitive())
  isBusiness?: boolean;
}
