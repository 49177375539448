import * as React from 'react';
import {createWizard} from '@youtoken/ui.elements';
import {cell} from '@youtoken/ui.cell';
import type {CardWithdrawalFormArgs} from './CardWithdrawalSurface/state/types';
import {CardWithdrawalPaymentMethods} from './CardWithdrawalPaymentMethods';
import {CardWithdrawalAddCard} from './CardWithdrawalAddCard';
import {CardWithdrawalSurface} from './CardWithdrawalSurface';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {getCardWithdrawalWizardOptions} from './utils';

export enum CardWithdrawalWizardScreenName {
  MAIN = 'main',
  PAYMENT_METHODS = 'paymentMethods',
  ADD_CARD = 'addCard',
  ADD_CARD_WITH_PAYMENT = 'addCardWithPayment',
}

export interface CardWithdrawalWizardNavigator {
  [CardWithdrawalWizardScreenName.MAIN]: CardWithdrawalFormArgs & {
    cardId?: string;
    amount?: string;
    withError?: string;
  };
  [CardWithdrawalWizardScreenName.PAYMENT_METHODS]: {};
  [CardWithdrawalWizardScreenName.ADD_CARD]: {};
  [CardWithdrawalWizardScreenName.ADD_CARD_WITH_PAYMENT]: {
    onSubmit: (cardId: string) => void;
    ticker: string;
  };
}

const Wizard = createWizard<CardWithdrawalWizardNavigator>();

export interface CardWithdrawalWizardProps {
  ticker: string;
  conversionTicker?: string;
  cardId?: string;
  amount?: string;
  withError?: string;
  onPressBack?: () => void;
}

export const CardWithdrawalWizard: React.FC<CardWithdrawalWizardProps> = cell(
  ({ticker, conversionTicker, cardId, amount, withError, onPressBack}) => {
    const {t} = useTranslation();

    return (
      <Wizard.Navigator
        initialName={CardWithdrawalWizardScreenName.MAIN}
        initialProps={{
          ticker,
          conversionTicker,
          cardId,
          amount,
          withError,
        }}
        goBack={onPressBack}
      >
        <Wizard.Screen
          name={CardWithdrawalWizardScreenName.MAIN}
          options={getCardWithdrawalWizardOptions}
          component={CardWithdrawalSurface}
        />
        <Wizard.Screen
          name={CardWithdrawalWizardScreenName.PAYMENT_METHODS}
          options={{title: t('surface.wallets.payment_methods')}}
          component={CardWithdrawalPaymentMethods}
        />
        <Wizard.Screen
          name={CardWithdrawalWizardScreenName.ADD_CARD}
          options={{title: t('surface.wallets.add_card')}}
          component={CardWithdrawalAddCard}
        />
        <Wizard.Screen
          name={CardWithdrawalWizardScreenName.ADD_CARD_WITH_PAYMENT}
          options={getCardWithdrawalWizardOptions}
          component={CardWithdrawalAddCard}
        />
      </Wizard.Navigator>
    );
  }
);
