import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ModalDynamic} from '@youtoken/ui.modal';
import {cell} from '@youtoken/ui.cell';
import {HodlFlipSurface} from './surface';

interface HodlFlipBottomSheetProps {
  hodlId: string;
  onClose: () => void;
}

export const HodlFlipBottomSheet: React.FC<HodlFlipBottomSheetProps> = cell(
  ({hodlId, onClose}) => {
    const {t} = useTranslation();
    const [isBottomSheetOpen, setIsBottomSheetOpen] = React.useState(true);

    const handleCloseBottomSheet = React.useCallback(() => {
      setIsBottomSheetOpen(false);

      if (onClose) {
        onClose();
      }
    }, [onClose]);

    return (
      <ModalDynamic
        isOpen={isBottomSheetOpen}
        onClose={handleCloseBottomSheet}
        webMinHeight={200}
        maxWidth={590}
        borderRadius={14}
        webOverlayBackgroundColor="$transparent"
        title={t('surface.hodls.flip_order.bottom_sheet_title')}
        shouldShowHeaderSeparator={false}
        // NOTE: Without two props below, the slider inside the bottom sheet will not move by horizontal gesture
        nativeActiveOffsetY={[-1, 1]}
        nativeFailOffsetX={[-5, 5]}
      >
        <HodlFlipSurface
          hodlId={hodlId}
          onFormSubmit={handleCloseBottomSheet}
        />
      </ModalDynamic>
    );
  }
);
