import * as React from 'react';
import {observer} from 'mobx-react';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {Box} from '@youtoken/ui.primitives';
import {FramesComponent} from './FramesComponent';
import {CardsForm} from './state';

interface AddPaymentMethodSurfaceProps {
  onPressCancel: () => void;
  onSubmit?: (cardId: string) => void;
  ticker?: string;
  method?: 'withdrawal' | 'deposit';
}

export const AddPaymentMethodSurface: React.FC<AddPaymentMethodSurfaceProps> =
  observer(({onPressCancel, onSubmit, ticker, method}) => {
    const onSuccess = React.useCallback(
      (cardId: string) => {
        if (onSubmit && cardId) {
          onSubmit(cardId);
        } else {
          onPressCancel();
        }
      },
      [onPressCancel, onSubmit]
    );

    const form = makeForm(() => {
      return new CardsForm({
        onSuccess,
      });
    });

    return (
      <Box flex={1} borderRadius={10} overflow="hidden">
        <Form form={form}>
          <FramesComponent ticker={ticker} method={method} />
        </Form>
      </Box>
    );
  });
