import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {NewInviteeModalContent} from '../components/NewInviteeModalContent';

type NewInviteeModalProps = {
  amount: number;
} & Omit<ModalDynamicProps, 'children'>;

export const NewInviteeModal = cell<NewInviteeModalProps>(
  ({amount, isOpen: _isOpen, onClose, ...modalProps}) => {
    const [isOpen, setOpen] = React.useState(false);

    React.useEffect(() => {
      if (_isOpen) {
        setTimeout(() => setOpen(_isOpen), 5000);
      } else {
        setOpen(_isOpen);
      }
    }, [_isOpen]);

    return (
      <ModalDynamic isOpen={isOpen} onClose={onClose} {...modalProps}>
        <NewInviteeModalContent p={32} amount={amount} onClose={onClose} />
      </ModalDynamic>
    );
  }
);
