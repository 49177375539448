import * as React from 'react';
import {SubmitButton, IntercomButton} from '@web-app/src/components/elements';
import {Heading, Text, Box} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {FormikCustom, TextField} from '@web-app/src/components/elements/Forms';
import {
  useInterval,
  useOnSubmit,
  useValidationSchema,
} from '@web-app/src/hooks';
import {handleGeneralError} from '@youtoken/ui.validation-messages';
import {AUTH_OPERATION_TYPES} from '../../../../../../config';
import {normalizeOnlyDigits} from '@web-app/src/utils';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';
import {__GLOBAL_RECAPTCHA__} from '@youtoken/ui.two-factor-auth-and-recaptcha';

const initialLeftTime = 45;

type Values = {
  code: string;
};

interface FormProps {
  operation: any;
}

export const SMS: React.FC<FormProps> = ({operation}) => {
  const {confirmOperation, refreshOperation, signInConfirmSuccess} =
    AuthMeResource.use({});

  const {push} = RouterResource.use({});

  const {t} = useTranslation();

  const [leftTime, setLeftTime] = React.useState(initialLeftTime);

  useInterval(() => {
    setLeftTime(leftTime - 1);
  }, 1000);

  const onSubmit = useOnSubmit<Values>(
    ({code}) => {
      return __GLOBAL_RECAPTCHA__
        .requestToken('sign_in')
        .then(token =>
          confirmOperation({
            //@ts-ignore
            token,
            operationId: operation.operationId,
            code,
          })
        )
        .then(payload => {
          signInConfirmSuccess(payload);
        })
        .catch(
          (e: {
            response: {
              data: {operationType: string; operationId: string};
              status: number;
            };
          }) => {
            const {
              response: {data: {operationType, operationId} = {}},
            } = e;

            if (
              operationType === AUTH_OPERATION_TYPES.CONFIRM_DEVICE &&
              operationId
            ) {
              push(`/device-confirm?operationId=${operationId}`);
              return Promise.resolve();
            }

            if (e.response?.status === 429) {
              push('/');
            }

            throw e;
          }
        );
    },
    {
      successMessage: t('surface.sign_in_confirm.message.code_passed'),
      errorMessage: t('surface.sign_in_confirm.message.code_invalid'),
      successMessageTestID: 'SIGNIN_TWOFA_SMS_SUCCESS',
      errorMessageTestID: 'SIGNIN_TWOFA_SMS_ERROR',
    }
  );

  const validationSchema = useValidationSchema<Values>(yup => ({
    code: yup.string().required(),
  }));

  const handleClickResend = React.useCallback(() => {
    __GLOBAL_RECAPTCHA__
      .requestToken('sign_in')
      .then(token =>
        refreshOperation({
          token,
          operationId: operation.operationId,
        }).catch(e => {
          handleGeneralError(e);
          throw e;
        })
      )
      .then(() => {
        setLeftTime(initialLeftTime);
      });
  }, []);

  return (
    <>
      <Heading variant="$heading-01" mb={5}>
        {t('surface.sign_in_confirm.title')}
      </Heading>
      <Text mb={{default: 20, desktop: 30}}>
        {t('surface.sign_in_confirm.subtitle_phone', {
          phoneMask: operation.phoneMask,
        })}
      </Text>
      <FormikCustom
        initialValues={{
          code: '',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        render={() => (
          <>
            <TextField
              type="text"
              label={t('surface.sign_in_confirm.code_label.sms')}
              placeholder={t('surface.sign_in_confirm.code_placeholder')}
              name="code"
              normalize={normalizeOnlyDigits}
            />
            <Box mb={{default: 20, desktop: 30}}>
              <Text>
                <Text>{t('surface.sign_in_confirm.didnt_receive_code')}</Text>{' '}
                {leftTime > 0 && (
                  <Text>
                    {t('surface.sign_in_confirm.resend_in', {count: leftTime})}
                  </Text>
                )}
                {leftTime <= 0 && (
                  <Link onPress={handleClickResend}>
                    {t('surface.sign_in_confirm.resend')}
                  </Link>
                )}
              </Text>
            </Box>
            <SubmitButton size="large" mb={30}>
              {t('surface.sign_in_confirm.continue')}
            </SubmitButton>
            <Trans
              t={t}
              i18nKey="surface.auth.to_restore"
              style={{flexWrap: 'wrap'}}
              parent={props => <Text {...props} />}
              defaults="To restore account access, <IntercomButton>contact support</IntercomButton>"
              components={{
                IntercomButton: (
                  <IntercomButton
                    render={({openIntercom, children}) => {
                      return <Link onPress={openIntercom}>{children}</Link>;
                    }}
                  />
                ),
              }}
            />
          </>
        )}
      />
    </>
  );
};
