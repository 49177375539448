import * as React from 'react';
import {HappyBirthday, NyRecap} from './templates';
import {Box} from '@youtoken/ui.primitives';
import {invariant, warning} from '@youtoken/ui.utils';
import {TemplateParams} from '@youtoken/ui.resource-insta-stories';

const templates = {
  HappyBirthday,
  NyRecap,
};

type StoryTemplateProps<T extends keyof TemplateParams> = {
  name: T;
  params: TemplateParams[T];
};

export const StoryTemplate = <T extends keyof TemplateParams>({
  name,
  params,
}: StoryTemplateProps<T>) => {
  const InnerComponent = templates[name] as React.ComponentType<
    TemplateParams[T]
  >;

  if (!InnerComponent) {
    warning(
      false,
      `StoryTemplate: template with a name="${name}" was not found!`,
      {name}
    );

    return null;
  }

  invariant(params, 'params was not provided for the template');

  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      backgroundColor="$transparent"
    >
      <InnerComponent {...params} />
    </Box>
  );
};
