import * as React from 'react';
import {HodlHistorySurface} from '@youtoken/ui.surface-hodl-positions';
import {modal} from '../../../stores';
import {ModalDynamic} from '@youtoken/ui.modal';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const modalHodlHistoryName = 'hodlHistory';

interface ModalHodlHistoryProps {
  id: string;
}

export const ModalHodlHistory: React.FC<ModalHodlHistoryProps> = ({id}) => {
  const {t} = useTranslation();
  const {opened, close} = modal;

  return (
    <ModalDynamic
      isOpen={opened?.name === modalHodlHistoryName}
      onClose={close}
      title={t('surface.hodls.item.history_modal_title')}
    >
      <HodlHistorySurface hodlId={id} />
    </ModalDynamic>
  );
};
