import * as React from 'react';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {DeleteAccountSurface} from '@youtoken/ui.surface-delete-account';
import {WebPageWrapper} from '@youtoken/ui.screen-components';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export const DeleteAccountPage: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <InnerPageShell>
      <WebPageWrapper
        hasBackButton
        onBackPress={() => SHARED_ROUTER_SERVICE.navigate('More', {})}
        windowTitle={t('surface.profile.delete_account.title')}
        title={t('surface.profile.delete_account.title')}
      >
        <DeleteAccountSurface />
      </WebPageWrapper>
    </InnerPageShell>
  );
});
