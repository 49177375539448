import * as React from 'react';
import {useWizard} from '@youtoken/ui.elements';
import {VoletWizard} from '../../VoletWizard';
import {type FiatDepositWizardNavigator, FiatDepositWizardScreenName} from '..';

export const FiatDepositWizardVolet: React.FC = () => {
  const {
    step: {
      props: {ticker},
    },
    goBack,
  } = useWizard<
    FiatDepositWizardNavigator,
    FiatDepositWizardScreenName.VOLET
  >();

  return <VoletWizard ticker={ticker} onPressBack={goBack} />;
};
