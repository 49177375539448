import React from 'react';
import {observer} from 'mobx-react';
import {Icon, LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {LoansListItemFeature, LoansListItemFeatureArgs} from '../../../state';

export interface DetailedGeneralProps extends BoxProps {
  mode: LoansListItemFeatureArgs['mode'];
  id: LoansListItemFeatureArgs['id'];
}

export const DetailedGeneral: React.FC<DetailedGeneralProps> = observer(
  ({mode, id, ...rest}) => {
    const {t} = useTranslation();

    const loan = LoansListItemFeature.use({
      mode,
      id,
    });

    if (!loan.id) {
      console.error(`Loan id=${id} not found`);
      return null;
    }

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        {...rest}
      >
        <Box flexDirection="row">
          <Box mr={10}>
            <LogoColored
              name={loan.collateralTicker as LogoColoredIconName}
              size={32}
            />
          </Box>
          <Box>
            <Text>
              <Text variant="$body-01-high-accent">
                {loan.collateralAmount}
              </Text>{' '}
              <Text color="$text-02">
                {loan.collateralTicker!.toUpperCase()}
              </Text>
            </Text>
            <Box flexDirection="row" alignItems="center">
              <Text variant="$body-02" color="$text-02">
                {t('surface.loans.item.ltv')} <Text>{loan.LTV}%</Text>
              </Text>
              {loan.mode === 'turbo' && (
                <Box flexDirection="row" alignItems="center" ml={6}>
                  <Box mr={4}>
                    <Icon name="turbo" size={16} color="$text-05" />
                  </Box>
                  <Text variant="$body-02" color="$interactive-01">
                    x<Text>{loan.turbo}</Text>
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box alignItems="flex-end">
          <Text>
            <Text variant="$body-01-high-accent">{loan.overdraftAmount}</Text>{' '}
            <Text color="$text-02">{loan.borrowedTicker!.toUpperCase()}</Text>
          </Text>
          <Text variant="$body-02" color="$text-02">
            {loan.repayUntilDistance}
          </Text>
        </Box>
      </Box>
    );
  }
);
