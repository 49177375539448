import * as React from 'react';
import {AxiosResponse} from 'axios';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {i18n, useTranslation} from '@youtoken/ui.service-i18n';
import {Form} from '@youtoken/ui.form-elements';
import {__GLOBAL_RECAPTCHA__} from '@youtoken/ui.two-factor-auth-and-recaptcha';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {
  extractErrorFromResponse,
  getTranslatedValidationMessage,
} from '@youtoken/ui.validation-messages';
import {useCountdown} from '@youtoken/ui.hooks';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {FeatureEmailVerificationOnSignUp} from '../EmalVerificationOnSignupForm/state';
import {FormContent} from './FormContent';
import {AxiosError} from '@youtoken/ui.errors';

export type EmailVerificationOnSignupFormProps = {
  operationId: string;
  onSubmit?: (payload: any) => Promise<void>;
};

const INITIAL_LEFT_TIME = 30;

export const EmailVerificationOnSignupForm: React.FC<EmailVerificationOnSignupFormProps> =
  observer(({operationId, onSubmit}) => {
    const feature = FeatureEmailVerificationOnSignUp.use({});
    const {confirmOperation, refreshOperation, signInConfirmSuccess} =
      AuthMeResource.use({});

    const [isLoading, setLoading] = React.useState(false);

    const handleClickResend = React.useCallback(() => {
      return __GLOBAL_RECAPTCHA__.requestToken('sign_up').then(token =>
        refreshOperation({
          operationId,
          token,
        })
      );
    }, [operationId]);

    React.useEffect(() => {
      return () => feature.form.instance.clear();
    }, []);

    const handleSubmit = React.useCallback(() => {
      return feature.form.instance
        .submit()
        .then(() => {
          setLoading(true);
          return __GLOBAL_RECAPTCHA__.requestToken('sign_up').then(token => {
            return confirmOperation({
              operationId,
              token,
              code: feature.form.emailCode.get('value'),
            });
          });
        })
        .then((response: AxiosResponse) => {
          if (response?.data) {
            signInConfirmSuccess(response, {firstSignIn: true});
          }
        })
        .then((response: AxiosResponse) => {
          onSubmit?.(response);
          return response;
        })
        .catch((error: AxiosError) => {
          const codeError = extractErrorFromResponse(
            error?.response?.data,
            'code'
          );
          const emailError = extractErrorFromResponse(
            error?.response?.data,
            'email'
          );

          const _errorTranslated = getTranslatedValidationMessage(
            codeError || emailError
          );

          feature.form.instance.clear();
          feature.form.emailCode.invalidate(_errorTranslated || 'Invalid code');
        })
        .finally(() => setLoading(false));
    }, [feature, operationId]);

    return (
      <Form form={feature}>
        <Box
          flex={1}
          width="100%"
          maxWidth={{desktop: 400, tablet: 400, phone: undefined}}
        >
          <FormContent
            value={feature.form.emailCode.get('value')}
            errorText={feature.form.instance.errors()?.emailCode}
            onChange={feature.form.emailCode.get('onChange')}
            onSubmit={handleSubmit}
            isLoading={isLoading}
          />
          <Timer onResend={handleClickResend} />
        </Box>
      </Form>
    );
  });

export const Timer: React.FC<{onResend: () => Promise<any>}> = ({onResend}) => {
  const [countdownLeft, countdown] = useCountdown(INITIAL_LEFT_TIME * 1000);

  const [isResending, setIsResending] = React.useState(false);

  React.useEffect(() => {
    countdown.start();
  }, []);

  const handleReset = React.useCallback(() => {
    setIsResending(true);
    onResend()
      .then(() => {
        countdown.start();
        return Promise.resolve(null);
      })
      .catch(() => {
        LOCAL_NOTIFICATIONS.error({text: t('common.errors.smth_went_wrong')});
      })
      .finally(() => setIsResending(false));
  }, [onResend, setIsResending, countdown]);

  const {t} = useTranslation();
  return (
    <Text
      variant="$body-02"
      color="$text-02"
      textAlign={{desktop: 'left', tablet: 'left', phone: 'center'}}
    >
      {t('surface.2fa_form.not_recived')}{' '}
      {isResending && (
        <Text variant="$body-02" color="$text-01">
          {t('surface.2fa.resending')}
        </Text>
      )}
      {countdownLeft > 0 && !isResending && (
        <Text variant="$body-02" color="$text-01">
          {t('surface.2fa_form.resend_in', {leftTime: countdownLeft / 1000})}
        </Text>
      )}
      {countdownLeft <= 0 && !isResending && (
        <Link variant="$body-02" onPress={handleReset}>
          {t('surface.2fa_form.resend')}
        </Link>
      )}
    </Text>
  );
};
