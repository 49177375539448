import * as React from 'react';
import {
  type ConfirmationOptions,
  LOCAL_CONFIRMATIONS,
} from '@youtoken/ui.local-confirmations';
import {ModalDynamic} from '@youtoken/ui.modal';
import {ScreenWrap} from '@youtoken/ui.screen-components';
import {Confirmation} from './Confirmation';

export interface ConfirmationModalProps {
  requestConfirmation: (params: ConfirmationOptions) => Promise<null>;
}

export const ConfirmationModal = React.forwardRef<ConfirmationModalProps, {}>(
  (_props, ref) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const [{title, description, confirmText, cancelText}, setOptions] =
      React.useState<ConfirmationOptions>({});

    const [requestFunctions, setRequestFunctions] = React.useState<{
      resolve: () => void;
      reject: () => void;
    } | null>(null);

    const confirm = React.useCallback(() => {
      setIsOpen(false);
      requestFunctions?.resolve();
    }, [requestFunctions]);

    const cancel = React.useCallback(() => {
      setIsOpen(false);
      requestFunctions?.reject();
    }, [requestFunctions]);

    const requestConfirmation = React.useCallback(
      (options: ConfirmationOptions) => {
        setOptions(options);
        setIsOpen(true);

        return new Promise<null>((resolve, reject) => {
          setRequestFunctions({
            resolve: () => {
              resolve(null);
            },
            reject,
          });
        });
      },
      []
    );

    React.useImperativeHandle(
      ref,
      () => ({
        requestConfirmation,
      }),
      []
    );

    React.useEffect(() => {
      LOCAL_CONFIRMATIONS.provideHandler(requestConfirmation);
    }, []);

    return (
      <ModalDynamic
        testID="CONFIRMATION_MODAL"
        isOpen={isOpen}
        webWidth={500}
        webMinHeight={200}
        onClose={cancel}
        pb={0}
      >
        <ScreenWrap>
          <Confirmation
            title={title}
            description={description}
            confirmText={confirmText}
            cancelText={cancelText}
            confirm={confirm}
            cancel={cancel}
          />
        </ScreenWrap>
      </ModalDynamic>
    );
  }
);
