import * as React from 'react';
import {ReanimatedBox} from '@youtoken/ui.primitives';
import type {ProgressComponent} from '../types';
import {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withTiming,
} from 'react-native-reanimated';
import {ProgressBarText} from './Text';
import {backgroundColor, borderRadius, height} from './consts';

export const Progress: React.FC<ProgressComponent> = ({
  progress,
  isIndeterminate,
  color,
  size = 'tiny',
  text,
  barColor,
}) => {
  const _progress = useSharedValue(progress ?? 0);
  const _isIndeterminate = useSharedValue(isIndeterminate);

  React.useEffect(() => {
    _progress.value = progress;
    _isIndeterminate.value = isIndeterminate;
  }, [progress, isIndeterminate]);

  const style = useAnimatedStyle(() => {
    const duration = 600;

    return {
      width: _isIndeterminate.value
        ? withRepeat(
            withSequence(
              withTiming('0%', {duration}),
              withTiming('50%', {duration}),
              withTiming('0%', {duration})
            ),
            -1
          )
        : withTiming(`${Math.min(100 * _progress.value, 100)}%`, {
            duration: 1000,
            easing: Easing.linear,
          }),

      left: _isIndeterminate.value
        ? withRepeat(
            withSequence(
              withTiming('0%', {duration}),
              withTiming('50%', {duration}),
              withTiming('100%', {duration})
            ),
            -1
          )
        : withTiming('0%', {
            duration: 1000,
            easing: Easing.linear,
          }),
    };
  }, [_isIndeterminate, _progress]);

  return (
    <>
      <ReanimatedBox
        position="absolute"
        top={0}
        backgroundColor={barColor || backgroundColor[color]}
        height={height[size]}
        borderRadius={borderRadius[size]}
        style={style}
      />
      {text && !isIndeterminate && size !== 'tiny' && (
        <ProgressBarText
          color={color}
          text={text}
          _progress={_progress}
          progress={progress}
        />
      )}
    </>
  );
};
