import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {
  type AccountLevelProgressDetailedProps,
  getAccountLevelProgressDetailedTitle,
  AccountLevelProgressDetailed,
} from '../smarts';

type AccountLevelProgressDetailedModal = AccountLevelProgressDetailedProps &
  Omit<ModalDynamicProps, 'children'>;

export const AccountLevelProgressDetailedModal =
  cell<AccountLevelProgressDetailedModal>(
    ({variant, volume, ...modalProps}) => {
      return (
        <ModalDynamic
          testID="ACCOUNT_LEVEL_PROGRESS_DETAILED_MODAL"
          title={getAccountLevelProgressDetailedTitle()}
          {...modalProps}
        >
          <AccountLevelProgressDetailed variant={variant} volume={volume} />
        </ModalDynamic>
      );
    }
  );
