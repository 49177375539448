import * as React from 'react';
import {observer} from 'mobx-react';
import {InformationTableRow} from '@youtoken/ui.information-table';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import type {TooltipProps} from '@youtoken/ui.tooltip';
import {useForm} from '@youtoken/ui.form-elements';
import {CardDepositForm} from '../../smart/CardDepositWizard/CardDepositSurface/state';
import {CardWithdrawalForm} from '../../smart/CardWithdrawalWizard/CardWithdrawalSurface/state';

interface FeeRowProps extends BoxProps {
  webTooltipPosition?: TooltipProps['webTooltipPosition'];
}

export const FeeRow: React.FC<FeeRowProps> = observer(
  ({webTooltipPosition = 'right', ...boxProps}) => {
    const {t} = useTranslation();
    const {
      view: {
        feeValueFormatted,
        feePercentFormatted,
        feeMaxValueFormatted,
        feeMinValueFormatted,
        tickerFormatted,
        conversionTickerFormatted,
      },
    } = useForm<CardDepositForm | CardWithdrawalForm>();
    const showFeeValue = !conversionTickerFormatted;

    return (
      <Box width="100%" {...boxProps}>
        <InformationTableRow
          left={t('surface.apple_pay.deposit.components.fee_row.fee')}
          leftTooltipText={
            showFeeValue
              ? t(
                  `surface.wallets.fiat_deposit.bank_card_fee_tooltip${
                    feeMinValueFormatted ? '_lte' : ''
                  }${feeMaxValueFormatted ? '_gte' : ''}`,
                  {
                    percentValue: feePercentFormatted,
                    minValue: feeMinValueFormatted,
                    maxValue: feeMaxValueFormatted,
                    ticker: tickerFormatted,
                  }
                )
              : undefined
          }
          right={
            showFeeValue ? (
              <>
                <Text mr={2} variant="$body-01-high-accent">
                  {feeValueFormatted}
                </Text>
                {tickerFormatted && <Text>{tickerFormatted}</Text>}
              </>
            ) : (
              <Text mr={8}>{feePercentFormatted}%</Text>
            )
          }
          tooltipContentWidth={260}
          webTooltipPosition={webTooltipPosition}
        />
      </Box>
    );
  }
);
