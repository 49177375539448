import * as React from 'react';
import {BorderBoxWrapper} from '@youtoken/ui.screen-components';
import {HODLItem} from '@youtoken/ui.resource-hodl';
import {Box} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {HODLsListItem} from '../HODLsListItem';
import {HODLsListProps} from './types';
import {observer} from 'mobx-react';

const renderItem = (item: HODLItem, index: number, list: HODLItem[]) => {
  return (
    <Box key={item.id}>
      <HODLsListItem hodl={item} />
      {index < list.length - 1 && <Separator />}
    </Box>
  );
};

export const HODLsList: React.FC<HODLsListProps> = observer(({hodlsList}) => {
  return <BorderBoxWrapper>{hodlsList.map(renderItem)}</BorderBoxWrapper>;
});
