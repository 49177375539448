import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {PaymentStatus} from '@youtoken/ui.elements';
import {Helmet} from './Helmet';

type StatusScreenProps = {
  status: 'success' | 'failed' | 'canceled' | 'processing';
  method?: 'deposit' | 'verification';
};
export const StatusScreen: React.FC<StatusScreenProps & BoxProps> = cell(
  ({status, method = 'deposit', ...boxProps}) => {
    const {t} = useTranslation();

    return (
      <>
        <Helmet />
        <Box
          height="100%"
          flex={1}
          justifyContent="space-around"
          alignItems="center"
          backgroundColor="$ui-overflow"
          p={24}
          {...boxProps}
        >
          <PaymentStatus
            status={status}
            text={t(`screens.status.${method}.${status}.title_nicer`)}
            subtitle={t(`screens.status.${method}.${status}.subtitle_nicer`)}
          />
        </Box>
      </>
    );
  }
);
