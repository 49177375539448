import * as React from 'react';
import {Box, type BoxProps, Text, Heading} from '@youtoken/ui.primitives';
import {useTranslation, Trans} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {Button} from '@youtoken/ui.buttons';
import {
  NewReferralActivityResource,
  NewReferralOverviewResource,
} from '@youtoken/ui.resource-new-referral';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {Steps, ActivitiesListHeader} from './components';
import {ActivitiesList, InviteFriendsModal, MinerImage} from '../components';

type InviteFriendsSurfaceProps = {};

export const InviteFriendsSurface: React.FC<
  InviteFriendsSurfaceProps & BoxProps
> = ({...boxProps}) => {
  const {
    activities: {activities},
    overview: {
      overview: {
        new: newFriends,
        conditions: {welcomeSparks, percentFormatted},
        referralCode,
      },
      setAsRead,
    },
  } = useResources({
    activities: getResourceDescriptor(NewReferralActivityResource, {
      limit: 3,
      offset: 0,
    }),
    overview: getResourceDescriptor(NewReferralOverviewResource, {}),
  });

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const closeModal = () => setIsModalOpen(false);

  const handlePressButton = () => {
    DATA_LAYER.trackStrict('referral-miner-referral-link-shown', {
      inviteMethod: 'home',
      referralCode,
    });
    setIsModalOpen(true);
  };

  const {t} = useTranslation();

  React.useEffect(() => {
    if (newFriends > 0) {
      setAsRead();
    }
  }, [newFriends, setAsRead]);

  return (
    <>
      <Box flexDirection="column" alignItems="stretch" {...boxProps}>
        <Box alignSelf="center" mb={16}>
          <MinerImage />
        </Box>
        <Box px={16} mb={24}>
          <Trans
            t={t}
            i18nKey="surface.new_referral.invite_friends.heading"
            parent={props => (
              <Heading
                variant="$heading-01"
                textAlign="center"
                mb={8}
                {...props}
              />
            )}
            components={{
              Strong: <Heading variant="$heading-01" color="$attention-01" />,
            }}
            values={{percent: percentFormatted}}
          />
          <Text color="$text-02" textAlign="center">
            {t('surface.new_referral.invite_friends.text', {
              percent: percentFormatted,
              amount: welcomeSparks,
            })}
          </Text>
        </Box>

        <Box p={16} borderWidth={1} borderColor="$ui-01" borderRadius={12}>
          <Steps mb={20} />
          <Button
            size="large"
            onPress={handlePressButton}
            testID="INVITE_FRIENDS_ACTION_BUTTON"
          >
            {t('surface.new_referral.invite_friends.action')}
          </Button>
        </Box>

        {activities.length > 0 && (
          <>
            <ActivitiesListHeader mt={24} mb={8} />
            <ActivitiesList activities={activities.slice(0, 3)} />
          </>
        )}
      </Box>
      <InviteFriendsModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export const InviteFriendsSurfaceCell = cell(InviteFriendsSurface);
