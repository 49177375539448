import * as React from 'react';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {BundleTariffOverviewResource} from '@youtoken/ui.resource-bundles';
import {BundleWithdrawalFormState} from './state';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {cell, DefaultSuspenseFallback} from '@youtoken/ui.cell';
import {type BoxProps} from '@youtoken/ui.primitives';
import {Content, Footer} from './components';

export {getBundleWithdrawalFormTitle} from './utils';

export interface BundleWithdrawalFormProps {
  id: string;
  onClose: () => void;
}

export const BundleWithdrawalForm: React.FC<
  BundleWithdrawalFormProps & BoxProps
> = cell(({id, ...boxProps}) => {
  const resources = useResources({
    rates: getResourceDescriptor(RatesResource, {}),
    wallets: getResourceDescriptor(WalletsResource, {}),
    bundleTariffOverview: getResourceDescriptor(BundleTariffOverviewResource, {
      id,
    }),
  });

  const state = makeForm(() => {
    return new BundleWithdrawalFormState({}, resources);
  });

  return (
    <Form form={state}>
      <Content zIndex={1} flex={1} p={24} />
      <Footer p={24} />
    </Form>
  );
});
