import {action, computed, observable, runInAction} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {type AxiosPromise} from 'axios';
import {TRANSPORT} from '@youtoken/ui.transport';
import {i18n} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {normalizeAmountByTicker} from '@youtoken/ui.normalizers';
import {
  getTranslatedValidationMessage,
  handleGeneralErrorTranslated,
} from '@youtoken/ui.validation-messages';
import {getExtraIdNameByTicker, walletVersionsToItems} from './utils';
import type {
  CryptoWithdrawalExternalFormStateArgs,
  CryptoWithdrawalExternalFormStateResource,
} from './CryptoWithdrawalExternalForm/state';
import type {
  CryptoWithdrawalLedgerFormStateArgs,
  CryptoWithdrawalLedgerFormStateResource,
} from './CryptoWithdrawalLedgerForm/state';
import {toBig} from '@youtoken/ui.formatting-utils';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {__GLOBAL_RECAPTCHA__} from '@youtoken/ui.two-factor-auth-and-recaptcha';

export class CryptoWithdrawalForm {
  @observable
  args:
    | CryptoWithdrawalExternalFormStateArgs
    | CryptoWithdrawalLedgerFormStateArgs;

  @observable
  resources:
    | CryptoWithdrawalExternalFormStateResource
    | CryptoWithdrawalLedgerFormStateResource;

  @observable
  instance: MobxReactForm;

  //#region wallet

  @computed
  get wallet() {
    return this.resources.wallets.getByTicker(this.ticker)!;
  }

  @computed
  get walletAmount() {
    return this.wallet.amount.toFixed();
  }

  @computed
  get walletVersions() {
    let versions = this.wallet.versions;

    if (this.ticker === 'btc') {
      versions = versions.filter(version => {
        return ['origin', 'bep20'].includes(version.version);
      });
    }

    return walletVersionsToItems(versions, '{{version}}_WITHDRAWAL_TAB');
  }

  @computed
  get walletVersion() {
    return this.walletVersions.find(v => v.value === this.version);
  }

  @computed
  get walletVersionLabel() {
    return this.walletVersion?.label;
  }

  @computed
  get walletVersionValue() {
    return this.walletVersion?.value;
  }

  @computed
  get walletVersionAddress() {
    return this.walletVersion?.address;
  }

  @computed
  get walletVersionExtraId() {
    return this.walletVersion?.extraId;
  }

  //#endregion wallet

  //#region fee

  @computed
  get feeProvider() {
    if (this.ticker === 'btc' && this.walletVersionValue === 'origin') {
      return 'explorer';
    }

    if (this.ticker === 'eth' && this.walletVersionValue === 'erc20') {
      return 'explorer';
    }

    return this.walletVersionValue;
  }

  @computed.struct
  get fee() {
    return this.resources.feeAll.getFeeForWithdraw(
      this.ticker,
      this.feeProvider
    );
  }

  @computed
  get feeMinAmount() {
    return this.fee?.minAmount.toFixed();
  }

  @computed
  get feeMaxAmount() {
    return this.fee?.maxAmount.toFixed();
  }

  @computed.struct
  get feeEstimationTime() {
    return this.fee?.estimationTime;
  }

  //#endregion fee

  //#region ticker

  @computed
  get ticker() {
    return this.instance?.$('ticker').get('value') ?? this.args.ticker;
  }

  @computed
  get tickerFormatted() {
    return this.ticker.toUpperCase();
  }

  //#endregion ticker

  //#region amount

  @computed
  get amountLabel() {
    return i18n.t('surface.wallets.crypto_withdrawal.amount');
  }

  @computed
  get amountPlaceholder() {
    return toBig(0).toFixed(getCoinDecimalPrecision(this.ticker));
  }

  @computed
  get amount() {
    return this.instance?.$('amount').get('value') ?? '';
  }

  @computed
  get amountError() {
    return getTranslatedValidationMessage(
      this.instance.$('ticker').get('error') ??
        this.instance.$('amount').get('error')
    );
  }

  @computed
  get amountHasError() {
    return Boolean(this.amountError);
  }

  @action
  amountOnChange = (value: string) => {
    this.instance.$('amount').get('onChange')(
      normalizeAmountByTicker(value, this.ticker)
    );
  };

  @action
  amountOnPressAll = () => {
    this.amountOnChange(this.walletAmount);
  };

  //#endregion amount

  //#region version

  @computed
  get versionLabel() {
    return i18n.t('surface.wallets.crypto_deposit.protocol', {
      ticker: this.ticker.toUpperCase(),
    });
  }

  @computed
  get version(): string | null {
    return this.instance?.$('version').get('value') || null;
  }

  @computed
  get shouldShowContent() {
    return this.version !== null;
  }

  @computed
  get versionError() {
    return getTranslatedValidationMessage(
      this.instance.$('version').get('error')
    );
  }

  @computed
  get versionHasError() {
    return Boolean(this.versionError);
  }

  @action.bound
  versionOnChange(value: string) {
    this.instance.$('version').get('onChange')(value);
  }

  //#endregion version

  //#region address

  @computed
  get addressLabel() {
    return i18n.t('surface.wallets.crypto_withdrawal.address');
  }

  @computed
  get addressPlaceholder() {
    // NOTE: undefined because placeholder shouldn't be null in RN
    return this.walletVersionAddress ?? undefined;
  }

  @computed
  get address() {
    return '';
  }

  @computed
  get addressError() {
    return '';
  }

  @computed
  get addressHasError() {
    return Boolean(this.addressError);
  }

  //#endregion address

  //#region extraId

  @computed
  get extraIdVisible() {
    /** NOTE:
     * extraId is null or string for tickers that use this field
     * extraId is undefined for other tickers
     */
    return typeof this.walletVersion?.extraId !== 'undefined';
  }

  @computed
  get extraIdLabel() {
    return i18n.t(
      `surface.wallets.crypto_withdrawal.${getExtraIdNameByTicker(this.ticker)}`
    );
  }

  @computed
  get extraIdPlaceholder() {
    return this.walletVersionExtraId;
  }

  @computed
  get extraId() {
    return '';
  }

  @computed
  get extraIdError() {
    return '';
  }

  @computed
  get extraIdHasError() {
    return Boolean(this.extraIdError);
  }

  //#endregion extraId

  //#region agree

  @computed
  get agreeLabel() {
    return i18n.t('surface.wallets.crypto_withdrawal.agree');
  }

  @computed
  get agree() {
    return this.instance?.$('agree').get('value') ?? false;
  }

  @computed
  get agreeError() {
    return getTranslatedValidationMessage(
      this.instance.$('agree').get('error')
    );
  }

  @computed
  get agreeHasError() {
    return Boolean(this.agreeError);
  }

  @action
  agreeOnChange = (value: boolean) => {
    return this.instance.$('agree').get('onChange')(value);
  };

  //#endregion extraId

  //#region twoFactorAuth

  @computed
  get twoFactorAuthType() {
    return this.resources.authMe.twoFactorAuthType;
  }

  @computed
  get twoFactorAuthOperationId() {
    return (
      this.instance?.$('twoFactorAuthOperationId').get('value') ?? undefined
    );
  }

  @computed
  get twoFactorAuthOperationCode() {
    return this.instance?.$('twoFactorAuthOperationCode').get('value') ?? '';
  }

  @computed
  get twoFactorAuthOperationCodeError() {
    return getTranslatedValidationMessage(
      this.instance.$('twoFactorAuthOperationId').get('error') ??
        this.instance.$('twoFactorAuthOperationCode').get('error')
    );
  }

  @action
  twoFactorAuthOperationIdOnChange = (value: string) => {
    this.instance.$('twoFactorAuthOperationId').get('onChange')(value);
  };

  @action
  twoFactorAuthOperationCodeOnChange = (value: string) => {
    this.instance.$('twoFactorAuthOperationCode').get('onChange')(value);
  };

  @action
  createTwoFactorAuthOperation = (): Promise<{
    operationId: string;
    phoneMask?: string;
  }> => {
    return __GLOBAL_RECAPTCHA__
      .requestToken('crypto_withdrawal_tfa')
      .then(token =>
        TRANSPORT.API.post('/v1/withdrawal/authorize', {
          operationId: this.twoFactorAuthOperationId,
          token,
        })
      )
      .then(({data}) => {
        runInAction(() => {
          this.twoFactorAuthOperationIdOnChange(data.operationId);
        });
        return data;
      })
      .catch(response => {
        handleGeneralErrorTranslated(response?.data);
      });
  };

  //#endregion twoFactorAuth

  @action
  submit = () => {
    return TRANSPORT.API.post('/v1/withdrawal/crypto', {
      type: 'crypto',
      ticker: this.ticker,
      address: this.address,
      amount: this.amount,
      version: this.walletVersionValue,
      extraId: this.extraIdVisible ? this.extraId : undefined,
      operationId: this.twoFactorAuthOperationId,
      code: this.twoFactorAuthOperationCode,
    })
      .then(() => {
        SHARED_ROUTER_SERVICE.navigate('__CloseModal');
      })
      .catch(error => {
        const data = error?.response?.data;

        if (data?.i18n?._error?.label === 'MISSING_FORM_A_SIGNED') {
          SHARED_ROUTER_SERVICE.navigate('FormAVerificationModal');
          LOCAL_NOTIFICATIONS.error({
            text: i18n.t('validation.MISSING_FORM_A_SIGNED'),
          });
          return;
        }

        handleFormSubmitError(
          this.instance,
          error,
          {
            operationId: 'twoFactorAuthOperationId',
            code: 'twoFactorAuthOperationCode',
          },
          () => {
            LOCAL_NOTIFICATIONS.error({
              text: i18n.t('surface.wallets.crypto_withdrawal.network_error'),
            });

            SHARED_ROUTER_SERVICE.navigate('__CloseModal');
          }
        );
      });
  };

  constructor(
    args:
      | CryptoWithdrawalExternalFormStateArgs
      | CryptoWithdrawalLedgerFormStateArgs,
    resources:
      | CryptoWithdrawalExternalFormStateResource
      | CryptoWithdrawalLedgerFormStateResource
  ) {
    this.args = args;
    this.resources = resources;
  }
}
