import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box, type BoxProps, Text, TouchableBox} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {IncentivesFeature} from '@youtoken/ui.resource-wallets';
import {Illustration} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export const IncentivesBalance: React.FC<BoxProps> = cell(boxProps => {
  const {t} = useTranslation();

  const {
    incentivesEnabled,
    incentivesBalanceIsPositive,
    incentivesBalanceFormatted,
    incentivesTickerSymbol,
    maxPercentOnDeposit,
    incentivesBurningIn,
    incentivesBurningInNoPrefix,
  } = IncentivesFeature.use({});

  const handleOpenModal = React.useCallback(() => {
    DATA_LAYER.trackStrict('incentives-preview-click', {
      balance: incentivesBalanceFormatted,
    });

    SHARED_ROUTER_SERVICE.navigate('IncentivesDetailsModal');
  }, [incentivesBalanceFormatted]);

  if (!incentivesEnabled) {
    return null;
  }

  return (
    <>
      <TouchableBox
        flexDirection="row"
        alignItems="center"
        px={16}
        py={12}
        justifyContent="space-between"
        borderRadius={12}
        backgroundColor={
          incentivesBalanceIsPositive ? '$interactive-02' : '$interactive-01'
        }
        onPress={handleOpenModal}
        {...boxProps}
      >
        <Box flexDirection="row" alignItems="center">
          <Box mr={12}>
            <Illustration
              name="illustration-4"
              style={{width: 40, height: 40}}
            />
          </Box>
          {incentivesBalanceIsPositive ? (
            <Box py={3}>
              <Box flexDirection="row" alignItems="center">
                <Text variant="$body-02" color="$text-02">
                  {t('surface.incentives.bonuses.title')}
                </Text>
              </Box>
              <Box
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                width="100%"
              >
                <Text
                  variant="$body-02-high-accent"
                  color="$text-01"
                  testID={`INCENTIVES_IN_${incentivesTickerSymbol}_AMOUNT`}
                >
                  {incentivesTickerSymbol}
                  {incentivesBalanceFormatted}
                </Text>
              </Box>
            </Box>
          ) : (
            <Box flexDirection="row" alignItems="center">
              <Text variant="$body-02-high-accent" color="$text-04">
                {t('surface.incentives.boost.title', {
                  percent: maxPercentOnDeposit,
                })}
              </Text>
            </Box>
          )}
        </Box>
        <Box flexDirection="row" alignItems="center">
          {incentivesBalanceIsPositive && Boolean(incentivesBurningIn) && (
            <Box maxWidth={150} mr={8}>
              <Text variant="$body-02" color="$text-05" textAlign="right">
                {t('surface.incentives.expiration_button.expires_and_more', {
                  period: incentivesBurningInNoPrefix,
                })}
              </Text>
            </Box>
          )}

          <Icon
            name="chevron_right"
            color={incentivesBalanceIsPositive ? '$text-05' : '$text-04'}
            size={20}
          />
        </Box>
      </TouchableBox>
    </>
  );
});
