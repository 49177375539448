import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {type TKey, i18n} from '@youtoken/ui.service-i18n';
import {invariant} from '@youtoken/ui.utils';
import {
  WalletsChooseTicker,
  type WalletsChooseTickerType,
  HeaderBase,
} from '../../components';
import {AddWalletsItemWrapper} from './AddWalletsItemWrapper';

const showHeader = Platform.select({
  default: true,
  native: false,
});

type AddWalletsModalProps = {
  initialFilter?: WalletsChooseTickerType;
};

const i18nKeys: {[key in WalletsChooseTickerType]?: TKey} = {
  fiat: 'surface.wallets.add_wallets_by_category.title.fiat',
  stable: 'surface.wallets.add_wallets_by_category.title.stable',
  crypto: 'surface.wallets.add_wallets_by_category.title.crypto',
};

export const getAddWalletsTitle = (initialFilter?: WalletsChooseTickerType) => {
  return initialFilter && ['fiat', 'stable', 'crypto'].includes(initialFilter)
    ? i18n.t(i18nKeys[initialFilter]!)
    : i18n.t('surface.wallets.add_wallets_default.title');
};

export const AddWalletsModal: React.FC<AddWalletsModalProps> = observer(
  ({initialFilter}) => {
    invariant(
      initialFilter,
      'initialFilter is required for add wallet modal',
      {},
      {initialFilter}
    );

    const title = getAddWalletsTitle(initialFilter);

    const mt = Platform.select({
      default: 24,
      web: 16,
    });

    return (
      <>
        {showHeader && <HeaderBase shouldShowSeparator>{title}</HeaderBase>}
        <WalletsChooseTicker
          type={initialFilter}
          method="addWallet"
          ListItemWrapper={AddWalletsItemWrapper}
          mt={mt}
        />
      </>
    );
  }
);
