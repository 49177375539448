import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {FormBase} from '../FormBase';
import type {BuyCryptoFormAuthorizedOnRampStateArgs} from './types';
import {
  type BuyCryptoFormAuthorizedStateBaseResources,
  DepositMethodRampEnum,
} from '../types';
import {computed} from 'mobx';

export class Form extends FormBase<BuyCryptoFormAuthorizedOnRampStateArgs> {
  checkUrl = '/v2/converts/ramp/check';

  apiUrl = '/v1/widget/on-ramp/start';

  get redirectUrls() {
    return {
      successUrl: `/deposit-success`,
      failUrl: `/deposit-fail`,
    };
  }

  @computed
  get voletRedirectUrls() {
    return {
      successUrl: `/deposit-success`,
      failUrl: `/deposit-fail`,
    };
  }

  @computed
  get bankCardsRedirectUrls() {
    return {
      successUrl: `/deposit-success`,
      declineUrl: `/deposit-fail`,
      cancelUrl: `/deposit-fail`,
    };
  }

  @computed
  get possibleDepositMethods() {
    return [
      this.allowCheckoutBankCardFrame && DepositMethodRampEnum.BANK_CARD_FRAME,
      this.allowCheckoutBankCard && DepositMethodRampEnum.BANK_CARD,
      this.allowVolet && DepositMethodRampEnum.VOLET,
      this.allowUseYHBalance && DepositMethodRampEnum.YH_BALANCE,
      this.allowBankWire && DepositMethodRampEnum.BANK_WIRE,
    ].filter(Boolean) as DepositMethodRampEnum[];
  }

  submit() {
    DATA_LAYER.trackStrict('ramp-widget-attempt', {
      direction: 'on-ramp', // todo get direction: 'on-ramp' / 'off-ramp'
      method: this.depositMethod,
      fromAmount: this.amount,
      toAmount: this.conversionAmount,
      fromTicker: this.ticker,
      toTicker: this.conversionTicker,
    });

    return super.submit()?.then(() => {
      if (this.depositMethod === DepositMethodRampEnum.YH_BALANCE)
        this.args.onYHBalanceConvertSuccess?.();
    });
  }

  constructor(
    args: BuyCryptoFormAuthorizedOnRampStateArgs,
    resources: BuyCryptoFormAuthorizedStateBaseResources
  ) {
    super(args, resources);
  }
}
