import {action, computed} from 'mobx';
import {stringify} from 'query-string';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {HODLItem} from '../HODLItem';
import {HODLItemData} from '../HODLItemData';
import {HODLsResponse} from '../HODLsResponse';

const LAST_CLOSED_HODLS_LIMIT = 5;

export class LastClosedHODLsResource extends createResource<{}, HODLsResponse>({
  skipRefreshOnVisible: false,
  getKey: () => `hodls:lastClosed`,
  getData: () =>
    TRANSPORT.API.get('v3/hodl/lastClosed', {
      params: {
        limit: LAST_CLOSED_HODLS_LIMIT + 1, // NOTE: loading an additional item helps see whether there are more than displayed, as BE can't send total count
      },
      paramsSerializer: (params: any) =>
        stringify(params, {arrayFormat: 'none'}),
    }).then(res => {
      const rows = res.data.map((i: any) => ({
        data: i,
      }));

      return deserialize(HODLsResponse, {
        rows,
      });
    }),
}) {
  @computed get dataWithLimit() {
    return this.data.rows.slice(0, LAST_CLOSED_HODLS_LIMIT);
  }

  @computed get totalClosedMoreThenLimit() {
    return this.data.rows.length > LAST_CLOSED_HODLS_LIMIT;
  }

  @action.bound
  addNewHodl = (hodl: HODLItemData) => {
    if (!this.data.rows.some(h => h.id === hodl.id)) {
      this.data.rows.unshift(deserialize(HODLItem, {data: hodl}));
    }
  };

  onInit() {
    super.onInit();

    TRANSPORT.SOCKET.on('hodl-closed', this.addNewHodl);
  }

  onDestroy() {
    super.onDestroy();

    TRANSPORT.SOCKET.off('hodl-closed', this.addNewHodl);
  }
}
