import * as React from 'react';
import {observer} from 'mobx-react';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {
  BankCardTransferSurface,
  getBankCardTransferTitle,
} from '@youtoken/ui.surface-wallets-bank-cards';
import {modal} from '../../../stores';

export const modalBankCardTransferName = 'bankCardTransfer';

type ModalBankCardTransferProps = {
  cardId: string;
} & Omit<ModalDynamicProps, 'children'>;

export const ModalBankCardTransfer = observer(
  ({cardId}: ModalBankCardTransferProps) => {
    const {opened, close} = modal;

    return (
      <ModalDynamic
        isOpen={opened?.name === modalBankCardTransferName}
        onClose={close}
        title={getBankCardTransferTitle()}
        webOverlayBackgroundColor="$transparent"
      >
        <BankCardTransferSurface cardId={cardId} />
      </ModalDynamic>
    );
  }
);
