import * as React from 'react';
import {observer} from 'mobx-react';
import {BundleTariff} from '@youtoken/ui.resource-bundles';
import {Box, Text} from '@youtoken/ui.primitives';
import {BundleIcon} from '../../Icon';

interface BundleSummaryTariffProps {
  data: BundleTariff;
}

export const BundleSummaryTariff: React.FC<BundleSummaryTariffProps> = observer(
  ({
    data: {
      design: {iconUrl, title, type},
    },
  }) => {
    return (
      <Box flexDirection="row" gap={8}>
        <BundleIcon uri={iconUrl} size={32} />
        <Box>
          <Text variant="$body-01-high-accent" color="$text-01">
            {title}
          </Text>
          <Text variant="$body-02" color="$text-02">
            {type}
          </Text>
        </Box>
      </Box>
    );
  }
);
