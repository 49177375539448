import React from 'react';
import {observer} from 'mobx-react';
import {Platform} from 'react-native';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {AmountAndPercentInput} from '@youtoken/ui.amount-and-percent-input';
import {AmountBadge, ValidationMessage} from '@youtoken/ui.hodl-elements';
import {AdjustTpSlFormClass} from '../form';

export const TakeProfitBlock: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const testIdSection = 'TAKE_PROFIT';

  const {
    view: {
      takeProfitValue,
      takeProfitPercent,
      takeProfitPercentMin,
      takeProfitPercentMax,
      takeProfitLimitMessage,
      setTakeProfitLimit,
      tpError,
      tpErrorIsClickable,
      maxProfit,
      maxTicker,
    },
    form: {updateTakeProfitValue, updateTakeProfitPercent, isShort, precision},
  } = useForm<AdjustTpSlFormClass>();

  const validationComponent = React.useMemo(() => {
    return (
      <ValidationMessage
        validationMessage={takeProfitLimitMessage}
        onValidationClick={setTakeProfitLimit}
        validationError={tpError}
        isValidationClickable={tpErrorIsClickable}
        testIdSection={testIdSection}
      />
    );
  }, [takeProfitLimitMessage, setTakeProfitLimit, tpError, tpErrorIsClickable]);

  return (
    <Box {...boxProps}>
      <Box
        position="relative"
        zIndex={1}
        width="100%"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box flexDirection="row" alignItems="center">
          <Text variant="$body-01-high-accent">
            {t('surface.hodls.adjust_tp.take_profit')}
          </Text>
          <QuestionTooltip
            content={t('surface.hodls.adjust_tp.take_profit_tooltip')}
            bodyHorizontalOffset={60}
            width={300}
            position={Platform.select({default: 'bottom', web: undefined})}
          />
        </Box>

        <AmountBadge amount={maxProfit} ticker={maxTicker} />
      </Box>

      <AmountAndPercentInput
        amountLabel={t('surface.hodls.adjust_tp.level')}
        percentLabel={t('surface.hodls.adjust_tp.change')}
        amountValue={takeProfitValue}
        percentValue={takeProfitPercent}
        precisionAmount={precision}
        percentRangeMin={takeProfitPercentMin}
        percentRangeMax={takeProfitPercentMax}
        onChangeAmount={updateTakeProfitValue}
        onChangePercent={updateTakeProfitPercent}
        sliderKey="takeProfit"
        percentSignIsActive={true}
        validationComponent={validationComponent}
        shouldReverseSlider={isShort}
        testIdSection={testIdSection}
        mt={8}
        pb={8}
      />
    </Box>
  );
});
