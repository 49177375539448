import {type TFuncKey} from 'react-i18next';
import {type TColorTokens} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';

export const useAccountLevelInfo = () => {
  const {t} = useTranslation();

  const {
    currentLevel,
    willBeDowngradedThisPeriod,
    data: {downgradeLevel},
    currentVolumeFormatted,
    currentLevelData: {requiredVolumeFormatted},
    levelProgress,
  } = LoyaltyResource.use({});

  if (currentLevel <= 2) {
    return null;
  }

  const isDowngradeToBasic = downgradeLevel === 2;

  // special text for Basic level
  const descriptionKey = isDowngradeToBasic
    ? 'surface.miner.account_level_info.description_downgrade_basic'
    : willBeDowngradedThisPeriod
    ? 'surface.miner.account_level_info.description_downgrade'
    : 'surface.miner.account_level_info.description';

  return {
    titleKey: (willBeDowngradedThisPeriod
      ? 'surface.miner.account_level_info.title_downgrade'
      : 'surface.miner.account_level_info.title') as TFuncKey,
    descriptionKey: descriptionKey as TFuncKey,
    textColor: willBeDowngradedThisPeriod
      ? '$attention-01'
      : ('$text-02' as keyof TColorTokens),
    progress: levelProgress,
    progressText: levelProgress
      ? t('surface.miner.cards.required_volume.value', {
          currentAmount: currentVolumeFormatted,
          maxAmount: requiredVolumeFormatted,
        })
      : null,
  };
};
