import * as React from 'react';
import {observer} from 'mobx-react';
import {chunk} from 'lodash';
import {
  type BoxProps,
  TouchableBox,
  Box,
  Text,
  useIsMobile,
} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {toBig, formatPercent} from '@youtoken/ui.formatting-utils';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {LoanTariffsResponseItem} from '@youtoken/ui.resource-loan-tariffs';
import {FeatureTurboCreateForm} from '../stores';
import {Error, Label, useForm, FieldWrap} from '../../elements';

export const PlansField: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {
    form: {termValues, instance},
  } = useForm<FeatureTurboCreateForm>();

  const isMobile = useIsMobile();

  const termValuesChunks = React.useMemo(() => {
    const cols = isMobile ? 3 : 5;

    let chunks = chunk<LoanTariffsResponseItem | null>(termValues, cols);
    while (
      chunks[chunks.length - 1] &&
      chunks[chunks.length - 1]!.length < cols
    ) {
      chunks[chunks.length - 1]!.push(null);
    }

    return chunks;
  }, [isMobile, termValues]);

  return (
    <FieldWrap {...props}>
      <Label>{t('surface.loans.create_turbo_form.term')}</Label>
      <Box m={-4}>
        {termValuesChunks.map((termValues, index) => {
          return (
            <Box key={`termRow${index}`} flexDirection="row" height={80}>
              {termValues.map((term, index) => {
                return <PlansFieldItem key={`termCol${index}`} term={term} />;
              })}
            </Box>
          );
        })}
      </Box>
      <Error errors={instance.errors()} name="term" />
    </FieldWrap>
  );
});

export interface PlansFieldItemProps extends BoxProps {
  term: LoanTariffsResponseItem | null;
}

export const PlansFieldItem: React.FC<PlansFieldItemProps> = observer(
  ({term}) => {
    const {t} = useTranslation();

    const {
      form: {instance},
    } = useForm<FeatureTurboCreateForm>();

    const createHandlePress = React.useCallback(
      (term: LoanTariffsResponseItem) => () => {
        instance.$('term').onChange(term);
      },
      [instance.$('term').onChange]
    );

    return (
      <Box flexGrow={1} flexBasis={1} m={4}>
        {term && (
          <TouchableBox
            onPress={createHandlePress(term)}
            key={term.id}
            justifyContent="center"
            flexGrow={1}
            px={16}
            py={8}
            bg={
              instance.$('term').value.id === term.id
                ? '$interactive-01'
                : '$interactive-02'
            }
            borderRadius={8}
          >
            <Box flexDirection="row" justifyContent="space-between">
              <Text
                variant="$heading-03"
                color={
                  instance.$('term').value.id === term.id
                    ? '$ui-background'
                    : '$interactive-01'
                }
              >
                <Text>{formatPercent(term.ltv)}</Text>%
              </Text>
              {term.type === 'vip' && (
                <Icon
                  name="star"
                  color={
                    instance.$('term').value.id === term.id
                      ? '$ui-background'
                      : '$interactive-01'
                  }
                />
              )}
            </Box>
            <Text
              variant="$body-02"
              color={
                instance.$('term').value.id === term.id
                  ? '$ui-background'
                  : '$interactive-01'
              }
            >
              <Trans
                t={t}
                i18nKey="surface.loans.create_turbo_form.term_days"
                components={{
                  Strong: <Text variant="$body-02-high-accent" />,
                }}
                values={{days: term.days}}
              />
            </Text>
            <Text
              variant="$body-02"
              color={
                instance.$('term').value.id === term.id
                  ? '$ui-background'
                  : '$interactive-01'
              }
            >
              <Trans
                t={t}
                i18nKey="surface.loans.create_turbo_form.term_pdl"
                components={{
                  Strong: <Text variant="$body-02-high-accent" />,
                }}
                values={{
                  percent: formatPercent(toBig(term.marginCall).abs()),
                }}
              />
            </Text>
          </TouchableBox>
        )}
      </Box>
    );
  }
);
