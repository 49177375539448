import * as React from 'react';
import {observer} from 'mobx-react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Wallet} from '@youtoken/ui.resource-wallets';
import {Box, Text} from '@youtoken/ui.primitives';
import {TickersStack} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import type {SectionHeaderProps} from '../types';
import {TICKERS_SIZE, TICKERS_BORDER_SIZE, KEYS} from '../constants';

export const SectionHeaderOther: React.FC<SectionHeaderProps> = observer(
  ({section, list, visibility}) => {
    const {t} = useTranslation();

    const tickers = React.useMemo(() => {
      return (list as Wallet[]).map(({ticker}) => {
        return ticker;
      });
    }, [list]);

    const handleAddPress = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('AddWallets', {initialFilter: section});
    }, [section]);

    return (
      <Box
        flex={1}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          flexDirection="row"
          alignItems="center"
          // to prevent label movement during layout animation
          minHeight={TICKERS_SIZE + 2 * TICKERS_BORDER_SIZE}
        >
          <Text variant="$body-01-high-accent" color="$text-01" mr={4}>
            {t(KEYS[section])}
          </Text>
          {Boolean(tickers.length) && (
            <>
              <Icon
                color={visibility ? '$text-03' : '$text-01'}
                name={visibility ? 'collapse' : 'expand'}
                size={16}
              />
              {!visibility && (
                <TickersStack
                  tickers={tickers}
                  variant="rightOnTop"
                  size={TICKERS_SIZE}
                  borderSize={TICKERS_BORDER_SIZE}
                  additionalOffset={2}
                  testID={`ADD_WALLET_SECTION_TICKERS_STACK_${section.toUpperCase()}`}
                  ml={4}
                />
              )}
            </>
          )}
        </Box>
        <Button
          icon="plus"
          color="interactive"
          type="secondary"
          size="small"
          testID={`ADD_WALLET_SECTION_ADD_WALLET_BUTTON_${section.toUpperCase()}`}
          onPress={handleAddPress}
          pr={12}
        >
          {t('surface.wallets.wallets_list.add_wallets_button')}
        </Button>
      </Box>
    );
  }
);
