import * as React from 'react';
import {observer} from 'mobx-react';
import {invariant} from '@youtoken/ui.utils';
import {GLOBAL} from '@youtoken/ui.service-global';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {MinerUpgradeRequirementsResource} from '@youtoken/ui.resources-miner';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  TradeAction,
  SparksAction,
  AdditionalBenefits,
  JumpToLevel,
  BottomText,
} from '.';
import {type TradeToUpgradeModalContentProps} from '../types';

export type BaseTabProps = {
  product: 'hodl' | 'convert';
  onActionPress: (amount?: number, multiplier?: number) => void;
} & Pick<TradeToUpgradeModalContentProps, 'variant'>;

export const BaseTab: React.FC<BaseTabProps & BoxProps> = observer(
  ({product, onActionPress, variant, ...boxProps}) => {
    const {
      data: {[product]: productData},
    } = MinerUpgradeRequirementsResource.use({});

    const options = productData?.options;

    invariant(
      options,
      `TradeToUpgradeModal: Error options for "${product}" [${
        GLOBAL.token ? 'authorized' : 'unauthorized'
      }]`
    );

    const {t} = useTranslation();

    const buttonText = React.useMemo(() => {
      if (product === 'hodl' && variant === 'sparks') {
        return t('surface.miner.upgrade_modal.sparks.trade_action');
      }
      if (product === 'hodl' && variant === 'volume') {
        return t('surface.miner.upgrade_modal.volume.trade_action');
      }
      if (product === 'convert' && variant === 'sparks') {
        return t('surface.miner.upgrade_modal.sparks.convert_action');
      }
      if (product === 'convert' && variant === 'volume') {
        return t('surface.miner.upgrade_modal.volume.convert_action');
      }
    }, [t, product, variant]);

    const ActionComponent = variant === 'sparks' ? SparksAction : TradeAction;

    return (
      <Box
        flexDirection="column"
        alignItems="stretch"
        flexGrow={1}
        flexShrink={0}
        {...boxProps}
      >
        <Box flex={1} justifyContent="space-between">
          <Box>
            {options.map((option, index) => (
              <ActionComponent
                key={index}
                mb={8}
                product={product}
                onPress={() =>
                  onActionPress(option.volumeEquivalent, option.multiplier)
                }
                testID={`UPGRADE_LEVEL_MODAL_${product.toUpperCase()}_${variant.toUpperCase()}_OPTION_${index}`}
                {...option}
              />
            ))}

            {product === 'hodl' && <AdditionalBenefits mb={8} />}

            <JumpToLevel
              variant={variant}
              onActionPress={onActionPress}
              product={product}
              testID="UPGRADE_LEVEL_MODAL_JUMP_TO_LEVEL"
            />
          </Box>

          <Box mt={24}>
            <Button
              size="large"
              mb={16}
              onPress={() => onActionPress()}
              testID="UPGRADE_LEVEL_MODAL_ACTION_BUTTON"
            >
              {buttonText}
            </Button>

            <BottomText variant={variant} product={product} />
          </Box>
        </Box>
      </Box>
    );
  }
);
