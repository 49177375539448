export const iconSet = {
  '1inch': require('./1inch.png'),
  aave: require('./aave.png'),
  acm: require('./acm.png'),
  ada: require('./ada.png'),
  agix: require('./agix.png'),
  algo: require('./algo.png'),
  ape: require('./ape.png'),
  apt: require('./apt.png'),
  arb: require('./arb.png'),
  asi: require('./asi.png'),
  asr: require('./asr.png'),
  astr: require('./astr.png'),
  atom: require('./atom.png'),
  avax: require('./avax.png'),
  axs: require('./axs.png'),
  banana: require('./banana.png'),
  bat: require('./bat.png'),
  bars: require('./bars.png'),
  bch: require('./bch.png'),
  beam: require('./beam.png'),
  bnb: require('./bnb.png'),
  bnt: require('./bnt.png'),
  bonk: require('./bonk.png'),
  boden: require('./boden.png'),
  bonus: require('./bonus.png'),
  brz: require('./brz.png'),
  bsv: require('./bsv.png'),
  btc: require('./btc.png'),
  btt: require('./btt.png'),
  bttc: require('./btt.png'), // i've created bttc.png for bttc just because, but we will use btt.png for both btt and bttc
  busd: require('./busd.png'),
  cake: require('./cake.png'),
  cati: require('./cati.png'),
  chf: require('./chf.png'),
  comp: require('./comp.png'),
  dai: require('./dai.png'),
  dash: require('./dash.png'),
  doge: require('./doge.png'),
  dogs: require('./dogs.png'),
  dot: require('./dot.png'),
  egld: require('./egld.png'),
  eigen: require('./eigen.png'),
  ena: require('./ena.png'),
  ens: require('./ens.png'),
  eos: require('./eos.png'),
  etc: require('./etc.png'),
  eth: require('./eth.png'),
  ethw: require('./ethw.png'),
  eur: require('./eur.png'),
  eurs: require('./eurs.png'),
  fet: require('./fet.png'),
  fil: require('./fil.png'),
  floki: require('./floki.png'),
  flow: require('./flow.png'),
  flr: require('./flr.png'),
  ftm: require('./ftm.png'),
  ftt: require('./ftt.png'),
  gala: require('./gala.png'),
  gbp: require('./gbp.png'),
  gmt: require('./gmt.png'),
  grt: require('./grt.png'),
  gt: require('./gt.png'),
  gyen: require('./gyen.png'),
  hbar: require('./hbar.png'),
  hero: require('./hero.png'),
  hmstr: require('./hmstr.png'),
  hnt: require('./hnt.png'),
  mhix: require('./mhix.png'),
  hot: require('./hot.png'),
  ht: require('./ht.png'),
  husd: require('./husd.png'),
  icp: require('./icp.png'),
  ilv: require('./ilv.png'),
  imx: require('./imx.png'),
  inj: require('./inj.png'),
  io: require('./io.png'),
  jasmy: require('./jasmy.png'),
  jup: require('./jup.png'),
  juv: require('./juv.png'),
  lazio: require('./lazio.png'),
  ldo: require('./ldo.png'),
  leo: require('./leo.png'),
  link: require('./link.png'),
  lista: require('./lista.png'),
  ltc: require('./ltc.png'),
  luna: require('./luna.png'),
  mana: require('./mana.png'),
  matic: require('./matic.png'),
  mav: require('./mav.png'),
  me: require('./me.png'),
  meme: require('./meme.png'),
  mkr: require('./mkr.png'),
  mnt: require('./mnt.png'),
  move: require('./move.png'),
  myro: require('./myro.png'),
  near: require('./near.png'),
  nem: require('./nem.png'),
  not: require('./not.png'),
  nzds: require('./nzds.png'),
  om: require('./om.png'),
  omg: require('./omg.png'),
  ondo: require('./ondo.png'),
  op: require('./op.png'),
  pax: require('./pax.png'),
  paxg: require('./paxg.png'),
  pendle: require('./pendle.png'),
  pengu: require('./pengu.png'),
  people: require('./people.png'),
  pepe: require('./pepe.png'),
  pol: require('./pol.png'),
  popcat: require('./popcat.png'),
  pyth: require('./pyth.png'),
  pyusd: require('./pyusd.png'),
  qnt: require('./qnt.png'),
  ray: require('./ray.png'),
  render: require('./render.png'),
  rep: require('./rep.png'),
  rlb: require('./rlb.png'),
  sand: require('./sand.png'),
  sgb: require('./sgb.png'),
  shib: require('./shib.png'),
  silly: require('./silly.png'),
  snx: require('./snx.png'),
  sol: require('./sol.png'),
  srm: require('./srm.png'),
  strk: require('./strk.png'),
  stx: require('./stx.png'),
  sui: require('./sui.png'),
  sushi: require('./sushi.png'),
  taud: require('./taud.png'),
  tcad: require('./tcad.png'),
  tgbp: require('./tgbp.png'),
  theta: require('./theta.png'),
  thkd: require('./thkd.png'),
  tia: require('./tia.png'),
  ton: require('./ton.png'),
  tremp: require('./tremp.png'),
  trump: require('./trump.png'),
  trx: require('./trx.png'),
  tusd: require('./tusd.png'),
  uni: require('./uni.png'),
  usd: require('./usd.png'),
  usdc: require('./usdc.png'),
  usdp: require('./usdp.png'),
  usdt: require('./usdt.png'),
  usual: require('./usual.png'),
  vet: require('./vet.png'),
  w: require('./w.png'),
  wen: require('./wen.png'),
  wif: require('./wif.png'),
  wld: require('./wld.png'),
  wxt: require('./wxt.png'),
  xaut: require('./xaut.png'),
  xchf: require('./xchf.png'),
  xlm: require('./xlm.png'),
  xmr: require('./xmr.png'),
  xrp: require('./xrp.png'),
  xtz: require('./xtz.png'),
  ybtc: require('./ybtc.png'),
  yfi: require('./yfi.png'),
  yhdl: require('./yhdl.png'),
  yhdlbeta: require('./yhdlbeta.png'),
  yusd: require('./yusd.png'),
  zec: require('./zec.png'),
  zil: require('./zil.png'),
  zrx: require('./zrx.png'),
  zro: require('./zro.png'),

  // added all the icons for payment methods;
  bancontact: require('./bancontact.png'),
  codi: require('./codi.png'),
  deal: require('./deal.png'),
  eps: require('./eps.png'),
  google: require('./google-pay.png'),
  multibanco: require('./multibanco.png'),
  p24: require('./p24.png'),
  sofort: require('./sofort.png'),
  spei: require('./spei.png'),
  volet: require('./volet.png'),

  s: require('./s.png'),
};

export type IconName = keyof typeof iconSet;
