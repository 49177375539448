import React from 'react';
import {observer} from 'mobx-react';
import {Heading, Box, Text, BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Illustration} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {HODLsFeatureListSmart} from '@youtoken/ui.hodl-elements';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

export interface ModalContentSmartProps extends BoxProps {
  onClose: () => void;
}

export const ModalContentSmart: React.FC<ModalContentSmartProps> = observer(
  ({onClose, ...boxProps}) => {
    const {t} = useTranslation();

    const handleClose = () => {
      DATA_LAYER.trackStrict('hodl-explore-click', {});
      onClose();
    };

    return (
      <Box {...boxProps}>
        <Box alignSelf="center" height={210}>
          <Illustration name="illustration-16" />
        </Box>

        <Box mt={-50} px={24}>
          <Heading color="$text-01" textAlign="center" mb={8}>
            {t('surface.hodls.portfolio.empty.title')}
          </Heading>
          <Text color="$text-02" textAlign="center">
            {t('surface.hodls.portfolio.empty.text')}
          </Text>
        </Box>

        <HODLsFeatureListSmart px={0} mx={24} my={32} />

        <Box px={24}>
          <Button size="large" onPress={handleClose}>
            <Text>{t('surface.hodls.multiply_crypto_modal.action')}</Text>
          </Button>
        </Box>
      </Box>
    );
  }
);
