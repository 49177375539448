import * as React from 'react';
import {observer} from 'mobx-react';
import {Button} from '@youtoken/ui.buttons';
import {Heading} from '@youtoken/ui.primitives';
import {ModalDynamic} from '@youtoken/ui.modal';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {TradeToUpgradeModalContent} from './TradeToUpgradeModalContent';

export {TradeToUpgradeModalContent};

type TradeToUpgradeModalProps = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
};

export const TradeToUpgradeModal: React.FC<TradeToUpgradeModalProps> = observer(
  ({title, isOpen, onClose}) => {
    return (
      <ModalDynamic
        testID="TRADE_TO_UPGRADE_MODAL"
        isOpen={isOpen}
        onClose={onClose}
      >
        <Heading variant="$heading-02" p={24} textAlign="center">
          {title}
        </Heading>
        <TradeToUpgradeModalContent
          variant="volume"
          px={24}
          pb={24}
          onClose={onClose}
        />
      </ModalDynamic>
    );
  }
);

export const TradeToUpgradeModalTrigger: React.FC = observer(() => {
  const {t} = useTranslation();

  const [isModalOpen, setModalOpen] = React.useState(false);

  const openModal = React.useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const onModalClose = React.useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <>
      <Button
        testID="ACCOUNT_LEVEL_DETAILED_WIDGET_PROGRESS_ACTION_BUTTON"
        size="large"
        icon="volume"
        width="100%"
        mt={16}
        onPress={openModal}
      >
        {t('surface.upgrade_modal.volume.title')}
      </Button>
      <TradeToUpgradeModal
        title={t('surface.upgrade_modal.volume.title')}
        isOpen={isModalOpen}
        onClose={onModalClose}
      />
    </>
  );
});
