import {computed, comparer} from 'mobx';
import {computedFn} from 'mobx-utils';
import {deserialize} from 'serializr';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LoyaltyLevel, LoyaltyResponse} from './LoyaltyResponse';
import {last} from 'lodash';
import {
  formatVolumeWithDecimalSeparators,
  getLevelColor,
  getLevelIconName,
  getLevelName,
} from '@youtoken/ui.loyalty-miner-utils';
import {priceFormatterInThousands} from '@youtoken/ui.formatting-utils';
import type {LoyaltyProgressBarLevel, LoyaltyProgressBarPoint} from './types';
import {type LoyaltyIconName} from '@youtoken/ui.icons';
import {invariant, warning} from '@youtoken/ui.utils';

export class LoyaltyResource extends createResource({
  getKey: () => `loyalty`,
  getData: () =>
    TRANSPORT.API.get('/v1/loyalty').then(response => {
      return deserialize(LoyaltyResponse, response.data);
    }),
  skipRefreshOnVisible: false,
}) {
  @computed get levels() {
    return this.data.levels;
  }

  @computed get currentLevel() {
    return this.data.currentLevel;
  }

  @computed get currentVolume() {
    return this.data.currentVolume;
  }

  @computed get currentVolumeFormatted() {
    return this.data.currentVolumeFormatted;
  }

  @computed get currentDeposit() {
    return this.data.currentDeposit;
  }

  @computed get currentDepositFormatted() {
    return this.data.currentDepositFormatted;
  }

  @computed get currentLevelData(): LoyaltyLevel {
    const level = this.getLevel(this.currentLevel);

    invariant(level, 'Current level data is not found');

    return level;
  }

  @computed get willBeDowngradedThisPeriod() {
    // If the current level is Newbie or Basic, there is no downgrade
    if (this.currentLevel <= 2) {
      return false;
    }

    const requiredVolume = this.currentLevelData.requiredVolume;
    // if there is no required volume then there is downgrade
    if (!requiredVolume) {
      return true;
    }

    return this.currentVolume < requiredVolume;
  }

  @computed get lastLevelData() {
    const lastLevel = this.levels[this.levels.length - 1];

    return lastLevel!;
  }

  getLevel = computedFn((level: number): LoyaltyLevel | undefined => {
    return this.levels.find(({level: _level}) => _level === level);
  });

  //#region progressBar

  @computed get progressBarLevels() {
    return this.levels.filter(({level}) => {
      return level >= 3 && level <= this.currentLevel + 1;
    });
  }

  @computed get progressBarLevelsCompleted() {
    return this.progressBarLevels.filter(({level}) =>
      this.getProgressBarLevelCompleted(level)
    );
  }

  @computed get progressBarPoints() {
    return this.progressBarLevels.map(({level}) => ({
      level: level,
      name: getLevelName(level),
      requiredVolume: this.getProgressBarRequiredVolume(level),
      iconColorBorder: this.getProgressBarLevelIconColorBorder(level),
      iconColor: this.getProgressBarLevelIconColor(level),
      iconName: this.getProgressBarLevelIconName(level),
    })) as LoyaltyProgressBarPoint[];
  }

  @computed get progressBarValue() {
    // Filter points that the current volume has passed
    const passedPoints = this.progressBarPoints.filter(
      point => point.requiredVolume <= this.currentVolume
    );

    // Early return if no points are passed
    if (passedPoints.length === 0) {
      return 0;
    }

    // Get the last passed point and its index
    const lastPassedPoint = last(passedPoints)!;
    const lastPassedPointIndex =
      this.progressBarPoints.indexOf(lastPassedPoint);

    // Get the next point
    const nextPoint = this.progressBarPoints[lastPassedPointIndex + 1];

    // Early return if there is no next point (full progress)
    if (!nextPoint) {
      return 1;
    }

    // Calculate volume differences
    const volumeToNextPointMax =
      nextPoint.requiredVolume - lastPassedPoint.requiredVolume;
    const volumeToNextPoint =
      this.currentVolume - lastPassedPoint.requiredVolume;

    // Calculate breakpoints
    const progressBarBreakpoints = this.progressBarPoints.map(
      (_, index) => index / (this.progressBarPoints.length - 1)
    );

    const breakpointByLastPassedPointIndex =
      progressBarBreakpoints[lastPassedPointIndex];
    const breakpointByNextPointIndex =
      progressBarBreakpoints[this.progressBarPoints.indexOf(nextPoint)];

    invariant(
      breakpointByNextPointIndex || breakpointByNextPointIndex === 0,
      'cannot get breakpoint by index of next point'
    );
    invariant(
      breakpointByLastPassedPointIndex ||
        breakpointByLastPassedPointIndex === 0,
      'cannot get breakpoint by index of last passed point'
    );

    // Calculate percentage to the next point
    const percentToNextPointMax =
      breakpointByNextPointIndex - breakpointByLastPassedPointIndex;
    const percentToNextPoint =
      volumeToNextPointMax > 0
        ? (volumeToNextPoint / volumeToNextPointMax) * percentToNextPointMax
        : 0;

    // Return the total progress
    return progressBarBreakpoints[lastPassedPointIndex]! + percentToNextPoint;
  }

  @computed get progressBarValueText() {
    return `$${priceFormatterInThousands(this.currentVolume)}`;
  }

  // NOTE: ProgressBarWidget requires requiredVolume = 0 for 3 lvl
  getProgressBarRequiredVolume = computedFn((level: number) => {
    if (level === 3) {
      return 0;
    }

    const levelItem = this.getLevel(level);

    return levelItem?.requiredVolume;
  });

  getProgressBarLevelCompleted = computedFn((level: number) => {
    return Boolean(
      this.progressBarLevels.find(({level: _level}) => {
        return (
          _level === level &&
          this.getProgressBarRequiredVolume(_level) &&
          this.getProgressBarRequiredVolume(_level)! <= this.currentVolume
        );
      })
    );
  });

  getProgressBarLevelIconName = computedFn((level: number) => {
    const iconName = getLevelIconName(level);

    if (this.getProgressBarLevelCompleted(level)) {
      return iconName;
    }

    return `${iconName}_grayscale` as LoyaltyIconName;
  });

  getProgressBarLevelIconColor = computedFn((level: number) => {
    if (this.getProgressBarLevelCompleted(level)) {
      return getLevelColor(level);
    }

    return '$ui-01';
  });

  getProgressBarLevelIconColorBorder = computedFn((level: number) => {
    const progressBarLevelCurrent = last(this.progressBarLevelsCompleted);

    if (level === progressBarLevelCurrent?.level) {
      return getLevelColor(level);
    }

    return '$transparent';
  });

  //#endregion progressBar

  //#region progressBarV2

  // Method to forecast the loyalty level and volume based on a target volume
  getProgressBarForecastLevel = computedFn((targetVolume: number) => {
    // Filter levels greater than the current level
    const futureLevels = this.levels.filter(
      ({level}) => level > this.currentLevel
    );

    // Initialize forecastLevel with current level and target volume
    let forecastLevel = {
      level: this.currentLevel,
      volume: targetVolume,
    };

    // Iterate through future levels to update forecastLevel
    for (const {level, requiredVolume} of futureLevels) {
      // Check if the target volume meets or exceeds the required volume for the next level
      if (requiredVolume && forecastLevel.volume >= requiredVolume) {
        // Update forecastLevel with the new level and subtract the required volume
        forecastLevel = {
          level,
          volume: forecastLevel.volume - requiredVolume,
        };
      } else {
        // If target volume is less than required volume, stop further processing
        break;
      }
    }

    return forecastLevel;
  });

  getProgressBarAchievedLevels = computedFn(
    (targetVolume: number = this.currentVolume) => {
      const {level: forecastLevel} =
        this.getProgressBarForecastLevel(targetVolume);

      return this.levels.filter(({level}) => {
        return level > this.currentLevel && level <= forecastLevel;
      });
    }
  );

  getProgressBarLevels = computedFn(
    (targetVolume: number = this.currentVolume): LoyaltyProgressBarLevel[] => {
      const maxLevel = this.lastLevelData;
      const forecastLevel = this.getProgressBarForecastLevel(targetVolume);
      const nextLevel = this.getLevel(
        maxLevel && maxLevel.level > forecastLevel.level
          ? forecastLevel.level + 1
          : forecastLevel.level
      );

      const achievedLevels = this.getProgressBarAchievedLevels(targetVolume);

      // Filter levels that are within the range from
      // (the current level to forecast level + 1) or (the max level - 1 to max level)
      const relevantLevels = this.levels.filter(({level}) => {
        if (maxLevel && maxLevel.level === this.currentLevel) {
          return level >= this.currentLevel - 1;
        }

        return level >= this.currentLevel && level <= forecastLevel.level + 1;
      });

      // Map relevant levels to the format required for the progress bar
      return relevantLevels.map(level => {
        const isNext = level.level === nextLevel?.level;

        const isAchieved =
          //  NOTE: If forecastLevel is max, then all achieved
          (maxLevel && forecastLevel.level === maxLevel.level) ||
          // NOTE: Else from > currentLevel to <= forecastLevel
          achievedLevels.some(({level: achievedLevel}) => {
            return level.level === achievedLevel;
          });

        const volume =
          isAchieved && !isNext ? level.requiredVolume : forecastLevel.volume;

        warning(volume != null, 'cant get volume');

        const volumeFormattedWithSeparators = formatVolumeWithDecimalSeparators(
          Number(volume!.toFixed(2))
        );

        return {
          level,
          isNext,
          isAchieved,
          volume: volume as number,
          volumeFormattedWithSeparators,
        };
      });
    }
  );

  getProgressBarLevelsWidget = computedFn(
    (targetVolume: number = this.currentVolume): LoyaltyProgressBarLevel[] => {
      return this.getProgressBarLevels(targetVolume).slice(-2);
    },
    {
      equals: comparer.structural,
    }
  );

  getProgressBarLevelsWidgetDetailed = computedFn(
    (targetVolume: number = this.currentVolume): LoyaltyProgressBarLevel[] => {
      return this.getProgressBarLevels(targetVolume).slice(1);
    }
  );

  getProgressBarValue = computedFn(
    (targetVolume: number = this.data.currentVolume) => {
      const {level: forecastLevel, volume: forecastVolume} =
        this.getProgressBarForecastLevel(targetVolume);

      const {level: nextForecastLevel, requiredVolume: nextRequiredVolume} =
        this.getLevel(forecastLevel + 1) ?? {};

      return nextForecastLevel && nextRequiredVolume
        ? forecastVolume / nextRequiredVolume
        : 1;
    }
  );

  //#endregion progressBarV2

  //#region progressBar on AccountLevelInfo in LoyaltyMiner
  @computed get levelProgress() {
    const isBasic = this.currentLevel === 2;
    const isDowngradeToBasic = this.data.downgradeLevel === 2;

    if (isBasic || isDowngradeToBasic || !this.willBeDowngradedThisPeriod) {
      // if there is no progress to show, return null and do not show the progress bar
      return null;
    }

    if (this.currentLevelData.requiredVolume) {
      // if there is required volume, show the progress
      return this.currentVolume / this.currentLevelData.requiredVolume;
    }

    return 0;
  }
  //#endregion
}
