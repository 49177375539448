// Import the native module. On web, it will be resolved to PlatformPayment.web.ts
// and on native platforms to PlatformPayment.ts
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {Platform} from 'react-native';
import PlatformPaymentModule from './PlatformPaymentModule';
import {
  MerchantCapability,
  PaymentNetwork,
  CompleteStatus,
  type PaymentData,
} from './PlatformPayment.types';

export {
  type MerchantCapability,
  type PaymentNetwork,
  type ChangeEventPayload,
  type PaymentData,
  type PlatformPaymentViewProps,
  CompleteStatus,
} from './PlatformPayment.types';

export const CANCELLED_STATUSES = [
  'closed', // native apple pay
  'Cancelled', // web apple pay
  'Canceled', // web apple pay apple js
  'The operation was aborted.', // safari apple pay payment request
  'The object does not support the operation or argument.', // safari apple pay js
];

export interface ShowBaseProps {
  currencyCode: string;
  paymentSummaryItems: {
    label: string;
    amount: string;
  }[];
}

const PAYMENT_NETWORKS: PaymentNetwork[] = ['visa', 'masterCard'];
const MERCHANT_CAPABILITIES: MerchantCapability[] = ['supports3DS'];
const COUNTRY_CODE = 'IT';

export default {
  show: async (data: ShowBaseProps): Promise<PaymentData> => {
    return PlatformPaymentModule.show({
      ...data,
      countryCode: COUNTRY_CODE,
      merchantCapabilities: MERCHANT_CAPABILITIES,
      supportedNetworks: PAYMENT_NETWORKS,
      merchantIdentifier: ENVIRONMENT.APPLE_PAY_MERCHANT_ID,
    });
  },
  dismiss: () => {
    PlatformPaymentModule.dismiss();
  },
  complete: async (status: CompleteStatus) => {
    PlatformPaymentModule.complete(status);
  },
  init: async () => {
    return new Promise<void | Event>((resolve, reject) => {
      if (
        !(Platform.OS === 'web') ||
        document.getElementById('apple-pay-sdk')
      ) {
        resolve();
        return;
      }

      const script = document.createElement('script');

      script.id = 'apple-pay-sdk';
      script.src =
        'https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js';
      script.async = true;
      script.crossOrigin = 'anonymous';
      script.onload = resolve;
      script.onerror = reject;

      document.body.insertBefore(script, document.body.firstChild);
    });
  },
  canMakePayments: (): boolean => {
    return PlatformPaymentModule.canMakePayments();
  },
};
