import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps, Heading} from '@youtoken/ui.primitives';
import {HODLsListFeature} from '../../../features';
import {ShowAllClosedButton} from '../../components';
import {HODLsList} from '../../../components';

export const HODLsRecentlyClosedBlock: React.FC<BoxProps> = observer(
  ({...boxProps}) => {
    const {t} = useTranslation();

    const {recentlyClosed, shouldShowAllClosedHODLsButton} =
      HODLsListFeature.use({});

    return (
      <Box {...boxProps}>
        <Box px={{default: 20, desktop: 0}} mb={16}>
          <Heading variant="$heading-01" color="$text-02">
            {t('surface.hodls.portfolio.recently_closed_title')}
          </Heading>
        </Box>

        <HODLsList hodlsList={recentlyClosed} />

        {shouldShowAllClosedHODLsButton && <ShowAllClosedButton py={24} />}
      </Box>
    );
  }
);
