import * as React from 'react';
import {Box, Heading} from '@youtoken/ui.primitives';
import {NyRecapStoryParams} from '@youtoken/ui.resource-insta-stories';

export const NyRecap: React.FC<NyRecapStoryParams> = ({user: {days}}) => {
  if (!days) {
    return null;
  }

  return (
    <Box
      flex={1}
      justifyContent="center"
      alignItems="center"
      padding={40}
      paddingTop={70}
    >
      <Heading
        variant="$heading-02"
        color="$text-04"
        textAlign="left"
        opacity={0.7}
      >
        Wow, what a journey! You spent {days > 1 ? `${days} days` : 'a day'}{' '}
        diving into the crypto market with YouHodler. Your energy and curiosity
        are nothing short of inspiring — keep it up!
      </Heading>
    </Box>
  );
};
