import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Total} from '@youtoken/ui.elements-smart';
import {ReferralsAndBonusesSummarySmart} from '@youtoken/ui.surfaces-referral-program';
import {WalletsFeature} from '../../state';
import {NewReferralBadge} from '@youtoken/ui.surface-new-referral';
import {Platform} from 'react-native';

export const Summary: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {summaryCurrency, summaryCapitalFormatted} = WalletsFeature.use({});

  return (
    <Box flexDirection="row" justifyContent="space-between" {...boxProps}>
      <Total
        text={t('surface.wallets.header.portfolio_value')}
        currency={summaryCurrency}
        currencyAmount={summaryCapitalFormatted}
        width="45%"
      />
      <Box alignItems="flex-end" width="55%">
        <SummaryProfitOrRewardsSmart />
      </Box>
    </Box>
  );
});

export const SummaryProfitOrRewardsSmart: React.FC = observer(() => {
  const {shouldDisplayRewardAndBonuses, newReferralEnabled} =
    AuthMeResource.use({});
  const isWeb = Platform.OS === 'web';

  return (
    <>
      {shouldDisplayRewardAndBonuses && !newReferralEnabled && (
        <ReferralsAndBonusesSummarySmart />
      )}
      {isWeb && newReferralEnabled && <NewReferralBadge />}
    </>
  );
});
