import * as React from 'react';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {LogoColored, LogoColoredIconName} from '@youtoken/ui.icons';
import {getCoinName} from '@youtoken/ui.coin-utils';
import {Checkbox, Separator} from '@youtoken/ui.elements';
import {typeKeys} from '@youtoken/ui.resource-history-new';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  type TransactionType,
  type TickersFilter,
  type TypesFilter,
} from './types';
import {SectionHeader} from './components';

interface FiltersMenuProps {
  tickersFilter: TickersFilter;
  typesFilter: TypesFilter;
  onTickerClick: (ticker: string) => void;
  onTypeClick: (type: TransactionType) => void;
  onAllTypesClick: () => void;
  getTickerFormatted: (ticker: string) => string;
  showTitles?: boolean;
  testIdBase?: string;
}

export const FiltersMenu: React.FC<FiltersMenuProps> = ({
  tickersFilter,
  typesFilter,
  onTickerClick,
  onTypeClick,
  onAllTypesClick,
  getTickerFormatted,
  showTitles = true,
  testIdBase = '',
}) => {
  const {t} = useTranslation();

  const isAnyTypeSelected = Object.values(typesFilter).some(Boolean);

  return (
    <Box flex={1}>
      {Object.keys(typesFilter).length > 0 && (
        <>
          {showTitles && (
            <SectionHeader
              title={t('surface.wallets.account_stats.transactions')}
              testID={`${testIdBase}_FILTERS_TRANSACTIONS_HEADING`}
            />
          )}
          <Box flexDirection="row" flexWrap="wrap" px={24} pb={16}>
            <Button
              key="all"
              size="small"
              mr={8}
              mb={8}
              type={isAnyTypeSelected ? 'secondary' : 'primary'}
              onPress={onAllTypesClick}
              testID={`${testIdBase}_FILTERS_TRANSACTIONS_FILTER_ALL${
                !isAnyTypeSelected ? '_CHECKED' : ''
              }`}
            >
              {t('surface.wallets.account_stats.all')}
            </Button>

            {Object.entries(typesFilter).map(([type, checked]) => (
              <Button
                key={type}
                size="small"
                mr={8}
                mb={8}
                type={checked ? 'primary' : 'secondary'}
                onPress={() => onTypeClick(type as TransactionType)}
                testID={`${testIdBase}_FILTERS_TRANSACTIONS_FILTER_${type}${
                  checked ? '_CHECKED' : ''
                }`}
              >
                {/* @ts-ignore */}
                {t(typeKeys[type], {
                  defaultValue: type.toLowerCase(),
                })}
              </Button>
            ))}
          </Box>
          <Separator />
        </>
      )}

      {showTitles && (
        <SectionHeader
          title={t('surface.wallets.account_stats.your_assets')}
          testID={`${testIdBase}_FILTERS_ASSETS_HEADING`}
        />
      )}
      <Box flexDirection="column">
        {Object.entries(tickersFilter).map(([ticker, checked]) => {
          return (
            <TouchableBox
              px={24}
              height={48}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              backgroundColor={checked ? '$interactive-02' : '$ui-background'}
              key={ticker}
              onPress={() => onTickerClick(ticker)}
              testID={`${testIdBase}_FILTERS_TICKERS_FILTER_${ticker.toUpperCase()}${
                checked ? '_CHECKED' : ''
              }`}
            >
              <Box flexDirection="row" alignItems="center" flexGrow={1}>
                <Box mr={8}>
                  <LogoColored name={ticker as LogoColoredIconName} size={20} />
                </Box>
                <Text variant="$body-01" color="$text-01">
                  {ticker === 'bonus'
                    ? t('surface.wallets.account_stats.filters.deposit_bonuses')
                    : getCoinName(ticker)}
                </Text>
                <Text variant="$body-01" color="$text-03" ml={8}>
                  {getTickerFormatted(ticker)}
                </Text>
              </Box>

              <Checkbox
                value={checked}
                onChange={() => {
                  onTickerClick(ticker);
                }}
              />
            </TouchableBox>
          );
        })}
      </Box>
    </Box>
  );
};
