import * as React from 'react';
import {BundleTariffOverviewResource} from '@youtoken/ui.resource-bundles';
import {cell} from '@youtoken/ui.cell';
import {ModalDynamic, ModalDynamicProps} from '@youtoken/ui.modal';
import {
  type BundleDepositFormProps,
  getBundleDepositFormTitle,
  BundleDepositForm,
} from '../../forms';

type BundleDepositModalProps = BundleDepositFormProps &
  Omit<ModalDynamicProps, 'children'>;

export const BundleDepositModal = cell<BundleDepositModalProps>(
  ({id, onClose}) => {
    const {
      data: {
        tariff: {
          design: {title},
        },
      },
    } = BundleTariffOverviewResource.use({
      id,
    });

    return (
      <ModalDynamic
        isOpen
        title={getBundleDepositFormTitle(title)}
        onClose={onClose}
        shouldBeScrollable
      >
        <BundleDepositForm id={id} onClose={onClose} />
      </ModalDynamic>
    );
  }
);
