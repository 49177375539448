import * as React from 'react';
import {createText} from './createText';
import {type TextProps} from './createRestyledText';

export {createText};
export type {TextProps};

export const TextStyleContext = React.createContext<TextProps>({
  color: '$text-01',
});

export const useMergedTextStyle = ({color, variant, ...props}: TextProps) => {
  const contextProps = React.useContext(TextStyleContext);

  return React.useMemo(() => {
    if (!color && !variant) {
      return contextProps;
    }

    let newProps = {
      ...contextProps,
    };

    if (color) {
      newProps.color = color;
    }

    if (variant) {
      newProps.variant = variant;
    }

    return newProps;
  }, [color, variant, contextProps]);
};
