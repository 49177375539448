import * as React from 'react';
import {observer} from 'mobx-react';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {useForm} from '@youtoken/ui.form-elements';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useIsKeyboardVisible} from '@youtoken/ui.hooks';
import {Trans} from '@youtoken/ui.elements';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {CreateHODLFormClass} from '../../CreateHODLForm';
import {RolloverInfo} from './RolloverInfo';
import {ExpirationInfo} from './ExpirationInfo';
import {CollateralInfo} from './CollateralInfo';

export const FooterSection: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const isKeyboardVisible = useIsKeyboardVisible();

  const {
    form: {
      instance,
      isShort,
      calculating,
      calculatedData,
      hodlExpirationPeriod,
    },
    view: {
      needDeposit,
      isDayOffNow,
      tradingHoursStartDay,
      tradingHoursStartTime,
      isCloseOnlyMode,
      submitButtonIsDisabled,
      settlementFeePercentFormatted,
      showCollateral,
    },
  } = useForm<CreateHODLFormClass>();

  const [readyToSend, setReadyToSend] = React.useState(false);
  const [formData, setFormData] = React.useState({});

  const handleSubmit = React.useCallback((formData: MobxReactForm) => {
    setReadyToSend(true);
    setFormData(formData);
  }, []);

  const handleDeposit = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('DepositWizard', {});
  }, []);

  React.useEffect(() => {
    if (!calculating && readyToSend) {
      instance.onSubmit(formData);
      setReadyToSend(false);
    }
  }, [calculating, readyToSend, formData]);

  const startActionKey = isShort
    ? 'surface.hodls.item.direction.start_down'
    : 'surface.hodls.item.direction.start_up';

  const hasRolloverInfo = Boolean(
    calculatedData && settlementFeePercentFormatted
  );

  const hasExpirationPeriod = Boolean(hodlExpirationPeriod);

  if (needDeposit && !submitButtonIsDisabled) {
    return (
      <Box flexGrow={1} {...boxProps}>
        <Button
          icon="fiat_deposit"
          size="large"
          onPress={handleDeposit}
          testID="DEPOSIT_BUTTON"
        >
          {t('surface.hodls.hodl_form.footer.deposit_action')}
        </Button>
      </Box>
    );
  }

  return (
    <Box {...boxProps}>
      <Box flexDirection="row" alignItems="center" gap={24}>
        <Box>
          {showCollateral && <CollateralInfo />}
          {hasRolloverInfo && <RolloverInfo />}
          {hasExpirationPeriod && <ExpirationInfo />}
        </Box>

        <Box flexGrow={1} zIndex={-1}>
          <Button
            icon={isShort ? 'down' : 'up'}
            color={isShort ? 'danger' : 'success'}
            size="large"
            onPress={handleSubmit}
            loading={instance.submitting}
            disabled={submitButtonIsDisabled}
            testID="START_BUTTON"
          >
            {t(startActionKey)}
          </Button>
        </Box>
      </Box>

      {isDayOffNow && (
        <Trans
          i18nKey="surface.hodls.hodl_form.footer.trading_hours"
          style={{
            flexWrap: 'wrap',
            marginTop: 16,
          }}
          values={{
            dayOfWeek: tradingHoursStartDay,
            time: tradingHoursStartTime,
          }}
          components={{
            Strong: <Text variant="$body-01-high-accent" />,
          }}
          parent={props => (
            <Text
              variant="$body-02"
              color="$text-02"
              textAlign="center"
              {...props}
            />
          )}
          defaults="Trading will resume on <Strong>{{dayOfWeek}} at {{time}}</Strong> GMT"
        />
      )}
      {isCloseOnlyMode && (
        <Text variant="$body-02" color="$text-02" textAlign="center" mt={16}>
          {t('surface.hodls.hodl_form.footer.close_only_mode')}
        </Text>
      )}
    </Box>
  );
});
