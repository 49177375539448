import * as React from 'react';
import {Share, Platform} from 'react-native';
import {observer} from 'mobx-react';
import * as Clipboard from 'expo-clipboard';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {Icon} from '@youtoken/ui.icons';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {NewReferralOverviewResource} from '@youtoken/ui.resource-new-referral';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

type ItemProps = {
  title: string;
  text: string;
  type: 'code' | 'link';
};

const canShare = Platform.select({
  native: true,
  web: Boolean(navigator?.canShare) ?? false,
})!;

export const Item: React.FC<ItemProps & BoxProps> = observer(
  ({title, text, type, ...boxProps}) => {
    const {t} = useTranslation();

    const handleCopy = () => {
      Clipboard.setStringAsync(text).then(() => {
        DATA_LAYER.trackStrict(
          'referral-miner-referral-code-copied',
          type === 'code' ? {referralCode: text} : {referralLink: text}
        );
        LOCAL_NOTIFICATIONS.info({text: t('common.notifications.copied')});
      });
    };

    return (
      <TouchableBox
        flexDirection="row"
        justifyContent="space-between"
        flexWrap="wrap"
        rowGap={4}
        px={16}
        py={12}
        borderWidth={1}
        borderRadius={12}
        borderColor="$ui-01"
        pr={36}
        onPress={handleCopy}
        {...boxProps}
      >
        <Text variant="$body-02" color="$text-02" mr={6}>
          {title}
        </Text>
        <Box flexDirection="row" alignItems="center">
          <Text variant="$body-02" mr={4}>
            {text}
          </Text>
        </Box>

        <Box
          position="absolute"
          right={12}
          top={12}
          testID={`REFERRAL_COPY_${type.toUpperCase()}`}
        >
          <Icon name="copy" size={16} color="$text-02" />
        </Box>
      </TouchableBox>
    );
  }
);

export const InviteFriendsModalContent: React.FC<BoxProps> = observer(
  boxProps => {
    const {t} = useTranslation();

    const {
      data: {referralCode, referralLink},
    } = NewReferralOverviewResource.use({});

    const handleShare = () => {
      Share.share(
        {
          message: `${t('surface.new_referral.share_message')}\n\n${t(
            'surface.new_referral.share_message_p2'
          )} ${referralLink}`,
        },
        {
          dialogTitle: t('surface.new_referral.invite_friends.modal.action'),
        }
      ).then(() => {
        DATA_LAYER.trackStrict('referral-miner-referral-link-shared', {
          referralCode,
        });
      });
    };

    return (
      <Box {...boxProps}>
        <Box flex={1} justifyContent="space-between">
          <Box>
            <Item
              type="code"
              title={t('surface.new_referral.invite_friends.modal.code')}
              text={referralCode}
              mb={12}
            />
            <Item
              type="link"
              title={t('surface.new_referral.invite_friends.modal.link')}
              text={referralLink}
              mb={24}
            />
          </Box>
          {canShare && (
            <Button
              size="large"
              onPress={handleShare}
              testID="REFERRAL_SHARE_BUTTON"
            >
              {t('surface.new_referral.invite_friends.modal.action')}
            </Button>
          )}
        </Box>
      </Box>
    );
  }
);
