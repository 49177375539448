import {useMemo} from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import type {BaseIconName} from '@youtoken/ui.icons';
import {
  VerificationItemStatus,
  VerificationItemAccountCode,
  verificationCodeActions,
} from '@youtoken/ui.resource-verification';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import type {VerificationItemProps} from './types';

export const useVerificationItem = ({
  status,
  isCurrent,
  code,
  isFileDownloadable,
  isBusiness,
}: {
  status: VerificationItemStatus;
  code: VerificationItemAccountCode;
  isCurrent: boolean;
  isFileDownloadable?: boolean;
  isBusiness?: boolean;
}): VerificationItemProps => {
  const {t} = useTranslation();

  const title = useMemo(() => {
    if (isBusiness) {
      return t('surface.more.verification_tier.business');
    }

    if (status === VerificationItemStatus.IN_PROGRESS) {
      return t('surface.more.verification_tier.in_progress');
    }
    if (
      status === VerificationItemStatus.SEND ||
      status === VerificationItemStatus.MANUAL_REVIEW
    ) {
      return t('surface.more.verification_tier.under_review');
    }
    if (
      status === VerificationItemStatus.ADDITIONAL_DOCS_REQUIRED ||
      status === VerificationItemStatus.DENY ||
      status === VerificationItemStatus.NOT_FINALIZED
    ) {
      return t('surface.more.verification_tier.failed');
    }

    return {
      [VerificationItemAccountCode.IDENTITY]: t(
        'surface.more.verification_tier.identity'
      ),
      [VerificationItemAccountCode.FORM_A]: t(
        'surface.more.verification_tier.form_a.title'
      ),
      [VerificationItemAccountCode.ADDRESS]: t(
        'surface.more.verification_tier.address'
      ),
    }[code];
  }, [t, status, isBusiness, code]);

  const description = useMemo(() => {
    if (isBusiness) {
      return (
        {
          [VerificationItemStatus.NONE]: t(
            'surface.more.verification_tier.business.description'
          ),
          [VerificationItemStatus.IN_PROGRESS]: t(
            'surface.more.verification_tier.business.description'
          ),
          [VerificationItemStatus.SEND]: t(
            'surface.verification_flow.item.waiting'
          ),
          [VerificationItemStatus.MANUAL_REVIEW]: t(
            'surface.verification_flow.item.waiting'
          ),
          [VerificationItemStatus.DENY]: t(
            'surface.onboarding_widget.action.verification_failed'
          ),
          [VerificationItemStatus.ADDITIONAL_DOCS_REQUIRED]: t(
            'surface.onboarding_widget.action.verification_failed'
          ),
        } as {[key in VerificationItemStatus]?: string}
      )[status]!;
    }

    if (status === VerificationItemStatus.SEND) {
      return t('surface.more.verification_tier.duration', {
        duration: code === VerificationItemAccountCode.IDENTITY ? 5 : 10,
      });
    }

    if (status === VerificationItemStatus.DENY) {
      return t('surface.more.verification_tier.support');
    }

    if (status === VerificationItemStatus.MANUAL_REVIEW) {
      return t('surface.more.verification_tier.manual_review');
    }

    if (status === VerificationItemStatus.ADDITIONAL_DOCS_REQUIRED) {
      return t('surface.more.verification_tier.try_again');
    }

    if (code === VerificationItemAccountCode.FORM_A && isFileDownloadable) {
      return t('surface.profile.verification.tier.contract_link.text');
    }

    return {
      [VerificationItemAccountCode.IDENTITY]: t(
        'surface.profile.verification.tier.description.identity'
      ),
      [VerificationItemAccountCode.FORM_A]: t(
        'surface.more.verification_tier.form_a.description'
      ),
      [VerificationItemAccountCode.ADDRESS]: t(
        'surface.profile.verification.tier.description.address'
      ),
    }[code];
  }, [t, code, isFileDownloadable, status]);

  const actionIcon = useMemo(() => {
    if (status === VerificationItemStatus.SEND) {
      return 'pending' as BaseIconName;
    }
    if (status === VerificationItemStatus.ACCEPT) {
      return 'verified' as BaseIconName;
    }
    if (status === VerificationItemStatus.DENY) {
      return 'chevron_right' as BaseIconName;
    }
    if (isCurrent) {
      return 'chevron_right' as BaseIconName;
    }
    return 'lock' as BaseIconName;
  }, [status, isCurrent]);

  const backgroundColor = useMemo(() => {
    if (
      [
        VerificationItemStatus.SEND,
        VerificationItemStatus.MANUAL_REVIEW,
      ].includes(status)
    ) {
      return '$attention-02';
    }
    if (status === VerificationItemStatus.ACCEPT) {
      return '$ui-background';
    }
    if (
      status === VerificationItemStatus.DENY ||
      status === VerificationItemStatus.ADDITIONAL_DOCS_REQUIRED
    ) {
      return '$danger-02';
    }
    if (isCurrent) {
      return '$interactive-01';
    }
    return '$ui-background';
  }, [status, isCurrent]);

  const iconColor = useMemo(() => {
    if (
      [
        VerificationItemStatus.SEND,
        VerificationItemStatus.MANUAL_REVIEW,
      ].includes(status)
    ) {
      return '$attention-03';
    }
    if (status === VerificationItemStatus.ACCEPT) {
      return '$success-03';
    }
    if (
      status === VerificationItemStatus.DENY ||
      status === VerificationItemStatus.ADDITIONAL_DOCS_REQUIRED
    ) {
      return '$danger-03';
    }
    if (isCurrent) {
      return '$fill-03';
    }
    return '$ui-01';
  }, [status, isCurrent]);

  const textColor = useMemo(() => {
    if (
      [
        VerificationItemStatus.SEND,
        VerificationItemStatus.MANUAL_REVIEW,
      ].includes(status)
    ) {
      return '$attention-01';
    }
    if (status === VerificationItemStatus.ACCEPT) {
      return '$success-01';
    }
    if (
      status === VerificationItemStatus.DENY ||
      status === VerificationItemStatus.ADDITIONAL_DOCS_REQUIRED
    ) {
      return '$danger-04';
    }
    if (isCurrent) {
      return '$text-04';
    }
    return '$text-03';
  }, [status, isCurrent]);

  const descriptionColor = useMemo(() => {
    if (code === VerificationItemAccountCode.FORM_A && isFileDownloadable) {
      return '$interactive-01';
    }
    return textColor;
  }, [code, isFileDownloadable, textColor]);

  const icon = {
    [VerificationItemAccountCode.IDENTITY]: 'id_verification' as BaseIconName,
    [VerificationItemAccountCode.FORM_A]: 'sign' as BaseIconName,
    [VerificationItemAccountCode.ADDRESS]: 'doc_address' as BaseIconName,
  }[code];

  const linkUp = code !== VerificationItemAccountCode.IDENTITY && !isCurrent;

  const linkDown = code !== VerificationItemAccountCode.ADDRESS && !isCurrent;

  const onPress = useMemo(() => {
    if (code === VerificationItemAccountCode.FORM_A && isFileDownloadable) {
      return () =>
        SHARED_ROUTER_SERVICE.navigate('Agreement', {
          url: `/v3/kyc/form-a`,
        });
    }
    if (!isCurrent) {
      return;
    }

    return verificationCodeActions[code];
  }, [code, isFileDownloadable, isCurrent]);

  return {
    backgroundColor,
    iconColor,
    textColor,
    descriptionColor,
    icon,
    title,
    description,
    actionIcon,
    onPress,
    linkUp,
    linkDown,
  };
};
