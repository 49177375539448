import Big, {BigSource} from 'big.js';
import {formatPercentTillPrecision} from '@youtoken/ui.formatting-utils';
import {warning} from '@youtoken/ui.utils';
import {getSettlementFeeSign} from './getSettlementFeeSign';

export const getSettlementFeePercentFormatted = (
  settlementFee?: BigSource,
  showPlus: boolean = true
) => {
  if (settlementFee === undefined || settlementFee === '') {
    return '';
  }

  try {
    const settlementFeeBig = Big(settlementFee);
    const settlementFeeSign = getSettlementFeeSign(settlementFee, showPlus);

    const amount = formatPercentTillPrecision(settlementFeeBig.abs(), 6);

    return `${settlementFeeSign}${amount}`;
  } catch (e) {
    warning(
      false,
      `getSettlementFeePercentFormatted: cannot convert $settlementFee to big number`,
      {},
      {
        value: settlementFee,
      }
    );
    return '';
  }
};
