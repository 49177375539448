import * as React from 'react';
import {observer} from 'mobx-react';
import {TouchableBox, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('MinerHowItWorks');
};

export const HowItWorksModalTrigger: React.FC<BoxProps> = observer(boxProps => {
  return (
    <>
      <TouchableBox
        width={36}
        height={36}
        justifyContent="center"
        alignItems="center"
        onPress={handlePress}
        {...boxProps}
      >
        <Icon name="question" color="$text-03" />
      </TouchableBox>
    </>
  );
});
