import * as React from 'react';
import {FlatList} from 'react-native';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {BundleTariffOverviewResourceArgs} from '@youtoken/ui.resource-bundles';
import {cell} from '@youtoken/ui.cell';
import {WebPageWrapper} from '@youtoken/ui.screen-components';
import {renderItem, keyExtractor} from './utils';
import {useData} from './hooks';

export const BundleSurface: React.FC<BundleTariffOverviewResourceArgs> = cell(
  ({id}) => {
    const items = useData(id);

    return (
      <WebPageWrapper
        hasBackButton
        windowTitle="Bundles"
        onBackPress={SHARED_ROUTER_SERVICE.goBack}
      >
        <FlatList
          data={items}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
        />
      </WebPageWrapper>
    );
  }
);
