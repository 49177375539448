import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface FiltersFooterProps {
  onApply: () => void;
  onClear: () => void;
  isAnyFilterSelected: boolean;
  testIdBase?: string;
}

export const FiltersFooter: React.FC<FiltersFooterProps> = ({
  onApply,
  onClear,
  isAnyFilterSelected,
  testIdBase = '',
}) => {
  const {t} = useTranslation();

  return (
    <Box flexDirection="row" justifyContent="space-between" py={24} px={12}>
      <Box mx={12} flex={1}>
        {isAnyFilterSelected && (
          <Button
            size="large"
            type="secondary"
            onPress={onClear}
            testID={`${testIdBase}_FILTERS_BUTTON_CLEAR`}
          >
            {t('surface.wallets.account_stats.clear')}
          </Button>
        )}
      </Box>
      <Box mx={12} flex={1}>
        <Button
          size="large"
          type="primary"
          onPress={onApply}
          testID={`${testIdBase}_FILTERS_BUTTON_APPLY`}
        >
          {t('surface.wallets.account_stats.apply')}
        </Button>
      </Box>
    </Box>
  );
};
